import React from "react";
import classNames from "classnames";
import { getCookie } from "helpers/helpers";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

function LandingNavItem({ link, name, id, subOption, icon }) {
  const { t } = useTranslation("global");
  const language = getCookie("language");

  return (
    <li
      className={classNames({
        "nav-menu__item": true,
      })}
    >
      {id === "login" ? (
        <NavLink to={link} state={{ fromLanding: true }}>
          <button className="btn-login no-arrow">{t(name)}</button>
        </NavLink>
      ) : (
        <NavLink
          to={link}
          state={{ fromLanding: id === "signup" }}
          className={({ isActive }) => {
            let className = "";

            if (isActive || (!!subOption && link.includes(subOption))) {
              className += "active-menu-item";
            }

            if (language === "fr") {
              className += " active-menu-item-fr";
            } else if (language === "ru") {
              className += " active-menu-item-fr";
            }

            return className;
          }}
        >
          {t(name)}
        </NavLink>
      )}
    </li>
  );
}

export default LandingNavItem;
