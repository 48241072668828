import crypto from "crypto";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { i18nGetData } from "../i18n";
import { cipher, genIV, genSecret, hash } from "../lib/utils/encryption";
import { adminSignUp, survivorSignUp } from "services/users";
import { validationsFormSignUp } from "helpers/validations";

export const useSignUp = (isAdmin, form, setForm) => {
  const [errors, setErrors] = useState({});
  let navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]:
        name === "name" || name === "last_name"
          ? value
          : name === "terms_and_conditions"
          ? !form.terms_and_conditions
          : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors(validationsFormSignUp(form, isAdmin));

    if (Object.keys(validationsFormSignUp(form, isAdmin)).length === 0) {
      // randomly generated content key
      const content_key = genSecret();
      const salt = genSecret();

      crypto.pbkdf2(form.password, salt, 2000, 32, "sha512", () => {
        // increased entropy version of password using salt
        // const password_zero_key = derivedKey.toString('base64');
        // symmetrically encrypted version of content key (using 0key)
        // const pass_key = cipher(password_zero_key, content_key);
        // generate IV
        const iv = genIV();

        const recovery_key = genSecret();
        const user = {
          first_name:
            form.first_name !== "" ? cipher(content_key, form.first_name) : "",
          last_name:
            form.last_name !== "" ? cipher(content_key, form.last_name) : "",
          hashed_email: hash(form.email),
          password: hash(form.password),
          recovery_key: recovery_key,
          email: form.email,
          salt: salt,
          p_content_key: content_key,
          iv: iv,
          setting: {
            two_factor_authentication: "always",
          },
        };

        (async () => {
          Promise.resolve(
            isAdmin ? adminSignUp(user) : survivorSignUp(user)
          )
            .then((res) => {
              if (res.status === 200) {
                navigate("/verify-account", {
                  state: {
                    hashed_email: hash(form.email),
                    email: form.email,
                    r_key: recovery_key,
                  },
                });
              }
            })
            .catch(() => {
              setErrors({
                error_response: `${i18nGetData(
                  "landing form create-account error-occurred"
                )}`,
              });
            });
        })();
      });
    } else {
      return;
    }
  };

  return {
    errors,
    handleChange,
    handleSubmit,
  };
};
