import React from "react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import CloseDialog from "./CloseDialog";
import HeaderForm from "components/HeaderForm";

function TimeOutConnection({ setTimeOutModal }) {
  const { t, i18n } = useTranslation("global");

  const handleClose = () => {
    setTimeOutModal(false);
  };

  return (
    <>
      <CloseDialog closeVerifyModal isFloatButton onClose={handleClose} />
      <div className="container-modal-timeout">
        <HeaderForm
          title={t("platform connection-timeout title")}
          description={t("platform connection-timeout description")}
          lang={i18n.language}
        />
        <div className="button-container">
          <Button
            text={t("platform delete-account-successful button")}
            class="btn-delete-account small"
            onClick={handleClose}
          />
        </div>
      </div>
    </>
  );
}

export default TimeOutConnection;

