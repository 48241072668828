import React, { Fragment } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import UploadFile from "../UploadFile";

function AnswerItemRadioButton({
  form,
  handleChange,
  handleChangeRadio,
  fieldName,
  subfieldContent,
  page,
}) {
  const { t, i18n } = useTranslation("global");

  const childComponent = (subField, index) => {
    switch (subField.type) {
      case "file":
        return <UploadFile form={form} />;
      case "textarea":
        return (
          <textarea
            type="text"
            placeholder={subField.placeholder}
            rows="10"
            name={subField.fieldName}
            value={form[subField.fieldName]}
            onChange={handleChange}
          />
        );
      default:
        return (
          <input
            type={subField.type}
            className="subinput-field"
            placeholder={subField.placeholder}
            name={subField.fieldName}
            value={form[subField.fieldName]}
            onChange={handleChange}
            key={subField + "-" + index}
          />
        );
    }
  };

  return (
    <div
      className={classNames({
        "radio-options-column": true,
        "witnesses-options": parseInt(page) === 3,
        "impact-options": parseInt(page) === 4,
        "radio-button-container-ar": i18n.language === "ar",
        
      })}
    >
      <div className="answer-row" key={fieldName + "r1"}>
        <input
          type="radio"
          id={fieldName + "yes"}
          name={fieldName}
          value="1"
          checked={form[fieldName] === true}
          onChange={handleChangeRadio}
        />
        <label htmlFor={fieldName + "yes"}>
          {t("your-incidents share-incident modal-agree-option")}
        </label>
      </div>
      <div className="answer-row" key={fieldName + "r2"}>
        <input
          type="radio"
          id={fieldName + "no"}
          name={fieldName}
          value="0"
          checked={form[fieldName] === false}
          onChange={handleChangeRadio}
        />
        <label htmlFor={fieldName + "no"}>
          {t("your-incidents share-incident modal-not-agree-option")}
        </label>
      </div>
      {form[fieldName] === true &&
        subfieldContent.length > 0 &&
        subfieldContent.map((subField, index) => {
          return (
            <Fragment key={subField.fieldName + "--" + index}>
              {childComponent(subField, index)}
            </Fragment>
          );
        })}
    </div>
  );
}

export default AnswerItemRadioButton;
