import { REFRESH_TOKEN_LIMIT, TIMEOUT_LIMIT } from "helpers/constants";

class InactivityTimer {
  constructor({
    onRefreshToken,
    logout,
    setShowInactivityModal,
    closeAllModals,
  }) {
    this.logout = logout;
    this.onRefreshToken = onRefreshToken;
    this.setShowInactivityModal = setShowInactivityModal;
    this.closeAllModals = closeAllModals;
    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateExpiredTime();
    this.updateRefreshExpiredTime();

    this.interval = setInterval(() => {
      const expiredTime = parseInt(
        localStorage.getItem("_expiredTime") || 0,
        10
      );
      // 300000ms = 5min
      if((Date.now() + 300000) >= expiredTime ){
        this.closeAllModals();
        this.setShowInactivityModal(true);
      }

      if (expiredTime < Date.now()) {
        this.cleanUp();
        this.logout();
      } else {
        const refreshExpiredTime = parseInt(
          localStorage.getItem("_refreshExpiredTime") || 0,
          10
        );

        if (refreshExpiredTime < Date.now()) {
          this.updateRefreshExpiredTime();
          this.onRefreshToken();
        }
      }
    }, 1000);
  }

  updateExpiredTime(e) {
    const comeFrom = e?.target?.id;
    if (comeFrom !== "inactivity-modal") {
      localStorage.setItem("_expiredTime", Date.now() + TIMEOUT_LIMIT * 1000);
    }
  }

  updateRefreshExpiredTime() {
    localStorage.setItem(
      "_refreshExpiredTime",
      Date.now() + REFRESH_TOKEN_LIMIT * 1000
    );
  }

  tracker() {
    window.addEventListener("click", this.eventHandler);
    window.addEventListener("mousedown", this.eventHandler);
    window.addEventListener("keydown", this.eventHandler);
  }

  cleanUp() {
    clearInterval(this.interval);
    localStorage.removeItem("_expiredTime");
    localStorage.removeItem("_refreshExpiredTime");
    window.removeEventListener("click", this.eventHandler);
    window.removeEventListener("mousedown", this.eventHandler);
    window.removeEventListener("keydown", this.eventHandler);
  }
}

export default InactivityTimer;

