import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

function LandingNavSubItem({ link, name, submenu }) {
  const { t } = useTranslation("global");

  return (
    <li
      className={classNames({
        "nav-menu__item": true,
      })}
    >
      <NavLink
        className={({ isActive }) =>
          isActive ? "active-menu-sub-item" : undefined
        }
        to={link}
      >
        {t(name)}
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          isActive ? "active-menu-sub-item" : undefined
        }
        to={link}
      >
        {t(submenu)}
      </NavLink>
    </li>
  );
}

export default LandingNavSubItem;
