import React, { useEffect, useState } from "react";
import classNames from "classnames";
import html2pdf from "html2pdf.js";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { io } from "socket.io-client";
import RequireAuth from "./components/RequireAuth";
import ModalContext from "./context/ModalContext";
import SubModalContext from "./context/SubModalContext";
import { UserSettingsProvider } from "./context/UserSettingContext";
import { BASE_URL } from "./helpers/constants";
import { getCookie, getRouteByKey } from "./helpers/helpers";
import ErrorPage from "./pages/landing/ErrorPage";
import AdminDashboard from "./pages/platform/AdminDashboard";
import UserDashboard from "./pages/platform/UserDashboard";
import routes from "./routes";
require("dotenv").config();

const socket = io.connect(BASE_URL, { transports: ["polling"] });

function App() {
  const [modal, setModal] = useState(false);
  const [modalChat, setModalChat] = useState(false);
  const [subModal, setSubModal] = useState(false);
  const [formStep, setFormStep] = useState(0);
  const [username, setUsername] = useState("");
  const [room, setRoom] = useState("");
  const { i18n } = useTranslation("global");
  const language = getCookie("language");

  useEffect(() => {
    if (i18n.language !== language) {
      i18n.changeLanguage(language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const getDashboard = (key, route) => {
    if (key === "dashboard") {
      return (
        <Route
          path={route}
          element={
            <UserDashboard
              socket={socket}
              username={username}
              setUsername={setUsername}
              room={room}
              setRoom={setRoom}
              html2pdf={html2pdf}
              setFormStep={setFormStep}
              modalChat={modalChat}
              setModalChat={setModalChat}
            />
          }
        />
      );
    } else {
      return (
        <Route
          path={route}
          element={
            <AdminDashboard
              socket={socket}
              username={username}
              setUsername={setUsername}
              room={room}
              setRoom={setRoom}
              html2pdf={html2pdf}
              modalChat={modalChat}
              setModalChat={setModalChat}
            />
          }
        />
      );
    }
  };

  const getRoutes = (allRoutes) => {
    return allRoutes.map((route) => {
      let routeComponent;

      if (route.route) {
        routeComponent = route?.isPrivate ? (
          <Route
            element={<RequireAuth allowedRoles={["survivor", "admin"]} />}
            key={route.key}
          >
            {route.key === "dashboard" || route.key === "admin-dashboard" ? (
              getDashboard(route.key, route.route)
            ) : (
              <Route path={route.route} element={route.component} />
            )}
          </Route>
        ) : (
          <Route path={route.route} element={route.component} key={route.key} />
        );

        return routeComponent;
      }

      return null;
    });
  };

  return (
    <ModalContext.Provider value={[modal, setModal]}>
      <SubModalContext.Provider value={[subModal, setSubModal]}>
        <UserSettingsProvider>
          <div
            className={classNames({
              "app-unhcr": true,
              "app-unhcr--chat-open": modalChat,
              "hide-overflow-y": language === "ar",
              "expand-shorter-views":
                formStep === 3 || formStep === 4 || formStep === 5,
            })}
          >
            <BrowserRouter>
              <Routes>
                <Route
                  path="/"
                  exact
                  element={<Navigate to={getRouteByKey("home").route} />}
                />
                {getRoutes(routes)}
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </BrowserRouter>
          </div>
        </UserSettingsProvider>
      </SubModalContext.Provider>
    </ModalContext.Provider>
  );
}

export default App;
