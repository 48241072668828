import React, { Fragment, useEffect, useState } from "react";
import { getReviewData } from "adapters/reviewInfo";
import downArrow from "assets/images/down-arrow-dropdown.svg";
import downloadIcon from "assets/images/download-icon.svg";
import upArrow from "assets/images/up-arrow-dropdown.svg";
import classNames from "classnames";
import Button from "components/Button";
import { incidentStatus } from "helpers/constants";
import { decipher } from "lib/utils/encryption";
import moment from "moment";
import "moment/locale/ar";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/ru";
import "moment/locale/zh-cn";
import { useTranslation } from "react-i18next";

function Review({
  form,
  errors,
  view = null,
  getIncidents,
  setSubModalContent,
  setSubModalView,
  handleBackToIncidentList,
  setSelectedFormId,
}) {
  const { t, i18n } = useTranslation("global");

  if (i18n.language === "ch") {
    moment.locale("zh-cn");
  } else {
    moment.locale(i18n.language);
  }

  const [selected, setSelected] = useState(null);
  const [reviewData, setReviewData] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }

    setSelected(i);
  };

  useEffect(() => {
    setReviewData(getReviewData(form));
    setErrorMessages(
      Object.keys(errors).map(function (key) {
        return errors[key];
      })
    );
  }, [form, errors]);

  const getDateAnswer = (date, timeOfIncident) => {
    // validate if has date of incidents
    if (date) {
      // validate if it's a range date
      if (date?.includes("-")) {
        const dateBetween = t(
          `${t("review date-between")} ${moment(date.split("-")[0]).format(
            "MMMM Do, YYYY"
          )} - ${moment(date.split("-")[1]).format("MMMM Do, YYYY")}`
        );
        // return date range with time of incident if exits
        return `${dateBetween}${timeOfIncident ?? ""}`;
      } else {
        // return date of incidents with time of incident if exits
        return `${moment(date).format("MMMM Do, YYYY")}${timeOfIncident ?? ""}`;
      }
    } else if (timeOfIncident) {
      // return time of incident
      return timeOfIncident.split(",")[1];
    }

    return "";
  };

  const getInfo = (item) => {
    switch (item.type) {
      case "list":
        return (
          <Fragment>
            {item.items.map((list, p) => (
              <div className="content-item" key={p}>
                {list.content.map((p, count) => (
                  <div
                    className="content-row subfield"
                    key={count}
                    lang={i18n.language}
                  >
                    <span>
                      <strong>{p.question}: </strong>
                    </span>
                    <span>{p.answer}</span>
                  </div>
                ))}
              </div>
            ))}
          </Fragment>
        );
      case "sub-fields":
        return (
          <Fragment>
            {item.content.map((c, i) => (
              <Fragment>
                <div
                  className="content-row"
                  key={"row" + i}
                  lang={i18n.language}
                >
                  <span>
                    <strong>{c.question}: </strong>
                  </span>
                  <span>{c.answer}</span>
                </div>
                {c?.subfield && c?.subfield.length > 0 ? (
                  <Fragment>
                    {c.subfield.map((list, p) => {
                      return (
                        <div className="content-item subfield" key={"sub" + i}>
                          {list.content.map((p, count) => (
                            <div
                              className="content-row"
                              key={count}
                              lang={i18n.language}
                            >
                              <span>
                                <strong>{p.question}: </strong>
                              </span>
                              <span>{p.answer}</span>
                            </div>
                          ))}
                        </div>
                      );
                    })}
                  </Fragment>
                ) : (
                  <div key={"none" + i}></div>
                )}
              </Fragment>
            ))}
          </Fragment>
        );
      case "docs":
        return (
          <Fragment>
            <div className="content-row subfield" lang={i18n.language}>
              <span>
                <strong>{item.content[0].question}: </strong>
              </span>
              <span>{item.content[0].answer}</span>
            </div>
            {item.content[0].docs ? (
              <div className="content-row subdocs" lang={i18n.language}>
                {item.content[0].docs.length &&
                  item.content[0].docs.map((d, index) => (
                    <a href={d.url}>
                      <span>{`${t(
                        "incident-form documentation document-name"
                      )} ${index + 1}`}</span>
                      <img src={downloadIcon} alt="download-icon" />
                    </a>
                  ))}
              </div>
            ) : null}
          </Fragment>
        );
      default:
        return (
          <Fragment>
            {item.content.map((c, i) => (
              <div className="content-row" key={i} lang={i18n.language}>
                <span>
                  <strong>{c.question}: </strong>
                </span>
                <span>
                  {c?.type === "date"
                    ? getDateAnswer(c.answer, c?.answer2)
                    : c.answer}
                </span>
              </div>
            ))}
          </Fragment>
        );
    }
  };

  const getIncidentStatusDate = (form) => {
    const status =
      form.status === incidentStatus.IN_DRAFT.id
        ? t(incidentStatus.IN_DRAFT.value)
        : form.status === incidentStatus.SUBMITTED.id ||
          form.status === incidentStatus.SHARED.id
        ? t(incidentStatus.SUBMITTED.value)
        : "";

    return `${status} ${moment(form.created_at).format("MMMM Do, YYYY")} ${
      view === "admin" && !form.isAnonymous
        ? `By ${
            form.survivor?.first_name
              ? decipher(form.survivor.p_content_key, form.survivor.first_name)
              : "-"
          }  ${
            form.survivor?.last_name
              ? decipher(form.survivor.p_content_key, form.survivor.last_name)
              : "-"
          }`
        : form.isAnonymous
        ? "Anonymously"
        : ""
    }`;
  };

  return (
    <Fragment>
      {!form.isShareable && view !== "admin" && view !== "survivor" && (
        <div className="review-header-notification">
          <div
            className="review-header-notification__title"
            lang={i18n.language}
          >
            <h4>{t("incident-form review message-top")}</h4>
          </div>
          <div
            className="review-header-notification__content"
            lang={i18n.language}
          >
            {t("incident-form review message-review-details")}
          </div>
        </div>
      )}

      {(form.status === incidentStatus.SUBMITTED.id ||
        form.status === incidentStatus.SHARED.id) &&
        view !== "admin" && (
          <div
            className={classNames({
              "review-header-form-shared": true,
              "ar-letter-spacing": i18n.language === "ar",
            })}
          >
            <div className="item-state-review">
              <span></span>
              <div className="item-state-review__text" lang={i18n.language}>
                {t("incident-form re-open report-submitted-message")}
              </div>
            </div>
            <div className="item-state-review share-report-option">
              <span
                className={classNames({
                  "is-shared": form.status === incidentStatus.SHARED.id,
                })}
              ></span>
              <div className="item-state-review__text" lang={i18n.language}>
                {form.status === incidentStatus.SHARED.id ? (
                  <div className="item-state-review__text" lang={i18n.language}>
                    {t("incident-form re-open shared-message")}
                  </div>
                ) : (
                  <Button
                    text={t("incident-form re-open btn-share-report")}
                    className={classNames({
                      "btn-share-report": true,
                      "is-disabled": form.status === incidentStatus.SHARED.id,
                      "ar-letter-spacing": i18n.language === "ar"
                    })}
                    onClick={(e) => {
                      e.preventDefault();
                      if (form.status === incidentStatus.SHARED.id) return;
                      getIncidents();
                      setSubModalContent("share-incident");
                      setSubModalView(true);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      <div
        className={classNames({
          "review-main-container": true,
          "ar-letter-spacing": i18n.language === "ar",
        })}
      >
        <div
          className={`review-options ${
            view === "review" ? "review-options-2" : ""
          }`}
        >
          {view !== "review" && (
            <Button
              class="btn-back-incidentlist left-arrow"
              text={t("incident-form re-open back-button")}
              onClick={handleBackToIncidentList}
            />
          )}

          <Button
            class="btn-download-pdf"
            text={t("incident-form re-open download-button")}
            onClick={(e) => {
              setSelectedFormId(form);
              setSubModalContent("download-incident");
              setSubModalView(true);
            }}
          />
        </div>
        <div className="review-header">
          <h3 className="review-header__title" lang={i18n.language}>
            {t("incident-form re-open title-incident-form")}:{" "}
            {form.incident_type}
          </h3>
          <span className="review-header__date-submitted" lang={i18n.language}>
            {getIncidentStatusDate(form)}
          </span>
        </div>
        <div className="review-accordion">
          {errorMessages.length > 0 && (
            <div className="review-errors" lang={i18n.language}>
              <span className="error-message">
                <strong>
                  {t("incident-form re-open some-fields-are-required")}
                </strong>
              </span>
              {errorMessages.map((e) => (
                <span className="error-message">{e}</span>
              ))}
            </div>
          )}
          {reviewData &&
            reviewData.map((item, i) => (
              <div className="item" key={i}>
                <div
                  className="title"
                  onClick={() => {
                    toggle(i);
                  }}
                  lang={i18n.language}
                >
                  <img src={selected === i ? upArrow : downArrow} alt="arrow" />
                  {item.title}
                </div>
                <div
                  className={classNames({
                    "content selected": selected === i,
                    content: true,
                    "content-ar": i18n.language === "ar",
                  })}
                  lang={i18n.language}
                >
                  {getInfo(item)}
                </div>
              </div>
            ))}
        </div>
      </div>
    </Fragment>
  );
}

export default Review;
