import {
  incidentFormJSON,
  incidentStatus,
  incidentView,
  perpetratorFormJSON,
} from "helpers/constants";
import { getCookieData } from "helpers/helpers";
import { validationsFormIncident, validatePerpetratorsList } from "helpers/validations";
import moment from "moment";
import "moment/locale/ar";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/ru";
import "moment/locale/zh-cn";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteFileOfIncident } from "services/files";
import { deletePerpetrator, upsertIncident } from "services/incidents";

export const useIncidentForm = (
  selectedIncident,
  statusNavigationButtons,
  selectedIncidentView,
  setSelectedIncidentView,
  setPageIncident,
  pageIncident,
  setModalSubmitContent,
  setSelectedFormId,
  getIncidents
) => {
  const [form, setForm] = useState(
    selectedIncident?.id ? selectedIncident : incidentFormJSON
  );

  const [perpetratorsList, setPerpetratorList] = useState(
    selectedIncident?.id && selectedIncident?.perpetrators
      ? selectedIncident.perpetrators
      : []
  );
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [filesOfIncident, setFilesOfIncident] = useState(
    selectedIncident?.id &&
      selectedIncident?.files &&
      selectedIncident?.files?.length > 0
      ? selectedIncident.files
      : []
  );
  // Remove all related witness and people on JXUI-714

  const { i18n } = useTranslation("global");

  const token = getCookieData("auth", { path: "/" });

  useEffect(() => {
    if (i18n.language === "ch") {
      moment.locale("zh-cn");
    } else {
      moment.locale(i18n.language);
    }

    setErrors(validationsFormIncident(form, perpetratorsList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const startDateValidation = (form) =>
    form.date === ""
      ? null
      : new Date(form.date.includes("-") ? form.date.split("-")[0] : form.date);

  const endDateValidation = (form) =>
    form.date === ""
      ? null
      : form.date.includes("-")
      ? new Date(form.date.split("-")[1])
      : null;

  const startDateTimeValidation = (form) =>
    form.date === ""
      ? null
      : !form.time_of_incident
      ? null
      : new Date(
          form.date.includes("-")
            ? [form.date.split("-")[0], form.time_of_incident]
            : [form.date, form.time_of_incident]
        );

  const [startDate, setStartDate] = useState(
    startDateValidation(
      selectedIncident?.id ? selectedIncident : incidentFormJSON
    )
  );

  const [endDate, setEndDate] = useState(
    endDateValidation(
      selectedIncident?.id ? selectedIncident : incidentFormJSON
    )
  );

  const [startDateTime, setStartDateTime] = useState(
    startDateTimeValidation(
      selectedIncident?.id ? selectedIncident : incidentFormJSON
    )
  );

  const [timeStart, setTimeStart] = useState("");
  const timeEnd = new Date();

  useEffect(() => {
    if (selectedIncidentView === incidentView.CREATE_INCIDENT) {
      setLoading(true);

      const newIncident = {
        ...form,
        status: incidentStatus.IN_DRAFT.id,
        form_step: 1,
        perpetrators: [perpetratorFormJSON],
      };

      setPageIncident(1);
      handleSubmit(newIncident, "new_incident");
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIncidentView]);

  useEffect(() => {
    setErrors(validationsFormIncident(form, perpetratorsList));
  }, [form, perpetratorsList, pageIncident]);

  const deleteUploadedFiles = (files) => {
    if (files.length > 0) {
      files.forEach((item) => {
        Promise.resolve(
          deleteFileOfIncident({
            token: token.accessToken,
            data: { file_id: item.id },
          })
        )
          .then(() => {})
          .catch((error) => {
            console.log("err", error);
          })
          .finally(() => {
            //final action
          });
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleChangePerpetrator = (e, i) => {
    const { name, value } = e.target;
    let values = [...perpetratorsList];
    const keyName = name.split("-");
    values[i] = { ...values[i], [keyName[0]]: value };
    setPerpetratorList(values);

    setForm({ ...form, perpetrators: values });
  };

  const handleRemovePerpetrator = (perpetrator, i) => {
    if (perpetrator.id) {
      setLoading(true);
      Promise.resolve(
        deletePerpetrator({
          token: token.accessToken,
          perpetrator,
        })
      )
        .then(() => {
          perpetratorsList.splice(i, 1);
          if (perpetratorsList.length > 0) {
            setPerpetratorList(perpetratorsList);
            setForm({ ...form, perpetrators: perpetratorsList });
          } else {
            setPerpetratorList([]);
            setForm({ ...form, perpetrators: [] });
          }
        })
        .catch((error) => {
          console.log("err", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      perpetratorsList.splice(i, 1);
      setPerpetratorList(perpetratorsList);
      setForm({ ...form, perpetrators: perpetratorsList });
    }
  };
  const handleSubmit = (incidentForm, action) => {
    Promise.resolve(
      upsertIncident({ token: token.accessToken, formData: incidentForm })
    )
      .then((res) => {
        if (res) {
          if (
            res.incident &&
            res.incident.id &&
            !localStorage.getItem("form_id")
          ) {
            localStorage.setItem("form_id", res.incident.id);
          }

          if (res.incident) {
            setForm({
              ...res.incident,
              files: filesOfIncident,
            });

            if (
              res.incident?.perpetrators &&
              res.incident.perpetrators.length > 0
            ) {
              setPerpetratorList([...res.incident.perpetrators]);
            }
          }

          if (action === "save-and-exit") {
            setSelectedIncidentView("");
            exitForm();
          } else if (action === "new_incident") {
            setSelectedIncidentView(incidentView.EDIT_INCIDENT);
            setSelectedFormId({
              ...res.incident,
            });
            setFilesOfIncident([]);
          } else if (action === "submit-and-share") {
            getIncidents(action);
            setModalSubmitContent("thankyoupage");
          } else if (action === "") {
            setPageIncident((currentPage) => currentPage + 1);
          }

          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("err", error);
      })
      .finally(() => {});
  };

  const saveAndNextStep = (e) => {
    e.preventDefault();
    if (statusNavigationButtons) {
      if (pageIncident < 5) {
        const updateForm = {
          ...form,
          form_step:
            pageIncident >= form.form_step ? pageIncident : form.form_step,
          // incident_id: localStorage.getItem("form_id") || "",
          perpetrators:
            pageIncident === 2 ? perpetratorsList : form.perpetrators,
          files: pageIncident === 3 ? filesOfIncident : form.files,
        };
        setLoading(true);
        handleSubmit(updateForm, "");
      }
    }
  };

  const submitAndShareForm = () => {
    if (
      Object.keys(validationsFormIncident(form, perpetratorsList)).length === 0
    ) {
      const updatedForm = {
        ...form,
        isShareable: true,
        form_step: 5,
        status: incidentStatus.SUBMITTED.id,
      };
      setLoading(true);
      handleSubmit(updatedForm, "submit-and-share");
    } else {
      return;
    }
  };


  const saveAndExit = (e) => {
    e.preventDefault();

    const perpetrators = pageIncident === 2 ? perpetratorsList : form.perpetrators;
    const errors = validatePerpetratorsList(perpetrators, form.status);

    if (Object.keys(errors).length > 0) {
      // Validation failed, user can try again
      console.log('Validation failed, user can reattempt');
    } else {
      const updatedForm = {
        ...form,
        form_step: pageIncident > form.form_step ? pageIncident : form.form_step,
        status: form.isShareable && form.status !== "" ? form.status : incidentStatus.IN_DRAFT.id,
        perpetrators: perpetrators,
      };

      setLoading(true);
      handleSubmit(updatedForm, "save-and-exit");
    }
  };
  const exitForm = () => {
    window.location.reload();
  };

  const backStep = (e) => {
    e.preventDefault();
    if (statusNavigationButtons) {
      setPageIncident((currentPage) =>
        currentPage === 1 ? 1 : currentPage - 1
      );
      if (parseInt(pageIncident) === 1) {
        window.location.reload();
      } else {
        setForm({
          ...form,
        });
      }
    }
  };

  const navigateToStep = (e) => {
    e.preventDefault();

    const step = parseInt(e.target.getAttribute("data-data"));

    setForm({
      ...form,
      form_step: step > form.form_step ? step : form.form_step,
    });

    setPageIncident(step);

    if (step === 5) {
      setErrors(validationsFormIncident(form, perpetratorsList));
    }
  };

  const handleDate = (date) => {
    if (date) {
      setStartDate(date);
      setTimeStart(moment(date).locale("en").format("HH:mm"));
      date = moment(date).locale("en").format("L");
      setForm({
        ...form,
        date: `${moment(date).locale("en").format("L")} ${
          endDate ? `- ${moment(endDate).locale("en").format("L")}` : ""
        }`,
      });
    } else {
      setStartDate("");
      setEndDate("");
      setForm({ ...form, date: "" });
    }
  };

  const handleEndDate = (date) => {
    if (date) {
      setEndDate(date);
      date = moment(date).locale("en").format("L");
      setForm({
        ...form,
        date: moment(startDate).locale("en").format("L") + " - " + date,
      });
    } else {
      setEndDate("");
      setForm({ ...form, date: moment(startDate).locale("en").format("L") });
    }
  };

  const handleTimeInput = (date) => {
    setStartDateTime(date);
    const time_of_incident = new moment(date).locale("en");
    setForm({ ...form, time_of_incident: time_of_incident.format("h:mm A") });
  };

  const handleChangeRadio = (e) => {
    const { name, id, value } = e.target;
    const type = e.target.getAttribute("data-type");

    switch (name) {
      case "isSurvivor":
        setForm({
          ...form,
          [name]: value === "true" ? true : false,
        });
        break;

      case "place":
        setForm({
          ...form,
          [name]: id.substring(6, 7),
        });
        break;

      case "anyone_involved":
        setForm({
          ...form,
          [name]: type === "text" && value === "true" ? true : false,
        });

        break;
      case "knowers":
        setForm({
          ...form,
          [name]: type === "text" && value === "true" ? true : false,
        });
        break;
      case "upload_file_option":
        deleteUploadedFiles(form.files);
        setForm({
          ...form,
          [name]: type === "text" ? value : value === "1" ? true : false,
          files: value === "0" ? [] : form.files,
        });

        break;
      default:
        setForm({
          ...form,
          [name]: type === "text" ? value : value === "1" ? true : false,
        });
        break;
    }
  };

  return {
    form,
    errors,
    loading,
    pageIncident,
    startDate,
    startDateTime,
    endDate,
    perpetratorsList,
    timeStart,
    timeEnd,
    filesOfIncident,
    setFilesOfIncident,
    exitForm,
    setTimeStart,
    setEndDate,
    handleEndDate,
    setPerpetratorList,
    setForm,
    handleChangePerpetrator,
    handleRemovePerpetrator,
    handleChange,
    handleSubmit,
    saveAndNextStep,
    navigateToStep,
    backStep,
    handleDate,
    saveAndExit,
    handleTimeInput,
    handleChangeRadio,
    submitAndShareForm,
  };
};
