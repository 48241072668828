import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { resendEmailVerificationForEmailChange } from "services/users";

function ChangeEmailFirstStep({
  onCancel,
  onConfirm,
  oldEmail,
  userData,
  errors,
  setErrors,
  token,
  attempts,
}) {
  const { t } = useTranslation("global");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    verificationCode: "",
    recoveryCode: "",
  });
  const [waitTime, setWaitTime] = useState(0);

  const handleResendCode = async () => {
    if (attempts === 3) {
    } else {
      resendEmailVerificationForEmailChange({ token });
      setWaitTime(60);
    }
  };

  useEffect(() => {
    waitTime > 0 && setTimeout(() => setWaitTime(waitTime - 1), 1000);
  }, [waitTime, setWaitTime]);

  const changeFormData = (e) => {
    //setErrors(validationsFormChangeEmailFirstStep({
    // ...formData,
    // [e.target.name]: e.target.value,
    //},userData.email));
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const showResendButton = waitTime === 0 && attempts < 3;
  const showWaitingCounter = waitTime > 0;

  return (
    <div className="changeEmail__container">
      <h2 className="modal-title">{t("settings change-email-modal title")}</h2>
      <p className="modal-description">
        {t("settings change-email-modal description")}
      </p>
      <div className="input-container">
        <label htmlFor="email">
          {t("settings change-email-modal confirm-email")}
          <span>*</span>
        </label>
        <input
          id="email"
          type="email"
          name="email"
          value={formData.email}
          onChange={changeFormData}
          placeholder={t(
            "settings change-email-modal confirm-email placeholder"
          )}
        />
        {errors?.email && <span className="error-message">{errors.email}</span>}
      </div>
      <div className="input-container">
        <label htmlFor="password">
          {t("settings change-email-modal password")}
          <span>*</span>
        </label>
        <input
          id="password"
          type="password"
          name="password"
          value={formData.password}
          onChange={changeFormData}
          placeholder="*******"
        />
        {errors?.password && (
          <span className="error-message">{errors.password}</span>
        )}
      </div>
      <div className="input-container">
        <label htmlFor="verificationCode">
          {t("settings change-email-modal verification")}
          <span>*</span>
        </label>
        <input
          id="verificationCode"
          type="text"
          name="verificationCode"
          value={formData.verificationCode}
          onChange={changeFormData}
          placeholder={t(
            "settings change-email-modal verification placeholder",
            { oldEmail: userData.email }
          )}
        />

        <div className="verify-code">
          {showWaitingCounter && (
            <div className="code-sended">
              <p>{t("settings change-email-modal code-sent")}</p>
              <span>
                {t("settings change-email-modal seconds-remaining", {
                  seconds: waitTime,
                })}
              </span>
            </div>
          )}
          {showResendButton && (
            <label className="container-loading container-loading-small">
              {t("landing form verify-account form-not-receive-code-message")}
              <span
                className="error-message resend-code pointer"
                onClick={handleResendCode}
              >
                {t("landing form two-factor-authentication-code-description-2")}
              </span>
            </label>
          )}
          {errors.verificationCode && (
            <span className="error-message">{errors.verificationCode}</span>
          )}
        </div>
      </div>
      <div className="input-container">
        <label htmlFor="recoveryCode">
          {t("settings change-email-modal recovery-code")}
          <span>*</span>
        </label>
        <input
          id="recoveryCode"
          type="text"
          name="recoveryCode"
          value={formData.recoveryCode}
          onChange={changeFormData}
          placeholder="*******"
        />

        <p>{t("settings change-email-modal recovery-code description")}</p>
        {errors?.recoveryCode && (
          <span className="error-message">{errors.recoveryCode}</span>
        )}
      </div>
      <div className="footer">
        <button
          onClick={(e) => {
            e.preventDefault();
            onConfirm(formData);
          }}
          className="confirm-button"
        >
          {t("settings change-email-modal button confirm")}
        </button>
        <button onClick={onCancel} className="cancel-button">
          {t("settings change-email-modal button cancel")}
        </button>
      </div>
    </div>
  );
}

export default ChangeEmailFirstStep;
