import React from "react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";

function LogoutOnClick({ logout, closePopup }) {
  const { t } = useTranslation("global");

  const handleLogout = () => {
    logout();
  };

  const handleClosePopup = () => {
    closePopup();
  };

  return (
    <div className="container-modal-logout-session">
      <div className="logout-session-text">
        {t("platform logout-confirm-title")}
      </div>
      <div className="button-container">
        <Button
          text={t("platform logout-confirm-yes")}
          class="btn-delete-account small"
          onClick={handleLogout}
        />
        <Button
          text={t("platform logout-confirm-no")}
          class="btn-cancel-verify"
          onClick={handleClosePopup}
        />
      </div>
    </div>
  );
}

export default LogoutOnClick;
