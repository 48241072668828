import React from "react";
import HeaderForm from "components/HeaderForm";
import CookiesBanner from "components/cookiesBanner";
import CloseDialog from "components/dialogs/CloseDialog";
import LoginForm from "pages/landing/login/LoginForm";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/pseash-logo-gray.svg";
import TwoFactorAuth from "../../components/TwoFactorAuthentication";
import ForgotPassword from "../../components/forms/ForgotPasswordForm";
import ResetPasswordForm from "../../components/forms/ResetPasswordForm";
import SignUpForm from "../../components/forms/SignUpForm";
import VerifyAccountForm from "../../components/forms/VerifyAccountForm";
import WelcomeAfterLogin from "../../components/forms/WelcomeAfterLogin";
import NotificationSuccess from "../../components/notification-messages/NotificationSuccess";

function LandingPlatform() {
  const location = useLocation();
  const pathname = location.pathname.replace("/", "");
  const { t, i18n } = useTranslation("global");
  const description = `<p>${t(
    "landing create account section-description"
  )}.</p>`;

  const getPlatformForm = () => {
    switch (pathname) {
      case "login":
        return <LoginForm pathname={pathname} />;
      case "sign-up":
        return <SignUpForm pathname={pathname} />;
      case "forgot-your-password":
        return <ForgotPassword pathname={pathname} />;
      case "reset-password":
        return location.state?.hashed_email ? (
          <ResetPasswordForm pathname={pathname} data={location.state} />
        ) : (
          <NotificationSuccess text="" />
        );
      case "verify-account":
        return location.state?.hashed_email ? (
          <VerifyAccountForm pathname={pathname} userData={location.state} />
        ) : (
          <NotificationSuccess text="" />
        );
      case "welcome":
        return location.state?.login === true ? (
          <WelcomeAfterLogin pathname={pathname} userData={location.state} />
        ) : (
          <LoginForm pathname={pathname} />
        );
      case "two-factor-auth":
        return <TwoFactorAuth pathname={pathname} userData={location.state} />;
      default:
        break;
    }
  };

  const getDetailForm = () => (
    <div className={"container-form__" + pathname} lang={i18n.language}>
      <div className="container-body__form">
        <HeaderForm
          title={t("landing create account section-title")}
          description={description}
          lang={i18n.language}
        />
      </div>
      <ul className="container_form__list">
        <li>
          <p>{t("landing create account section-list-item-1")}</p>
        </li>
        <li>
          <p>{t("landing create account section-list-item-2")}</p>
        </li>
        <li>
          <p>{t("landing create account section-list-item-3")}</p>
        </li>
        <li>
          <p>{t("landing create account section-list-item-4")}</p>
        </li>
        <li>
          <p>{t("landing create account section-list-item-5")}</p>
          <Link to="/terms-of-use" className="required-fields-text">
            {t("landing create account terms-and-conditions")}
          </Link>
        </li>
      </ul>
    </div>
  );

  const getLogo = () => (
    <div className="container-logo">
      <img src={logo} alt="unhcr logo" />
    </div>
  );

  return (
    <div className={"view-" + pathname}>
      <div
        className={`column-left  ${
          pathname === "sign-up" ? "" : "logo-column"
        }`}
      >
        {pathname === "sign-up" ? getDetailForm() : getLogo()}
      </div>
      <div className="column-right">
        {pathname !== "welcome" && !location.state?.login && (
          <CloseDialog shouldGoToLandingOnClick isFloatButton />
        )}
        {getPlatformForm()}
      </div>
      <CookiesBanner />
    </div>
  );
}

export default LandingPlatform;
