import React from "react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import HeaderForm from "components/HeaderForm";
import { useCountdown } from "helpers/countdown";

function DialogInactivitySession({ setShowInactivityModal }) {
  const { t, i18n } = useTranslation("global");
  const expiredTime = parseInt(localStorage.getItem("_expiredTime") || 0, 10);

  const [, , minutes, seconds] = useCountdown(new Date(expiredTime));

  const handleClick = () => {
    setShowInactivityModal(false);
  };

  return (
    <div className="inactivity-container">
      <HeaderForm
        title={t("platform inactivity-modal title")}
        description={t("platform inactivity-modal description", {
          minutes,
          seconds: seconds >= 10 ? seconds : `0${seconds}`,
        })}
        lang={i18n.language}
      />
      <div className="button-container">
        <Button
          text={t("platform inactivity-modal button")}
          class="btn-delete-account small"
          id="continue-session"
          disabled={minutes + seconds === 0}
          onClick={handleClick}
        />
      </div>
    </div>
  );
}

export default DialogInactivitySession;

