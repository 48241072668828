import { useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteFileOfIncident, uploadFile } from "services/files";
import { incidentStatus } from "../helpers/constants";
import { getCookieData, validateFileSize } from "../helpers/helpers";

export const useUploadFile = (
  incident,
  setFilesOfIncident,
  setForm,
  setTimeOutModal
) => {
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [filesUploading, setFilesUploading] = useState(0);
  const [errors, setErrors] = useState([]);

  const { t } = useTranslation("global");

  const deleteAllFiles = (filesAlreadyUpdated) => {
    const token = getCookieData("auth", { path: "/" });
    filesAlreadyUpdated.forEach((item) => {
      Promise.resolve(
        deleteFileOfIncident({
          token: token.accessToken,
          data: { file_id: item.id },
        })
      )
        .then((res) => {
          if (res) {
            setFilesUploaded([]);
            setFilesOfIncident([]);
            setForm({
              ...incident,
              files: [],
            });
          }
        })
        .catch((error) => {
          console.log("err", error);
        })
        .finally(() => {
          //final action
        });
    });
  };

  const handleFile = (e) => {
    const files = e.target.files;

    const array = [];

    setErrors([]);

    if (files.length > 0) {
      setFilesUploading(files.length);

      Object.keys(files).forEach(async (key) => {
        if (validateFileSize(files[key].size)) {
          await handleUpload(files[key], files[key].name);
        } else {
          const fileName = files[key].name;
          array.push(
            t("incident-form documentation validation max-size", { fileName })
          );
        }
      });
      setErrors(array);
    }
  };

  const handleUpload = async (file, name) => {
    const token = getCookieData("auth", { path: "/" });
    let formData = new FormData();
    formData.append("file", file);
    formData.append("incident_id", localStorage.getItem("form_id"));
    formData.append("status", incidentStatus.IN_DRAFT.id);

    await Promise.resolve(uploadFile({ token: token.accessToken, formData }))
      .then((response) => {
        if (response) {
          setFilesUploading((current) => (current - 1 > 0 ? current - 1 : 0));
          const newFile = response?.file;
          filesUploaded.push(newFile);
          setFilesUploaded(filesUploaded);

          setFilesOfIncident(filesUploaded);

          setForm({
            ...incident,
            files: filesUploaded,
          });
        }
      })
      .catch((err) => {
        if (err?.response?.status === 504 || err?.message === "TimeoutError") {
          setFilesUploading((current) => (current - 1 > 0 ? current - 1 : 0));
          errors.push(
            t("incident-form documentation validation timeout", {
              fileName: name,
            })
          );
          setErrors(errors);
          setTimeOutModal(true);
        }
        console.log(err);
      });
  };

  const handleDelete = (id) => {
    const token = getCookieData("auth", { path: "/" });
    Promise.resolve(
      deleteFileOfIncident({ token: token.accessToken, data: { file_id: id } })
    )
      .then((res) => {
        if (res) {
          let listUpdated = filesUploaded.filter(function (obj) {
            return obj.id !== id;
          });
          setFilesUploaded(listUpdated);
          setFilesOfIncident(listUpdated);
          setForm({
            ...incident,
            files: listUpdated,
          });
        }
      })
      .catch((error) => {
        console.log("err", error);
      })
      .finally(() => {
        //final action
      });
  };

  return {
    filesUploaded,
    filesUploading,
    errors,
    deleteAllFiles,
    setFilesUploaded,
    setFilesUploading,
    handleFile,
    handleDelete,
  };
};

