import React, { Fragment } from "react";
import classNames from "classnames";
import FormRoadMap from "components/FormRoadMap";
import CookiesBanner from "components/cookiesBanner";
import { useTranslation } from "react-i18next";
import { addPrefixLanding } from "../../helpers/helpers";
import Footer from "./components/Footer";
import Header from "./components/Header";

function HelpWithTheTool() {
  const { i18n } = useTranslation("global");
  return (
    <Fragment>
      <Header />
      <section
        className={classNames({
          [addPrefixLanding("support-section")]: true,
          [addPrefixLanding("section-2")]: true,
        })}
        lang={i18n.language}
      >
        <FormRoadMap />
      </section>
      <Footer />
      <CookiesBanner />
    </Fragment>
  );
}

export default HelpWithTheTool;

