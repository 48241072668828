import React from "react";
import classNames from "classnames";
import Loading from "components/Loading";
import { useTranslation } from "react-i18next";
import { sendInviteCode, adminCount } from "services/users";
import TwoFactorAuthForm from "../../components/TwoFactorAuthentication/TwoFactorAuthForm";
import Button from "../Button";
import { EMAIL_RE } from "helpers/regexps";

function UserSettings({
  contactForm,
  errors,
  form,
  handleChange,
  handleChangeUserSettings,
  handleDeleteAccount,
  handleSubmit,
  handleSubmitContactForm,
  isLoading,
  onOpen,
  passwordForm,
  phoneNumber,
  role,
  setForm,
  setOpenInviteAdmin,
  setTogglePassword,
  setToggleSettings,
  setToggleUserInfo,
  setBlockDeleteAdminModal,
  togglePassword,
  toggleSettings,
  toggleUserInfo,
  tokenTemp,
}) {
  const { t, i18n } = useTranslation("global");
  const [contactErrors, setContactErrors] = React.useState({});

  const handleDeleteAccountSubmit = async () => {
    let adminCountResponse;

    await Promise.resolve(adminCount(tokenTemp))
      .then((res) => {
        adminCountResponse = res.adminCount;
      })
      .catch((err) => {
        console.error(err);
      });

    if (adminCountResponse === 1) {
      setBlockDeleteAdminModal(true);
      return;
    }

    handleDeleteAccount();
  };

  const handleSaveSettings = (e) => {
    e.preventDefault();

    if (EMAIL_RE.test(contactForm.email)) {
      setContactErrors({});
      handleSubmitContactForm(e);
    } else {
      setContactErrors({
        email: t("settings change-email-error email"),
      });
    }
  };

  return (
    <div className={classNames({
      "portal-container portal-settings": true,
      "ar-letter-spacing": i18n.language === "ar",
    })} >
      {isLoading ? (
        <div className="loading-container">
          <Loading />
        </div>
      ) : (
        <>
          <div className="tab-container__title" lang={i18n.language}>
            <h3 className="settings-title">{t("settings title")}</h3>
            <span>{t("settings description")}</span>
          </div>
          <div className="tab-container__content settings">
            <form className="form-container simple-form">
              <div className="tab-container__row two-columns">
                <div className="tab-container__column" lang={i18n.language}>
                  <input
                    type="text"
                    placeholder={t("settings form first-name-label")}
                    name="first_name"
                    value={contactForm.first_name}
                    disabled={toggleUserInfo}
                    maxLength={100}
                    onChange={handleChangeUserSettings}
                    className="settings-input"
                  />
                  <span>{t("settings form first-name-label")}</span>
                  {errors.first_name && (
                    <span className="error-message">{errors.first_name}</span>
                  )}
                </div>
                <div className="tab-container__column" lang={i18n.language}>
                  <input
                    type="text"
                    placeholder={t("settings form last-name-label")}
                    name="last_name"
                    maxLength={100}
                    value={contactForm.last_name}
                    disabled={toggleUserInfo}
                    onChange={handleChangeUserSettings}
                    className="settings-input"
                  />
                  <span>{t("settings form last-name-label")}</span>
                  {errors.last_name && (
                    <span className="error-message">{errors.last_name}</span>
                  )}
                </div>
              </div>
              <div className="tab-container__row">
                <div className="tab-container__column" lang={i18n.language}>
                  <input
                    type="text"
                    value={contactForm.id}
                    disabled={true}
                    className="settings-input"
                  />
                  <div
                    lang={i18n.language}
                  >
                    <span>{t("settings form encrypted-user-id-hint")}</span>
                  </div>
                  <span></span>
                </div>
              </div>
              <div className="tab-container__row two-columns">
                <div className="tab-container__column" lang={i18n.language}>
                  <input
                    type="email"
                    name="email"
                    maxLength={100}
                    value={contactForm.email}
                    disabled={role === "admin" || toggleUserInfo}
                    onChange={handleChangeUserSettings}
                    className="settings-input"
                  />
                  <span>{t("settings form user-email")}</span>
                  {contactErrors.email && (
                    <span className="error-message">
                      {t("settings change-email-error email")}
                    </span>
                  )}
                </div>
              </div>
              {contactForm.message !== "" && (
                <div className="container-form__question-label flex-column success-notification" lang={i18n.language}>
                  <span>{contactForm.message}</span>
                </div>
              )}
              <div className="tab-container__row buttons-container">
                <Button
                  className="custom"
                  text={
                    toggleUserInfo
                      ? t("settings form edit-button")
                      : t("settings form cancel-button")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setToggleUserInfo(!toggleUserInfo);
                    toggleUserInfo === false && window.location.reload();
                  }}
                />
                {toggleUserInfo === false && (
                  <Button
                    text={t("settings form save-button")}
                    onClick={handleSaveSettings}
                    className="btn-save-setting"
                  />
                )}
              </div>
            </form>
          </div>
          <div className="tab-container__title" lang={i18n.language}>
            <h3 className="settings-title">
              {t("settings form update-your-password")}
            </h3>
          </div>
          <div className="tab-container__content settings">
            <form>
              <div className="tab-container__row">
                <div className="tab-container__column" lang={i18n.language}>
                  <input
                    type="password"
                    maxLength={100}
                    name="current_password"
                    value={passwordForm.current_password}
                    disabled={togglePassword}
                    onChange={handleChange}
                    className="settings-input"
                  />
                  <span>{t("settings form current-password-label")}</span>
                  {errors.current_password && (
                    <span className="error-message">
                      {errors.current_password}
                    </span>
                  )}
                </div>
              </div>
              <div className="tab-container__row ">
                <div className="tab-container__column" lang={i18n.language}>
                  <input
                    type="password"
                    maxLength={100}
                    name="new_password"
                    value={passwordForm.new_password}
                    disabled={togglePassword}
                    onChange={handleChange}
                    className="settings-input"
                  />
                  <span>{t("settings form new-password-label")}</span>

                  {errors.new_password && (
                    <span className="error-message">{errors.new_password}</span>
                  )}
                </div>
              </div>

              <div className="tab-container__row">
                <div className="tab-container__column" lang={i18n.language}>
                  <input
                    type="password"
                    maxLength={100}
                    name="confirm_password"
                    value={passwordForm.confirm_password}
                    disabled={togglePassword}
                    onChange={handleChange}
                    className="settings-input"
                  />
                  <span>{t("settings form repeat-new-password-label")}</span>
                  {errors.confirm_password && (
                    <span className="error-message">
                      {errors.confirm_password}
                    </span>
                  )}
                </div>
              </div>
              {passwordForm.message !== "" && (
                <div className="container-form__question-label flex-column success-notification" lang={i18n.language}>
                  <span>{passwordForm.message}</span>
                </div>
              )}
              <div className="tab-container__row buttons-container">
                <Button
                  className="custom"
                  text={
                    togglePassword
                      ? t("settings form edit-button")
                      : t("settings form cancel-button")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setTogglePassword(!togglePassword);
                    togglePassword === false && window.location.reload();
                  }}
                />
                {togglePassword === false && (
                  <Button
                    text={t("settings form save-button")}
                    onClick={handleSubmit}
                    className="btn-save-setting"
                  />
                )}
              </div>
            </form>
          </div>
          <div className="tab-container__title" lang={i18n.language}>
            <h3 className="settings-title">{t("settings title-2")}</h3>
            <span className="settings-second-description">
              {t("setting description-2")}
            </span>
          </div>
          <div className="tab-container__content settings">
            <div className="container-form__two-factor-auth container-form__two-factor-auth-setting">
              <TwoFactorAuthForm
                role={role}
                form={form}
                setForm={setForm}
                onSubmit={onOpen}
                phoneNumber={phoneNumber}
                toggleSettings={toggleSettings}
                setToggleSettings={setToggleSettings}
                isSetting
              />
              {contactForm.message !== "" && (
                <div className="container-form__question-label flex-column success-notification" lang={i18n.language}>
                  <span>{contactForm.message}</span>
                </div>
              )}
            </div>
          </div>
          <div className={classNames({
              "tab-container__footer": true,
              "ar-letter-spacing": i18n.language === "ar",
            })}>
            {role === "admin" ? (
              <Button
                className="btn-invite-admin small"
                text={t("setting-invite-admin")}
                onClick={() => {
                  setOpenInviteAdmin(true);
                  sendInviteCode({}, tokenTemp);
                }}
              />
            ) : null}
            <Button
              className="btn-delete-account small"
              text={t("settings form delete-account-button")}
              onClick={handleDeleteAccountSubmit}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default UserSettings;

