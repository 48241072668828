import React from "react";
import { useNavigate } from "react-router-dom";
import whiteCloseIcon from "../../assets/images/close-btn-white.svg";
import closeIcon from "../../assets/images/close-btn.svg";

function CloseDialog(props) {
  const navigate = useNavigate();

  const handleClose = (e) => {
    e.preventDefault();

    if (props.closeVerifyModal) {
      props.onClose();
    }

    if (props.shouldGoToLandingOnClick) {
      navigate("/home");
      return;
    }

    if (props && props.view === "pre-landing-3") {
      props.setModalContent("pre-landing-2");
    } else if (props && !props.isChatRoom) {
      props.setModal(false);
      props.setModalContent("");
      props.getIncidentsList();
    }

    if (props?.isChatRoom) {
      props.handleLeaveChat();
      props.setCurrentChatRoom({});
      props.setModalContent("");
      props.setModal(false);
    }
  };

  return (
    <div
      className={
        props.isFloatButton
          ? "floating-close-btn-container"
          : "btn-close-container"
      }
    >
      <button className="custom btn-close" onClick={handleClose}>
        <img
          src={props.isFloatButton ? whiteCloseIcon : closeIcon}
          alt="Close button"
        />
      </button>
    </div>
  );
}

export default CloseDialog;
