import React, { useState } from "react";
import classNames from "classnames";
import Button from "components/Button";
import Modal from "components/Modal";
import CloseDialog from "components/dialogs/CloseDialog";
import Prelanding from "pages/landing/modals/Prelanding";
import PrelandingInfo from "pages/landing/modals/PrelandingInfo";
import { useTranslation } from "react-i18next";

const LandingModals = ({ setModal }) => {
  const { t, i18n } = useTranslation("global");
  const [modalContent, setModalContent] = useState("pre-landing-2");

  const goToNext = (e) => {
    e.preventDefault();
    switch (modalContent) {
      case "pre-landing-3":
        setModalContent("pre-landing-2");
        break;
      case "pre-landing-2":
        setModal(false);
        setModalContent("");
        break;
      default:
        setModal(false);
        setModalContent("");
        break;
    }
  };

  return (
    <Modal>
      <div
        className={classNames({
          "lightbox-component": true,
          [`${modalContent}`]: true,
        })}
      >
        <div className="modal-container">
          {modalContent === "pre-landing-3" ? (
            <>
              <CloseDialog
                setModal={setModal}
                setModalContent={setModalContent}
                view={modalContent}
              />
              <PrelandingInfo
                text={`${t("prelanding 3 part 1")} ${t("prelanding 3 part 2")}`}
                goToNext={goToNext}
                lang={i18n.language}
              />
            </>
          ) : (
            <>
              <CloseDialog shouldGoToLandingOnClick isFloatButton />
              <Prelanding
                text={t("prelanding 2")}
                modalContent={modalContent}
                setModalContent={setModalContent}
              />
            </>
          )}
          {modalContent !== "pre-landing-3" ? (
            <div className="get-started-btn">
              <Button
                class={classNames({
                  "btn-prelanding": true,
                  "ar-letter-spacing": i18n.language === "ar",
                })}
                text={t("prelanding 1 btn")}
                onClick={goToNext}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Modal>
  );
};

export default LandingModals;

