import classNames from "classnames";
import React from "react";
import NotificationItem from "./NotificationItem";
import emptyIcon from "../../assets/images/empty-icon.svg";
import { useTranslation } from "react-i18next";

function NotificationList({
  notificationList,
  setModalChat,
  modalChat,
  updateNotifications,
}) {
  const [currentId, setCurrentId] = React.useState("");
  const { t } = useTranslation("global");

  return (
    <div
      className={classNames({
        "notification-items-container": true,
        "item-list":
          notificationList && notificationList.length > 0 ? true : false,
      })}
    >
      {notificationList && notificationList.length > 0 ? (
        notificationList.map((item, i) => (
          <NotificationItem
            key={i}
            title={item.message}
            description=""
            date={item.created_at}
            id={item.id}
            status={!item.read}
            type="notification"
            updateNotifications={updateNotifications}
            modalChat={modalChat}
            setModalChat={setModalChat}
            currentId={currentId}
            setCurrentId={setCurrentId}
          />
        ))
      ) : (
        <div className="empty-container">
          <div>
            <img src={emptyIcon} alt="icon-empty" />
          </div>
          <p>{t("notifications empty message")}</p>
        </div>
      )}
    </div>
  );
}

export default NotificationList;

