import React from "react";

export default function PrelandingInfo({ text, goToNext, lang = 'en' }) {
  return (
    <div className="pre-landing-container">
      <div className="column-center" lang={lang}>
        <div
          className="text-description"
          onClick={goToNext}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </div>
    </div>
  );
}
