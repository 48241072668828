import axios from "axios";
import { BASE_URL, ENDPOINT } from "helpers/constants";

export const getIncidents = async ({ token }) => {
  const res = await axios.get(BASE_URL + ENDPOINT.incident.create, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      Accept: "*/*",
    },
  });
  return res.data;
};

export const deleteIncident = async ({ token, incident }) => {
  const res = await axios.delete(BASE_URL + ENDPOINT.incident.create, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      Accept: "*/*",
    },
    data: incident,
  });
  return res.data;
};

export const upsertIncident = async ({ token, formData }) => {
  const res = await axios.post(BASE_URL + ENDPOINT.incident.create, formData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      Accept: "*/*",
    },
  });
  return res.data;
};

export const deletePerpetrator = async ({ token, perpetrator }) => {
  const res = await axios.post(
    BASE_URL + ENDPOINT.perpetrator.delete,
    perpetrator,
    {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    }
  );
  return res.data;
};

export const readIncident = async ({ token, id, markAsRead }) => {
  const res = await axios.put(
    BASE_URL + ENDPOINT.incident.read + id,
    {
      markAsRead
    },
    {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    }
  );
  return res.data;
};
