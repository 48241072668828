import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

function NotificationSuccess({ text }) {
  const { t, i18n } = useTranslation("global");

  return (
    <div
      className={classNames({
        "container-form__question-label flex-column success-notification": true,
        "ar-letter-spacing": i18n.language === "ar",
      })}
      lang={i18n.language}
    >
      <span>{text}</span>
      <Link to="/login">{t("navigation-success")}</Link>
    </div>
  );
}

export default NotificationSuccess;

