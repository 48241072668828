import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { addPrefixLanding } from "../helpers/helpers";
import { useTranslation } from "react-i18next";

function YourIncidentForms(props) {
  const { t, i18n } = useTranslation("global");

  return (
    <section
      className={classNames({
        "section-create-incident": true,
      })}
    >
      <div className={addPrefixLanding("section-container")}>
        <div
          className={classNames({
            [addPrefixLanding("section-container__row")]: true,
            [addPrefixLanding("horizontal-orientation")]: true,
            [addPrefixLanding("center-content")]: true,
          })}
        >
          <div
            className={classNames({
              [addPrefixLanding("section-container__column")]: true,
              "flex-center": true,
            })}
          >
            <div className={addPrefixLanding("section-1-content")}>
              <h2
                className={addPrefixLanding("section-title")}
                lang={i18n.language}
              >
                {t("platform tab-your-incidents-description")}
              </h2>
              <div
                className={classNames({
                  [addPrefixLanding("container-preview-link")]: true,
                  [addPrefixLanding("container-preview-link-ar")]:
                    i18n.language === "ar",
                })}
              >
                <span lang={i18n.language}>
                  {t("platform tab-your-incidents-record-not-sure")}
                </span>
                <Link
                  className={classNames({
                    [addPrefixLanding("preview-link")]: true,
                    [addPrefixLanding("preview-link-ar")]: i18n.language === "ar",
                  })}
                  to="/support-resources/help-with-the-tool"
                  target="_blank"
                  lang={i18n.language}
                >
                  {t("platform tab-your-incidents-record-btn-preview")}
                </Link>
              </div>
            </div>
          </div>
          <div
            className={classNames({
              [addPrefixLanding("section-container__column")]: true,
              "flex-center": true,
            })}
          >
            <div className={addPrefixLanding("section-1-content")}>
              <Link
                className={classNames({
                  [addPrefixLanding("button-circle")]: true,
                  [addPrefixLanding("button-login")]: true,
                })}
                to="/#"
                onClick={props.startIncident}
              >
                <span>
                  {t("platform tab-your-incidents-record-btn-create")}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default YourIncidentForms;

