import React, { useEffect, useState } from "react";
import axios from "axios";
import DateRange from "components/DateRange";
import Loading from "components/Loading";
import { BASE_URL, ENDPOINT } from "helpers/constants";
import { getCookieData } from "helpers/helpers";
import { i18nGetData } from "../../i18n";
import StatisticsItem from "./StatisticsItem";
import BarChartItem from "./charts/BarChartItem";
import CircleChartItem from "./charts/CircleChartItem";
import NoData from "./charts/NoData";

function Dashboard() {
  const [loading, setLoading] = useState(false);

  const [incidentFormsSubmitted, setIncidentFormsSubmitted] = useState(0);
  const [uniquePerpetrators, setuniquePerpetrators] = useState(0);
  const [totalMatches, setTotalMatches] = useState(0);
  const [incidentsSharedWithOfficer, setIncidentsSharedWithOfficer] =
    useState(0);
  const [messagesSentToVCO, setMessagesSentToVCO] = useState(0);
  const [messagesSentAnonymously, setMessagesSentAnonymously] = useState(0);
  const [incidentsFormsShared, setIncidentsFormsShared] = useState({});
  const [incidentAnnualTrend, setIncidentAnnualTrend] = useState({});

  const minDate = new Date("01/01/2023");
  const [startDate, setStartDate] = useState(minDate);
  const [endDate, setEndDate] = useState(new Date());
  const [error, setError] = useState("");

  const handleEndDate = (date) => {
    setError("");
    const newDate = new Date(date);
    setEndDate(newDate);
  };
  const handleStartDate = (date) => {
    setError("");
    const newDate = new Date(date);

    if (newDate < minDate || newDate > endDate) {
      setError(i18nGetData("platform dashboard section min-date-error"));
    }
    setStartDate(newDate);
  };

  const handleGetStats = () => {
    getStatisticsData();
  };

  //format date dd/mm/YYYY
  const formatDate = (date) => {
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, "0");
    const day = `${d.getDate()}`.padStart(2, "0");
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const getToken = () => {
    return getCookieData("auth", { path: "/" }) !== ""
      ? getCookieData("auth", { path: "/" }).accessToken
      : "";
  };
  const options = {
    token: getToken(),
    data: {
      from: formatDate(startDate),
      to: formatDate(endDate),
    },
  };

  const getStats = async ({ token, data }) => {
    const res = await axios.post(BASE_URL + ENDPOINT.vco.dashboard, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return res.data;
  };

  const getStatisticsData = async () => {
    setLoading(true);
    const data = await getStats(options);

    setIncidentAnnualTrend(data.data.incidentAnnualTrend);
    setIncidentFormsSubmitted(data.data.incidentFormsSubmitted);
    setIncidentsFormsShared(data.data.incidentsFormsShared);
    setIncidentsSharedWithOfficer(data.data.incidentsSharedWithOfficer);
    setMessagesSentAnonymously(data.data.messagesSentAnonymously);
    setMessagesSentToVCO(data.data.messagesSentToVCO);
    setTotalMatches(data.data.totalMatches);
    setuniquePerpetrators(data.data.uniquePerpetrators);

    setLoading(false);
  };

  useEffect(() => {
    getStatisticsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  return (
    <div className="dashboard-tab">
      <DateRange
        startDate={startDate}
        endDate={endDate}
        handleStartDate={handleStartDate}
        handleEndDate={handleEndDate}
        error={error}
        handleGetStats={handleGetStats}
        minDate={minDate}
      />
      <div className="top-statistics">
        <div className="info-container">
          <StatisticsItem
            title={i18nGetData(
              "platform dashboard section incident-forms-submitted"
            )}
            data={incidentFormsSubmitted}
          />
          <StatisticsItem
            title={i18nGetData(
              "platform dashboard section unique-perpetrators"
            )}
            data={uniquePerpetrators}
          />
          <StatisticsItem
            title={i18nGetData("platform dashboard section total-matches")}
            data={totalMatches}
          />
        </div>
        <div className="info-container">
          <StatisticsItem
            title={i18nGetData(
              "platform dashboard section incident-shared-with-officer"
            )}
            data={incidentsSharedWithOfficer}
          />
          <StatisticsItem
            title={i18nGetData(
              "platform dashboard section message-started-with-vco"
            )}
            data={messagesSentToVCO}
          />
          <StatisticsItem
            title={i18nGetData("platform dashboard messages-sent-anonymously")}
            data={`${Number.parseFloat(messagesSentAnonymously).toFixed(2)}%`}
          />
        </div>
      </div>
      <div className="dashboard-spacer"></div>
      <div className="bottom-statistics">
        <div className="charts-container">
          <div className="charts-item">
            {incidentsFormsShared.countAnonymous === 0 &&
            incidentsFormsShared.countNotAnonymous === 0 ? (
              <NoData
                title={i18nGetData("platform dashboard incident-form-shared")}
              />
            ) : (
              <CircleChartItem
                incidentsFormsShared={incidentsFormsShared}
                title={i18nGetData("platform dashboard incident-form-shared")}
              />
            )}
          </div>
          <div className="charts-item">
            {Object.entries(incidentAnnualTrend).length === 0 ? (
              <NoData
                title={i18nGetData(
                  "platform dashboard incident-form-annual-trend"
                )}
              />
            ) : (
              <BarChartItem
                incidentAnnualTrend={incidentAnnualTrend}
                title={i18nGetData(
                  "platform dashboard incident-form-annual-trend"
                )}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
