import React from "react";
import classNames from "classnames";
import { getCookie } from "helpers/helpers";
import FAQItem from "./FAQItem";

function FrequentlyAskedQuestions({ frequentlyAskedQuestions }) {
  const language = getCookie("language");
  return (
    <ul
      className={classNames({
        "faq-container": true,
        "faq-container-ar": language === "ar",
      })}
    >
      {frequentlyAskedQuestions.map((FAQ, index) => (
        <FAQItem FAQ={FAQ} key={index} />
      ))}
    </ul>
  );
}

export default FrequentlyAskedQuestions;
