import React, { useEffect } from "react";
import classNames from "classnames";
import NotificationRecoveryKey from "components/notification-messages/NotificationRecoveryKey";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { validationsFormVerifyAccount } from "../../helpers/validations";
import { useVerifyAccount } from "../../hooks/useVerifyAccount";
import Button from "../Button";
import HeaderForm from "../HeaderForm";
import Loading from "../Loading";
import NotificationSuccess from "../notification-messages/NotificationSuccess";

function VerifyAccount({ userData, pathname }) {
  const { t, i18n } = useTranslation("global");
  const title = t("landing form verify-account section-title");
  const description = `<p>${t(
    "landing form verify-account section-detail"
  )}</p>`;

  const {
    form,
    errors,
    loading,
    formStatus,
    counter,
    attempts,
    setCounter,
    handleChange,
    handleBlur,
    handleSubmit,
    handleResendCode,
  } = useVerifyAccount(
    { code: "", email: userData.email, hashed_email: userData.hashed_email },
    validationsFormVerifyAccount
  );

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter, setCounter]);

  return (
    <div className={"container-form__" + pathname}>
      <HeaderForm
        title={title}
        description={description}
        lang={i18n.language}
      />
      {formStatus === "submitted" ? (
        <NotificationSuccess
          text={t("landing form verify-account form-success-message")}
        />
      ) : (
        <div
          className={classNames({
            "container-body__form": true,
            "ar-letter-spacing": i18n.language === "ar",
          })}
        >
          <form className="container-form">
            <div className="container_form__row ">
              <div
                className={classNames({
                  container_form__col: true,
                  require_field: true,
                  "ar-letter-spacing": i18n.language === "ar",
                })}
                lang={i18n.language}
              >
                <label className="container-loading container-loading-small">
                  {t(
                    "landing form verify-account form-verification-code-input"
                  )}{" "}
                  {loading && <Loading />}
                </label>
                <input
                  className={classNames({
                    "error-field": errors.code,
                  })}
                  type="text"
                  placeholder={`${t(
                    "landing form verify-account form-verification-code-input"
                  )}*`}
                  name="code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={form.code}
                  required
                />
                {errors.code && (
                  <span className="error-message">{errors.code}</span>
                )}
              </div>
            </div>
            <div
              className={classNames({
                container_form__row: true,
                "ar-letter-spacing": i18n.language === "ar",
              })}
            >
              <div className="container_form__col">
                <Button
                  class="btn-send-verification"
                  text={t(
                    "landing form verify-account form-verify-account-button"
                  )}
                  onClick={handleSubmit}
                  disabled={loading || attempts === 0}
                />
              </div>
            </div>
          </form>
        </div>
      )}
      {formStatus === "submitted" ? (
        <NotificationRecoveryKey
          containerClass={""}
          recoveryKey={userData.r_key}
        />
      ) : (
        <>
          <div
            className={classNames({
              "container-form__question-label flex-column label-resend-code": true,
              "ar-letter-spacing": i18n.language === "ar",
            })}
          >
            <span lang={i18n.language}>
              {t("landing form verify-account form-not-receive-code-message")}
            </span>
            <Button
              class="small btn-resend-code"
              text={t("landing form verify-account form-resend-code-button")}
              onClick={handleResendCode}
              disabled={
                counter > 0 || attempts <= 0 || formStatus === "submitted"
                  ? true
                  : false
              }
            />
            {counter > 0 && attempts >= 1 && (
              <span className="counter-verification-code" lang={i18n.language}>
                <strong>
                  {t("landing form verify-account form-wait-hint", { counter })}
                </strong>
              </span>
            )}
            {attempts >= 0 && (
              <span className="counter-verification-code" lang={i18n.language}>
                {t("landing form verify-account form-remaining-attempts-hint", {
                  attempts,
                })}
              </span>
            )}
          </div>
          {attempts === 0 && (
            <div className="container-body__form" lang={i18n.language}>
              <span className="error-message">
                {t(
                  "landing form verify-account form-remaining-attempts-reached"
                )}
              </span>
            </div>
          )}
        </>
      )}
      <div
        className={classNames({
          "back-button-padding": true,
          "ar-letter-spacing": i18n.language === "ar",
        })}
      >
        <Link
          className="back-button-verify-account"
          to="/sign-up"
          state={{ fromLogin: true }}
        >
          {t("incident-form navigation-back")}
        </Link>
      </div>
    </div>
  );
}

export default VerifyAccount;

