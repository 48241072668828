import React, { Fragment, useState, useRef } from "react";
import previewIcon from "../assets/images/preview-icon.png";
import downloadIcon from "../assets/images/download-icon.png";
import { resourceType } from "helpers/constants";
import ReactPlayer from "react-player/vimeo";
import closeIcon from "assets/images/close-btn.svg";
import classNames from "classnames";
import { getCookie } from "helpers/helpers";

function MediaItem({ title, description, thumbnail, url, type }) {
  const [openVideo, setVideo] = useState(false);
  const [playingVideo, setPlayingVideo] = useState(false);
  const player = useRef(null);
  const modal = useRef(null);
  const language = getCookie("language");

  const onDownload = () => {
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const fileUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = fileUrl;
        a.download = `${title}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  const pdfViewer = () => {
    const downloadPDF = document.createElement("a");
    downloadPDF.href = `${url}#toolbar=1`;
    downloadPDF.download = `${title}.pdf`;
    downloadPDF.target = "_blank";
    document.body.appendChild(downloadPDF);
    downloadPDF.click();
    document.body.removeChild(downloadPDF);
  };

  const onClickThumbnail = (e) => {
    if (e.view.innerWidth < 1000) {
      if (type === resourceType.PDF || type === resourceType.POWERPOINT) {
        pdfViewer();
      } else {
        openVideoViewer();
      }
    }
  };

  const openVideoViewer = () => {
    setVideo(true);
    modal.current.scrollIntoView();
  };

  const onClose = () => {
    setVideo(false);
    setPlayingVideo(false);
  };

  return (
    <Fragment>
      {type === resourceType.VIDEO && (
        <div
          ref={modal}
          className="video-modal"
          style={{
            opacity: openVideo ? 1 : 0,
            height: openVideo ? "100vh" : 0,
          }}
        >
          <div className="btn-close-container">
            <button className="custom btn-close" onClick={onClose}>
              <img src={closeIcon} alt="Close button" />
            </button>
          </div>
          <ReactPlayer
            ref={player}
            url="https://vimeo.com/822079339"
            height="80%"
            width="80%"
            volume={1}
            className="react-player"
            playing={playingVideo}
            onClick={() => setPlayingVideo(false)}
            onEnded={() => {
              setPlayingVideo(false);
              player.seekTo(0);
            }}
          />
          {!playingVideo && (
            <div className="video-placeholder">
              <div
                className="play-button-container"
                onClick={() => setPlayingVideo(true)}
              >
                <div className="play-button" />
              </div>
            </div>
          )}
        </div>
      )}
      <div
        className={classNames({
          "media-container": true,
          "media-container-ar": language === "ar",
        })}
      >
        <div className="thumbnail" onClick={onClickThumbnail}>
          <img className={type} src={thumbnail} alt="thumbnails" />
        </div>
        <div className="description-container">
          <div className="buttons-container">
            <div
              className="preview"
              onClick={
                type === resourceType.VIDEO ? openVideoViewer : pdfViewer
              }
            >
              <img src={previewIcon} alt="preview logo" />
            </div>
            {type !== resourceType.VIDEO && (
              <div
                className={classNames({
                  download: language !== "ar",
                  "download-ar": language === "ar",
                })}
                onClick={onDownload}
              >
                <img src={downloadIcon} alt="preview logo" />
              </div>
            )}
          </div>
          <div className="title">{title}</div>
          <div className="description">{description}</div>
        </div>
      </div>
    </Fragment>
  );
}

export default MediaItem;
