import axios from "axios";
import { BASE_URL, ENDPOINT } from "helpers/constants";

export const getChatRooms = async ({ token }) => {
  const res = await axios.get(BASE_URL + ENDPOINT.chat.chatroom, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  return res.data;
};

export const createChatRoom = async ({ token, body }) => {
  const res = await axios.post(BASE_URL + ENDPOINT.chat.chatroom, body , {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  return res.data;
};

export const getChatRoomById = async ({ token, id }) => {
  const res = await axios.get(BASE_URL + ENDPOINT.chat.chatroom + "/" + id, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  return res.data;
};

export const updateChatStatus = async ({ token, id, role, action }) => {
  const res = await axios.put(
    BASE_URL + ENDPOINT.chat.chatroom + "/" + id,
    { role, action },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  return res.data;
};

export const deleteChatHistory = async ({ token, id }) => {
  const res = await axios.delete(BASE_URL + ENDPOINT.chat.chatroom + "/" + id, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  return res.data;
};

export const getChatRoomMessages = async ({ token, id }) => {
  const res = await axios.get(
    BASE_URL + ENDPOINT.chat.chatroom + "/" + id + "/messages",
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  return res.data;
};
