import { useContext, useDebugValue } from "react";
import AuthContext from "../context/AuthProvider";
import { getCookieData } from "../helpers/helpers";

const useAuth = () => {
  const { auth } = useContext(AuthContext);

  useDebugValue(auth, (auth) =>
    getCookieData("auth", { path: "/" }) ? "Logged In" : "Logged Out"
  );
  return useContext(AuthContext);
};

export default useAuth;
