import React, { Fragment } from "react";

function InputTextSubfield(props) {
  return (
    <Fragment>
      {props.form[props.fieldName] === props.showSubField && (
        <div className="subcontainer-answer require_field">
          <input
            type={props.otherOption.type}
            maxLength={100}
            placeholder={props.otherOption.placeholder}
            name={"custom_" + props.fieldName}
            value={
              props.form["custom_" + props.fieldName]
                ? props.form["custom_" + props.fieldName]
                : ""
            }
            onChange={props.handleChange}
            required={props.otherOption.required}
          />
          {props.errors[`custom_${props.fieldName}`] && (
            <span className="error-message">
              {props.errors[`custom_${props.fieldName}`]}
            </span>
          )}
        </div>
      )}
    </Fragment>
  );
}

export default InputTextSubfield;
