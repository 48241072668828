import React from "react";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";

function BarChartItem({ incidentAnnualTrend, title }) {
  const COLORS = ["#284B7D", "#FF6C6C"];

  const data = [];

  Object.entries(incidentAnnualTrend).forEach(([key, value]) => {
    data.push({
      name: key,
      count: value,
    });
  });

  const getCurrentYear = () => {
    const date = new Date();
    return date.getFullYear();
  };

  return (
    <div className="item-info-data">
      <div className="item-info-data__title">{title}</div>

      <div className="item-info-data__char-trend">
        <span className="item-info-data__char-trend__anonymous-bubble"></span>
        <p className="item-info-data__char-trend__lable-text">
          {getCurrentYear()}
        </p>
      </div>
      <div
        width="214px"
        height="250px"
        style={{
          width: "214px",
          height: "250px",
          display: "flex",
          margin: "0 auto",
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={250}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            barSize={20}
          >
            <XAxis dataKey="name" />
            <Tooltip />
            <Bar dataKey="count" fill="#284B7D" radius={[10, 10, 0, 0]}>
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.name === getCurrentYear() ? COLORS[1] : COLORS[0]}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default BarChartItem;
