import { getCookieData, saveCookieData } from "helpers/helpers";
import i18n from "i18next";
import { useState } from "react";
import axios from "../api/axios";
import { BASE_URL, ENDPOINT } from "../helpers/constants";
import { i18nGetData } from "../i18n";
import { hash } from "../lib/utils/encryption";
const MAXIMUM_ATTEMPTS = 5;

export const useResetPassword = (initialForm, validateForm) => {
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState({});
  const [counter, setCounter] = useState(60);
  const [attempts, setAttempts] = useState(3);
  const [verificationAttempts, setVerificationAttempts] =
    useState(MAXIMUM_ATTEMPTS);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleBlur = (e) => {
    handleChange(e);
    setErrors(validateForm(form));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validateForm(form));
    const blocked = getCookieData("blocked");
    if (blocked) {
      setErrors({
        message: i18nGetData(
          "landing form reset-password-error maximum-attempts",
          { maxAttempts: MAXIMUM_ATTEMPTS }
        ),
      });
      return;
    }
    if (Object.keys(validateForm(form)).length === 0) {
      (async () => {
        try {
          const response = await axios.post(
            BASE_URL + ENDPOINT.user.recovery_password,
            {
              hashed_email: form.hashed_email,
              recovery_key: form.recovery_key,
              verification_code: form.verification_code,
              password: hash(form.password),
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Accept-Language": i18n.language,
              },
            }
          );

          if (response.status === 200) {
            // navigate('/verify-account/', { state: { hashed_email: hash(form.email), email: form.email } })
            setForm({
              ...form,
              new_recovery_key: response.data.new_recovery_key,
            });
          } else if (response.status === 400) {
            // verifica la información proporcionada y vuelve a intentarlo
            setForm({
              ...form,
              new_recovery_key: "bad-request",
            });
          }
        } catch (error) {
          if (verificationAttempts === 1) {
            setErrors({
              message: i18nGetData(
                "landing form reset-password-error maximum-attempts",
                { verificationAttempts: MAXIMUM_ATTEMPTS }
              ),
            });

            // Block user for 1 hour
            const date = new Date();
            date.setHours(date.getHours() + 1);
            saveCookieData("blocked", true, { path: "/", expires: date });
          } else if (verificationAttempts > 1) {
            setErrors({
              message: `${i18nGetData(
                "landing form create-account error-occurred",
                { verificationAttempts: verificationAttempts - 1 }
              )}`,
            });
            setVerificationAttempts(verificationAttempts - 1);
          } else {
            setErrors({
              error_response: `${i18nGetData(
                "landing form reset-password-error"
              )}.`,
            });
          }
        }
      })();
    } else {
      return;
    }
  };
  const handleResendCode = async (e) => {
    e.preventDefault();

    setCounter(60);
    setAttempts((current) => current - 1);

    try {
      const response = await axios.post(
        BASE_URL + ENDPOINT.user.email_code,
        {
          email: form.email,
          hashed_email: form.hashed_email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status) {
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    form,
    errors,
    counter,
    attempts,
    handleChange,
    handleBlur,
    handleResendCode,
    handleSubmit,
    setCounter,
  };
};
