import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderForm from "components/HeaderForm";
import {
  twoFactorAuthentication,
  Role,
  twoFactorAuthErrors,
} from "../../helpers/constants";
import TwoFactorAuthForm from "./TwoFactorAuthForm";
import CodeVerification from "./CodeVerification";
import { updateUserSetting, verify2FA } from "../../services/users";
import { login } from "../../pages/landing/login/services/login";
import { saveCookieData, showPhoneNumber } from "../../helpers/helpers";
import useAuth from "../../hooks/useAuth";
import classNames from "classnames";

function TwoFactorAuth() {
  const { t, i18n } = useTranslation("global");
  const [form, setForm] = useState({});
  const [codeError, setCodeError] = useState("");
  const [disableResend, setDisableResend] = useState(false);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    authorization,
    phone,
    role,
    two_factor_authentication,
    hashed_email,
    password,
  } = location.state;
  const { setAuth } = useAuth();

  useEffect(() => {
    if (
      codeError === "" &&
      two_factor_authentication === twoFactorAuthentication.EVERY_LOG_IN
    ) {
      setStep(2);
    } else {
      setForm({
        option:
          role === Role.ADMIN
            ? twoFactorAuthentication.EVERY_LOG_IN
            : two_factor_authentication || twoFactorAuthentication.DISABLED,
        phoneNumber: phone || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goTo = (role) => {
    if (role === "survivor") {
      navigate("/welcome", {
        replace: true,
        state: {
          login: true,
        },
      });
    } else {
      navigate("/admin-dashboard/");
    }
  };

  const saveData = (authorization, refreshToken) => {
    setAuth({
      loggedUser: true,
      role: "survivor",
      accessToken: authorization,
      refreshToken,
    });

    saveCookieData(
      "auth",
      {
        loggedUser: true,
        role: "survivor",
        accessToken: authorization,
        refreshToken,
      },
      { path: "/" }
    );
  };

  const handleErrors = (error) => {
    setCodeError(
      error.response.data.error === twoFactorAuthErrors.BAD_CODE
        ? t("landing form two-factor-authentication-error-1")
        : error.response.data.error === twoFactorAuthErrors.PHONE_ERROR
        ? error.response.data.message
        : t("landing form two-factor-authentication-error-2")
    );
  };

  const handleSubmit = (data) => {
    if (step === 1) {
      if (form.option === twoFactorAuthentication.DISABLED) {
        Promise.resolve(
          updateUserSetting(authorization, {
            two_factor_authentication: form.option,
          })
        )
          .then((res) => {
            if (res) {
              navigate("/dashboard");
            }
          })
          .catch((error) => {
            handleErrors(error);
          });
      } else {
        setCodeError("");
        Promise.resolve(
          updateUserSetting(authorization, {
            two_factor_authentication: form.option,
            phone: form.phoneNumber,
          })
        )
          .then((res) => {
            if (res) {
              Promise.resolve(
                login({
                  user: {
                    hashed_email,
                    password,
                  },
                  language: i18n.language,
                })
              ).catch((error) => {
                if (
                  error.response.data.error === twoFactorAuthErrors.EVERY_LOG_IN
                ) {
                  setStep(2);
                } else {
                  handleErrors(error);
                }
              });
            }
          })
          .catch((error) => {
            handleErrors(error);
          });
      }
    } else if (step === 2) {
      setCodeError("");
      setLoading(true);
      Promise.resolve(
        verify2FA({
          authCode: data,
          hashed_email,
        })
      )
        .then((res) => {
          if (res) {
            const { role, authorization, refreshToken } = res;
            goTo(role);
            saveData(authorization, refreshToken);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (error.response.data.error === twoFactorAuthErrors.PHONE_ERROR) {
            setStep(1);
          }
          handleErrors(error);
          setLoading(false);
        });
    }
  };

  const handleResendCode = () => {
    const user = {
      hashed_email,
      password,
    };

    if (!disableResend) {
      setDisableResend(true);
      setLoading(true);
      Promise.resolve(login({ user, language: i18n.language }))
        .then((res) => {
          if (res) {
            setLoading(false);
            setTimeout(() => {
              setDisableResend(false);
            }, 60000);
          }
        })
        .catch((error) => {
          if (error.response.data.error === twoFactorAuthErrors.PHONE_ERROR) {
            setStep(1);
          }

          if (error.response.data.error !== twoFactorAuthErrors.EVERY_LOG_IN) {
            handleErrors(error);
          }
          setLoading(false);
        });
    }
  };

  const descriptionFirstStep = `<p>${t(
    "landing two-factor-authentication section-description-form"
  )}</p>`;

  const descriptionSecondStep = `<p>${
    !!phone || form?.phoneNumber
      ? `${t(
          "landing two-factor-authentication section-description-verification",
          {
            phone:
              step === 2 ? showPhoneNumber(form?.phoneNumber || phone) : "",
          }
        )}`
      : t(
          "landing two-factor-authentication section-description-verification-2"
        )
  }</p>`;

  return (
    <div
      className={classNames({
        "container-form__two-factor-auth": true,
        "ar-letter-spacing": i18n.language === "ar",
      })}
    >
      <HeaderForm
        title={t("landing two-factor-authentication section-title")}
        description={step === 1 ? descriptionFirstStep : descriptionSecondStep}
        lang={i18n.language}
      />
      <div className="container-body__form">
        {step === 1 ? (
          <TwoFactorAuthForm
            role={role}
            form={form}
            setForm={setForm}
            onSubmit={handleSubmit}
            codeError={codeError}
          />
        ) : (
          <CodeVerification
            onSubmit={handleSubmit}
            codeError={codeError}
            disableResend={disableResend}
            handleResendCode={handleResendCode}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
}

export default TwoFactorAuth;

