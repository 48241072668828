import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import classNames from "classnames";

function DialogEditIncident(props) {
  const { t, i18n } = useTranslation("global");

  return (
    <Fragment>
      <div className="lightbox-container__content">
        <p lang={i18n.language}>{t("incident-form dialog edit-incident-1")}</p>
        <p lang={i18n.language}>{t("incident-form dialog edit-incident-2")}</p>
        <p lang={i18n.language}>{t("incident-form dialog edit-incident-3")}</p>
        <Button
          text={t("incident-form dialog edit-incident-answer-button")}
          class={classNames({
            "btn-start-incident-form-modal": true,
            "ar-letter-spacing": i18n.language === "ar",
          })}
          onClick={(e) => {
            props.setModalContent("overview-modal-edit-incident");
          }}
        />
      </div>
    </Fragment>
  );
}

export default DialogEditIncident;
