import React, { Fragment } from "react";
import Question from "components/questions/Question";
import { characterLimitMessage } from "helpers/helpers";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

function Impact({ form, errors, page, handleChange, handleChangeRadio }) {
  const { t, i18n } = useTranslation("global");

  console.log(t("incident-form impact question-concerns"));
  console.log(t("incident-form impact question-impact"));
  console.log(t("incident-form impact question-anything-to-add"));
  return (
    <Fragment>
      <div
        className={classNames({
          "small-column step-incident impact": true,
          "ar-letter-spacing": i18n.language === "ar",
        })}
      >
        <div className="container-question" lang={i18n.language}>
          <div className="question-title">
            {t("incident-form impact question-feel")}
          </div>
          <div className="question-answers">
            {" "}
            <textarea
              type="text"
              placeholder={`${t(
                "incident-form impact question-feel-answer"
              )}\n ${characterLimitMessage(3000, t("platform char-limit"))}`}
              rows="10"
              maxLength={3000}
              name="feelings"
              value={form.feelings}
              onChange={handleChange}
              lang={i18n.language}
            />
          </div>
        </div>
      </div>
      <div
        className={classNames({
          "big-column step-incident impact": true,
          "ar-letter-spacing": i18n.language === "ar",
        })}
      >
        <Question
          questionTitle={t("incident-form impact question-support")}
          answerType="radiobutton"
          form={form}
          errors={errors}
          handleChange={handleChange}
          handleChangeRadio={handleChangeRadio}
          defaultOption={1}
          fieldName="need_support"
          subfield={false}
          subfieldContent={[]}
          page={page}
        />

        <span className="note-message-form" lang={i18n.language}>
          {t("incident-form perpetrator question-support-message")}
        </span>

        {form.need_support === true && (
          <div className="container-question" lang={i18n.language}>
            <div className="question-title">
              {t("incident-form impact question-kind-of-support")}
            </div>
            <div className="question-answers">
              <textarea
                type="text"
                maxLength={3000}
                placeholder={`${t(
                  "incident-form impact question-kind-of-support-answer"
                )} ${characterLimitMessage(3000, t("platform char-limit"))}`}
                rows="10"
                name="support_type"
                value={form.support_type}
                onChange={handleChange}
              />
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default Impact;

