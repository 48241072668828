import React, { useContext } from "react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HeaderForm from "components/HeaderForm";
import AuthContext from "context/AuthProvider";
import { clearCookiesData } from "helpers/helpers";
import closeIcon from "../../assets/images/close-btn-white.svg";

function DeleteAccountSuccessful({ setSubmodalContent }) {
  const { t, i18n } = useTranslation("global");
  const { setAuth } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleClick = () => {
    setAuth({});
    clearCookiesData();
    navigate("/home");
    setSubmodalContent("");
  };

  return (
    <div className="delete-account-container">
      <div className="floating-close-btn-container">
        <button className="custom btn-close" onClick={handleClick}>
          <img src={closeIcon} alt="Close button" />
        </button>
      </div>
      <HeaderForm
        title={t("platform delete-account-successful title")}
        description={t("platform delete-account-successful description")}
        lang={i18n.language}
      />
      <div className="button-container">
        <Button
          text={t("platform delete-account-successful button")}
          class="btn-delete-account small"
          onClick={handleClick}
        />
      </div>
    </div>
  );
}

export default DeleteAccountSuccessful;
