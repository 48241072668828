import classNames from "classnames";
import { decipher } from "lib/utils/encryption";
import { useState, useEffect, useRef } from "react";
import { getCustomDateFormat } from "../../helpers/helpers";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";
import { incidentItemId } from "../../adapters/incidentForm";
import { incidentView, AUTO_REPLAY_ID } from "helpers/constants";

const Messages = ({
  socket,
  senderId,
  currentChatRoom,
  messages,
  isLoading,
  setSelectedIncident,
  setSelectedIncidentView,
  setModalChat,
  userRole,
}) => {
  const { i18n, t } = useTranslation("global");
  const [messagesReceived, setMessagesReceived] = useState(messages);

  const messagesColumnRef = useRef(null);

  // Runs whenever a socket event is recieved from the server
  useEffect(() => {
    socket.on("receive_message", (data) => {
      setMessagesReceived((state) => [
        ...state,
        {
          message:
            data.sender === AUTO_REPLAY_ID
              ? data.content
              : decipher(currentChatRoom.p_content_key, data.content),
          username: data.sender,
          created_at: data.created_at,
          senderRole: data.senderRole,
          sender: data.sender,
          senderName: data.senderName,
          status: data.status,
        },
      ]);
    });

    // Remove event listener on component unmount
    return () => socket.off("receive_message");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  // Scroll to the most recent message
  useEffect(() => {
    messagesColumnRef.current.scrollTop =
      messagesColumnRef.current.scrollHeight;
  }, [messagesReceived]);

  const onIncidentClick = (incidentForm) => {
    setModalChat(false);
    setSelectedIncident(incidentForm);
    setSelectedIncidentView(incidentView.INCIDENT_REVIEW);
  };

  return (
    <div className="chat-messages-container" ref={messagesColumnRef}>
      {isLoading ? (
        <div className="loading-container">
          <Loading />
        </div>
      ) : (
        <>
          {currentChatRoom?.incidents?.length ? (
            <div className="incidents-shared-container"  lang={i18n.language}>
              <p>{t("messages incidents-shared")}</p>
              {currentChatRoom.incidents.map((incident) => (
                <a
                  href={
                    userRole === "admin"
                      ? "#incident-forms"
                      : "#your-incident-forms"
                  }
                  onClick={() => onIncidentClick(incident)}
                >
                  {incidentItemId(incident.id)}
                </a>
              ))}
            </div>
          ) : null}
          {messagesReceived.map((msg, i) => (
            <div
              className={classNames({
                "chat-message-item": true,
                "current-user": msg?.senderRole === userRole,
              })}
              key={i}
            >
              <div
                className="chat-message-item__container"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span className="chat-message-item__username">
                  {msg?.sender && msg?.sender === senderId
                    ? t("send-message you")
                    : msg?.senderName}
                </span>
                <span className="chat-message-item__date">
                  {getCustomDateFormat(msg.created_at, i18n)}
                </span>
              </div>
              <p
                className={classNames({
                  "chat-message-item__card_message": true,
                  sender: msg.senderRole === userRole,
                  receiver: msg.senderRole !== userRole,
                })}
                lang={i18n.language}
              >
                {msg?.message ? msg?.message : msg?.content}
              </p>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Messages;
