import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Perpetrator from "./Perpetrator";

const perpetratorObj = {
  id: null,
  first_name: "",
  last_name: "",
  perpetrator_email: "",
  perpetrator_position_or_grade: "",
  perpetrator_location: "",
  relation_with_perpetrator: "",
  custom_relation_with_perpetrator: "",
  perpetrator_role: "", //required
  custom_perpetrator_role: "",
  knew_perpetrator: null,
  affiliation_perpetrator: "",
  perpetrator_nationality: "",
};

export const usePerpetratorList = ({
  perpetratorsList,
  setPerpetratorList,
  form,
  setForm,
}) => {
  const [countPerpetrator, setCountPerpetrator] = useState(
    perpetratorsList?.length || 0
  );

  const handleAddPerpetrator = (e) => {
    e.preventDefault();

    if (countPerpetrator < 5) {
      setCountPerpetrator((current) => current + 1);
      setPerpetratorList([...perpetratorsList, perpetratorObj]);
      setForm({
        ...form,
        perpetrators: [...perpetratorsList, perpetratorObj],
      });
    }
  };

  return {
    countPerpetrator,
    setCountPerpetrator,
    handleAddPerpetrator,
  };
};

function PerpetratorFormList({
  form,
  setForm,
  errors,
  perpetratorsList,
  handleChange,
  setPerpetratorList,
  handleChangePerpetrator,
  handleRemovePerpetrator,
  page,
}) {
  const { t, i18n } = useTranslation("global");

  const { countPerpetrator, handleAddPerpetrator, setCountPerpetrator } =
    usePerpetratorList({ perpetratorsList, setPerpetratorList, form, setForm });

  return (
    <div className="perpetrator-list__container required-message">
      <div className="required-indicator-message"  lang={i18n.language}>
        {t("incident-form general top-message-required")}
      </div>
      <div className="perpetrator-list__items">
        {perpetratorsList.length > 0 &&
          perpetratorsList.map((item, index) => (
            <Perpetrator
              key={index}
              id={index}
              form={form}
              errors={errors}
              perpetrator={item}
              perpetratorsList={perpetratorsList}
              setPerpetratorList={setPerpetratorList}
              handleChange={handleChange}
              handleChangePerpetrator={handleChangePerpetrator}
              handleRemovePerpetrator={handleRemovePerpetrator}
              countPerpetrator={countPerpetrator}
              setCountPerpetrator={setCountPerpetrator}
              page={page}
              handleAddPerpetrator={handleAddPerpetrator}
            />
          ))}
      </div>
    </div>
  );
}

export default PerpetratorFormList;
