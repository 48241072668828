import axios from "axios";
import classNames from "classnames";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { readIncident } from "services/incidents";
import {
  BASE_URL,
  ENDPOINT,
  Role,
  incidentStatus,
  incidentView,
} from "../../helpers/constants";
import { getCookieData, getCustomDateFormat } from "../../helpers/helpers";
import Button from "../Button";

function NotificationItem({
  currentChatRoom,
  currentId,
  date,
  deleteHistory,
  description,
  form,
  getChatRoomData,
  getIncidents,
  handleDelete,
  handleEdit,
  id,
  isRead,
  markAsUnread,
  modalChat,
  setCurrentId,
  setModalChat,
  setSelectedFormId,
  setSelectedIncidentView,
  setSubModal,
  setSubModalContent,
  status,
  title,
  type,
  updateNotifications,
  userRole,
  view,
  viewIncident,
}) {
  const { t, i18n } = useTranslation("global");
  const [toggle, setToggle] = useState(false);

  const handleDeleteNotificationById = (e) => {
    e.preventDefault();
    const user = getCookieData("auth", { path: "/" });

    var data = JSON.stringify({
      notifications: [id],
    });

    var config = {
      method: "delete",
      url: BASE_URL + ENDPOINT.notifications.delete,
      headers: {
        Authorization: `${user.accessToken}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        updateNotifications();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleMarkAsReadUnreadNotification = (e) => {
    e.preventDefault();
    const user = getCookieData("auth", { path: "/" });

    var config = {
      method: "put",
      url: BASE_URL + ENDPOINT.notifications.get_notifications + "/" + id,
      headers: {
        Authorization: `${user.accessToken}`,
      },
      data: {
        read: status,
      },
    };

    axios(config)
      .then(function () {
        updateNotifications();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleMarkAsReadIncident = () => {
    const token = getCookieData("auth", { path: "/" });

    Promise.resolve(
      readIncident({
        token: token.accessToken,
        id,
        markAsRead: true,
      })
    ).catch((error) => {
      console.log("err", error);
    });
  };

  const handleMarkAsReadUnreadChatRoom = (markAs) => {

    markAsUnread(id, markAs);
    setToggle(!toggle);
  };

  const handleDeleteChatRoom = (e) => {
    e.stopPropagation();
    e.preventDefault();
    deleteHistory(id);
    setToggle(!toggle);
  };

  const handleEditIncident = (e) => {
    e.stopPropagation();
    e.preventDefault();
    handleEdit(form);
  };

  const handleDownloadIncident = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedFormId(form);
    setSubModalContent("download-incident");
    setSubModal(true);

    if (view === Role.ADMIN && !isRead) {
      handleMarkAsReadIncident();
    }
  };

  const handleDeleteIncident = (e) => {
    e.stopPropagation();
    e.preventDefault();
    handleDelete(form);
  };

  const handleShareIncident = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedFormId(form);
    setSubModalContent("share-incident");
    setSubModal(true);
  };

  const handleClickItem = (e) => {
    e.stopPropagation();

    if (
      type === "chatroom" &&
      id !== currentChatRoom?.id &&
      currentChatRoom.id
    ) {
      markAsUnread(currentChatRoom.id, "open");
      getChatRoomData(id);
      setSubModal(false);
      setModalChat(true);
    } else if (
      type === "chatroom" &&
      Object.keys(currentChatRoom).length === 0
    ) {
      markAsUnread(id, "open");
      setSubModal(false);
      setModalChat(true);
      getChatRoomData(id);
    } else if (type === "incident") {
      if (form?.status && form.status === incidentStatus.IN_DRAFT.id) {
        handleEditIncident(e);
      } else {
        if (view === Role.ADMIN && !isRead) {
          handleMarkAsReadIncident();
        }

        viewIncident(form);
        setSelectedIncidentView(incidentView.INCIDENT_REVIEW);
      }
    }
  };

  const toggleReadIncident = async (markAsRead) => {
    const token = getCookieData("auth", { path: "/" });
    await readIncident({ token: token.accessToken, id, markAsRead });
    getIncidents();
  };

  const getMenuItems = () => {
    switch (type) {
      case "chatroom":
        return (
          <Fragment>
            <Button
              className="custom actions-options"
              text={
                !status
                  ? t("notifications chatroom mark-as-unread-button")
                  : t("notifications chatroom mark-as-read-button")
              }
              onClick={(e)=>{
                e.stopPropagation();
                e.preventDefault();
                handleMarkAsReadUnreadChatRoom(status ? 'read' : 'unread')
              }}
            />
            {userRole === Role.SURVIVOR && (
              <Button
                className="custom actions-options delete-option"
                text={t("notifications chatroom delete-message-history-button")}
                onClick={handleDeleteChatRoom}
              />
            )}
          </Fragment>
        );

      case "notification":
        return (
          <Fragment>
            <Button
              className="custom actions-options"
              text={
                !status
                  ? t("notifications notification mark-as-unread-button")
                  : t("notifications notification mark-as-read-button")
              }
              onClick={handleMarkAsReadUnreadNotification}
            />
            <Button
              className="custom actions-options delete-option"
              text={t("notifications notification delete-notification-button")}
              onClick={handleDeleteNotificationById}
            />
          </Fragment>
        );

      default:
        return (
          <Fragment>
            {view === "survivor" ? (
              <Fragment>
                <Button
                  className="custom actions-options"
                  text={t("notifications other edit-form-button")}
                  onClick={handleEditIncident}
                />
                <Button
                  className="custom actions-options"
                  text={t("notifications other download-form-button")}
                  onClick={handleDownloadIncident}
                />
                {form && form.status === incidentStatus.SUBMITTED.id && (
                  <Button
                    className="custom actions-options"
                    text={t("notifications other share-form-button")}
                    onClick={handleShareIncident}
                  />
                )}
                <Button
                  className="custom actions-options delete-option"
                  text={t("notifications other delete-form-button")}
                  onClick={handleDeleteIncident}
                />
              </Fragment>
            ) : (
              <>
                {isRead ? (
                  <Button
                    className="custom actions-options"
                    text={t("notifications notification mark-as-unread-button")}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      toggleReadIncident(false);
                    }}
                  />
                ) : (
                  <Button
                    className="custom actions-options"
                    text={t("notifications notification mark-as-read-button")}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      toggleReadIncident(true);
                    }}
                  />
                )}
                <Button
                  className="custom actions-options"
                  text={t("notifications other download-form-button")}
                  onClick={handleDownloadIncident}
                />
              </>
            )}
          </Fragment>
        );
    }
  };

  const handleCurrentId = (e) => {
    e.stopPropagation();

    if (id !== currentId) {
      setCurrentId(id);
    } else {
      setCurrentId("");
    }
  };

  React.useEffect(() => {
    if (currentId !== id) {
      setToggle(false);
    } else {
      setToggle(true);
    }
  }, [currentId, id]);

  //STATUS TRUE MEANS THERE ARE NEW MESSAGES

  return (
    <div
      className={classNames({
        "notification-item": true,
        open: toggle === false,
        close: toggle,
        "chat-item": type === "chatroom",
        "selected-item": type === "chatroom" && id === currentChatRoom.id,
      })}
      onClick={handleClickItem}
    >
      <div className="notification-item__container">
        <div
          className={classNames({
            "notification-item__title": true,
            "notification-item__title-ar": type === "notification" && i18n.language === 'ar',
            "modal-chat-open": modalChat,
            readed:
              type === "incident"
                ? view === Role.ADMIN
                  ? isRead
                  : false
                : !status,
            open: toggle === false,
            close: toggle,
            "selected-item": type === "chatroom" && id === currentChatRoom.id,
          })}
          lang={i18n.language}
        >
          {title}
        </div>
        <div
          className={classNames({
            "notification-item__description": true,
            readed:
              type === "incident"
                ? view === Role.ADMIN
                  ? isRead
                  : false
                : !status,
            open: toggle === false,
            close: toggle,
            "selected-item": type === "chatroom" && id === currentChatRoom.id,
          })}
          lang={i18n.language}
        >
          {description}
        </div>

        {(type === "notification" || type === "chatroom") && (
          <div
            className={classNames({
              "notification-item__date": true,
              readed: !status,
              open: toggle === false,
              close: toggle,
              "selected-item": type === "chatroom" && id === currentChatRoom.id,
            })}
          >
            {getCustomDateFormat(
              type === "chatroom" &&
                id === currentChatRoom.id &&
                currentChatRoom?.last_message_date
                ? currentChatRoom.last_message_date
                : date,
              i18n
            )}
          </div>
        )}
      </div>
      <div
        className={classNames({
          "options-menu": true,
          open: toggle === false,
          close: toggle,
        })}
        onClick={handleCurrentId}
      ></div>
      <div
        className={classNames({
          "notification-item__options-menu": true,
          open: toggle === false,
          close: toggle,
        })}
      >
        {getMenuItems()}
      </div>
    </div>
  );
}

export default NotificationItem;
