import React from "react";
import Button from "components/Button";
import BtnCloseWithX from "components/icons/BtnCloseWithX";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

function WelcomeAfterLogin() {
  const { t, i18n } = useTranslation("global");
  let navigate = useNavigate();

  const handleClick = (e) => {
    navigate("/dashboard/");
  };

  return (
    <div className="container-modal-welcome">
      <button
        className="small custom btn-close welcome-modal"
        onClick={handleClick}
      >
        <BtnCloseWithX color="white" strokeWidth="1" height="80" width="80" />
      </button>
      <div className="welcome-text" lang={i18n.language}>
        {t("platform post-login-pop-up")}
      </div>
      <Button
        text={t("platform post-login-pop-up-btn-next")}
        class={classNames({
          "btn-next-welcome-modal": true,
          "ar-letter-spacing": i18n.language === "ar",
        })}
        onClick={handleClick}
      />
    </div>
  );
}

export default WelcomeAfterLogin;
