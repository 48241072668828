import React, { Fragment } from "react";
import { incidentView } from "helpers/constants";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import classNames from "classnames";

function DialogCreateNewIncident({
  setModalContent,
  setSelectedIncidentView,
  selectedIncidentView,
}) {
  const { t, i18n } = useTranslation("global");

  const handleNext = () => {
    setModalContent("overview-modal-create-incident");
    if (selectedIncidentView === incidentView.INCIDENT_LIST) {
      setSelectedIncidentView(incidentView.CREATE_INCIDENT);
    }
  };

  return (
    <Fragment>
      <div className="lightbox-container__content" lang={i18n.language}>
        <p lang={i18n.language}>{t("platform start-incident-pop-up")}</p>
        <Button
          text={t("platform start-incident-pop-up-btn-next")}
          class={classNames({
            "btn-start-incident-form-modal": true,
            "ar-letter-spacing": i18n.language === "ar",
          })}
          onClick={handleNext}
        />
      </div>
    </Fragment>
  );
}

export default DialogCreateNewIncident;

