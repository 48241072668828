import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import classNames from "classnames";

function DialogThankyouShareForm(props) {
  const { t, i18n } = useTranslation("global");

  return (
    <Fragment>
      <div
        className={classNames({
          "content-sublightbox__title submodal-incident__form": true,
          "content-sublightbox__title-ar": i18n.language === "ar",
        })}
        lang={i18n.language}
      >
        {t("incident-form submission-thankyou-title")}
      </div>
      <div className="content-sublightbox__content submodal-incident__form">
        <div className="content-sublightbox__content-description submodal-incident__form">
          <p
            className={classNames({
              "ar-text-align": i18n.language === "ar",
            })}
            lang={i18n.language}
          >
            {t("incident-form submission-thankyou-description")}
          </p>
        </div>
        <div className="content-sublightbox__options submodal-incident__form">
          <Button
            class="custom btn-close-thankyou"
            text={t("incident-form submission-thankyou-btn-close")}
            onClick={(e) => {
              e.preventDefault();
              props.setSubModal(false);
              props.setSubModalContent("");
            }}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default DialogThankyouShareForm;

