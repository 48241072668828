import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import global_ar from "translations/ar/global.json";
import global_ch from "translations/ch/global.json";
import global_en from "translations/en/global.json";
import global_es from "translations/es/global.json";
import global_fr from "translations/fr/global.json";
import global_ru from "translations/ru/global.json";

i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    es: {
      global: global_es,
    },
    fr: {
      global: global_fr,
    },
    ar: {
      global: global_ar,
    },
    ch: {
      global: global_ch,
    },
    ru: {
      global: global_ru,
    },
  },
});

export default i18n;

export const i18nGetData = (key, options) => {
  return i18n.t(key, { ns: "global", ...options });
};
