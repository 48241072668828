import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { default as ReactSelect, components } from "react-select";
import { decipher, cipher, genSecret } from "lib/utils/encryption";
import Button from "../Button";
import { createChatRoom } from "services/chats";
import { validationsFormStartMessage } from "helpers/validations";
import classNames from "classnames";

const initialForm = {
  subject: "",
  remain_anonymous: true,
  share_incident: false,
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

export const useStartMessage = (
  initialForm,
  tokenTemp,
  joinRoom,
  getChatRoomData,
  user,
  setMessagingView,
  setSubModal,
  setModalChat
) => {
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState({});
  const [optionSelected, setOption] = useState("");
  const [ifSelected, setIFSelected] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChangeRadio = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value === "1" ? true : false });
  };

  const handleChangeText = (e) => {
    const { value } = e.target;
    setForm({ ...form, subject: value });
  };

  const handleChangeSelect = (selected) => {
    const selectedIncidentForms = [
      ...selected.map((item) => {
        return item.value;
      }),
    ];

    setOption(selected);
    setIFSelected(selectedIncidentForms);
  };

  const handleSubmit = async (form) => {
    setLoading(true);
    setErrors(validationsFormStartMessage(form));
    if (!form.subject) {
      setLoading(false);
      return;
    }

    const fullName = `${decipher(
      user?.p_content_key,
      user?.first_name
    )} ${decipher(user?.p_content_key, user?.last_name)}`;

    const p_content_key = genSecret();

    const body = {
      subject: form.subject !== "" ? cipher(p_content_key, form.subject) : "",
      anonymous: form.remain_anonymous,
      survivorFullName: form.remain_anonymous ? "" : fullName,
      p_content_key,
      incidents: ifSelected,
    };

    await Promise.resolve(createChatRoom({ token: tokenTemp, body }))
      .then((res) => {
        getChatRoomData(res.chatRoom.id);
        joinRoom(
          form.subject,
          form.remain_anonymous,
          ifSelected,
          p_content_key,
          res.chatRoom,
          true
        );
        setMessagingView("view-chat");
        setSubModal(false);
        setModalChat(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return {
    form,
    errors,
    optionSelected,
    loading,
    handleChangeRadio,
    handleChangeText,
    handleChangeSelect,
    handleSubmit,
  };
};

function DialogStartMessage({
  tokenTemp,
  joinRoom,
  getChatRoomData,
  userData,
  setMessagingView,
  setSubModal,
  setModalChat,
  incidentIds,
}) {
  const { t, i18n } = useTranslation("global");
  const {
    form,
    errors,
    optionSelected,
    loading,
    handleChangeRadio,
    handleChangeText,
    handleChangeSelect,
    handleSubmit,
  } = useStartMessage(
    initialForm,
    tokenTemp,
    joinRoom,
    getChatRoomData,
    userData,
    setMessagingView,
    setSubModal,
    setModalChat
  );

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(form);
  };

  const onExit = () => {
    setSubModal(false);
    setModalChat(false);
  };

  return (
    <Fragment>
      <div
        className={classNames({
          "content-sublightbox__title submodal-start-message": true,
          "content-sublightbox__title-ar": i18n.language === "ar",
        })}
      >
        {t("messages start-message modal title")}
      </div>
      <div
        className={classNames({
          "content-sublightbox__content submodal-start-message": true,
          "ar-letter-spacing": i18n.language === "ar",
        })}
      >
        <div
          className="content-sublightbox__content-description submodal-start-message"
          lang={i18n.language}
        >
          <p>{t("messages start-message modal description")}</p>
          <form className="content-sublightbox__form">
            <div className="container_form__row">
              <div className="label-title">
                {t("messages start-message modal subject")}
              </div>
              <input
                type="text"
                placeholder={t(
                  "messages start-message modal subject placeholder"
                )}
                name="subject"
                value={form.subject}
                maxLength="60"
                onChange={handleChangeText}
              />
              <div className="character-limit-message">
                {errors.subject && (
                  <span className="error-message">{t(errors.subject)}</span>
                )}{" "}
                <span>{t("platform character-limit")}: 60</span>
              </div>
            </div>
            <div className="container_form__row">
              <div className="label-title">
                {t("incident-form message start-message-anonymous")}
              </div>
              <div
                className={classNames({
                  "container-radio-buttons-options start-message": true,
                  "radio-button-container-ar": i18n.language === "ar",
                })}
              >
                <div className="radio-button-container">
                  <input
                    type="radio"
                    id="remain-anonymous"
                    name="remain_anonymous"
                    value="1"
                    checked={form.remain_anonymous === true}
                    onChange={handleChangeRadio}
                  />
                  <label htmlFor="remain-anonymous">
                    {t("incident-form message start-message-anonymous-yes")}
                  </label>
                </div>
                <div className="radio-button-container">
                  <input
                    type="radio"
                    id="remain-anonymous-no"
                    name="remain_anonymous"
                    value="0"
                    checked={form.remain_anonymous === false}
                    onChange={handleChangeRadio}
                  />
                  <label htmlFor="remain-anonymous-no">
                    {t("incident-form message start-message-anonymous-no")}
                  </label>
                </div>
              </div>
              <span className="message-note-form">
                {t("incident-form message start-message-anonymous-message")}
              </span>
            </div>
            <div className="container_form__row">
              <div className="label-title">
                {t("incident-form message start-message-share-incident")}
              </div>
              <div
                className={classNames({
                  "container-radio-buttons-options start-message": true,
                  "radio-button-container-ar": i18n.language === "ar",
                })}
              >
                <div className="radio-button-container">
                  <input
                    type="radio"
                    id="share-incident"
                    name="share_incident"
                    value="1"
                    checked={form.share_incident === true}
                    onChange={handleChangeRadio}
                  />
                  <label htmlFor="share-incident">
                    {t(
                      "incident-form message start-message-share-incident-yes"
                    )}
                  </label>
                </div>
                <div className="radio-button-container">
                  <input
                    type="radio"
                    id="share-incident-no"
                    name="share_incident"
                    value="0"
                    checked={form.share_incident === false}
                    onChange={handleChangeRadio}
                  />
                  <label htmlFor="share-incident-no">
                    {t("incident-form message start-message-share-incident-no")}
                  </label>
                </div>
              </div>
              <span className="message-note-form">
                {t(
                  "incident-form message start-message-share-incident-message"
                )}
              </span>
            </div>
            {form.share_incident === true && (
              <Fragment>
                <div className="container_form__row">
                  <ReactSelect
                    options={incidentIds}
                    isMulti
                    className="select-if-ids"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    onChange={handleChangeSelect}
                    allowSelectAll={true}
                    placeholder={t("incident-form select-incident-placeholder")}
                    value={optionSelected}
                    noOptionsMessage={() =>
                      t("incident-form message no-incidents")
                    }
                  />
                </div>
              </Fragment>
            )}
            <div
              className={classNames({
                "content-sublightbox__options submodal-incident__form start-message-modal": true,
                "start-message-modal-ar": i18n.language === "ar",
              })}
            >
              <Button
                class="custom btn-exit-start-message"
                text={t("incident-form message start-message-btn-exit")}
                onClick={onExit}
              />
              <Button
                class="custom btn-star-a-new-message-unhcr"
                text={t(
                  "incident-form message start-message-btn-start-message"
                )}
                disabled={loading}
                onClick={onSubmit}
              />
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default DialogStartMessage;
