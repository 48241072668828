import React from "react";
import classNames from "classnames";
import { addPrefixLanding, getCookie } from "helpers/helpers";
import { useTranslation } from "react-i18next";

function AboutTheToolItem({ icon, title, description }) {
  const { t } = useTranslation("global");
  const lang = getCookie("language");

  return (
    <article
      className={classNames({
        [addPrefixLanding("section-about-the-tool__item")]: true,
        "large-string-lang": lang !== "en" && lang !== "ch" && lang !== "ar",
      })}
    >
      <figure
        className={addPrefixLanding("section-about-the-tool__icon-container")}
      >
        <img src={icon} alt={title} />
      </figure>
      <h4 className={addPrefixLanding("section-about-the-tool__title")}>
        {t(`${title}`)}
      </h4>
      <p className={addPrefixLanding("section-about-the-tool__description")}>
        {t(`${description}`)}
      </p>
    </article>
  );
}

export default AboutTheToolItem;
