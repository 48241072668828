import React, { Fragment } from "react";
import axios from "axios";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { BASE_URL, ENDPOINT } from "../../helpers/constants";
import { getCookieData } from "../../helpers/helpers";
import Button from "../Button";
import NotificationList from "../notifications/NotificationList";

function DialogNotifications(props) {
  const { t, i18n } = useTranslation("global");

  const handleMarkAllAsRead = (e) => {
    e.preventDefault();
    const user = getCookieData("auth", { path: "/" });

    let config = {
      method: "put",
      url: BASE_URL + ENDPOINT.notifications.get_notifications,
      headers: {
        Authorization: `${user.accessToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        props.updateNotifications();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const isSomeRead =
    props &&
    props.notificationList &&
    props.notificationList.length > 0 &&
    props.notificationList.filter((item) => !item.read).length > 0;

  return (
    <Fragment>
      <div className="lightbox-container__title" lang={i18n.language}>
        <h3>{t("notifications title")}</h3>
      </div>
      <div className="lightbox-container__content">
        <div className="lightbox-container__options">
          <Button
            class={classNames({
              custom: true,
              "btn-option-notification-list": true,
              "btn-option-notification-list-disabled": isSomeRead,
            })}
            text={t("notifications mark-all-as-read-button")}
            disabled={!isSomeRead}
            onClick={(e) => {
              handleMarkAllAsRead(e);
            }}
          />
          <Button
            class="custom btn-option-notification-list notification-settings"
            text={t("notifications settings-button")}
            onClick={(e) => {
              props.setModalContent("notifications-settings");
            }}
          />
        </div>
      </div>

      <NotificationList
        notificationList={props.notificationList}
        updateNotifications={props.updateNotifications}
        modalChat={props.modalChat}
        setModalChat={props.setModalChat}
      />
    </Fragment>
  );
}

export default DialogNotifications;
