import React, { Fragment } from "react";
import classNames from "classnames";
import ResourcesTab from "components/ResourcesTab";
import { useTranslation } from "react-i18next";
import { addPrefixLanding } from "../../helpers/helpers";
import Footer from "./components/Footer";
import Header from "./components/Header";

function UNHCRResources() {
  const { i18n } = useTranslation("global");
  return (
    <Fragment>
      <Header />
      <section
        className={classNames({
          [addPrefixLanding("support-section")]: true,
          [addPrefixLanding("section-2")]: true,
        })}
        lang={i18n.language}
      >
        <ResourcesTab isLanding />
      </section>
      <Footer />
    </Fragment>
  );
}

export default UNHCRResources;
