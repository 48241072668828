import React, { Fragment } from "react";
import classNames from "classnames";
import FormRoadMap from "../../components/FormRoadMap";
import { addPrefixLanding } from "../../helpers/helpers";
import Footer from "./components/Footer";
import Header from "./components/Header";

function ErrorPage() {
  return (
    <Fragment>
      <Header />
      <section
        className={classNames({
          [addPrefixLanding("support-section")]: true,
          [addPrefixLanding("section-2")]: true,
        })}
      >
        <FormRoadMap />
      </section>
      <Footer />
    </Fragment>
  );
}

export default ErrorPage;
