/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import "./style.scss";

const CookiesBanner = () => {
  const cookies = new Cookies();
  const [userAcceptedCookies, setUserAcceptedCookies] = useState(
    cookies.get("userAcceptedCookies")
  );
  const { t } = useTranslation("global");

  const handleAccept = () => {
    cookies.set("userAcceptedCookies", true);
    setUserAcceptedCookies(true);
  };

  if (userAcceptedCookies) {
    return null;
  }
  const description = `<p>${t("cookies message")}</p>`;
  return (
    <div className="cookies-banner">
      <div
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      ></div>
      <button onClick={handleAccept}>{t("cookies button")}</button>
    </div>
  );
};

export default CookiesBanner;
