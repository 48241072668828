import React, { Fragment } from "react";
import classNames from "classnames";

function AnswerItemSelect({
  form,
  errors,
  handleChange,
  handleBlur,
  options,
  fieldName,
  defaultOption,
  subfieldContent,
  otherOption,
}) {
  return (
    <Fragment>
      <select
        className={classNames({
          "error-field": errors[fieldName],
        })}
        name={fieldName}
        onChange={handleChange}
        defaultValue={defaultOption ? defaultOption : ""}
      >
        <option value="" disabled>
          Select one option
        </option>
        {options.map((option, index) => {
          if (option?.sub_options) {
            return (
              <optgroup label={option.value} key={"group" + index}>
                {option?.sub_options.map((item, i) => (
                  <option key={fieldName + " _sub" + i} value={item}>
                    {item}
                  </option>
                ))}
              </optgroup>
            );
          } else {
            const value = option?.value ? option?.value : option;
            return (
              <option key={fieldName + index} value={value}>
                {value}
              </option>
            );
          }
        })}
      </select>
      {errors[fieldName] && (
        <span className="error-message">{errors[fieldName]}</span>
      )}
      {form[fieldName] === "Other" && (
        <div className="subcontainer-answer require_field">
          <input
            type={otherOption.type}
            placeholder={otherOption.placeholder}
            name={"custom_" + fieldName}
            value={
              form["custom_" + fieldName] ? form["custom_" + fieldName] : ""
            }
            onChange={handleChange}
            required={otherOption.required}
          />
        </div>
      )}
    </Fragment>
  );
}

export default AnswerItemSelect;
