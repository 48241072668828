import React, { Fragment } from "react";
import UploadFile from "components/UploadFile";
import Question from "components/questions/Question";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

function Witnesses({
  form,
  errors,
  handleChange,
  handleChangeRadio,
  setForm,
  filesOfIncident,
  setFilesOfIncident,
  page,
  setTimeOutModal,
  timeOutModal,
}) {
  const { t, i18n } = useTranslation("global");

  return (
    <Fragment>
      <div
        className={classNames({
          "big-column step-documentation": true,
          "ar-letter-spacing": i18n.language === "ar",
        })}
      >
        <Question
          questionTitle={t("incident-form documentation question-upload-files")}
          answerType="radiobutton"
          form={form}
          errors={errors}
          handleChangeRadio={handleChangeRadio}
          handleChange={handleChange}
          fieldName="upload_file_option"
          defaultOption={1}
          subfield={false}
          subfieldContent={{
            fieldName: "upload_file",
            field: "download",
            type: "file",
            required: false,
          }}
          page={page}
        />
        <UploadFile
          incident={form}
          setForm={setForm}
          filesOfIncident={filesOfIncident}
          setFilesOfIncident={setFilesOfIncident}
          setTimeOutModal={setTimeOutModal}
          timeOutModal={timeOutModal}
        />
      </div>

      <div className="small-column step-documentation"></div>
    </Fragment>
  );
}

export default Witnesses;

