import React, { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCookieData } from "../helpers/helpers";
import { updateUserSetting } from "../services/users";

const UserSettingsContext = createContext(undefined);

export const UserSettingsProvider = ({ children }) => {
  const [userSettings, setUserSettings] = useState();
  const { i18n } = useTranslation("global");

  useEffect(() => {
    const cookieData = getCookieData("auth", { path: "/" });
    const language = userSettings?.language;

    if (language) {
      i18n.changeLanguage(language);
    }

    // if `userSettings.id` is defined is logged user, we could use `cookieData.accessToken` but is not cleaned up after logout
    if (userSettings?.id && cookieData?.accessToken) {
      updateUserSetting(cookieData.accessToken, { language });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettings]);

  return (
    <UserSettingsContext.Provider value={[userSettings, setUserSettings]}>
      {children}
    </UserSettingsContext.Provider>
  );
};

export default UserSettingsContext;
