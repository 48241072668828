import "./styles.scss";
import { useTranslation } from "react-i18next";

const ChangeEmailFourthStep = ({ onClose, email }) => {
  const { t } = useTranslation("global");

  return (
    <div className="changeEmail__container">
      <h2 className="modal-title">
        {t("settings change-email-modal title 4")}
      </h2>
      <p className="modal-description">
        {t("settings change-email-modal description 4", { email })}
      </p>
      <div className="footer">
        <button onClick={onClose} className="confirm-button">
          {t("settings change-email-modal button confirm 4")}
        </button>
      </div>
    </div>
  );
};

export default ChangeEmailFourthStep;
