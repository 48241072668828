import React from "react";
import classNames from "classnames";
import Button from "../Button";
import { getCookie } from "helpers/helpers";

function NavigationItem(props) {
  const language = getCookie("language");
  return (
    <div
      class={classNames({
        "step-container": true,
        "step-container-ru": language === "ru" && props.step === 2,
      })}
      data-step={props.step}
      data-current={props.current}
    >
      <Button
        class={classNames({
          "step-navigation__button custom": true,
          "custom-step-two-ru": language === "ru" && props.step === 2,
          "custom-step-two-es": language === "es" && props.step === 4,
          filled: props.current > props.step ? true : false,
          "icon-star fs-1": props.current > props.step ? true : false,
          active: props.current === props.step ? true : false,
        })}
        text={props.name}
        onClick={props.onClick}
        data={props.step}
      />
    </div>
  );
}

export default NavigationItem;
