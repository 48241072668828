import React, { createContext, useState } from "react";
import { getCookieData } from "../helpers/helpers";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(
    getCookieData("auth", { path: "/" })?.accessToken
      ? getCookieData("auth", { path: "/" })
      : {}
  );

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
