export const useSearch = (setSearch) => {
  const handleChange = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const getUnreadIncidents = (incidents) => {
    let count = 0;
    for (let i = 0; i < incidents.length; i++) {
      if (incidents[i].isRead === false) {
        count++;
      }
    }
    return count;
  };

  return {
    handleChange,
    getUnreadIncidents,
  };
};
