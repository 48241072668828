import React, { Fragment, useEffect } from "react";
import { useUploadFile } from "../hooks/useUploadFile";
import Button from "./Button";
import { limitText } from "helpers/helpers";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";
import downloadIcon from "assets/images/download-icon.svg";
import classNames from "classnames";
import TimeOutConnection from "components/dialogs/DialogTimeOutConnection";
import Modal from "components/Modal";

function UploadFile({
  incident,
  filesOfIncident,
  setFilesOfIncident,
  setForm,
  setTimeOutModal,
  timeOutModal,
}) {
  const { t, i18n } = useTranslation("global");
  const {
    filesUploaded,
    filesUploading,
    errors,
    setFilesUploaded,
    handleFile,
    handleDelete,
    deleteAllFiles,
  } = useUploadFile(incident, setFilesOfIncident, setForm, setTimeOutModal);

  useEffect(() => {
    if (filesOfIncident && filesOfIncident.length > 0) {
      setFilesUploaded(
        filesOfIncident.map((file) => ({ ...file, progress: 100 }))
      );
    }
  }, [filesOfIncident, setFilesUploaded]);

  useEffect(() => {
    if (incident.upload_file_option === false && filesOfIncident.length > 0) {
      deleteAllFiles(filesOfIncident);
    }
  }, [deleteAllFiles, filesOfIncident, incident]);

  const [filesQuantity, setFilesQuantity] = React.useState(0);

  const messageError = (error) => (
    <div className="mt-1" lang={i18n.language}>
      <span className="error-message">{`${error}`}</span>
    </div>
  );

  const handleMessageError = () => {
    return errors && errors.length === filesQuantity ? (
      errors.map((error) => messageError(error))
    ) : (
      <div className="updating-files">
        <Loading />
        <span className="uploading-files-info">
          {t("incident-form documentation loading-files-message")}
        </span>
      </div>
    );
  };

  return (
    <Fragment>
      {timeOutModal && filesUploading === 0 && (
        <Modal>
          <div
            className={classNames({
              "lightbox-component": true,
              timeout: true,
            })}
            lang={i18n.language}
          >
            <div
              className={classNames({
                "logout-confirm": true,
                "lightbox-container": true,
                timeout: true,
              })}
            >
              <TimeOutConnection setTimeOutModal={setTimeOutModal} />
            </div>
          </div>
        </Modal>
      )}
      {incident.upload_file_option ? (
        filesUploading === 0 ? (
          <div className="container-question">
            <div className="upload-file-field" lang={i18n.language}>
              <input
                id="upload-file"
                aria-label="File browser"
                type="file"
                name="file_data"
                multiple
                onChange={(e) => {
                  handleFile(e);
                  setFilesQuantity(e.target.files.length);
                }}
              />
              <div className="upload-drag-and-drop">
                <div className="icon"></div>
                <div className="text">
                  {t(
                    "incident-form documentation question-upload-files-drag-and-drop-text-1"
                  )}
                  {
                    <p>
                      {t(
                        "incident-form documentation question-upload-files-drag-and-drop-text-2"
                      )}
                    </p>
                  }{" "}
                  {t(
                    "incident-form documentation question-upload-files-drag-and-drop-text-3"
                  )}
                </div>
              </div>
            </div>
            <br />
            <span className="note-message-form" lang={i18n.language}>
              {t(
                "incident-form documentation question-upload-files-drag-and-drop-text-note"
              )}
            </span>
            <span className="note-message-form" lang={i18n.language}>
              {t(
                "incident-form documentation question-upload-files-drag-and-drop-text-maximum"
              )}
            </span>

            {filesUploaded && filesUploaded.length > 0 && (
              <div className="upload-files-section">
                <div className="container-items-uploaded">
                  <h4 lang={i18n.language}>
                    {t(
                      "incident-form documentation question-upload-files-items-loaded"
                    )}
                  </h4>
                  <div className="list-items-uploaded">
                    {filesUploaded.length > 0 &&
                      filesUploaded.map((file, index) => (
                        <div className="item-uploaded" key={index}>
                          <div className="item-uploaded-info__row">
                            <a
                              className="item-uploaded-download"
                              href={file.url}
                            >
                              <img src={downloadIcon} alt="download-icon" />
                            </a>
                            <div className="item-uploaded-property">
                              <span>{limitText(file.name, 13)}</span>
                            </div>
                            <div className="item-uploaded-property">
                              <progress
                                id="file"
                                max="100"
                                value={
                                  file?.id && file?.id !== ""
                                    ? 100
                                    : file.progress
                                }
                              >
                                {file?.id && file?.id !== ""
                                  ? "100%"
                                  : file.progress + "%"}
                              </progress>
                              <span className="progress-percentage">
                                {file?.id && file?.id !== ""
                                  ? "100%"
                                  : file.progress + "%"}
                              </span>
                            </div>
                            <Button
                              class="custom btn-remove-file"
                              text={t(
                                "incident-form documentation remove-button-text"
                              )}
                              onClick={(e) => {
                                e.preventDefault();
                                handleDelete(file.id);
                              }}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="container-upload-files"></div>
              </div>
            )}
            {errors && errors.length > 0
              ? errors.map((error) => messageError(error))
              : null}
          </div>
        ) : (
          handleMessageError()
        )
      ) : !incident.upload_file_option && filesOfIncident.length > 0 ? (
        <span className="note-message-form" lang={i18n.language}>
          {t("platform step-documentation deleted-files-text")}
        </span>
      ) : null}
    </Fragment>
  );
}

export default UploadFile;

