import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../Button";
import classNames from "classnames";
import Loading from "components/Loading";

function CodeVerification({
  onSubmit,
  codeError = "",
  disableResend = false,
  handleResendCode,
  isSetting = false,
  onCancel,
  loading = false,
  inviteAdminEmail,
}) {
  const { t, i18n } = useTranslation("global");
  const [code, setCode] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(code);
  };

  const handleEnterCode = (e) => {
    e.preventDefault();
    setCode(e.target.value);
  };

  return (
    <form className="container-form">
      <div className="container_form__row ">
        <div className="container_form__col">
          <div className="question-answers">
            <div className="container-radio-buttons-options">
              <div
                className={classNames({
                  "verify-code": true,
                  "ar-letter-spacing": i18n.language === "ar",
                })}
                lang={i18n.language}
              >
                <label
                  className={classNames({
                    "container-loading container-loading-small": true,
                    "container-loading-small-ar": i18n.language === "ar",
                  })}
                >
                  {t("landing form two-factor-authentication-code")}
                  {loading && <Loading />}
                </label>
                <input
                  type={"text"}
                  placeholder="..........."
                  name="verify-code"
                  onChange={handleEnterCode}
                  value={code}
                  required
                />
                {codeError !== "" && (
                  <span className="error-message">{codeError}</span>
                )}
                {disableResend && (
                  <span className="text-input-description">
                    {t("landing form two-factor-authentication-resend-code")}
                  </span>
                )}
                <span className="text-input-description">
                  {t(
                    "landing form two-factor-authentication-code-description-1"
                  )}
                  <span
                    className={classNames({
                      "error-message": true,
                      "resend-code": true,
                      "resend-code-disabled": disableResend,
                    })}
                    onClick={handleResendCode}
                  >
                    {t(
                      "landing form two-factor-authentication-code-description-2"
                    )}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames({
          "button-two-factor-auth-container": true,
          "button-two-factor-auth-setting-container": isSetting,
        })}
      >
        {!isSetting && (
          <Link className="back-button-link left-arrow" to="/login">
            {t("incident-form navigation-back")}
          </Link>
        )}
        <div className="container_form__row">
          <div className="container_form__col buttons-container">
            <Button
              class={classNames({
                "btn-verify-code": isSetting,
                "btn-reset-password": !isSetting,
                "ar-letter-spacing": i18n.language === "ar",
              })}
              text={
                inviteAdminEmail
                  ? "Confirm"
                  : isSetting
                  ? t("setting form verify-phone-number")
                  : t("landing form two-factor-authentication-btn-verify")
              }
              onClick={handleSubmit}
              disabled={code === "" ? true : false}
            />
          </div>
        </div>
        {isSetting && (
          <div className="container_form__row">
            <div className="container_form__col buttons-container">
              <Button
                class={classNames({
                  "btn-cancel-verify": true,
                  "ar-letter-spacing": i18n.language === "ar",
                })}
                text={t("settings form cancel-button")}
                onClick={onCancel}
              />
            </div>
          </div>
        )}
      </div>
    </form>
  );
}

export default CodeVerification;

