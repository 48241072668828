import React, { Fragment } from "react";
import closeIconSubModal from "assets/images/close-btn-white.svg";
import classNames from "classnames";
import Button from "components/Button";
import Loading from "components/Loading";
import DialogConfirmSubmitIncident from "components/dialogs/DialogConfirmSubmitIncident";
import DialogThankyouFormSubmitted from "components/dialogs/DialogThankyouFormSubmitted";
import NavigationItem from "components/navigation/NavigationItem";
import {
  incidentStatus,
  incidentView,
  subFormWitnessAndPeople,
} from "helpers/constants";
import Impact from "pages/platform/incidents/components/Impact";
import Incident from "pages/platform/incidents/components/Incident";
import PerpetratorFormList from "pages/platform/incidents/components/PerpetratorFormList";
import Review from "pages/platform/incidents/components/Review";
import Witnesses from "pages/platform/incidents/components/Witnesses";
import { useIncidentForm } from "pages/platform/incidents/hooks/useIncidentForm";
import { useTranslation } from "react-i18next";

const IncidentForm = ({
  selectedIncident,
  selectedIncidentView,
  setSelectedIncidentView,
  setSubModalView,
  setSubModalContentView,
  getIncidents,
  statusNavigationButtons,
  setSelectedFormId,
  setPageIncident,
  pageIncident,
  modalSubmitContent,
  setModalSubmitContent,
  modalSubmit,
  setModalSubmit,
  setTimeOutModal,
  timeOutModal,
}) => {
  const { t } = useTranslation("global");

  const {
    form,
    errors,
    startDate,
    endDate,
    startDateTime,
    perpetratorsList,
    timeStart,
    timeEnd,
    handleEndTime,
    filesOfIncident,
    loading,
    exitForm,
    setFilesOfIncident,
    setForm,
    setTimeStart,
    setEndDate,
    setPerpetratorList,
    handleChangePerpetrator,
    handleRemovePerpetrator,
    handleChange,
    saveAndNextStep,
    backStep,
    navigateToStep,
    saveAndExit,
    handleDate,
    handleEndDate,
    handleTimeInput,
    handleChangeRadio,
    submitAndShareForm,
  } = useIncidentForm(
    selectedIncident,
    statusNavigationButtons,
    selectedIncidentView,
    setSelectedIncidentView,
    setPageIncident,
    pageIncident,
    setModalSubmitContent,
    setSelectedFormId,
    getIncidents
  );

  const navigationItems = [
    t("incident-form navigation-step-1"),
    t("incident-form navigation-step-2"),
    t("incident-form navigation-step-3"),
    t("incident-form navigation-step-4"),
    t("incident-form navigation-step-5"),
  ];

  const checkErrors = () => Object.keys(errors).length > 0;
  const { i18n } = useTranslation("global");

  const displayStep = () => {
    switch (parseInt(pageIncident)) {
      case 1:
        return (
          <Incident
            form={form}
            handleChange={handleChange}
            startDate={startDate}
            endDate={endDate}
            setEndDate={setEndDate}
            handleDate={handleDate}
            handleEndDate={handleEndDate}
            startDateTime={startDateTime}
            timeStart={timeStart}
            setTimeStart={setTimeStart}
            handleEndTime={handleEndTime}
            timeEnd={timeEnd}
            handleTimeInput={handleTimeInput}
            handleChangeRadio={handleChangeRadio}
            errors={errors}
          />
        );
      case 2:
        return (
          <PerpetratorFormList
            form={form}
            setForm={setForm}
            errors={errors}
            handleChangePerpetrator={handleChangePerpetrator}
            perpetratorsList={perpetratorsList}
            setPerpetratorList={setPerpetratorList}
            handleChange={handleChange}
            handleChangeRadio={handleChangeRadio}
            handleRemovePerpetrator={handleRemovePerpetrator}
            page={pageIncident}
          />
        );
      case 3:
        return (
          <Witnesses
            form={form}
            setForm={setForm}
            errors={errors}
            handleChange={handleChange}
            handleChangeRadio={handleChangeRadio}
            setFilesOfIncident={setFilesOfIncident}
            filesOfIncident={filesOfIncident}
            subFormWitnessAndPeople={subFormWitnessAndPeople}
            page={pageIncident}
            setTimeOutModal={setTimeOutModal}
            timeOutModal={timeOutModal}
          />
        );
      case 4:
        return (
          <Impact
            form={form}
            errors={errors}
            handleChange={handleChange}
            handleChangeRadio={handleChangeRadio}
            page={pageIncident}
          />
        );
      case 5:
        return (
          <Review
            form={form}
            errors={errors}
            setSubModalContent={setSubModalContentView}
            setSubModalView={setSubModalView}
            setSelectedFormId={setSelectedFormId}
            getIncidents={getIncidents}
            view="review"
          />
        );
      default:
        return (
          <div className="loading-container">
            <Loading />
          </div>
        );
    }
  };

  const getSubModalContent = () => {
    if (modalSubmitContent === "confirm-submit-incident") {
      return (
        <DialogConfirmSubmitIncident
          setSubModal={setModalSubmit}
          submitAndShareForm={submitAndShareForm}
          modalSubmitContent={modalSubmitContent}
          setModalSubmitContent={setModalSubmitContent}
        />
      );
    } else {
      return (
        <DialogThankyouFormSubmitted
          setModalSubmit={setModalSubmit}
          setSubModalContent={setSubModalContentView}
          setSubModalView={setSubModalView}
        />
      );
    }
  };

  return (
    <Fragment>
      {modalSubmit && (
        <div
          className={classNames({
            "sub-lightbox-component": true,
            [`${modalSubmitContent}`]: true,
          })}
        >
          <div className="close-sub-lightbox">
            <button
              className="small custom btn-close"
              onClick={(e) => {
                setSelectedIncidentView(incidentView.INCIDENT_LIST);
              }}
            >
              <img src={closeIconSubModal} alt="Close button" />
            </button>
          </div>
          <div
            className={classNames({
              "container-sublightbox": true,
              [`${modalSubmitContent}`]: true,
            })}
          >
            <div className="content-sublightbox">{getSubModalContent()}</div>
          </div>
        </div>
      )}
      {loading ? (
        <div className="loading-container">
          {" "}
          <Loading />
        </div>
      ) : (
        <div
          className={classNames({
            "incident-form-layout": true,
            "vh-documentation": parseInt(pageIncident) === 3,
            "min-vh-documentation":
              parseInt(pageIncident) === 4 || parseInt(pageIncident) === 5,
          })}
        >
          <div
            className={classNames({
              "step-form-container": true,
              "step-review-container":
                parseInt(pageIncident) === navigationItems.length
                  ? true
                  : false,
            })}
          >
            {displayStep()}
          </div>

          <div className="aside-incident-form">
            <div
              className={classNames({
                "steps-navigation": true,
                "steps-navigation-ch":
                  i18n.language === "ch" && parseInt(pageIncident) === 3,
                "steps-navigation-impact": parseInt(pageIncident) === 4,
              })}
            >
              {navigationItems.map((item, index) => {
                return (
                  <NavigationItem
                    name={item}
                    step={index + 1}
                    steps={navigationItems.length}
                    current={pageIncident}
                    onClick={navigateToStep}
                    key={index}
                    form={form}
                  />
                );
              })}
            </div>
            <div
              className={classNames({
                "nav-buttons": true,
                "nav-buttons-ch":
                  i18n.language === "ch" && parseInt(pageIncident) === 3,
                "review-step": parseInt(pageIncident) === 5,
                "review-step-submitted":
                  form.status === incidentStatus.SUBMITTED.id,
                "review-step-submitted-impact": parseInt(pageIncident) === 4,
                "review-step-submitted-impact-non-english":
                  i18n.language !== "en" && parseInt(pageIncident) === 4,
              })}
            >
              <div className="buttons-container">
                <Button
                  class={classNames({
                    "left-arrow btn-incident-form-nav": true,
                    "ar-letter-spacing": i18n.language === "ar",
                  })}
                  onClick={backStep}
                  text={t("incident-form navigation-back")}
                />
                {parseInt(pageIncident) !== navigationItems.length && (
                  <Button
                    className={classNames({
                      "btn-incident-form-nav":
                        parseInt(pageIncident) < navigationItems.length - 1,
                      "final-step btn-incident-form-nav":
                        parseInt(pageIncident) === navigationItems.length - 1,
                      "final-step review btn-incident-form-nav":
                        parseInt(pageIncident) > navigationItems.length - 1,
                      "review-non-english": i18n.language !== "en",
                      "ar-letter-spacing": i18n.language === "ar",
                    })}
                    onClick={saveAndNextStep}
                    text={
                      parseInt(pageIncident) < navigationItems.length - 1
                        ? t("incident-form navigation-next")
                        : parseInt(pageIncident) === navigationItems.length - 1
                        ? t("incident-form navigation-review-and-submit")
                        : t("incident-form navigation-submit-incident-form")
                    }
                  />
                )}
                {parseInt(pageIncident) === navigationItems.length &&
                form.status === incidentStatus.IN_DRAFT.id ? (
                  <Button
                    class={classNames({
                      "final-step review btn-incident-form-nav": true,
                      "ar-letter-spacing": i18n.language === "ar",
                    })}
                    text={t("incident-form navigation-submit-incident-form")}
                    disabled={checkErrors()}
                    onClick={(e) => {
                      setModalSubmitContent("confirm-submit-incident");
                      setModalSubmit(true);
                    }}
                  />
                ) : null}

                {selectedIncidentView === incidentView.INCIDENT_REVIEW ? (
                  <Button
                    class={classNames({
                      "custom btn-incident-form-nav": true,
                      "ar-letter-spacing": i18n.language === "ar",
                    })}
                    text={t("incident-form navigation-form-exit")}
                    onClick={(e) => {
                      exitForm();
                    }}
                  />
                ) : (
                  <Button
                    class={classNames({
                      custom: true,
                      "btn-incident-form-nav": true,
                      "btn-incident-form-nav-review": true,
                      "impact-exit": parseInt(pageIncident) === 4,
                      "ar-letter-spacing": i18n.language === "ar",
                    })}
                    text={t("incident-form navigation-save-and-exit")}
                    onClick={saveAndExit}
                  />
                )}
              </div>
            </div>
            <div
              className={classNames({
                custom: true,
                "mobile-required-msj": true,
                "mobile-required-msj-ar": i18n.language === "ar",
              })}
            >
              <p>
                {t("landing form create-account-required-field-text")}
              </p>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default IncidentForm;
