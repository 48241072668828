import { ReactComponent as AboutToolIcon } from "../assets/images/how-to-use-the-tool-active.svg";
import { i18nGetData } from "../i18n";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const APP_LANGUAGES = [
  { id: "en", name: "EN" },
  { id: "es", name: "ES" },
  { id: "fr", name: "FR" },
  { id: "ar", name: "AR" },
  { id: "ch", name: "CH" },
  { id: "ru", name: "RU" },
];

/* =============================================================================== */
//Endpoints
export const ENDPOINT = {
  user: {
    delete_account: "/user/delete",
    email_code: "/user/email/auth",
    invite_admin: "/user/admin/inviteAdmin",
    login: "/user/login",
    logout: "/user/logout",
    recovery_password: "/user/recovery/verify",
    refresh_token: "/token",
    register: "/user/register",
    adminRegister: "/user/admin/register",
    verify_account: "/user/email/verify",
    update_user: "/user/data",
    resend_2fa_code: "/user/2fa/sendCode",
    send_code_recovery: "/user/email/recovery",
    send_invite_email: "/user/admin/inviteCode",
    settings: "/user/setting",
    update_2fa: "/user/setting/2fa",
    update_password: "/user/password",
    update_survivor: "/user/information",
    user_data: "/user",
    verify_2fa: "/user/login/2fa",
    verify_invite_admin_code: "/user/admin/verifyInviteAdminCode",
    admin_count: "/user/admin/count",
  },
  vco: {
    dashboard: "/dashboard",
  },
  incident: {
    create: "/incident",
    read: "/incident/read/",
  },
  files: {
    files: "/file",
    files_of_incident: "/file/incident",
    files_of_user: "/file/user",
  },
  notifications: {
    get_notifications: "/notification",
    delete: "/notification/batch",
  },
  chat: {
    chatroom: "/chatroom",
  },
  message: {
    message: "/message",
  },
  push_notifications: {
    notification: "/notification",
    batch: "/notification/batch",
  },
  perpetrator: {
    delete: "/perpetrator",
  },
};

export const perpetratorFormJSON = {
  id: null,
  first_name: "",
  last_name: "",
  perpetrator_email: "",
  perpetrator_position_or_grade: "",
  perpetrator_location: "",
  relation_with_perpetrator: "",
  custom_relation_with_perpetrator: "",
  perpetrator_role: "",
  custom_perpetrator_role: "",
  knew_perpetrator: null,
  affiliation_perpetrator: "",
  perpetrator_nationality: "",
};

export const subFormWitnessAndPeople = {
  id: "",
  name: "",
  note: "",
};

export const incidentFormJSON = {
  form_step: 1,
  incident_iv: "",
  id: "",
  isSurvivor: true,
  date: "",
  time_of_incident: "",
  what_happened: "",
  place: "",
  custom_place: "",
  description: "",
  status: "",
  anyone_involved: null,
  knowers: null,
  upload_file_option: null,
  upload_file: "",
  perpetrators: [],
  feelings: "",
  concerns: "",
  need_support: null,
  support_type: "",
  has_impact: null,
  impact_description: "",
  anything_else: "",
  isShareable: null,
  isAnonymous: null,
  files: [],
};
export const initialFormLogin = {
  email: "",
  password: "",
};

export const initialFormSignUp = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  repeat_password: "",
  terms_and_conditions: false,
};

export const inviteAdminInitialForm = {
  invite_admin_email: "",
  invite_admin_confirm_email: "",
  invite_admin_password: "",
  invite_admin_verification_code: "",
};

export const notificationSettingsJSON = {
  match_identified: true,
  new_message: true,
  new_resource: true,
  match_shared: true,
  notification_token: "",
  email_notification: true,
  push_notification: true,
  submitted: true,
  language: "en",
};

export const contactFormJSON = {
  id: "",
  first_name: "",
  last_name: "",
  message: "",
  key: "",
};

export const passwordFormJSON = {
  hashed_email: "",
  new_password: "",
  confirm_password: "",
  current_password: "",
  passwordStatus: "",
  message: "",
};

export const chatFormJSON = {
  content: "",
};

export const customCookies = ["welcome-landing", "welcome-platform"];

//incidents
export const optionsIncidentType = [
  {
    id: 1,
    value: true,
    name: i18nGetData("incident-form options-incident-type me"),
  },
  {
    id: 2,
    value: false,
    name: i18nGetData("incident-form options-incident-type someone-else"),
  },
];

export const getOptionsPlace = () => [
  {
    id: 0,
    value: "0",
    name: i18nGetData(
      "incident-form incident question-where-did-this-occur-answer-1"
    ),
  },
  {
    id: 1,
    value: "1",
    name: i18nGetData(
      "incident-form incident question-where-did-this-occur-answer-2"
    ),
  },
  {
    id: 2,
    value: "2",
    name: i18nGetData(
      "incident-form incident question-where-did-this-occur-answer-3"
    ),
  },
  {
    id: 3,
    value: "3",
    name: i18nGetData(
      "incident-form incident question-where-did-this-occur-answer-4"
    ),
  },
  {
    id: 4,
    value: "4",
    name: i18nGetData(
      "incident-form incident question-where-did-this-occur-answer-5"
    ),
  },
  {
    id: 5,
    value: "5",
    name: i18nGetData(
      "incident-form incident question-where-did-this-occur-answer-6"
    ),
  },
  {
    id: 6,
    value: "6",
    name: i18nGetData(
      "incident-form incident question-where-did-this-occur-answer-7"
    ),
  },
  {
    id: 7,
    value: "7",
    name: i18nGetData(
      "incident-form incident question-where-did-this-occur-answer-8"
    ),
  },
];

// Tab Menu Items
export const getUserMenuItems = () => [
  {
    id: 1,
    title: i18nGetData("platform navigation-item-1"),
    name: i18nGetData("platform navigation-item-1"),
    url: "#how-to-use-the-tool",
  },
  {
    id: 2,
    title: i18nGetData("platform navigation-header-2"),
    name: i18nGetData("platform navigation-item-2"),
    url: "#your-incident-forms",
  },
  {
    id: 3,
    title: i18nGetData("platform navigation-header-3"),
    name: i18nGetData("platform navigation-item-3"),
    url: "#resources",
  },
  {
    id: 4,
    title: i18nGetData("platform navigation-header-4"),
    name: i18nGetData("platform navigation-item-4"),
    url: "#messages",
  },
  {
    id: 5,
    title: i18nGetData("platform navigation-header-5"),
    name: i18nGetData("platform navigation-item-5"),
    url: "#settings",
  },
];

export const getAdminMenuItems = () => [
  {
    id: 1,
    title: i18nGetData("platform navigation-header-1"),
    name: i18nGetData("platform navigation-header-1"),
    url: "#dashboard",
  },
  {
    id: 2,
    title: i18nGetData("platform tab-incidents-search-title admin"),
    name: i18nGetData("platform tab-incidents-search-title admin"),
    url: "#incident-forms",
  },
  {
    id: 3,
    title: i18nGetData("platform navigation-header-3"),
    name: i18nGetData("platform navigation-header-3"),
    url: "#resources",
  },
  {
    id: 4,
    title: i18nGetData("platform navigation-header-4"),
    name: i18nGetData("platform navigation-header-4"),
    url: "#messages",
  },
  { id: 5, title: "Settings", name: "Settings", url: "#settings" },
];

export const menuItems = [
  {
    key: "about",
    name: "landing about",
    link: "/about-the-tool",
    icon: <AboutToolIcon />,
  },
  {
    key: "support-resources",
    name: "landing support",
    link: "/support-resources/message-vco",
    items: [
      {
        key: "message-vco",
        name: "landing sub menu 1",
        submenu: "landing sub menu 1 - 1",
        link: "/support-resources/message-vco",
      },
      {
        key: "unhcr-resources",
        name: "landing sub menu 2",
        submenu: "landing sub menu 2 - 1",
        link: "/support-resources/unhcr-resources",
      },
      {
        key: "help-with-the-tool",
        name: "landing sub menu 3",
        submenu: "landing sub menu 3 - 1",
        link: "/support-resources/help-with-the-tool",
      },
    ],
  },
  {
    key: "record-incident",
    name: "landing record",
    link: "/record-an-incident",
    items: [],
  },
  {
    key: "help",
    name: "landing help",
    link: "/help",
  },
];

export const optionsItems = [
  {
    key: "en",
    name: "ENG",
    link: "",
    lang: true,
  },
  {
    key: "login",
    name: "landing btn login",
    link: "/login",
  },
  {
    key: "signup",
    name: "landing btn signup",
    link: "/sign-up",
  },
];

export const frequentlyAskedQuestions = [
  {
    ask: "landing about section-faq-ask-1",
    question: "landing about section-faq-question-1",
  },
  {
    ask: "landing about section-faq-ask-2",
    question: "landing about section-faq-question-2",
  },
  {
    ask: "landing about section-faq-ask-3",
    question: "landing about section-faq-question-3",
  },
  {
    ask: "landing about section-faq-ask-4",
    question: "landing about section-faq-question-4",
  },
  {
    ask: "landing about section-faq-ask-5",
    question: "landing about section-faq-question-5",
  },
  {
    ask: "landing about section-faq-ask-6",
    question: "landing about section-faq-question-6",
  },
  {
    ask: "landing about section-faq-ask-7",
    question: "landing about section-faq-question-7",
  },
  {
    ask: "landing about section-faq-ask-8",
    question: "landing about section-faq-question-8",
  },
  {
    ask: "landing about section-faq-ask-9",
    question: "landing about section-faq-question-9",
  },
  {
    ask: "landing about section-faq-ask-10",
    question: "landing about section-faq-question-10",
  },
  {
    ask: "landing about section-faq-ask-11",
    question: "landing about section-faq-question-11",
  },
  {
    ask: "landing about section-faq-ask-12",
    question: "landing about section-faq-question-12",
  },
  {
    ask: "landing about section-faq-ask-13",
    question: "landing about section-faq-question-13",
  },
  {
    ask: "landing about section-faq-ask-14",
    question: "landing about section-faq-question-14",
  },
  {
    ask: "landing about section-faq-ask-15",
    question: "landing about section-faq-question-15",
  },
  {
    ask: "landing about section-faq-ask-16",
    question: "landing about section-faq-question-16",
  },
  {
    ask: "landing about section-faq-ask-17",
    question: "landing about section-faq-question-17",
  },
  {
    ask: "landing about section-faq-ask-18",
    question: "landing about section-faq-question-18",
  },
  {
    ask: "landing about section-faq-ask-19",
    question: "landing about section-faq-question-19",
  },
];

export const unhcrResources = [
  {
    title: "landing about section-unhcr-resources-item-1-title",
  },
  {
    title: "landing about section-unhcr-resources-item-2-title",
  },
  {
    title: "landing about section-unhcr-resources-item-3-title",
  },
  {
    title: "landing about section-unhcr-resources-item-4-title",
  },
  {
    title: "landing about section-unhcr-resources-item-5-title",
  },
];

export const twoFactorAuthentication = {
  DISABLED: "never",
  EVERY_LOG_IN: "always",
};

export const Role = {
  ADMIN: "admin",
  SURVIVOR: "survivor",
};

export const optionRadios = [
  {
    title: "landing two-factor-authentication option-1-title",
    description: "landing two-factor-authentication option-1-description",
    value: twoFactorAuthentication.DISABLED,
    defaultValue: true,
    users: [Role.SURVIVOR],
  },
  {
    title: "landing two-factor-authentication option-3-title",
    description: "landing two-factor-authentication option-3-description",
    value: twoFactorAuthentication.EVERY_LOG_IN,
    defaultValue: false,
    users: [Role.SURVIVOR, Role.ADMIN],
  },
];

export const TWO_FA_CODE = "2fa-code";

export const twoFactorAuthErrors = {
  EVERY_LOG_IN: "U2FA_ALWAYS_REQUIRED",
  BAD_CODE: "U2FA_CODE_NOT_AVAILABLE",
  PHONE_ERROR: "U2FA_CODE_NOT_SENT",
};

export const incidentStatus = {
  IN_DRAFT: {
    id: "draft",
    value: "incident-form status in-draft",
  },
  SUBMITTED: {
    id: "submitted",
    value: "incident-form status submitted",
  },
  SHARED: {
    id: "shared",
    value_1: "incident-form status not-shared-yet",
    value_2: "incident-form status shared",
  },
};

export const TIMEOUT_LIMIT = 15 * 60;
export const REFRESH_TOKEN_LIMIT = 10 * 60;

export const incidentView = {
  INCIDENT_LIST: "incident-list",
  INCIDENT_REVIEW: "incident-review",
  CREATE_INCIDENT: "create-incident",
  EDIT_INCIDENT: "edit-incident",
  EMPTY: "empty",
  LOADING: "loading",
};

export const overviewStatus = {
  DONE: "done",
  IN_PROGRESS: "in-progress",
  INCOMPLETE: "incomplete",
};
export const resourcePath = {
  DOCS: "docs",
  ABOUT_RESOURCES_THUMBNAILS: "about-page-resources/thumbnails",
  ABOUT_RESOURCES_DOCS: "about-page-resources/docs",
  RESOURCES: "resources",
};

export const resourceType = {
  PDF: "pdf",
  VIDEO: "video",
  POWERPOINT: "powerpoint",
};

export const AUTO_REPLAY_ID = "autoReplayId";

export const ADMIN_INVITE_CODE = "ADMIN_INVITE_CODE";
