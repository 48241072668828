import React from "react";
import logo from "../../../assets/images/pseash-logo-gray.svg";
import { useTranslation } from "react-i18next";

export default function Prelanding({ text, modalContent, setModalContent }) {
  const { i18n } = useTranslation("global");

  return (
    <div className="pre-landing-container">
      <div className="column-left logo-column">
        <div className="container-logo">
          <img src={logo} alt="unhcr logo" />
        </div>
      </div>
      <div className="column-right">
        <div
          className="text-description"
          lang={i18n.language}
          onClick={(e) => {
            e.preventDefault();
            if (
              modalContent === "pre-landing-2" &&
              e?.target?.localName === "a"
            ) {
              setModalContent("pre-landing-3");
            }
          }}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </div>
    </div>
  );
}
