import React, { Fragment } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { frequentlyAskedQuestions } from "../../helpers/constants";
import { addPrefixLanding } from "../../helpers/helpers";
import FrequentlyAskedQuestions from "../landing/components/FrequentlyAskedQuestions";
import Footer from "./components/Footer";
import Header from "./components/Header";

function Help() {
  const { t, i18n } = useTranslation("global");
  return (
    <Fragment>
      <Header />
      <section
        className={classNames({
          [addPrefixLanding("home-section")]: true,
          [addPrefixLanding("section-1")]: true,
        })}
        lang={i18n.language}
      >
        <div className={addPrefixLanding("section-container")}>
          <div
            className={classNames({
              [addPrefixLanding("section-container__row")]: true,
              [addPrefixLanding("center-content")]: true,
            })}
          >
            <div
              className={classNames({
                [addPrefixLanding(
                  "section-container__frequently-asked-questions"
                )]: true,
              })}
            >
              <div className="section-content">
                <h2 className={addPrefixLanding("section-title")}>
                  {t("landing about section-faq")}
                </h2>
                <FrequentlyAskedQuestions
                  frequentlyAskedQuestions={frequentlyAskedQuestions}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}

export default Help;
