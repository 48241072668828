import React, { Fragment, useState } from "react";
import classNames from "classnames";
import Button from "components/Button";
import Loading from "components/Loading";
import YourIncidentForms from "components/YourIncidentForms";
import { Role, incidentView } from "helpers/constants";
import { addPrefixLanding, getSearchIncident } from "helpers/helpers";
import IncidentForm from "pages/platform/incidents/IncidentForm";
import IncidentList from "pages/platform/incidents/IncidentList";
import Review from "pages/platform/incidents/components/Review";
import Search from "pages/platform/search/components/Search";
import { useTranslation } from "react-i18next";

const IncidentForms = ({
  user,
  incidents,
  selectedIncidentView,
  setSelectedIncidentView,
  setSubModalContent,
  setSelectedIncident,
  selectedIncident,
  setSubModal,
  setStatusNavigationButtons = () => {},
  statusNavigationButtons = false,
  pageIncident = 5,
  setPageIncident,
  setModalContent,
  setModal,
  setFormToDelete,
  getIncidents,
  modalSubmitContent,
  setModalSubmitContent,
  modalSubmit,
  setModalSubmit,
  setTimeOutModal,
  timeOutModal,
}) => {
  const { t, i18n } = useTranslation("global");
  const [search, setSearch] = useState("");

  const handleCreate = (e) => {
    e.preventDefault();
    localStorage.removeItem("form_id");
    setModalContent("create-new-incident");
    setModal(true);
    setModalSubmit(false);
    setSelectedIncident({});
  };

  const handleEdit = (incident) => {
    localStorage.setItem("form_id", incident.id);
    setSelectedIncident(incident);
    setModalContent("edit-incident");
    setModalSubmit(false);
    setModal(true);
  };

  const handleDelete = (form) => {
    setSubModalContent("delete-incident");
    setSubModal(true);
    setFormToDelete(form);
  };

  const handleShowIncidentItem = (incident) => {
    setSelectedIncident(incident);
  };

  const handleBackToIncidentList = (e) => {
    e.preventDefault();
    localStorage.removeItem("form_id");
    getIncidents();
  };

  const incidentViews = () => {
    switch (selectedIncidentView) {
      case incidentView.EDIT_INCIDENT:
      case incidentView.CREATE_INCIDENT:
        return (
          <IncidentForm
            selectedIncident={selectedIncident}
            selectedIncidentView={selectedIncidentView}
            setSelectedIncidentView={setSelectedIncidentView}
            setSubModalContentView={setSubModalContent}
            setSelectedFormId={setSelectedIncident}
            setSubModalView={setSubModal}
            getIncidents={getIncidents}
            setStatusNavigationButtons={setStatusNavigationButtons}
            statusNavigationButtons={statusNavigationButtons}
            setPageIncident={setPageIncident}
            pageIncident={pageIncident}
            modalSubmitContent={modalSubmitContent}
            setModalSubmitContent={setModalSubmitContent}
            modalSubmit={modalSubmit}
            setModalSubmit={setModalSubmit}
            setTimeOutModal={setTimeOutModal}
            timeOutModal={timeOutModal}
          />
        );
      case incidentView.INCIDENT_REVIEW:
        return (
          <Fragment>
            <div className="incident-form-layout">
              <div className="step-form-container step-review-container step-just-review-container ">
                <Review
                  form={selectedIncident}
                  errors={{}}
                  setSelectedIncidentView={setSelectedIncidentView}
                  setSubModalContent={setSubModalContent}
                  setSelectedFormId={setSelectedIncident}
                  setSubModalView={setSubModal}
                  getIncidents={getIncidents}
                  view={user?.role}
                  handleBackToIncidentList={handleBackToIncidentList}
                />
              </div>
            </div>
          </Fragment>
        );
      case incidentView.INCIDENT_LIST:
        return (
          <Fragment>
            <div className="incidents-container">
              {user?.role === Role.ADMIN && (
                <Search
                  setSearch={setSearch}
                  incidents={getSearchIncident(
                    search,
                    incidents,
                    String(t("platform incident-item-shared anonymously"))
                  )}
                />
              )}
              {incidents && incidents.length > 0 ? (
                <IncidentList
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  setSelectedIncidentView={setSelectedIncidentView}
                  setSelectedFormId={setSelectedIncident}
                  incidentList={
                    user?.role === Role.ADMIN
                      ? getSearchIncident(
                          search,
                          incidents,
                          String(t("platform incident-item-shared anonymously"))
                        )
                      : incidents
                  }
                  setSubModalContent={setSubModalContent}
                  setModal={setModal}
                  setModalContent={setModalContent}
                  setSubModal={setSubModal}
                  showIncidentItem={handleShowIncidentItem}
                  handleShowIncidentItem={handleShowIncidentItem}
                  view={user?.role}
                  getIncidents={getIncidents}
                />
              ) : (
                <>
                  {user?.role === Role.ADMIN ? (
                    <div className={addPrefixLanding("section-container")}>
                      <h2
                        className={classNames({
                          [addPrefixLanding("section-title")]: true,
                          [addPrefixLanding("section-title-admin")]: true,
                        })}
                        lang={i18n.language}
                      >
                        {t("platform tab-your-incidents-description admin")}
                      </h2>
                    </div>
                  ) : (
                    <YourIncidentForms startIncident={handleCreate} />
                  )}
                </>
              )}
            </div>
            {incidents &&
              incidents.length > 0 &&
              user?.role === Role.SURVIVOR && (
                <div
                  className={classNames({
                    "incidents-options-container": true,
                    "ar-letter-spacing": i18n.language === "ar",
                  })}
                >
                  <Button
                    class="btn-create-incident-list"
                    onClick={handleCreate}
                    text={t("your-incidents new-incident-form-button")}
                  />
                </div>
              )}
          </Fragment>
        );
      case incidentView.EMPTY:
        return "";
      default:
        return (
          <div className="loading-container">
            <Loading />
          </div>
        );
    }
  };
  return <>{incidentViews()}</>;
};

export default IncidentForms;

