import React from "react";
import PropsTypes from "prop-types";

class Button extends React.Component {
  render() {
    return (
      <button
        id={this.props.id}
        className={this.props.class ?? this.props.className}
        onClick={this.props.onClick}
        data-data={this.props.data}
        disabled={this.props.disabled}
      >
        {this.props.text}
      </button>
    );
  }
}

Button.PropsTypes = {
  id: PropsTypes.string,
  class: PropsTypes.string,
  className: PropsTypes.string,
  text: PropsTypes.string,
  onClick: PropsTypes.func,
  data: PropsTypes.data,
  disabled: PropsTypes.bool,
};

export default Button;
