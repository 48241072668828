import React, { Fragment, useState } from "react";
import Question from "components/questions/Question";
import { getOptionsPlace } from "helpers/constants";
import { characterLimitMessage } from "helpers/helpers";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

function Incident({
  form,
  errors,
  handleChange,
  handleDate,
  startDate,
  handleEndDate,
  endDate,
  startDateTime,
  handleTimeInput,
  handleEndTime,
  timeEnd,
  timeStart,
  setTimeStart,
  handleChangeRadio,
}) {
  const { t, i18n } = useTranslation("global");
  const [toggleDate, setToggleDate] = useState(false);
  const [toggleTime, setToggleTime] = useState(false);
  const optionsPlace = getOptionsPlace();
  return (
    <Fragment>
      <div
        className={classNames({
          "small-column step-incident required-message": true,
          "ar-letter-spacing": i18n.language === "ar",
        })}
      >
        <div className="required-indicator-message" lang={i18n.language}>
          {t("incident-form general top-message-required")}
        </div>
        {/* Some snippets were removed in the JXUI-492 PR*/}

        <Question
          questionTitle={t("incident-form incident question-date")}
          answerType="datepicker"
          form={form}
          rows={4}
          errors={errors}
          handleChange={handleChange}
          defaultOption={""}
          fieldName="date"
          subfield={false}
          otherOption={{}}
          startDate={startDate}
          handleDate={handleDate}
          handleEndDate={handleEndDate}
          endDate={endDate}
          required={true}
          toggleDate={toggleDate}
          setToggleDate={setToggleDate}
          fieldMessage={t("incident-form incident question-date-message")}
        />

        <Question
          questionTitle={t("incident-form incident question-time")}
          answerType="input-text"
          form={form}
          rows={4}
          errors={errors}
          handleChange={handleChange}
          defaultOption={""}
          placeHolder={t("incident-form incident question-time-placeholder")}
          fieldName="time_of_incident"
          subfield={false}
          otherOption={{}}
          startDate={startDate}
          handleDate={handleDate}
          handleEndDate={handleEndDate}
          handleTimeInput={handleTimeInput}
          timeStart={timeStart}
          setTimeStart={setTimeStart}
          endDate={endDate}
          required={false}
          toggleTime={toggleTime}
          timeEnd={timeEnd}
          startDateTime={startDateTime}
          setToggleTime={setToggleTime}
          handleEndTime={handleEndTime}
          fieldMessage={t("incident-form incident question-time-message")}
        />
      </div>
      <div
        className={classNames({
          "big-column step-incident required-message": true,
          "ar-letter-spacing": i18n.language === "ar",
        })}
      >
        <Question
          questionTitle={t("incident-form incident question-what-happened")}
          answerType="textarea"
          form={form}
          errors={errors}
          handleChange={handleChange}
          fieldName="what_happened"
          placeHolder={`${t(
            "incident-form incident question-what-happened-placeholder"
          )} ${characterLimitMessage(3000, t("platform char-limit"))} `}
          required={true}
          fieldMessage={t(
            "incident-form incident question-what-happened-message"
          )}
        />

        <Question
          questionTitle={t(
            "incident-form incident question-where-did-this-occur"
          )}
          answerType="radio"
          type="text"
          rows={4}
          form={form}
          errors={errors}
          handleChangeRadio={handleChangeRadio}
          handleChange={handleChange}
          options={optionsPlace}
          defaultOption={null}
          showSubField={optionsPlace[7].value}
          fieldName="place"
          subfield={false}
          subfieldContent={{}}
          otherOption={{
            field: "input",
            type: "text",
            placeholder: t(
              "incident-form incident question-where-did-this-occur-answer-8-placeholder"
            ),
            required: true,
          }}
          required={true}
        />
      </div>
    </Fragment>
  );
}

export default Incident;

