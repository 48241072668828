import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import HeaderForm from "components/HeaderForm";
import Button from "components/Button";
import { useLogin } from "pages/landing/login/hooks/useLogin";
import { initialFormLogin } from "helpers/constants";
import { validationsFormLogin } from "helpers/validations";
import { getCookieData } from "helpers/helpers";
import { characterLimitMessage } from "helpers/helpers";
import LandingModals from "pages/landing/modals";

const LoginForm = () => {
  const { t, i18n } = useTranslation("global");
  const title = t("landing login section-title");
  const description = `<p>${t("landing login section-description")}</p>`;
  const blocked = getCookieData("blocked");
  const [modal, setModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const { fromLanding } = location?.state || {};
    if (fromLanding) {
      setModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { form, errors, handleChange, handleSubmit } = useLogin(
    initialFormLogin,
    validationsFormLogin
  );

  return (
    <div className="container-form__login">
      <HeaderForm
        title={title}
        description={description}
        lang={i18n.language}
      />
      {modal && <LandingModals setModal={setModal} />}
      <div className="container-body__form">
        <form className="container-form">
          <div className="container_form__row" lang={i18n.language}>
            <div
              className={classNames({
                container_form__col: true,
                require_field: true,
                "ar-letter-spacing": i18n.language === "ar",
              })}
            >
              <label>{t("landing form login-email")}</label>
              <input
                className={classNames({
                  "error-field": errors.email,
                })}
                type="email"
                placeholder={`${t(
                  "landing form login-email-placeholder"
                )} ${characterLimitMessage(100, t("platform char-limit"))}`}
                name="email"
                maxLength={100}
                onChange={handleChange}
                value={form.email}
                autoComplete="off"
                required
              />
              {errors.email && (
                <span className="error-message">{errors.email}</span>
              )}
            </div>
          </div>
          <div className="container_form__row" lang={i18n.language}>
            <div
              className={classNames({
                container_form__col: true,
                require_field: true,
                "ar-letter-spacing": i18n.language === "ar",
              })}
            >
              <label>{t("landing form login-password")}</label>
              <input
                className={classNames({
                  "error-field": errors.password,
                })}
                type="password"
                name="password"
                maxLength={100}
                placeholder={t("landing form login-password-placeholder")}
                onChange={handleChange}
                value={form.password}
                required
              />
              {errors.password && (
                <span className="error-message">{errors.password}</span>
              )}
            </div>
          </div>
          {errors.message && (
            <div className="container_form__row" lang={i18n.language}>
              <span className="error-message">{errors.message}</span>
            </div>
          )}
          <div
            className={classNames({
              "container_form__row row-forgot-password-and-account": true,
              "ar-letter-spacing": i18n.language === "ar",
            })}
          >
            <Link to="/forgot-your-password">
              {t("landing form login-forgot-password-text")}
            </Link>
            <div className="container-form__question-label">
              <span>{t("landing form login-have-account-text")}</span>
              <Link to="/sign-up" state={{ fromLogin: true }} className="link">
                {t("landing form login-signup-link")}
              </Link>
            </div>
          </div>
          <div
            className={classNames({
              "button-landing-container": true,
              "ar-letter-spacing": i18n.language === "ar",
            })}
          >
            <Link className="back-button-link left-arrow" to="/home">
              {t("landing form login-btn-back")}
            </Link>
            <div className="container_form__row">
              <div className="container_form__col buttons-container">
                <span
                  className="required-fields-text hide-mobile"
                  lang={i18n.language}
                >
                  {t("landing form login-required-field-text")}
                </span>
                <Button
                  id="btn-login"
                  class="btn-login"
                  text={t("landing form login-btn-start")}
                  onClick={handleSubmit}
                  disabled={blocked}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
