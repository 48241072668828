import React from "react";
import CookiesBanner from "components/cookiesBanner";
import LandingFooterBar from "./LandingFooterBar";

function Footer() {
  return (
    <>
      <CookiesBanner />
      <LandingFooterBar />
    </>
  );
}

export default Footer;
