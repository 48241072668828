import axios from "axios";
import i18n from "i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL, ENDPOINT } from "../helpers/constants";
import { i18nGetData } from "../i18n";
import { hash } from "../lib/utils/encryption";

export const useForgotPassword = (initialForm, validateForm) => {
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState({});
  let navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleBlur = (e) => {
    handleChange(e);
    setErrors(validateForm(form));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validateForm(form));

    if (Object.keys(validateForm(form)).length === 0) {
      (async () => {
        try {
          const response = await axios.post(
            BASE_URL + ENDPOINT.user.send_code_recovery,
            {
              email: form.email,
              hashed_email: hash(form.email),
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Accept-Language": i18n.language,
              },
            }
          );

          if (response.status === 200) {
            navigate("/reset-password", {
              state: { hashed_email: hash(form.email), email: form.email },
            });
          }
        } catch (error) {
          setErrors({
            error_response: `${i18nGetData(
              "landing form forgot-password-error"
            )}.`,
          });
        }
      })();
    } else {
      return;
    }
  };

  return {
    form,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
  };
};
