import { useState } from "react";
import { getCookieData } from "helpers/helpers";
import { deleteUser } from "services/users";

export const useConfirmDelete = (
  validateForm,
  initialForm,
  setSubModal,
  setSubModalContent,
  setDeleteSuccessfulModal,
  setLoading
) => {
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: name === "confirm_delete" ? !form.confirm_delete : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validateForm(form));
    if (Object.keys(validateForm(form)).length === 0) {
      setLoading(true);
      const token = getCookieData("auth", { path: "/" });
      Promise.resolve(deleteUser(token.accessToken))
        .then(() => {
          setDeleteSuccessfulModal(true);
          setSubModal(false);
          setSubModalContent("");
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  return {
    form,
    errors,
    handleChange,
    handleSubmit,
  };
};
