import React from "react";
import Button from "components/Button";
import useInviteAdmin from "hooks/useInviteAdmin";

function InviteAdminForm({ onTwoFactorAuthOpen, onClose, setInviteAdminForm }) {
  const {
    attempts,
    counter,
    errors,
    form,
    handleChange,
    handleResendCode,
    handleSubmit,
    setCounter,
    setErrors,
  } = useInviteAdmin();

  const handleOpenTwoFactorAuth = async (event) => {
    event.preventDefault();
    const response = await handleSubmit();

    if (response === 200) {
      setInviteAdminForm(form);
      onTwoFactorAuthOpen();
      onClose();

      return;
    }

    if (response === "USER_ALREADY_CREATED") {
      setErrors({
        ...errors,
        invite_admin_email: "This user is already created.",
      });
    }

    if (response === "INVITE_ALREADY_CREATED") {
      setErrors({
        ...errors,
        invite_admin_email: "This invitation was already sent.",
      });
    }

    if (response === "BAD_PASS") {
      setErrors({
        ...errors,
        invite_admin_password: "Incorrect password.",
      });
    }

    if (response === "INVALID_CODE") {
      setErrors({
        ...errors,
        invite_admin_verification_code: "Invalid Code.",
      });
    }
  };

  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  return (
    <section className="invite-admin">
      <h1 className="invite-admin__title">Invite Another Admin</h1>

      <p className="invite-admin__description">
        Enter the Administrators email to invite them to create an account and
        join the tool. For security reasons, please confirm your account to
        invite the user.
      </p>

      <form className="invite-admin__form">
        <div className="invite-admin-form-group">
          <label
            className="invite-admin-form-group__label invite-admin-form-group__label--required-field"
            for="invite-email"
          >
            Invite Email
          </label>

          <input
            className="invite-admin-form-group__input"
            type="email"
            placeholder={"Enter the email to be invited"}
            name="invite_admin_email"
            id="invite-admin-email"
            maxLength={100}
            onChange={handleChange}
            value={form.invite_admin_email}
          />
        </div>

        {errors.invite_admin_email && (
          <p className="invite-admin-form__error-message">
            {errors.invite_admin_email}
          </p>
        )}

        <div className="invite-admin-form-group">
          <label
            className="invite-admin-form-group__label invite-admin-form-group__label--required-field"
            for="confirm-invite-admin-email"
          >
            Confirm Invite Email
          </label>

          <input
            className="invite-admin-form-group__input"
            type="email"
            placeholder={"Enter the email to be invited"}
            name="invite_admin_confirm_email"
            id="confirm-invite-admin-email"
            maxLength={100}
            onChange={handleChange}
            value={form.invite_admin_confirm_email}
          />
        </div>

        {errors.invite_admin_confirm_email && (
          <p className="invite-admin-form__error-message">
            {errors.invite_admin_confirm_email}
          </p>
        )}

        <div className="invite-admin-form-group">
          <label
            className="invite-admin-form-group__label invite-admin-form-group__label--required-field"
            for="invite-email"
          >
            Enter Your Password
          </label>

          <input
            className="invite-admin-form-group__input invite-admin-form-group__input--password"
            type="password"
            placeholder={"••••••"}
            name="invite_admin_password"
            id="invite-admin-password"
            maxLength={100}
            onChange={handleChange}
            value={form.invite_admin_password}
          />
        </div>

        {errors.invite_admin_password && (
          <p className="invite-admin-form__error-message">
            {errors.invite_admin_password}
          </p>
        )}

        <div className="invite-admin-form-group">
          <label
            className="invite-admin-form-group__label invite-admin-form-group__label--required-field"
            for="invite-email"
          >
            Verification Code
          </label>

          <input
            className="invite-admin-form-group__input invite-admin-form-group__input--password"
            type="text"
            placeholder={"Enter code sent to your email"}
            name="invite_admin_verification_code"
            id="invite-admin-verification-code"
            maxLength={100}
            onChange={handleChange}
            value={form.invite_admin_verification_code}
          />
        </div>

        {errors.invite_admin_verification_code && (
          <p className="invite-admin-form__error-message">
            {errors.invite_admin_verification_code}
          </p>
        )}

        <div className="invite-admin-form-resend-button-group">
          <span class="invite-admin-form-resend-button-group__message">
            {"Didn't receive a code?"}
          </span>{" "}
          <Button
            class="invite-admin-form-resend-button-group__button"
            text={"Resend Code"}
            disabled={counter > 0 || attempts === 0 ? true : false}
            onClick={handleResendCode}
          />
        </div>

        <div className="invite-admin-info-messages">
          {counter > 0 && attempts > 0 && attempts <= 3 && (
            <p className="invite-admin-info-messages__info-message">
              {`Wait ${counter} seconds`}
            </p>
          )}

          {attempts > 0 && attempts < 3 && (
            <p className="invite-admin-info-messages__info-message">
              {`Remaining attempts ${attempts}`}
            </p>
          )}

          {attempts === 0 && (
            <p className="invite-admin-info-messages__info-message">
              {
                "You have reached the number of tries to receive a new code. Please contact the system administrator for support."
              }
            </p>
          )}
        </div>

        <div className="invite-admin-form-submit-button-group">
          <Button
            class="invite-admin-form-submit-button-group__submit-button"
            text={"Invite Admin"}
            disabled={attempts === 0 ? true : false}
            onClick={handleOpenTwoFactorAuth}
          />
          <Button
            class="invite-admin-form-submit-button-group__cancel-button"
            text={"Cancel"}
            onClick={onClose}
          />
        </div>
      </form>
    </section>
  );
}

export default InviteAdminForm;
