import { useState } from "react";
import { resendVerificationCode, verifyAccount } from "services/users";
import { NUMBERS } from "../helpers/regexps";
import { i18nGetData } from "../i18n";

export const useVerifyAccount = (initialForm, validateForm) => {
  const [form, setLoginForm] = useState(initialForm);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [formStatus, setFormStatus] = useState(null);
  const [counter, setCounter] = useState(60);
  const [attempts, setAttempts] = useState(3);
  const [toggleCopy, setToggleCopy] = useState(false);

  const handleChange = (e) => {
    if (NUMBERS.test(e.target.value) || e.target.value === "") {
      const { name, value } = e.target;

      setLoginForm({
        ...form,
        [name]: value,
      });
    }
  };

  const handleBlur = (e) => {
    handleChange(e);
    setErrors(validateForm(form));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors(validateForm(form));

    if (Object.keys(validateForm(form)).length === 0) {
      setLoading(true);

      const user = {
        authCode: form.code,
        hashed_email: form.hashed_email,
      };

      Promise.resolve(verifyAccount(user))
        .then(() => {
          setErrors({});
          setLoading(false);
          setFormStatus("submitted");
        })
        .catch((error) => {
          setLoading(false);
          setFormStatus(null);
          if (error.response.status === 400 && attempts > 0) {
            setErrors({
              code: i18nGetData(
                "landing form two-factor-authentication-error-1"
              ),
            });
            setAttempts((current) => current - 1);
          } else if (error.response.status === 403) {
            setAttempts(0);
          }
        });
    }
  };

  const handleResendCode = async (e) => {
    e.preventDefault();
    if (attempts === 0 ) return;
    setCounter(60);
    setAttempts((current) => current - 1);
    const body = {
      email: form.email,
      hashed_email: form.hashed_email,
    };

    Promise.resolve(resendVerificationCode(body)).catch((error) => {
      console.log(error);
    });
  };

  const copyToClipboard = (str) => {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
  };

  const handleCopyKey = (e) => {
    e.preventDefault();

    const { children } = e.target.parentElement;

    const { innerText } = Array.from(children)[0];

    copyToClipboard(innerText);
    setToggleCopy(true);

    setTimeout(() => {
      setToggleCopy(false);
    }, 3000);
  };

  return {
    form,
    errors,
    loading,
    formStatus,
    counter,
    attempts,
    toggleCopy,
    setCounter,
    setToggleCopy,
    handleChange,
    handleBlur,
    handleSubmit,
    handleResendCode,
    handleCopyKey,
  };
};
