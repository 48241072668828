import React from "react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";

function LogoutSession({ logout }) {
  const { t } = useTranslation("global");

  const handleClick = () => {
    logout();
  };

  return (
    <div className="container-modal-logout-session">
      <div className="logout-session-text">
        {t("platform logout-session-text")}
      </div>
      <div className="button-container">
        <Button
          text={t("platform navigation-item-6")}
          class="btn-logout"
          onClick={handleClick}
        />
      </div>
    </div>
  );
}

export default LogoutSession;
