import About from "pages/landing/About";
import Help from "pages/landing/Help";
import HelpWithTheTool from "pages/landing/HelpWithTheTool";
import Home from "pages/landing/Home";
import MessageWithVCO from "pages/landing/MessageWithVCO";
import RecordAnIncident from "pages/landing/RecordAnIncident";
import TermsAndPrivacy from "pages/landing/TermsAndPrivacy";
import UNHCRResources from "pages/landing/UNHCRResources";
import AdminDashboard from "pages/platform/AdminDashboard";
import LandingPlatform from "pages/platform/LandingPlatform";
import UserDashboard from "pages/platform/UserDashboard";

const routes = [
  {
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard/",
    component: <UserDashboard />,
    isPrivate: true,
    section: "platform",
  },
  {
    name: "AdminDashboard",
    key: "admin-dashboard",
    route: "/admin-dashboard/",
    component: <AdminDashboard />,
    isPrivate: true,
    section: "platform",
  },
  {
    name: "Home",
    key: "home",
    route: "/home",
    component: <Home />,
    section: "landing",
  },
  {
    name: "About",
    key: "about",
    route: "/about-the-tool",
    component: <About />,
    section: "landing",
  },
  {
    name: "Message with VCO",
    key: "message-vco",
    route: "/support-resources/message-vco",
    component: <MessageWithVCO />,
    section: "landing",
  },
  {
    name: "UNHCR resources",
    key: "support-resources",
    route: "/support-resources/unhcr-resources",
    component: <UNHCRResources />,
    section: "landing",
  },
  {
    name: "Help wit the tool",
    key: "help-with-the-too",
    route: "/support-resources/help-with-the-tool",
    component: <HelpWithTheTool />,
    section: "landing",
  },
  {
    name: "Record an Incident",
    key: "record-incident",
    route: "/record-an-incident",
    component: <RecordAnIncident />,
    section: "landing",
  },
  {
    name: "Help",
    key: "help",
    route: "/help",
    component: <Help />,
    section: "landing",
  },
  {
    name: "Login",
    key: "login",
    route: "/login",
    component: <LandingPlatform />,
    section: "platform",
  },
  {
    name: "Sign up",
    key: "signup",
    route: "/sign-up",
    component: <LandingPlatform />,
    section: "platform",
  },
  {
    name: "Reset Password",
    key: "reset-password",
    route: "/reset-password",
    component: <LandingPlatform />,
    section: "platform",
  },
  {
    name: "Forgot your password",
    key: "forgot-your-password",
    route: "/forgot-your-password",
    component: <LandingPlatform />,
    section: "platform",
  },
  {
    name: "Verify Account",
    key: "verify-account",
    route: "/verify-account",
    component: <LandingPlatform />,
    section: "platform",
  },
  {
    name: "Welcome",
    key: "welcome",
    route: "/welcome",
    component: <LandingPlatform />,
    isPrivate: true,
    section: "platform",
  },
  {
    name: "Two Factor Auth",
    key: "two-factor-auth",
    route: "/two-factor-auth",
    component: <LandingPlatform />,
    section: "platform",
  },
  {
    name: "Terms of Use",
    key: "terms-of-use",
    route: "/terms-of-use",
    component: <TermsAndPrivacy />,
    section: "landing",
  },
  {
    name: "Privacy Statement",
    key: "privacy-statement",
    route: "/privacy-statement",
    component: <TermsAndPrivacy />,
    section: "landing",
  },
];

export default routes;
