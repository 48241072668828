import React from "react";
import PropsTypes from "prop-types";

class HeaderForm extends React.Component {
  render() {
    return (
      <div
        className="container-body__form container-heading__form"
        lang={this.props.lang}
      >
        <h1>{this.props.title}</h1>
        <div
          className="container-heading__form-description"
          dangerouslySetInnerHTML={{
            __html: this.props.description,
          }}
        ></div>
      </div>
    );
  }
}

HeaderForm.PropsTypes = {
  title: PropsTypes.string,
  description: PropsTypes.string,
  lang: PropsTypes.string,
};

export default HeaderForm;

