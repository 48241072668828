import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import Loading from "components/Loading";
import { resourcePath } from "helpers/constants";

function HowToUseTheTool() {
  const { i18n, t } = useTranslation("global");
  const [numPages, setNumPages] = useState(null);
  const FILE_NAME = "HOW-IT-WORKS";
  const URL = `${process.env.REACT_APP_RESOURCES_BASE_URL}/${resourcePath.DOCS}/${i18n.language}/${FILE_NAME}.pdf`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const onClick = () => {
    const downloadPDF = document.createElement("a");
    downloadPDF.href = `${URL}#toolbar=1`;
    downloadPDF.download = FILE_NAME;
    downloadPDF.target = "_blank";
    document.body.appendChild(downloadPDF);
    downloadPDF.click();
    document.body.removeChild(downloadPDF);
  };

  const onDownload = () => {
    fetch(URL)
      .then((res) => res.blob())
      .then((blob) => {
        const fileUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = fileUrl;
        a.download = `${FILE_NAME}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  return (
    <div className="how-to-use-the-tool" lang={i18n.language}>
      <div className="download-button">
        <button className="btn-no-option down-arrow" onClick={onDownload}>
          {t("landing resources internal-resource-downloadpdf-btn")}
        </button>
      </div>
      <div className="pdf-page-preview">
        <Document
          file={URL}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<Loading />}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <>
              <div className="page-desktop">
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  scale={0.5}
                />
              </div>
              <div className="page-mobile" onClick={onClick}>
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  scale={0.2}
                />
              </div>
            </>
          ))}
        </Document>
      </div>
    </div>
  );
}

export default HowToUseTheTool;

