import React from "react";
import { PieChart, Pie, Cell, Label } from "recharts";
import { useTranslation } from "react-i18next";

const LABELS = {
  anonymous: "Anonymous",
  non_anonymous: "Non-Anonymous",
};
const COLORS = {
  [LABELS.anonymous]: "#FF6C6C",
  [LABELS.non_anonymous]: "#284B7D",
}

function CircleChartItem({ incidentsFormsShared, title }) {
  const countAnonymous = parseInt(incidentsFormsShared.countAnonymous);
  const countNotAnonymous = parseInt(incidentsFormsShared.countNotAnonymous);

  const { t } = useTranslation("global");

  const countAnonymousArray = countAnonymous ? [{
    name: LABELS.anonymous,
    value: countAnonymous,
  }] : []

  const countNotAnonymousArray = countNotAnonymous ? [{
    name: LABELS.non_anonymous,
    value: countNotAnonymous,
  }] : []

  const data = [...countAnonymousArray, ...countNotAnonymousArray];

  return (
    <div className="item-info-data">
      <div className="item-info-data__title">{title}</div>

      <div className="item-info-data__char-legend">
        <span className="item-info-data__char-legend__anonymous-bubble"></span>
        <p className="item-info-data__char_legend__lable-text">
          {t("translation-path-for-anonymous")}
        </p>
        <span className="item-info-data__char-legend__non-anonymous-bubble"></span>
        <p className="item-info-data__char-legend__lable-text">
          {t("translation-path-for-non-anonymous")}
        </p>
      </div>
      <div style={{ maxWidth: '83%', margin: '0 auto' }}>
        <PieChart width={263} height={300}>
          <Pie
            data={data}
            cx={100}
            cy={100}
            innerRadius={80}
            outerRadius={90}
            fill="#8884d8"
            dataKey="value"
          >
            <Label
              value={incidentsFormsShared.percentage + "%"}
              position="center"
              style={{
                fill: "#FF6C6C",
                fontWeight: "bold",
                fontSize: "3rem",
                fontFamily: "Bitter",
              }}
            />
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[entry.name]} />
            ))}
          </Pie>
        </PieChart>
      </div>
    </div>
  );
}

export default CircleChartItem;
