import React from "react";
import { useTranslation } from "react-i18next";
import { useSearch } from "../hooks/useSearch";

function Search({ setSearch, incidents }) {
  const { t } = useTranslation("global");
  const { handleChange, getUnreadIncidents } = useSearch(setSearch);

  return (
    <div className="container-incident-search">
      <div className="container-incident-search__title">
        {`${t(
          "platform tab-incidents-search-title admin"
        )} (${getUnreadIncidents(incidents)} ${t(
          "platform tab-incidents-search-title unread"
        )})`}
      </div>
      <div className="contianer-incident-search__form-container">
        <div className="incident-search__form">
          <input
            className="incident-search__input-search"
            type="text"
            name="search"
            onKeyUp={handleChange}
            autoComplete="off"
            required
            placeholder={t(
              "platform tab-incidents-search-input-placeholder admin"
            )}
          />
          <button className="incident-search-btn">
            <span></span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Search;
