import { cipher } from "lib/utils/encryption";
import { useState } from "react";

export const useChat = (
  initialForm,
  validateForm,
  socket,
  userData,
  currentChatRoom
) => {
  const [form, setForm] = useState(initialForm);
  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    if (form.content !== "") {
      const createdtime = Date.now();
      // Send message to server. We can't specify who we send the message to from the frontend. We can only send to server. Server can then send message to rest of users in room
      const data = {
        content: cipher(currentChatRoom.p_content_key, form.content),
        senderId: userData?.id, //daniel@jdoe.io,
        chatRoomId: currentChatRoom?.id,
        createdtime,
        role: userData?.role,
      };
      socket.emit("send_message", data);
      setForm({
        content: "",
      });
    }
  };

  return {
    form,
    errors,
    handleChange,
    handleSubmit,
  };
};
