import { useLocation, Navigate, Outlet } from "react-router-dom";
import { saveCookieData } from "../helpers/helpers";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const location = useLocation();

  saveCookieData("auth", auth, { path: "/" });

  const data = auth ? auth : {};

  return data?.accessToken ||
    (auth.loggedUser && allowedRoles.includes(auth.role)) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
