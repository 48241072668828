import { i18nGetData } from "../i18n";
import { EMAIL_RE, EMAIL_UNHCR_DOMAIN, PASSWORD_RE } from "./regexps";
import { domainStringSelection } from "helpers/helpers";

export const validationsFormLogin = (form) => {
  let errors = {};

  if (!form.email.trim()) {
    errors.email = i18nGetData("landing form login-email-required");
  } else if (!EMAIL_RE.test(form.email.trim())) {
    errors.email = i18nGetData("landing form login-email-invalid");
  }

  if (!form.password.trim()) {
    errors.password = i18nGetData("landing form login-password-required");
  } else if (form.password.length < 8) {
    errors.password = i18nGetData("landing form login-password-strong");
  }

  return errors;
};

export const validationsFormSignUp = (form, isAdmin = false) => {
  let errors = {};

  if (isAdmin) {
    if (!form.first_name.trim()) {
      errors.first_name = i18nGetData(
        "settings form validations first-name-required"
      );
    }

    if (!form.last_name.trim()) {
      errors.last_name = i18nGetData("Last Name is required");
    }
  }

  if (!form.email.trim()) {
    errors.email = i18nGetData("landing form create-account-email-required");
  } else if (!EMAIL_RE.test(form.email.trim())) {
    errors.email = i18nGetData("landing form create-account-email-invalid");
  }

  if (!form.password.trim()) {
    errors.password = i18nGetData(
      "landing form create-account-password-required"
    );
  } else if (form.password.length < 8) {
    errors.password = i18nGetData("landing form create-account-password-weak");
  } else if (!PASSWORD_RE.test(form.password)) {
    errors.password = i18nGetData(
      "landing form create-account-password-no-characters"
    );
  }

  if (!form.repeat_password.trim()) {
    errors.repeat_password = i18nGetData(
      "landing form create-account-password-required"
    );
  } else if (form.password !== form.repeat_password) {
    errors.repeat_password = i18nGetData(
      "landing form create-account-confirm-password-dont-match"
    );
  }

  if (form?.terms_and_conditions === false) {
    errors.message = i18nGetData(
      "landing form create-account-accept-terms-and-conditions-required"
    );
  }
  return errors;
};

export const validationsFormVerifyAccount = (form) => {
  let errors = {};
  if (!form.code.trim()) {
    errors.code = i18nGetData(
      "landing form verify-account form-verification-code-required"
    );
  }

  return errors;
};

export const validationsFormIncident = (form, perpetratorsList) => {
  let errors = {};

  if (!form.date.trim()) {
    errors.date = `${i18nGetData(
      "incident-form validations incident-required"
    )} ${i18nGetData("incident-form validations date-required")}`;
  } else {
    if (form.date.includes("-")) {
      let [startDate, endDate] = form.date.split("-");
      if (startDate.trim() === "" || endDate.trim() === "") {
        errors.date = `${i18nGetData(
          "incident-form validations incident-required"
        )} ${i18nGetData("incident-form validations both-dates-required")}`;
      }
    }
  }

  if (!form.what_happened.trim()) {
    errors.what_happened = `${i18nGetData(
      "incident-form validations incident-required"
    )} ${i18nGetData("incident-form validations description-required")}`;
  }

  if (!form.place.trim()) {
    errors.place = `${i18nGetData(
      "incident-form validations incident-required"
    )} ${i18nGetData("incident-form validations place-required")}`;
  }

  if (form?.place && form?.place === "7" && !form.custom_place.trim()) {
    errors.custom_place = `${i18nGetData(
      "incident-form validations incident-required"
    )} ${i18nGetData("incident-form validations custom-place-required")}`;
  }

  if (perpetratorsList && perpetratorsList.length > 0) {
    perpetratorsList.map((item, index) => {
      if (parseInt(item.knew_perpetrator) === 1) {
        if (!item.first_name.trim()) {
          errors["first_name" + index] = `${i18nGetData(
            "incident-form validations perpetrator-required"
          )} ${i18nGetData(
            "incident-form validations perpetrator-first-name-required"
          )}`;
        }
        if (!item.last_name.trim()) {
          errors["last_name" + index] = `${i18nGetData(
            "incident-form validations perpetrator-required"
          )} ${i18nGetData(
            "incident-form validations perpetrator-last-name-required"
          )}`;
        }
      } else if (item.knew_perpetrator === null) {
        errors["knew_perpetrator" + index] = `${i18nGetData(
          "incident-form validations perpetrator-required"
        )} ${i18nGetData(
          "incident-form validations perpetrator-knew_perpetrator-required"
        )}`;
      }
      return item;
    });
  }

  return errors;
};

export const validationsFormPassword = (form) => {
  let errors = {};

  if (!form.new_password.trim()) {
    errors.new_password = i18nGetData(
      "settings form validations password-required"
    );
  } else if (form.new_password.length < 8) {
    errors.new_password = i18nGetData(
      "settings form validations password-weak"
    );
  } else if (!PASSWORD_RE.test(form.new_password)) {
    errors.new_password = i18nGetData(
      "landing form create-account-password-no-characters"
    );
  }

  if (!form.confirm_password.trim()) {
    errors.confirm_password = i18nGetData(
      "settings form validations password-required"
    );
  } else if (form.new_password !== form.confirm_password) {
    errors.confirm_password = i18nGetData(
      "settings form validations confirm-password-dont-match"
    );
  }

  if (!form.current_password.trim()) {
    errors.current_password = i18nGetData(
      "settings form validations password-required"
    );
  } else if (form.current_password.length < 8) {
    errors.current_password = i18nGetData(
      "settings form validations password-weak"
    );
  } else if (!PASSWORD_RE.test(form.current_password)) {
    errors.current_password = i18nGetData(
      "settings form validations password-no-match"
    );
  }
  return errors;
};

export const validationsFormChat = (form) => {
  let errors = {};
  if (!form.content.trim())
    errors.content = i18nGetData(
      "messages anonymous-message validation text-required"
    );

  return errors;
};

export const validationsFormDeleteAccount = (form) => {
  const errors = {};

  if (form?.confirm_delete === false) {
    errors.message = i18nGetData(
      "your-incidents delete-incident confirm-error"
    );
  }

  return errors;
};

export const validationsFormChangeEmailFirstStep = (form, oldEmail) => {
  let errors = {};

  if (!form?.email?.trim()) {
    errors.email = i18nGetData("settings change-email email-required");
  } else if (form?.email?.trim() !== oldEmail.trim()) {
    errors.email = i18nGetData("settings change-email-error");
  }

  if (!form?.recoveryCode?.trim()) {
    errors.recoveryCode = i18nGetData(
      "settings change-email-error-recovery-code"
    );
  }
  if (!form?.verificationCode?.trim()) {
    errors.verificationCode = i18nGetData(
      "landing form verify-account form-verification-code-required"
    );
  }

  if (!form?.password?.trim()) {
    errors.password = i18nGetData(
      "landing form create-account-password-required"
    )
  }

  return errors;
};

export const validationsFormStartMessage = (form) => {
  const errors = {};

  if (!form?.subject.trim()) {
    errors.subject = "incident-form message subject-required-error";
  }

  return errors;
};

export const validatePerpetratorsList = (perpetratorsList, formStatus) => {
  let errors = {};
  if (formStatus !== "submitted") {
    return errors;
  }

  if (perpetratorsList && perpetratorsList.length > 0) {
    perpetratorsList.map((item, index) => {
      if (parseInt(item.knew_perpetrator) === 1) {
        if (!item.first_name.trim()) {
          errors["first_name" + index] = `${i18nGetData(
            "incident-form validations perpetrator-required"
          )} ${i18nGetData(
            "incident-form validations perpetrator-first-name-required"
          )}`;
        }
        if (!item.last_name.trim()) {
          errors["last_name" + index] = `${i18nGetData(
            "incident-form validations perpetrator-required"
          )} ${i18nGetData(
            "incident-form validations perpetrator-last-name-required"
          )}`;
        }
      } else if (item.knew_perpetrator === null) {
        errors["knew_perpetrator" + index] = `${i18nGetData(
          "incident-form validations perpetrator-required"
        )} ${i18nGetData(
          "incident-form validations perpetrator-knew_perpetrator-required"
        )}`;
      }
      return item;
    });
  }

  return errors;
};

export const inviteAdminFormValidations = (form) => {
  let errors = {};

  /* INVITE EMAIL VALIDATION */

  // console.log(EMAIL_UNHCR_DOMAIN);

  if (!form.invite_admin_email.trim()) {
    errors.invite_admin_email = "Email is required";
  } else if (!EMAIL_RE.test(form.invite_admin_email.trim())) {
    errors.invite_admin_email = "Email address incorrectly formatted";
  } else if (
    EMAIL_RE.test(form.invite_admin_email.trim()) &&
    !EMAIL_UNHCR_DOMAIN.test(form.invite_admin_email.trim())
  ) {
    errors.invite_admin_email = `Email domain must be ${domainStringSelection(
      process.env.REACT_APP_FRONT_ENV
    )}`;
  }

  /* CONFIRM INVITE EMAIL VALIDATION */

  if (!form.invite_admin_confirm_email.trim()) {
    errors.invite_admin_confirm_email = "Email is required";
  } else if (!EMAIL_RE.test(form.invite_admin_confirm_email.trim())) {
    errors.invite_admin_confirm_email = i18nGetData(
      "Email address incorrectly formatted"
    );
  } else if (
    EMAIL_RE.test(form.invite_admin_confirm_email.trim()) &&
    !EMAIL_UNHCR_DOMAIN.test(form.invite_admin_confirm_email.trim())
  ) {
    errors.invite_admin_confirm_email = `Email domain must be ${domainStringSelection(
      process.env.REACT_APP_FRONT_ENV
    )}`;
  } else if (form.invite_admin_email !== form.invite_admin_confirm_email) {
    errors.invite_admin_confirm_email = "Invite emails must match";
  }

  /* PASSWORD VALIDATION */

  if (!form.invite_admin_password.trim()) {
    errors.invite_admin_password = "Password is required";
  } else if (form.invite_admin_password.length < 8) {
    errors.invite_admin_password = "Password must be at least eight characters";
  } else if (!PASSWORD_RE.test(form.invite_admin_password)) {
    errors.invite_admin_password =
      "The email or password you entered did not match our records. Please double-check and try again";
  }

  if (!form.invite_admin_verification_code.trim()) {
    errors.invite_admin_verification_code = "Verification code is required";
  }

  return errors;
};

export const validationsFormContact = (form) => {
  let errors = {};

  if (!form.first_name.trim()) {
    errors.first_name = i18nGetData(
      "settings form validations first-name-required"
    );
  }

  if (!form.last_name.trim()) {
    errors.last_name = i18nGetData(
      "settings form validations last-name-required"
    );
  }

  return errors;
};
