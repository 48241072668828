import axios from "axios";
import { BASE_URL, ENDPOINT } from "helpers/constants";
import i18n from "i18next";

export const getNotifications = async ({ token }) => {
  const res = await axios.get(
    BASE_URL + ENDPOINT.notifications.get_notifications,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        "Accept-Language": i18n.language,
      },
    }
  );
  return res.data;
};
