export const NAME_RE = /^[A-Za-z\s]+$/;
export const PHONE_RE_NA = /^[0-9]{10}$/;
// eslint-disable-next-line no-useless-escape
export const PHONE_RE_GL = /^[\+][0-9]{7,12}$/;
export const NUMBERS = /^[0-9]{1,6}$/;
// eslint-disable-next-line no-useless-escape
export const EMAIL_RE =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// eslint-disable-next-line no-useless-escape
export const PASSWORD_RE = /(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])/g;
export const TIME_RE = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/;

const domainValidationSelection = (environment = "production") => {
  const domainOptions = {
    develop: process.env.REACT_APP_INVITE_ADMIN_DOMAIN_VALIDATION_DEVELOP,
    qa: process.env.REACT_APP_INVITE_ADMIN_DOMAIN_VALIDATION_QA,
    staging: process.env.REACT_APP_INVITE_ADMIN_DOMAIN_VALIDATION_STAGING,
    production: process.env.REACT_APP_INVITE_ADMIN_DOMAIN_VALIDATION_PRODUCTION,
  };

  return new RegExp(domainOptions[environment.toLowerCase()]);
};

export const EMAIL_UNHCR_DOMAIN = domainValidationSelection(
  process.env.REACT_APP_FRONT_ENV
);

//TODO: Remove all const and just let the regex variables;
export const ANDROID_URL = "http://10.0.2.2:8088/graphql";
export const GENYMOTION_URL = "http://10.0.3.2:8088/graphql";
export const IOS_URL = "http://localhost:8088/graphql";
export const HEROKU_URL = "https://tranquil-hamlet-76293.herokuapp.com/graphql";

export const RTYPE_DICT = [
  "Partner",
  "Family or Guardian",
  "Colleague or Employer",
  "Teacher or Coach",
  "Acquaintance",
  "Stranger",
];

export const RTYPE_DICT_AND_COLORS = {
  Partner: "#612223",
  "Family or Guardian": "#4A0001",
  "Colleague or Employer": "#9F1614",
  "Teacher or Coach": "#7E1210",
  Acquaintance: "#E77172",
  Stranger: "#E98B8D",
  "Multiple Perpetrators": "#612223",
};
