import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { incidentView, overviewStatus } from "helpers/constants";
import {
  incidentStep,
  perpetratorsStep,
  documentationStep,
  impactStep,
  reviewStep,
} from "helpers/helpers";

function FormRoadMap(props) {
  const { i18n, t } = useTranslation("global");
  const form = props?.form || null;
  const {
    form_step,
    what_happened,
    place,
    date,
    custom_place,
    perpetrators,
    upload_file_option,
    feelings,
    isAnonymous,
    status,
  } = props?.form || {};

  const firstStep = form
    ? incidentStep(form_step, what_happened, place, date, custom_place)
    : {};
  const secondStep = form ? perpetratorsStep(form_step, perpetrators) : {};
  const thirdStep = form
    ? documentationStep(form_step, upload_file_option)
    : {};
  const fourthStep = form ? impactStep(form_step, feelings) : {};
  const fifthStep = form ? reviewStep(form_step, isAnonymous, status) : {};

  const goTo = (step) => {
    props.setPageIncident(step);
    props.setModal(false);
    props.setModalContent("");
    props.setSelectedIncidentView(incidentView.EDIT_INCIDENT);
  };

  return (
    <div className="roadmap-container">
      <div
        className={classNames({
          "roadmap-title-container": true,
          "roadmap-title-container-ar": i18n.language === "ar",
        })}
      >
        <div
          className={classNames({
            "roadmap-message-text": true,
            "roadmap-message-text-ar": i18n.language === "ar",
          })}
        lang={i18n.language}

        >
          {t("platform overview-pop-up-subtitle")}
        </div>
        <div
          className={classNames({
            "roadmap-overview-title": true,
            "roadmap-overview-title-ar": i18n.language === "ar",
          })}
        lang={i18n.language}

        >
          {t("platform overview-pop-up-title")}
        </div>
      </div>
      <div className="scroll-container" lang={i18n.language}>
        <div className="roadmap-content">
          <div
            className={classNames({
              "roadmap-column": true,
              "roadmap-column-ar": i18n.language === "ar",
            })}
          >
            <div
              className={classNames({
                "roadmap-form-step__title": true,
                clickable: true,
              })}
              onClick={(e) => {
                e.stopPropagation();
                goTo(1);
              }}
              title={t("platform overview-pop-up-item-1-step-title")}
            >
              {t("platform overview-pop-up-item-1-step-title")}
            </div>
            <div
              className={classNames({
                "roadmap-form-step__content": true,
                "roadmap-form-step__content-done icon-star fs-2":
                  form && firstStep.stepStatus === overviewStatus.DONE,
                "roadmap-form-step__content-in_progress":
                  form && firstStep.stepStatus === overviewStatus.IN_PROGRESS,
                "roadmap-form-step__content-ar": i18n.language === "ar",
              })}
            >
              <div
                className={classNames({
                  "roadmap-form-step__item": true,
                  "roadmap-form-step__item-done": form
                    ? firstStep.isWhatHappenedCompleted
                    : false,
                })}
                title={t("platform overview-pop-up-item-1-question-key")}
              >
                <div className="roadmap-form-step__item-question-key">
                  {t("platform overview-pop-up-item-1-question-key")}
                </div>
                <div></div>
              </div>
              <div
                className={classNames({
                  "roadmap-form-step__item": true,
                  "roadmap-form-step__item-done": form
                    ? firstStep.isPlaceCompleted
                    : false,
                })}
                title={t("platform overview-pop-up-item-2-question-key")}
              >
                <div className="roadmap-form-step__item-question-key">
                  {t("platform overview-pop-up-item-2-question-key")}
                </div>
                <div></div>
              </div>
              <div
                className={classNames({
                  "roadmap-form-step__item": true,
                  "roadmap-form-step__item-done": form
                    ? firstStep.isDateCompleted
                    : false,
                })}
                title={t("platform overview-pop-up-item-3-question-key")}
              >
                <div className="roadmap-form-step__item-question-key">
                  {t("platform overview-pop-up-item-3-question-key")}
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div
            className={classNames({
              "roadmap-column": true,
              "roadmap-column-ar": i18n.language === "ar",
            })}
          >
            <div
              className={classNames({
                "roadmap-form-step__title": true,
                clickable: true,
              })}
              onClick={(e) => {
                e.stopPropagation();
                goTo(2);
              }}
              title={t("platform overview-pop-up-item-2-step-title")}
            >
              {t("platform overview-pop-up-item-2-step-title")}
            </div>
            <div
              className={classNames({
                "roadmap-form-step__content": true,
                "roadmap-form-step__content-done icon-star fs-2":
                  form && secondStep.stepStatus === overviewStatus.DONE,
                "roadmap-form-step__content-in_progress":
                  form && secondStep.stepStatus === overviewStatus.IN_PROGRESS,
                "roadmap-form-step__content-ar": i18n.language === "ar",
              })}
            >
              <div
                className={classNames({
                  "roadmap-form-step__item": true,
                  "roadmap-form-step__item-done": form
                    ? secondStep.isKnewPerpetratorsCompleted
                    : false,
                })}
                title={t("platform overview-pop-up-item-4-question-key")}
              >
                <div className="roadmap-form-step__item-question-key">
                  {t("platform overview-pop-up-item-4-question-key")}
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div
            className={classNames({
              "roadmap-column": true,
              "roadmap-column-ar": i18n.language === "ar",
              "last-element": true,
            })}
          >
            <div
              className={classNames({
                "roadmap-form-step__title": true,
                clickable: true,
              })}
              onClick={(e) => {
                e.stopPropagation();
                goTo(3);
              }}
              title={t("platform overview-pop-up-item-3-step-title")}
            >
              {t("platform overview-pop-up-item-3-step-title")}
            </div>
            <div
              className={classNames({
                "roadmap-form-step__content": true,
                "roadmap-form-step__content-done icon-star fs-2":
                  form && thirdStep.stepStatus === overviewStatus.DONE,
                "roadmap-form-step__content-in_progress":
                  form && thirdStep.stepStatus === overviewStatus.IN_PROGRESS,
                "roadmap-form-step__content-ar": i18n.language === "ar",
              })}
            >
              <div
                className={classNames({
                  "roadmap-form-step__item": true,
                  "roadmap-form-step__item-done": form
                    ? thirdStep.isAnyDocumentationCompleted
                    : false,
                })}
                title={t("platform overview-pop-up-item-8-question-key")}
              >
                <div className="roadmap-form-step__item-question-key">
                  {t("platform overview-pop-up-item-8-question-key")}
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div
            className={classNames({
              "roadmap-column": true,
              "roadmap-column-ar": i18n.language === "ar",
            })}
          >
            <div
              className={classNames({
                "roadmap-form-step__title": true,
                clickable: true,
              })}
              onClick={(e) => {
                e.stopPropagation();
                goTo(4);
              }}
              title={t("platform overview-pop-up-item-4-step-title")}
            >
              {t("platform overview-pop-up-item-4-step-title")}
            </div>
            <div
              className={classNames({
                "roadmap-form-step__content": true,
                "roadmap-form-step__content-done icon-star fs-2":
                  form && fourthStep.stepStatus === overviewStatus.DONE,
                "roadmap-form-step__content-in_progress":
                  form && fourthStep.stepStatus === overviewStatus.IN_PROGRESS,
                "roadmap-form-step__content-ar": i18n.language === "ar",
              })}
            >
              <div
                className={classNames({
                  "roadmap-form-step__item": true,
                  "roadmap-form-step__item-done": form
                    ? fourthStep.isFeelingCompleted
                    : false,
                })}
                title={t("platform overview-pop-up-item-9-question-key")}
              >
                <div className="roadmap-form-step__item-question-key">
                  {t("platform overview-pop-up-item-9-question-key")}
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div
            className={classNames({
              "roadmap-column": true,
              "roadmap-column-ar": i18n.language === "ar",
              "last-element": i18n.language !== "ar",
            })}
          >
            <div
              className={classNames({
                "roadmap-form-step__title": true,
                clickable: true,
              })}
              onClick={(e) => {
                e.stopPropagation();
                goTo(5);
              }}
              title={t("platform overview-pop-up-item-5-step-title")}
            >
              {t("platform overview-pop-up-item-5-step-title")}
            </div>
            <div
              className={classNames({
                "roadmap-form-step__content": true,
                "roadmap-form-step__content-done icon-star fs-2":
                  form && fifthStep.stepStatus === overviewStatus.DONE,
                "roadmap-form-step__content-in_progress":
                  form && fifthStep.stepStatus === overviewStatus.IN_PROGRESS,
                "roadmap-form-step__content-ar": i18n.language === "ar",
              })}
            >
              <div
                className={classNames({
                  "roadmap-form-step__item": true,
                  "roadmap-form-step__item-done": form
                    ? fifthStep.isReviewCompleted
                    : false,
                })}
                title={t("platform overview-pop-up-item-13-question-key")}
              >
                <div className="roadmap-form-step__item-question-key">
                  {t("platform overview-pop-up-item-13-question-key")}
                </div>
                <div></div>
              </div>
              <div
                className={classNames({
                  "roadmap-form-step__item": true,
                  "roadmap-form-step__item-done": form
                    ? fifthStep.isAnonymousCompleted
                    : false,
                })}
                title={t("platform overview-pop-up-item-14-question-key")}
              >
                <div className="roadmap-form-step__item-question-key">
                  {t("platform overview-pop-up-item-14-question-key")}
                </div>
                <div></div>
              </div>
              <div
                className={classNames({
                  "roadmap-form-step__item": true,
                  "roadmap-form-step__item-done": form
                    ? fifthStep.isSubmitCompleted
                    : false,
                })}
                title={t("platform overview-pop-up-item-15-question-key")}
              >
                <div className="roadmap-form-step__item-question-key">
                  {t("platform overview-pop-up-item-15-question-key")}
                </div>
                <div className="roadmap-form-step__item-question-text"></div>
                <div></div>
              </div>
              <div
                className={classNames({
                  "roadmap-form-step__item": true,
                  "roadmap-form-step__item-done": form
                    ? fifthStep.isShareCompleted
                    : false,
                })}
                title={t("platform overview-pop-up-item-16-question-key")}
              >
                <div className="roadmap-form-step__item-question-key">
                  {t("platform overview-pop-up-item-16-question-key")}
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormRoadMap;
