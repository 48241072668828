import React from "react";

function StatisticsItem({ title, data }) {
  return (
    <div className="item-info-data">
      <div className="item-info-data__title">{title}</div>
      <div className="item-info-data__info">{data ?? 0}</div>
    </div>
  );
}

export default StatisticsItem;
