import React, { Fragment, useState } from "react";
import { getReviewData } from "adapters/reviewInfo";
import classNames from "classnames";
import {
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  SectionType,
  TextRun,
  AlignmentType,
} from "docx";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import moment from "moment";
import "moment/locale/ar";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/ru";
import "moment/locale/zh-cn";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import Loading from "../../components/Loading";

const ArabicRegex = /[\u0600-\u06FF\u0750-\u077F]/;

function DialogDownloadIncident(props) {
  const { i18n, t } = useTranslation("global");
  const [activateLoader, setActivateLoader] = useState(null);

  if (i18n.language === "ch") {
    moment.locale("zh-cn");
  } else {
    moment.locale(i18n.language);
  }

  const generateAndDownloadZipWithIncidentFile = (incidentFile, extension) => {
    const zip = new JSZip();

    zip.file(`incident.${extension}`, incidentFile, { binary: true });

    if (props?.form?.files?.length > 0) {
      const files = zip.folder("files");
      props.form.files.forEach((file) => {
        // use request to fetch the file and add it to the zip
        files.file(
          file.name,
          fetch(file.url).then((r) => r.blob()),
          { binary: true }
        );
      });
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "incident.zip");
      setActivateLoader(false);
    });
  };

  const handleDownloadPDF = () => {
    const element = document.getElementById("pdf-incident");

    var opt = {
      margin: [20, 35],
      filename: "incident.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true },
      jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
      pagebreak: { mode: ["css", "whiteline"], avoid: [".content-row"] },
    };

    props
      .html2pdf()
      .set(opt)
      .from(element)
      .outputPdf()
      .then((pdf) => {
        generateAndDownloadZipWithIncidentFile(pdf, "pdf");
      });
  };

  const getDateAnswer = (date, timeOfIncident) => {
    // validate if has date of incidents
    if (date) {
      // validate if it's a range date
      if (date?.includes("-")) {
        const dateBetween = t(
          `${t("review date-between")} ${moment(date.split("-")[0]).format(
            "MMMM Do, YYYY"
          )} - ${moment(date.split("-")[1]).format("MMMM Do, YYYY")}`
        );
        // return date range with time of incident if exits
        return `${dateBetween}${timeOfIncident ?? ""}`;
      } else {
        // return date of incidents with time of incident if exits
        return `${moment(date).format("MMMM Do, YYYY")}${timeOfIncident ?? ""}`;
      }
    } else if (timeOfIncident) {
      // return time of incident
      return timeOfIncident.split(",")[1];
    }

    return "";
  };

  const isArabic = (text) => ArabicRegex.test(text);

  const docAlignment = () =>
    i18n.language === "ar" ? AlignmentType.RIGHT : AlignmentType.LEFT;

  const firstTextByLanguage = (item, type) => {
    if (type === "list") {
      return isArabic(item.answer) || i18n.language !== "ar"
        ? `${item.question}: `
        : `${item.answer}`;
    } else {
      return isArabic(item.answer) ||
        i18n.language !== "ar" ||
        item?.type === "date"
        ? `${item.question}: `
        : item?.type === "date"
        ? getDateAnswer(item.answer, item?.answer2)
        : item.answer;
    }
  };

  const firstBoldByLanguage = (item, type) => {
    if (type === "list") {
      return isArabic(item.answer) || i18n.language !== "ar" ? true : false;
    } else {
      return isArabic(item.answer) ||
        i18n.language !== "ar" ||
        item?.type === "date"
        ? true
        : false;
    }
  };

  const secondTextByLanguage = (item, type) => {
    if (type === "list") {
      return isArabic(item.answer) || i18n.language !== "ar"
        ? `${item.answer}`
        : ` :${item.question} `;
    } else {
      return isArabic(item.answer) ||
        i18n.language !== "ar" ||
        item?.type === "date"
        ? item?.type === "date"
          ? getDateAnswer(item.answer, item?.answer2)
          : item.answer
        : ` :${item.question}`;
    }
  };

  const secondBoldByLanguage = (item, type) => {
    if (type === "list") {
      return isArabic(item.answer) || i18n.language !== "ar" ? false : true;
    } else {
      return isArabic(item.answer) ||
        i18n.language !== "ar" ||
        item?.type === "date"
        ? false
        : true;
    }
  };

  const handleDownloadWord = (e) => {
    e.preventDefault();
    const doc = new Document({
      sections: [
        {
          properties: {
            type: SectionType.CONTINUOUS,
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text:
                    i18n.language === "ar"
                      ? `:${t("your-incidents download-incident file-title")}`
                      : `${t("your-incidents download-incident file-title")}:`,
                  color: "000000",
                  bold: true,
                  size: 64,
                }),
              ],
              alignment: docAlignment(),
              heading: HeadingLevel.HEADING_1,
              spacing: {
                after: 500,
                before: 500,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `${t(
                    `incident-form status ${props.form.status}`
                  )} ${moment(props.form.created_at).format("MMMM Do, YYYY")} ${
                    props.view === "admin" && props.form.survivor !== ""
                      ? `By ${props.form.survivor}`
                      : ""
                  }`,
                  color: "000000",
                  bold: true,
                }),
              ],
              alignment: docAlignment(),
              heading: HeadingLevel.HEADING_2,
              spacing: {
                after: 500,
                before: 500,
              },
            }),
          ],
        },
        ...data.map((item) => {
          let subFields = [];
          if (item.type === "list") {
            subFields = item.items.map(
              (list, p) =>
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${t("incident-form validations item")}: ${p + 1}`,
                      bold: true,
                    }),
                    ...list.content.map(
                      (p) =>
                        new TextRun({
                          text:
                            isArabic(item.answer) || i18n.language !== "ar"
                              ? `${p.question}: ${p.answer}`
                              : `${p.answer}: ${p.question}`,
                          break: 1,
                        })
                    ),
                  ],
                  alignment: docAlignment(),
                  border: {
                    bottom: {
                      color: "000000",
                      space: 1,
                      style: "single",
                      size: 6,
                    },
                  },
                })
            );
          } else if (item.type === "sub-fields") {
            subFields = item.content.map(
              (c) =>
                new Paragraph({
                  children: [
                    new TextRun({
                      text: firstTextByLanguage(c, item.type),
                      bold: firstBoldByLanguage(c, item.type),
                    }),
                    new TextRun({
                      text: secondTextByLanguage(c, item.type),
                      bold: secondBoldByLanguage(c, item.type),
                    }),
                  ],
                  alignment: docAlignment(),
                  border: {
                    bottom: {
                      color: "000000",
                      space: 1,
                      style: "single",
                      size: 6,
                    },
                  },
                })
            );
          } else {
            subFields = item.content.map(
              (c) =>
                new Paragraph({
                  children: [
                    new TextRun({
                      text: firstTextByLanguage(c, item.type),
                      bold: firstBoldByLanguage(c, item.type),
                    }),
                    new TextRun({
                      text: secondTextByLanguage(c, item.type),
                      bold: secondBoldByLanguage(c, item.type),
                    }),
                  ],
                  alignment: docAlignment(),
                  border: {
                    bottom: {
                      color: "auto",
                      space: 1,
                      style: "single",
                      size: 6,
                    },
                  },
                })
            );
          }

          return {
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item.title,
                    color: "000000",
                  }),
                ],
                alignment: docAlignment(),
                heading: HeadingLevel.HEADING_2,
                spacing: {
                  before: 200,
                },
              }),
              ...subFields,
            ],
            properties: {
              type: SectionType.CONTINUOUS,
            },
          };
        }),
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      generateAndDownloadZipWithIncidentFile(blob, "docx");
    });
  };

  const getInfo = (item) => {
    switch (item.type) {
      case "list":
        return (
          <Fragment>
            {item.items.map((list, p) => (
              <div className="content-item" key={p}>
                <div className="item-indicator" lang={i18n.language}>
                  {t("incident-form validations item")}: {p + 1}
                </div>
                {list.content.map((p, count) => (
                  <div
                    className="content-row subfield"
                    key={count}
                    lang={i18n.language}
                  >
                    <span>
                      <strong>{p.question}: </strong>
                    </span>
                    <span>{p.answer}</span>
                  </div>
                ))}
              </div>
            ))}
          </Fragment>
        );
      case "sub-fields":
        return (
          <Fragment>
            {item.content.map((c, i) => (
              <Fragment>
                <div
                  className="content-row"
                  key={"row" + i}
                  lang={i18n.language}
                >
                  <span>
                    <strong>{c.question}: </strong>
                  </span>
                  <span>{c.answer}</span>
                </div>
                {c?.subfield && c?.subfield.length > 0 ? (
                  <Fragment>
                    {c.subfield.map((list, p) => (
                      <div className="content-item subfield" key={"sub" + i}>
                        <div className="item-indicator">
                          {t("incident-form validations item")}: {p + 1}
                        </div>
                        {list.content.map((p, count) => (
                          <div
                            className="content-row"
                            key={count}
                            lang={i18n.language}
                          >
                            <span>
                              <strong>{p.question}: </strong>
                            </span>
                            <span>{p.answer}</span>
                          </div>
                        ))}
                      </div>
                    ))}
                  </Fragment>
                ) : (
                  <div key={"none" + i}></div>
                )}
              </Fragment>
            ))}
          </Fragment>
        );
      default:
        return (
          <Fragment>
            {item.content.map((c, i) => (
              <div className="content-row" key={i} lang={i18n.language}>
                <span>
                  <strong>{c.question}: </strong>
                </span>
                <span>
                  {c?.type === "date"
                    ? getDateAnswer(c.answer, c?.answer2)
                    : c.answer}
                </span>
              </div>
            ))}
          </Fragment>
        );
    }
  };

  const data = getReviewData(props.form);

  return (
    <Fragment>
      <div
        className={classNames({
          "content-sublightbox__title": true,
          "content-sublightbox__title-ar": i18n.language === "ar",
        })}
        lang={i18n.language}
      >
        {t("your-incidents download-incident modal-title")}:
      </div>
      <div className="content-sublightbox__content download-incident">
        <div
          className={classNames({
            "content-sublightbox__content-description": true,
            "download-incident": true,
            "download-incident--loading": activateLoader,
            "ar-letter-spacing": i18n.language === "ar",
          })}
        >
          <button
            className="btn-download pdf"
            onClick={(e) => {
              e.preventDefault();
              setActivateLoader(true);
              handleDownloadPDF(e);
            }}
          >
            {t("landing resources internal-resource-pdf-btn")}
          </button>
          <button
            className="btn-download word"
            onClick={(e) => {
              e.preventDefault();
              setActivateLoader(true);
              handleDownloadWord(e);
            }}
          >
            {t("landing resources internal-resource-word-btn")}
          </button>
        </div>
        {activateLoader ? (
          <div className="loader-container">
            <Loading />
          </div>
        ) : null}
        <div className="pdf-template" id="pdf-incident" lang={i18n.language}>
          <h1>{t("your-incidents download-incident file-title")}:</h1>
          <h3 className="review-header__date-submitted">
            {t(`incident-form status ${props.form.status}`)}{" "}
            {moment(props.form.created_at).format("MMMM Do, YYYY")}{" "}
            {props.view === "admin" && props.form.survivor !== ""
              ? `By ${props.form.survivor}`
              : ""}
          </h3>
          <div className="review-accordion">
            {data.map((item, i) => {
              return (
                <div className="item" key={i}>
                  <div className="title">{item.title}</div>
                  <div className="content selected">{getInfo(item)}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="content-sublightbox__options download-incident">
          <Button
            class="custom btn-no-option btn-back-download-incident"
            text={t("landing resources internal-resource-back-btn")}
            onClick={(e) => {
              e.preventDefault();
              window.location.reload();
            }}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default DialogDownloadIncident;
