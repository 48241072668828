import axios from "axios";
import { BASE_URL, ENDPOINT } from "helpers/constants";
import i18n from "i18next";

export const resendEmailVerificationForEmailChange = async ({ token }) => {
  const res = await axios.post(
    BASE_URL + "/user/information/emailResend",
    {},
    {
      headers: {
        "Content-Type": "application/json",
        authorization: token,
        Accept: "*/*",
      },
    }
  );
  return res.data;
};

export const resend2faCodeForEmailChange = async ({ token }) => {
  const res = await axios.post(
    BASE_URL + "/user/information/resend2faCode",
    {},
    {
      headers: {
        "Content-Type": "application/json",
        authorization: token,
        Accept: "*/*",
      },
    }
  );
  return res.data;
};

export const resendNewEmailCode = async ({ token, formData }) => {
  const res = await axios.post(
    BASE_URL + "/user/information/resendNewEmailCode",
    formData,
    {
      headers: {
        "Content-Type": "application/json",
        authorization: token,
        Accept: "*/*",
      },
    }
  );
  return res.data;
};

export const updatePassword = async ({ token, formData }) => {
  const res = await axios.put(
    BASE_URL + ENDPOINT.user.update_password,
    formData,
    {
      headers: {
        "Content-Type": "application/json",
        authorization: token,
        Accept: "*/*",
      },
    }
  );
  return res.data;
};

export const updateSurvivorInfo = async ({ token, formData }) => {
  const res = await axios.put(
    BASE_URL + ENDPOINT.user.update_survivor,
    formData,
    {
      headers: {
        "Content-Type": "application/json",
        authorization: token,
        Accept: "*/*",
      },
    }
  );
  return res.data;
};

export const updateUserInfo = async ({ token, formData }) => {
  const res = await axios.put(BASE_URL + ENDPOINT.user.update_user, formData, {
    headers: {
      "Content-Type": "application/json",
      authorization: token,
      Accept: "*/*",
    },
  });
  return res.data;
};

export const getUser = async ({ token }) => {
  const res = await axios.get(BASE_URL + ENDPOINT.user.user_data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  return res.data;
};

export const getUserSetting = async (token) => {
  const res = await axios.get(BASE_URL + ENDPOINT.user.settings, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  return res.data;
};

export const updateUserSetting = async (token, settings) => {
  const res = await axios.put(BASE_URL + ENDPOINT.user.settings, settings, {
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
  });
  return res.data;
};

export const verify2FA = async (body) => {
  const res = await axios.post(BASE_URL + ENDPOINT.user.verify_2fa, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res.data;
};

export const verifyAccount = async (body) => {
  const res = await axios.post(BASE_URL + ENDPOINT.user.verify_account, body, {
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": i18n.language,
    },
  });
  return res.data;
};

export const resendVerificationCode = async (body) => {
  const res = await axios.post(BASE_URL + ENDPOINT.user.email_code, body, {
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": i18n.language,
    },
  });
  return res.data;
};

export const updateTwoFactorAuthSetting = async (token, body) => {
  const res = await axios.put(BASE_URL + ENDPOINT.user.update_2fa, body, {
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": i18n.language,
      authorization: token,
    },
  });
  return res.data;
};

export const resendTwoFactorAuthCode = async (token, body) => {
  const res = await axios.post(BASE_URL + ENDPOINT.user.resend_2fa_code, body, {
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": i18n.language,
      authorization: token,
    },
  });
  return res.data;
};

export const deleteUser = async (token) => {
  const res = await axios.delete(BASE_URL + ENDPOINT.user.delete_account, {
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": i18n.language,
      authorization: token,
    },
  });
  return res.data;
};

export const survivorSignUp = async (body) => {
  const res = await axios.post(BASE_URL + ENDPOINT.user.register, body, {
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": i18n.language,
    },
  });

  return res;
};

export const adminSignUp = async (body) => {
  const res = await axios.post(BASE_URL + ENDPOINT.user.adminRegister, body, {
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": i18n.language,
    },
  });

  return res;
};

export const sendInviteCode = async (body, token) => {
  const res = await axios.post(
    BASE_URL + ENDPOINT.user.send_invite_email,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": i18n.language,
        authorization: token,
      },
    }
  );
  return res.data;
};

export const inviteAdminVerificationCode = async (body, token) => {
  const res = await axios.post(
    BASE_URL + ENDPOINT.user.verify_invite_admin_code,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  return res;
};

export const inviteAdminSubmit = async (token, body) => {
  const res = await axios.post(BASE_URL + ENDPOINT.user.invite_admin, body, {
    headers: {
      "Content-Type": "application/json",
      authorization: token,
      "Accept-Language": "en",
    },
  });
  return res.data;
};

export const adminCount = async (token) => {
  const res = await axios.get(BASE_URL + ENDPOINT.user.admin_count, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  return res.data;
};
