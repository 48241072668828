import classNames from "classnames";
import Loading from "components/Loading";
import { decipher } from "lib/utils/encryption";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import NotificationItem from "./notifications/NotificationItem";

const WelcomeMessageView = ({ props }) => {
  const { t, i18n } = useTranslation("global");

  return (
    <div
      className={classNames({
        "messaging-tab": true,
        "ar-letter-spacing": i18n.language === "ar",
      })}
    >
      <div
        className={classNames({
          "messaging-tab__welcome": true,
          "admin-zero-state": props.userType === "admin",
        })}
      >
        {props.userType !== "admin" ? (
          <>
            <div className="welcome-title" lang={i18n.language}>
              {t("landing resources section-message-description")}
            </div>
            <div className="welcome-options" lang={i18n.language}>
              <Button
                className="btn-start-message"
                text={t(
                  "incident-form message start-message-btn-start-message"
                )}
                onClick={(e) => {
                  e.preventDefault();
                  if (props.modalChat) {
                    props.setModalChat(false);
                  }
                  props.setSubModalContent("start-message");
                  props.setSubModal(true);
                }}
              />
            </div>
            <div className="welcome-note">
              {t("messages start-message modal description")}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

const ChatListView = ({ chatRoomList, props }) => {
  const { t, i18n } = useTranslation("global");
  const [currentId, setCurrentId] = React.useState("");

  const userRole = props.userData.role ?? props.userType;
  const isAdmin = userRole === "admin";
  const chatRoomStatus = isAdmin ? "vco_status" : "status";

  return (
    <div
      className={classNames({
        "chat-tab": true,
        "ar-letter-spacing": i18n.language === "ar",
      })}
    >
      <div
        className={classNames({
          "chat-tab__panel-options": true,
          "chat-tab__panel-options-admin": props.userType === "admin",
        })}
      >
        <Button
          className="btn-start-message panel-options"
          text={t("incident-form message start-message-btn-start-message")}
          onClick={(e) => {
            e.preventDefault();
            if (props.modalChat) {
              props.setModalChat(false);
            }
            props.setSubModalContent("start-message");
            props.getIncidents("chatroom");
            props.setSubModal(true);
          }}
        />
      </div>
      <div className="chatrooms-container-list">
        {chatRoomList && chatRoomList.length > 0
          ? chatRoomList.map((item, i) => (
              <NotificationItem
                key={i}
                title={decipher(item.p_content_key, item.subject)}
                description=""
                date={item?.last_message_date || item.created_at}
                id={item.id}
                status={item[chatRoomStatus]}
                type="chatroom"
                getChatRoomData={props.getChatRoomData}
                currentChatRoom={props.currentChatRoom}
                deleteHistory={props.deleteHistory}
                markAsUnread={props.markAsUnread}
                currentId={currentId}
                setCurrentId={setCurrentId}
                role={props.userType}
                setModalChat={props.setModalChat}
                setSubModal={props.setSubModal}
                userRole={props.userData.role}
              />
            ))
          : null}
      </div>
    </div>
  );
};

function MessagingTab(props) {
  const { socket, chatRooms, view } = props;

  React.useEffect(() => {
    socket.emit("join_chatroom_list");
  }, [socket]);

  return (
    <>
      {view === "view-welcome" ? (
        <WelcomeMessageView props={props} />
      ) : view === "view-chat" ? (
        <ChatListView chatRoomList={chatRooms} props={props} />
      ) : (
        <div className="loading-container">
          <Loading />
        </div>
      )}
    </>
  );
}

export default MessagingTab;
