import React, { Fragment, useEffect, useState, useRef } from "react";
import classNames from "classnames";
import Loading from "components/Loading";
import { resourcePath } from "helpers/constants";
import { addPrefixLanding } from "helpers/helpers";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";

function TermsAndPrivacy() {
  const [numPages, setNumPages] = useState(null);
  const [url, setURL] = useState("");
  const location = useLocation();
  const containerRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [pageWidth, setPageWidth] = useState(
    containerRef?.current?.offsetWidth > 900
      ? 900
      : containerRef?.current?.offsetWidth
  );

  useEffect(() => {
    if (location.pathname.includes("terms-of-use")) {
      setURL(
        `${process.env.REACT_APP_RESOURCES_BASE_URL}/${resourcePath.RESOURCES}/terms-of-use.pdf`
      );
    } else {
      setURL(
        `${process.env.REACT_APP_RESOURCES_BASE_URL}/${resourcePath.RESOURCES}/privacy-statement.pdf`
      );
    }
  }, [location.pathname]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (containerRef.current) {
        setPageWidth(
          containerRef.current.offsetWidth > 900
            ? 900
            : containerRef.current.offsetWidth
        );
      }
    });

    return () => {
      window.removeEventListener("resize", () => {
        if (containerRef.current) {
          setPageWidth(
            containerRef.current.offsetWidth > 900
              ? 900
              : // eslint-disable-next-line react-hooks/exhaustive-deps
                containerRef.current.offsetWidth
          );
        }
      });
    };
  }, []);

  return (
    <Fragment>
      <Header />
      <section
        className={classNames({
          [addPrefixLanding("support-section")]: true,
          [addPrefixLanding("section-2")]: true,
        })}
      >
        <div
          className={classNames({
            "resource-page": true,
            "resource-page-terms-and-conditions": true,
          })}
        >
          <div className="resource-page-preview" ref={containerRef}>
            <Document
              file={url}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<Loading />}
              renderMode="svg"
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page 
                  key={`page_${index + 1}`} 
                  pageNumber={index + 1} 
                  renderAnnotationLayer={true}
                  renderTextLayer={false}
                  externalLinkTarget="_blank"
                  width={
                    containerRef.current?.offsetWidth > 900
                      ? 900
                      : containerRef.current?.offsetWidth
                  } 
                />
              ))}
            </Document>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}

export default TermsAndPrivacy;
