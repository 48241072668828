/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import classNames from "classnames";
import logo from "../assets/images/pseash-logo-blue.svg";
import LanguageSelector from "./navigation/LanguageSelector";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function AsideNavbar(props) {
  const { t, i18n } = useTranslation("global");
  const openMenu = props.openMenu;

  const unreadNotifications = props.notificationList.filter(
    (notification) => !notification.read
  );
  const notificationIndicator =
    unreadNotifications.length > 99 ? "99+" : unreadNotifications.length;

  const onClickLogout = () => {
    props.setShowPopup(true);
  };

  return (
    <aside
      className={classNames({
        "sidebar-menu": true,
        "sidebar-menu-show-menu": openMenu,
        "ar-letter-spacing": i18n.language === "ar",
      })}
      lang={i18n.language}
    >
      <div className="sidebar-menu__top">
        <div className="logo-container">
          <Link to="/home" target="_blank">
            <img className="logo" src={logo} alt="Logo" />
          </Link>
        </div>
        <div className="user-actions">
          <ul className="">
            <li className="nav-menu__item lang-selector">
              <LanguageSelector />
            </li>
          </ul>
          <div
            className="notifications-icon"
            onClick={(e) => {
              props.setModalContent("notifications");
              props.setModal(true);
            }}
          >
            <div className="avatar">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.73 21.75C13.5542 22.0531 13.3018 22.3047 12.9982 22.4795C12.6946 22.6544 12.3504 22.7465 12 22.7465C11.6496 22.7465 11.3054 22.6544 11.0018 22.4795C10.6982 22.3047 10.4458 22.0531 10.27 21.75M18 8.75C18 7.1587 17.3679 5.63258 16.2426 4.50736C15.1174 3.38214 13.5913 2.75 12 2.75C10.4087 2.75 8.88258 3.38214 7.75736 4.50736C6.63214 5.63258 6 7.1587 6 8.75C6 15.75 3 17.75 3 17.75H21C21 17.75 18 15.75 18 8.75Z"
                  stroke="#FFFFFF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="avatar"
                />
              </svg>
            </div>

            <div className="indicator">
              <span>{notificationIndicator}</span>
            </div>
          </div>
        </div>
        <div className="items-container">
          <ul className="">
            {props.menuItems.map((item, index) => {
              return index <= 3 ? (
                <li
                  key={index}
                  className={classNames({
                    selected: props.toggle === item.id,
                  })}
                  onClick={(e) => {
                    props.setSubModal(false);
                    props.setModalChat(false);
                    props.setOpen(false);
                    props.setCurrentChatRoom({});
                    props.handleToggle(item.id);
                    if (item.id === 2) {
                      props.getIncidentsList();
                    }
                  }}
                >
                  <a href={item.url}>{item.name}</a>
                </li>
              ) : null;
            })}
          </ul>
        </div>
      </div>
      <div className="sidebar-menu__bottom">
        <ul className="">
          <li
            onClick={() => {
              props.setSubModal(false);
              props.setModalChat(false);
              props.setCurrentChatRoom({});
              props.handleToggle(5);
              props.setOpen(false);
            }}
            className={classNames({
              selected: props.toggle === 5,
            })}
          >
            <a href="#settings">{t("platform navigation-item-5")}</a>
          </li>
          <li onClick={onClickLogout}>
            <a href="#">{t("platform navigation-item-6")}</a>
          </li>
        </ul>
        <Link to="/terms-of-use" target="_blank">
          {t("platform navigation-item-7")}
        </Link>
        <Link to="/privacy-statement" target="_blank">
          {t("platform navigation-item-8")}
        </Link>
      </div>
    </aside>
  );
}

export default AsideNavbar;
