import React, { Fragment, useState } from "react";
import { incidentStatus } from "helpers/constants";
import { useTranslation } from "react-i18next";
import { upsertIncident } from "services/incidents";
import Button from "../Button";
import classNames from "classnames";

export const useStartMessage = (
  validateForm,
  initialForm,
  tokenTemp,
  setSubModalContent,
  user,
  getIncidents
) => {
  const [form, setForm] = useState(initialForm);

  const handleChangeRadio = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value === "0" ? true : false });
  };

  const handleChangeText = (e) => {
    const { value } = e.target;

    setForm({ ...form, subject: value });
  };

  const handleSubmit = async (form, incidentForm) => {
    const incident = {
      ...incidentForm,
      isShareable: true,
      isAnonymous: form.remain_anonymous,
      status: incidentStatus.SHARED.id,
    };

    await Promise.resolve(
      upsertIncident({
        token: tokenTemp,
        formData: incident,
      })
    ).then(() => {
      getIncidents();
      setSubModalContent("thankyou-form-shared");
    });
  };

  return {
    form,
    handleChangeRadio,
    handleChangeText,
    handleSubmit,
  };
};

const initialForm = {
  remain_anonymous: true,
  share_incident: true,
};

const validationsForms = (form) => {
  const errors = {};

  return errors;
};

function DialogShareIncident(props) {
  const { t, i18n } = useTranslation("global");
  const { form, handleChangeRadio, handleSubmit } = useStartMessage(
    validationsForms,
    initialForm,
    props.tokenTemp,
    props.setSubModalContent,
    props.userData,
    props.getIncidents
  );

  return (
    <Fragment>
      <div
        className={classNames({
          "content-sublightbox__title submodal-start-message": true,
          "content-sublightbox__title-ar": i18n.language === "ar",
        })}
      >
        {t("your-incidents share-incident modal-title")}
      </div>
      <div className="content-sublightbox__content submodal-start-message">
        <div className="content-sublightbox__content-description submodal-start-message">
          <p
            className={classNames({
              "ar-text-align": i18n.language === "ar",
            })}
            lang={i18n.language}
          >
            {t("your-incidents share-incident modal-description")}
          </p>
          <form className="content-sublightbox__form" lang={i18n.language}>
            <div className="container_form__row">
              <div className="label-title">
                {t("your-incidents share-incident modal-question")}
              </div>
              <div className="container-radio-buttons-options start-message">
                <div className="radio-button-container">
                  <input
                    type="radio"
                    id="remain-anonymous"
                    name="remain_anonymous"
                    value="1"
                    onChange={handleChangeRadio}
                  />
                  <label htmlFor="remain-anonymous">
                    {t("your-incidents share-incident modal-not-agree-option")}
                  </label>
                </div>
                <div className="radio-button-container">
                  <input
                    type="radio"
                    id="remain-anonymous-no"
                    name="remain_anonymous"
                    value="0"
                    defaultChecked={true}
                    onChange={handleChangeRadio}
                  />
                  <label htmlFor="remain-anonymous-no">
                    {t("your-incidents share-incident modal-agree-option")}
                  </label>
                </div>
              </div>
              <span className="message-note-form" lang={i18n.language}>
                {t("your-incidents share-incident modal-question-hint")}
              </span>
            </div>
          </form>
        </div>
        <div
          className="content-sublightbox__options submodal-incident__form"
          lang={i18n.language}
        >
          <Button
            class="custom btn-confirm-submit"
            text={t("your-incidents share-incident modal-submit-confirmation")}
            onClick={(e) => {
              handleSubmit(form, props.selectedFormId);
            }}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default DialogShareIncident;

