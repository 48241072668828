import { getCookieData, saveCookieData } from "helpers/helpers";
import useAuth from "hooks/useAuth";
import { cipher, decipher, hash } from "lib/utils/encryption";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  TWO_FA_CODE,
  twoFactorAuthErrors,
  twoFactorAuthentication,
} from "../../../../helpers/constants";
import { i18nGetData } from "../../../../i18n";
import { login } from "../services/login";

const MAXIMUM_ATTEMPTS = 5;

export const useLogin = (initialForm, validateForm) => {
  const { i18n } = useTranslation("global");
  const [form, setLoginForm] = useState(initialForm);
  const [errors, setErrors] = useState({});
  const [attempts, setAttempts] = useState(MAXIMUM_ATTEMPTS);
  const { setAuth } = useAuth();

  let navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setLoginForm({
      ...form,
      [name]: value,
    });
    if (name === 'email') {
      setAttempts(MAXIMUM_ATTEMPTS);
    }
  };
  const handleBlur = (e) => {
    handleChange(e);
    setErrors(validateForm(form));
  };

  const goTo = (role) => {
    if (role === "survivor") {
      navigate("/welcome", {
        replace: true,
        state: {
          login: true,
        },
      });
    } else {
      navigate("/admin-dashboard/");
    }
  };

  const saveData = (authorization, refreshToken) => {
    setAuth({
      loggedUser: true,
      role: "survivor",
      accessToken: authorization,
      refreshToken: refreshToken,
    });

    saveCookieData(
      "auth",
      {
        loggedUser: true,
        role: "survivor",
        accessToken: authorization,
        refreshToken: refreshToken,
      },
      { path: "/" }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors(validateForm(form));

    const blocked = getCookieData("blocked");
    if (blocked) {
      setErrors({
        message: i18nGetData("landing form error maximum-attempts"),
      });
      return;
    }
    if (Object.keys(validateForm(form)).length === 0) {
      const cipherCode = localStorage.getItem(cipher(TWO_FA_CODE, TWO_FA_CODE));
      const decipherCode = cipherCode ? decipher(TWO_FA_CODE, cipherCode) : "";

      const user = {
        hashed_email: hash(form.email),
        password: hash(form.password),
        code: decipherCode,
      };

      Promise.resolve(login({ user: user, language: i18n.language }))
        .then((res) => {
          const {
            id,
            role,
            phone,
            two_factor_authentication,
            authorization,
            refreshToken,
          } = res;

          if (!res.isEmailVerified) {
            navigate("/verify-account", {
              state: {
                hashed_email: user.hashed_email,
                email: form.email,
              },
            });
          }

          saveData(authorization, refreshToken);

          if (
            two_factor_authentication == null ||
            two_factor_authentication === "always"
          ) {
            navigate("/two-factor-auth", {
              replace: true,
              state: {
                id,
                authorization,
                role,
                phone,
                two_factor_authentication,
                hashed_email: hash(form.email),
                password: hash(form.password),
              },
            });
          } else {
            goTo(res.role);
          }
        })
        .catch((error) => {
          if (
            error?.response?.data?.error === twoFactorAuthErrors.EVERY_LOG_IN
          ) {
            navigate("/two-factor-auth", {
              replace: true,
              state: {
                two_factor_authentication: twoFactorAuthentication.EVERY_LOG_IN,
                hashed_email: hash(form.email),
                password: hash(form.password),
                phone: error?.response?.data?.phone,
              },
            });
          } else if (error.response.status === 401) {
            navigate("/verify-account", {
              state: {
                hashed_email: user.hashed_email,
                email: form.email,
              },
            });
          } else {
            if (attempts === 1) {
              setErrors({
                message: i18nGetData("landing form error maximum-attempts"),
              });

              // Block user for 1 hour
              const date = new Date();
              date.setHours(date.getHours() + 1);
              saveCookieData("blocked", true, { path: "/", expires: date });
            } else {
              setErrors({
                message: `${i18nGetData(
                  "landing form error something-went-wrong",
                  { attempts: attempts - 1 }
                )}`,
              });
              setAttempts(attempts - 1);
            }
          }
        })
        .finally(() => {
          //final action
        });
    } else {
      return;
    }
  };

  return {
    form,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  };
};
