import React from "react";
import LandingNavSubItem from "./LandingNavSubItem";

function NavigationSubItems({ containerClass, navClass, menuItems }) {
  return (
    <div className={containerClass}>
      <ul className={navClass}>
        {menuItems.map((item, index) => {
          return (
            <LandingNavSubItem
              key={"item-" + index}
              link={item.link}
              name={item.name}
              submenu={item.submenu}
            />
          );
        })}
      </ul>
    </div>
  );
}

export default NavigationSubItems;
