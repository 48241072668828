import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import Loading from "components/Loading";
import Modal from "components/Modal";
import { useConfirmDelete } from "hooks/useConfirmDelete";
import { validationsFormDeleteAccount } from "helpers/validations";
import classNames from "classnames";

const initialForm = {
  confirm_delete: false,
};

function DialogDeleteAccount({
  setSubModal,
  setSubModalContent,
  setDeleteSuccessfulModal,
}) {
  const { t, i18n } = useTranslation("global");
  const [loading, setLoading] = useState(false);

  const { form, errors, handleChange, handleSubmit } = useConfirmDelete(
    validationsFormDeleteAccount,
    initialForm,
    setSubModal,
    setSubModalContent,
    setDeleteSuccessfulModal,
    setLoading
  );

  return (
    <Fragment>
      {loading ? (
        <Modal>
          <div className="lightbox-component deleting-account">
            <div className="loading-container">
              <Loading />
            </div>
          </div>
        </Modal>
      ) : (
        <>
          <div
            className={classNames({
              "content-sublightbox__title": true,
              "content-sublightbox__title-ar": i18n.language === "ar",
            })}
            lang={i18n.language}
          >
            {t("settings delete-account modal-title")}
          </div>
          <div
            className={classNames({
              "content-sublightbox__content": true,
              "ar-letter-spacing": i18n.language === "ar",
            })}
          >
            <div
              className="content-sublightbox__content-description"
              lang={i18n.language}
            >
              <p
                className={classNames({
                  "ar-text-align": i18n.language === "ar",
                })}
                lang={i18n.language}
              >
                {t("settings delete-account modal-description")}
              </p>
              <form className="content-sublightbox__form">
                <div className="container_form__row">
                  <div className="container_form__col terms-and-conditions">
                    <input
                      type="checkbox"
                      id="confirm_delete"
                      name="confirm_delete"
                      value={form.terms}
                      onChange={handleChange}
                    />
                    <label htmlFor="confirm_delete" lang={i18n.language}>
                      {t("settings delete-account modal-agree-checkbox")}
                    </label>
                  </div>
                </div>
                {form.confirm_delete === false && (
                  <div className="container_form__row" lang={i18n.language}>
                    <span className="error-message">{errors.message}</span>
                  </div>
                )}
              </form>
            </div>
            <div className="content-sublightbox__options" lang={i18n.language}>
              <Button
                class="custom btn-no-option"
                text={t("settings delete-account modal-cancel-button")}
                onClick={(e) => {
                  e.preventDefault();
                  setSubModal(false);
                  setSubModalContent("");
                }}
              />
              <Button
                class="custom btn-confirm-delete-account"
                text={t("settings delete-account modal-confirm-button")}
                onClick={handleSubmit}
                disabled={!form.confirm_delete}
              />
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
}

export default DialogDeleteAccount;

