import React from "react";

function RadioButton(props) {
  const elementId = props?.item
    ? props.fieldName + "-" + props.id + "-" + props.item
    : props.fieldName + "-" + props.id;
  const formValue = props?.item ? props.form : props.form[props.fieldName];

  return (
    <div className="radio-button-container">
      <input
        data-type={props.type}
        type="radio"
        id={elementId}
        name={props.fieldName}
        value={props.value}
        checked={formValue === props.value}
        onChange={props.handleChangeRadio}
      />
      <label htmlFor={elementId}>{props.name}</label>
    </div>
  );
}

export default RadioButton;
