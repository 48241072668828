import {
  clearCookiesData,
  getCookieData,
  saveCookieData,
} from "helpers/helpers";
import { refreshToken } from "pages/landing/login/services/login";
import { useNavigate } from "react-router-dom";
import useAuth from "./useAuth";

const useRefreshToken = (setExpiredModal) => {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();

  const onRefreshToken = async () => {
    const cookieData = getCookieData("auth", { path: "/" });

    refreshToken(cookieData.accessToken, cookieData.refreshToken)
      .then(({ authorization: accessToken, refreshToken }) => {
        setAuth({ ...auth, accessToken, refreshToken });
        saveCookieData(
          "auth",
          { ...auth, accessToken, refreshToken },
          { path: "/" }
        );
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setAuth({});
          clearCookiesData();
          navigate("/login");
        } else if (err.response.status === 403) {
          setExpiredModal(true);
        }
      });
  };

  return onRefreshToken;
};

export default useRefreshToken;
