import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthProvider";
import { clearCookiesData, getCookieData } from "../helpers/helpers";
import { logout } from "../pages/landing/login/services/login";

const useLogout = () => {
  const navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);

  const onLogout = () => {
    const cookieData = getCookieData("auth", { path: "/" });
    logout(cookieData.accessToken)
      .then(() => {
        setAuth({});
        clearCookiesData();
        navigate("/home");
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          setAuth({});
          clearCookiesData();
          navigate("/home");
        }
      });
  };
  return onLogout;
};

export default useLogout;
