import React from "react";
import LanguageSelector from "../../../../components/navigation/LanguageSelector";
import LandingNavItem from "./LandingNavItem";

const NavigationItems = ({
  containerClass,
  navClass,
  menuItems,
  subOption,
}) => {
  return (
    <>
      <div className={containerClass}>
        <ul className={navClass}>
          {menuItems.map((item, index) => {
            if (!item?.lang) {
              if (item?.items && item?.items.length > 0) {
                return (
                  <div className="item-sub-option">
                    <LandingNavItem
                      key={"item-" + index}
                      link={item?.link ? item.link : ""}
                      name={item.name}
                      id={item.key}
                      subOption={subOption}
                    />
                    <div className="submenu-mobile">
                      {item?.items.map((subitem, subindex) => (
                        <LandingNavItem
                          key={"subitem-" + subindex}
                          link={subitem.link}
                          name={subitem.name}
                          id={subitem.key}
                        />
                      ))}
                    </div>
                  </div>
                );
              } else {
                return (
                  <>
                    <LandingNavItem
                      key={"item-" + index}
                      link={item?.link ? item.link : ""}
                      name={item.name}
                      id={item.key}
                      icon={item?.icon}
                    />
                  </>
                );
              }
            } else {
              return (
                <li className="nav-menu__item lang-selector" key={index}>
                  <LanguageSelector />
                </li>
              );
            }
          })}
        </ul>
      </div>
    </>
  );
};

export default NavigationItems;
