import React, { Fragment, useEffect } from "react";
import classNames from "classnames";
import { getCookieData } from "helpers/helpers";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import HeaderForm from "../../components/HeaderForm";
import { PASSWORD_RE } from "../../helpers/regexps";
import { useResetPassword } from "../../hooks/useResetPassword";
import { i18nGetData } from "../../i18n";
import Button from "../Button";
import NotificationRecoveryKey from "../notification-messages/NotificationRecoveryKey";
import NotificationSuccess from "../notification-messages/NotificationSuccess";

const validationsForm = (form) => {
  let errors = {};

  if (!form.verification_code.trim()) {
    errors.verification_code = i18nGetData(
      "landing form reset-password-verification-code-required"
    );
  }

  if (!form.recovery_key.trim()) {
    errors.recovery_key = i18nGetData(
      "landing form reset-password-recovery key-required"
    );
  }

  if (!form.password.trim()) {
    errors.password = i18nGetData(
      "landing form reset-password-new-password-required"
    );
  } else if (form.password.length < 8) {
    errors.password = i18nGetData(
      "landing form reset-password-new-password-weak"
    );
  } else if (!PASSWORD_RE.test(form.password)) {
    errors.password = i18nGetData(
      "landing form create-account-password-no-characters"
    );
  }

  if (!form.repeat_password.trim()) {
    errors.repeat_password = i18nGetData(
      "landing form reset-password-new-password-required"
    );
  } else if (form.password !== form.repeat_password) {
    errors.repeat_password = i18nGetData(
      "landing form reset-password-passwords-doesnt-match"
    );
  }

  return errors;
};

function ResetPasswordForm({ data }) {
  const { t, i18n } = useTranslation("global");
  const {
    form,
    errors,
    counter,
    attempts,
    handleChange,
    handleSubmit,
    handleResendCode,
    setCounter,
  } = useResetPassword(
    {
      hashed_email: data.hashed_email,
      recovery_key: "",
      verification_code: "",
      password: "",
      repeat_password: "",
      new_recovery_key: "",
    },
    validationsForm
  );
  const blocked = getCookieData("blocked");

  const title = t("landing reset password section-title");
  const description = `<p>${t(
    "landing reset password section-description"
  )}</p>`;

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter, setCounter]);

  return (
    <div className="container-form__reset-password">
      <HeaderForm
        title={title}
        description={description}
        lang={i18n.language}
      />
      <div className="container-body__form">
        {form.new_recovery_key === "" ? (
          <form className="container-form">
            <div className="container_form__row ">
              <div
                className={classNames({
                  "container_form__col require_field": true,
                  "ar-letter-spacing": i18n.language === "ar",
                })}
                lang={i18n.language}
              >
                <label>
                  {t("landing form reset-password-verification-code")}
                </label>
                <input
                  type="text"
                  placeholder={`${t(
                    "landing form reset-password-verification-code"
                  )}*`}
                  name="verification_code"
                  value={form.verification_code}
                  onChange={handleChange}
                />
                {errors.verification_code && (
                  <span className="error-message">
                    {errors.verification_code}
                  </span>
                )}
              </div>
            </div>
            <div
              className={classNames({
                "container_form__row ": true,
                "ar-letter-spacing": i18n.language === "ar",
              })}
            >
              <div
                className="container_form__col require_field"
                lang={i18n.language}
              >
                <label>
                  {t("landing form reset-password-recovery key-label")}
                </label>
                <input
                  type="password"
                  placeholder={t(
                    "landing form reset-password-recovery key-placeholder"
                  )}
                  name="recovery_key"
                  value={form.recovery_key}
                  onChange={handleChange}
                />
                {errors.recovery_key && (
                  <span className="error-message">{errors.recovery_key}</span>
                )}
                <span
                  className={classNames({
                    "form_message password_field": true,
                  })}
                >
                  {t("landing form reset-password-recovery key-note")}
                </span>
              </div>
            </div>
            <div
              className={classNames({
                "container_form__row ": true,
                "ar-letter-spacing": i18n.language === "ar",
              })}
            >
              <div
                className="container_form__col require_field"
                lang={i18n.language}
              >
                <label>{t("landing form reset-password-new-password")}</label>
                <input
                  className={classNames({
                    "error-field": errors.password,
                  })}
                  type="password"
                  name="password"
                  placeholder={`${t("settings form new-password-label")}*`}
                  onChange={handleChange}
                  value={form.password}
                  required
                />
                {errors.password !== "no-match" && (
                  <span className="error-message">{errors.password}</span>
                )}
                <span
                  className={classNames({
                    "form_message password_field": true,
                    "error-message": errors.password === "no-match",
                  })}
                >
                  {t("landing form reset-password-password-note")}
                </span>
              </div>
            </div>
            <div
              className={classNames({
                "container_form__row ": true,
                "ar-letter-spacing": i18n.language === "ar",
              })}
            >
              <div className="container_form__col" lang={i18n.language}>
                <label>
                  {t("landing form reset-password-re-enter-password")}
                </label>
                <input
                  className={classNames({
                    "error-field": errors.repeat_password,
                  })}
                  type="password"
                  name="repeat_password"
                  placeholder={`${t(
                    "landing form reset-password-re-enter-password"
                  )}*`}
                  onChange={handleChange}
                  value={form.repeat_password}
                  required
                />
                {errors.repeat_password && (
                  <span className="error-message">
                    {errors.repeat_password}
                  </span>
                )}
                {errors.message && (
                  <div className="container_form__row">
                    <span className="error-message">{errors.message}</span>
                  </div>
                )}
              </div>
            </div>
            <div
              className={classNames({
                "button-landing-container ": true,
                "ar-letter-spacing": i18n.language === "ar",
              })}
            >
              <Link className="back-button-link" to="/login">
                <i className="icon-arrow-white"></i>
                {t("incident-form navigation-back")}
              </Link>
              <div className="container_form__row">
                <div className="container_form__col buttons-container">
                  <span
                    className="required-fields-text hide-mobile"
                    lang={i18n.language}
                  >
                    <span className="required-fields-text">
                      {t("landing form create-account-required-field-text")}
                    </span>
                  </span>
                  <Button
                    class="btn-reset-password"
                    text={t("landing form reset-password-btn-reset-password")}
                    onClick={handleSubmit}
                    disabled={blocked || form.new_recovery_key !== ""}
                  />
                  <span
                    className="required-fields-text hide-mobile"
                    lang={i18n.language}
                  >
                    <span className="required-fields-text">
                      {t(
                        "landing form verify-account form-not-receive-code-message"
                      )}
                    </span>
                  </span>
                  <Button
                    class="small btn-resend-code"
                    text={t(
                      "landing form verify-account form-resend-code-button"
                    )}
                    onClick={handleResendCode}
                    disabled={counter > 0 || attempts <= 0 ? true : false}
                  />
                  {counter > 0 && attempts >= 1 && (
                    <span
                      className="counter-verification-code"
                      lang={i18n.language}
                    >
                      <strong>
                        {t("landing form verify-account form-wait-hint", {
                          counter,
                        })}
                      </strong>
                    </span>
                  )}
                  {attempts >= 0 && (
                    <span
                      className="counter-verification-code"
                      lang={i18n.language}
                    >
                      {t(
                        "landing form verify-account form-remaining-attempts-hint",
                        {
                          attempts,
                        }
                      )}
                    </span>
                  )}
                </div>
                {attempts === 0 && (
                  <div className="container-body__form" lang={i18n.language}>
                    <span className="error-message">
                      {t(
                        "landing form verify-account form-remaining-attempts-reached"
                      )}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </form>
        ) : form.new_recovery_key === "bad-request" ? (
          <div></div>
        ) : (
          <Fragment>
            <NotificationSuccess
              text={t("landing form reset-password-success")}
            />
            <NotificationRecoveryKey
              containerClass={""}
              recoveryKey={form.new_recovery_key}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default ResetPasswordForm;
