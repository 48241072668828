import React from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import Button from "../components/Button";
import "react-datepicker/dist/react-datepicker.css";

function DateRange(props) {
  const { t } = useTranslation("global");

  return (
    <div className="dateRangeContainer">
      <h2 className="title">{t("platform dashboard date-range-selection")}</h2>
      <div className="datePickerContainer dashboard-mobile-view">
        <div className="datePickerWrapper">
          <p className="blueText">
            {t("platform dashboard start-date-range-selection")}
          </p>
          <DatePicker
            maxDate={new Date()}
            selected={props.startDate}
            minDate={props.minDate}
            onChange={props.handleStartDate}
            dateFormat="MM/dd/yyyy"
            placeholderText={t("platform dashboard question-date-placeholder")}
          />
        </div>
        <div className="datePickerWrapper">
          <p className="blueText">
            {t("platform dashboard end-date-range-selection")}
          </p>
          <DatePicker
            maxDate={new Date()}
            minDate={props.startDate}
            selected={props.endDate}
            onChange={props.handleEndDate}
            dateFormat="MM/dd/yyyy"
            placeholderText={t("platform dashboard question-date-placeholder")}
          />
        </div>
        <div className="buttonContainer date-range">
          <Button
            class="btn-save-setting"
            text={t("platform dashboard search-btn")}
            onClick={props.handleGetStats}
            disabled={props.error !== ""}
          />
          {props.error && <span className="error-message">{props.error}</span>}
        </div>
      </div>
    </div>
  );
}

export default DateRange;
