import React, { useEffect } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import closeIcon from "../../../../assets/images/close-btn-white.svg";
import { ReactComponent as MenuIcon } from "../../../../assets/images/menu-icon-white.svg";
import logo from "../../../../assets/images/pseash-logo-gray.svg";
import { menuItems, optionsItems } from "../../../../helpers/constants";
import { addPrefixLanding } from "../../../../helpers/helpers";
import NavigationItems from "../NavigationItems";
import NavigationSubItems from "../NavigationItems/NavigationSubItems";

function LandingNavBar() {
  const [menuSubOption, setMenuSubOption] = React.useState([]);
  const [subOption, setOption] = React.useState(null);
  const [optionSelected, setOptionSelected] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const routes = location?.pathname?.replace("/", "").split("/");
  const { i18n, t } = useTranslation("global");

  useEffect(() => {
    if (routes.length > 1) {
      const item = menuItems.find((item) => item.key === routes[0]);
      setMenuSubOption(item.items);
      const subitem = item?.items?.find((item) => item.key === routes[1]);
      setOptionSelected(subitem?.name ? t(subitem.name) : "");
      setOption(routes[0]);
    } else {
      const item = menuItems.find((item) => item.link.includes(routes[0]));
      setOptionSelected(item?.name ? t(item.name) : "");
      setOption(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes]);

  const handleMenu = () => {
    setOpen((value) => !value);
  };
  return (
    <>
      <div className={addPrefixLanding("navigationbar")} lang={i18n.language}>
        <a
          className={classNames({
            "app-landing-nav-logo": true,
            "app-landing-nav-logo-ar": i18n.language === "ar",
          })}
          href="/home"
        >
          <img src={logo} alt="PSEASH Logo" />
        </a>
        <div className="landing-nav-title">{optionSelected}</div>
        <div
          className={classNames({
            [addPrefixLanding("nav-container")]: true,
            [addPrefixLanding("nav-container-ar")]: i18n.language === "ar",
          })}
        >
          <div
            className={classNames({
              [addPrefixLanding("nav-menu-items")]: true,
              [addPrefixLanding("show-menu")]: open,
            })}
          >
            <div className="privacy-and-terms">
              <Link to="/terms-of-use" target="_blank">
                {t("platform navigation-item-7")}
              </Link>
              <Link to="/privacy-statement" target="_blank">
                {t("platform navigation-item-8")}
              </Link>
            </div>
            <nav className={addPrefixLanding("nav-container-page-menu")}>
              <NavigationItems
                containerClass={addPrefixLanding(
                  "navigationbar__items-container"
                )}
                navClass={addPrefixLanding("navigationbar__nav-menu")}
                menuItems={menuItems}
                subOption={menuSubOption.length > 0 ? null : subOption}
              />
            </nav>
            <NavigationItems
              containerClass={addPrefixLanding(
                "navigationbar__options-container"
              )}
              navClass={addPrefixLanding("navigationbar__options-nav-menu")}
              menuItems={optionsItems}
            />

            <a className="mobile-logo-menu" href="/home">
              <img src={logo} alt="PSEASH Logo" />
            </a>
            <div className={addPrefixLanding("close-menu-button")}>
              <button className="custom btn-close" onClick={handleMenu}>
                <img src={closeIcon} alt="Close button" />
              </button>
            </div>
          </div>
          <div className={addPrefixLanding("menu-button")} onClick={handleMenu}>
            <MenuIcon />
          </div>
        </div>
      </div>
      {menuSubOption && menuSubOption.length > 1 ? (
        <div
          className={addPrefixLanding("navigation-sub-bar")}
          lang={i18n.language}
        >
          <NavigationSubItems
            containerClass={addPrefixLanding(
              "navigation-sub-bar__items-container"
            )}
            navClass={addPrefixLanding("navigation-sub-bar__nav-sub-menu")}
            menuItems={menuSubOption}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default LandingNavBar;

