import React from "react";
import { chatFormJSON, Role } from "../../helpers/constants";
import { validationsFormChat } from "../../helpers/validations";
import { useChat } from "../../hooks/useChat";
import Button from "../Button";
import CloseDialog from "../dialogs/CloseDialog";
import Messages from "../message-system/Messages";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";
import { decipher } from "lib/utils/encryption";
import classNames from "classnames";

function ModalChatContent({
  setModalChat,
  socket,
  userData,
  currentChatRoom,
  setModalContent,
  messages,
  isMessagesLoading,
  setCurrentChatRoom,
  markAsUnread,
  setSelectedIncident,
  setSelectedIncidentView,
  setChatroomList,
  chatroomList,
}) {
  const { t, i18n } = useTranslation("global");
  const { form, handleChange, handleSubmit } = useChat(
    chatFormJSON,
    validationsFormChat,
    socket,
    userData,
    currentChatRoom
  );

  const handleLeaveChat = () => {
    const updateChatRoomList = chatroomList.map((chat) => ({
      ...chat,
      vco_status:
        userData.role === Role.ADMIN && currentChatRoom.id === chat.id
          ? false
          : chat.vco_status,
      status:
        userData.role === Role.SURVIVOR && currentChatRoom.id === chat.id
          ? false
          : chat.status,
    }));

    setChatroomList(updateChatRoomList);

    socket.emit("leave_room", {
      userID: userData.id,
    });
  };

  const getChatRoomDescription = (chatRoom, userData) => {
    if (userData.role === Role.ADMIN) {
      if (chatRoom.anonymous) {
        return t("message non-annonymous-message description", {
          option: t("message non-annonymous-message description-annonymous"),
        });
      } else {
        if (
          currentChatRoom?.user?.first_name &&
          currentChatRoom?.user?.last_name
        ) {
          return t("message non-annonymous-message description", {
            option: `${decipher(
              currentChatRoom.user.p_content_key,
              currentChatRoom.user.first_name
            )} ${decipher(
              currentChatRoom.user.p_content_key,
              currentChatRoom.user.last_name
            )}`,
          });
        } else {
          return t("message non-annonymous-message description", {
            option: t(
              "message non-annonymous-message description-not-annonymous"
            ),
          });
        }
      }
    } else {
      if (currentChatRoom.anonymous) {
        return t("message non-annonymous-message description-alternate");
      } else {
        return t("messages anonymous-message description");
      }
    }
  };

  if (isMessagesLoading) return <Loading />;

  return (
    <div
      className={classNames({
        "chat-modal": true,
        "ar-letter-spacing": i18n.language === "ar",
      })}
    >
      {/* send socket event on modal close to chage the status of the messages in the chat room */}
      <CloseDialog
        setModal={setModalChat}
        setModalContent={setModalContent}
        isChatRoom
        setCurrentChatRoom={setCurrentChatRoom}
        currentChatRoom={currentChatRoom}
        markAsUnread={markAsUnread}
        userData={userData}
        userType={userData.role}
        handleLeaveChat={handleLeaveChat}
      />
      <div className="chat-header" lang={i18n.language}>
        <div className="chat-header__title">
          {currentChatRoom.anonymous
            ? t("messages anonymous-message title")
            : t("messages non-anonymous-message title")}
        </div>
        <div className="chat-header__description">
          <p>{getChatRoomDescription(currentChatRoom, userData)}</p>
        </div>
      </div>
      <div className="chat-history-container">
        <Messages
          isLoading={isMessagesLoading}
          messages={messages}
          socket={socket}
          senderId={userData.id}
          currentChatRoom={currentChatRoom}
          userIv={userData.p_content_key}
          setSelectedIncident={setSelectedIncident}
          setSelectedIncidentView={setSelectedIncidentView}
          setModalChat={setModalChat}
          userRole={userData.role}
        />
      </div>
      <div className="chat-message-container" lang={i18n.language}>
        <textarea
          placeholder={t("messages anonymous-message input-placeholder")}
          name="content"
          onChange={handleChange}
          value={form.content}
        />
        <Button
          className="btn-send-message"
          text={t("messages anonymous-message send-button")}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
export default ModalChatContent;

