import axios from "axios";
import { BASE_URL, ENDPOINT } from "helpers/constants";
import i18n from "i18next";

export const uploadFile = async ({ token, formData }) => {
  const res = await axios.post(BASE_URL + ENDPOINT.files.files, formData, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "multipart/form-data",
      "Accept-Language": i18n.language,
      "Access-Control-Allow-Origin": "*",
    },
    timeout: 600000,
    timeoutErrorMessage: "TimeoutError",
  });
  return res.data;
};

export const getFilesOfIncident = async ({ token, data }) => {
  const res = await axios.post(
    BASE_URL + ENDPOINT.files.files_of_incident,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
  return res.data;
};

export const deleteFileOfIncident = async ({ token, data }) => {
  const res = await axios.delete(BASE_URL + ENDPOINT.files.files, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    data: data,
  });
  return res.data;
};

