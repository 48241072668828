import React, { Fragment, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player/vimeo";
import { Link } from "react-router-dom";
import { ReactComponent as DownArrow } from "../../assets/images/down-arrow-orange.svg";
import gardIcon from "../../assets/images/landing/gard-icon.svg";
import lockIcon from "../../assets/images/landing/lock-icon.svg";
import matchIcon from "../../assets/images/landing/match-icon.svg";
import Logo from "../../assets/images/not-only-me-logo.png";
import { addPrefixLanding, getCookie } from "../../helpers/helpers";
import AboutTheToolItem from "./components/AboutTheToolItem";
import Footer from "./components/Footer";
import Header from "./components/Header";

function Home() {
  const player = useRef(null);
  const { t, i18n } = useTranslation("global");
  const [playingVideo, setPlayingVideo] = useState(false);
  const lang = getCookie("language");

  const aboutTheToolItems = [
    {
      title: t("landing about section-anonymity-item-1"),
      description: t("landing about section-anonymity-item-1-description"),
      icon: gardIcon,
    },
    {
      title: t("landing about section-anonymity-item-2"),
      description: t("landing about section-anonymity-item-2-description"),
      icon: lockIcon,
    },
    {
      title: t("landing about section-anonymity-item-3"),
      description: t("landing about section-anonymity-item-3-description"),
      icon: matchIcon,
    },
  ];

  const howToUseToolItems = [
    {
      title: t("landing about section-howtouse-not-ready"),
      items: [
        {
          title: t("landing about section-howtouse-not-ready-item-1-title"),
          description: t(
            "landing about section-howtouse-not-ready-item-1-description"
          ),
        },
        {
          title: t("landing about section-howtouse-not-ready-item-2-title"),
          description: t(
            "landing about section-howtouse-not-ready-item-2-description"
          ),
        },
        {
          title: t("landing about section-howtouse-not-ready-item-3-title"),
          description: t(
            "landing about section-howtouse-not-ready-item-3-description"
          ),
        },
      ],
    },
    {
      title: t("landing about section-howtouse-ready"),
      items: [
        {
          title: t("landing about section-howtouse-ready-item-1-title"),
          description: t(
            "landing about section-howtouse-ready-item-1-description"
          ),
        },
        {
          title: t("landing about section-howtouse-ready-item-2-title"),
          description: t(
            "landing about section-howtouse-ready-item-2-description"
          ),
        },
        {
          title: t("landing about section-howtouse-ready-item-3-title"),
          description: t(
            "landing about section-howtouse-ready-item-3-description"
          ),
        },
        {
          title: t("landing about section-howtouse-ready-item-4-title"),
          description: t(
            "landing about section-howtouse-ready-item-4-description"
          ),
        },
      ],
    },
  ];

  const getContentHowTo = () => {
    return howToUseToolItems.map((item, index) => {
      return (
        <div
          key={index}
          className={classNames({
            [addPrefixLanding("section-container__row")]: true,
          })}
        >
          <div className={addPrefixLanding("how-to__title")}>{item.title}</div>
          <div className={addPrefixLanding("how-to__content")}>
            {item.items.map((subItem, i) => {
              return (
                <div
                  key={i}
                  className={classNames({
                    [addPrefixLanding("how-to__items")]: true,
                    "four-columns": item.items.length > 3,
                  })}
                >
                  <div className="container-info">
                    <div
                      className={classNames({
                        title: true,
                        "w-50":
                          (i !== 1 && item.items.length === 3) ||
                          (i !== 3 && item.items.length > 3),
                      })}
                    >
                      {subItem.title}
                    </div>
                    <div className="description">{subItem.description}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };

  return (
    <Fragment>
      <Header />
      <section
        className={classNames({
          [addPrefixLanding("home-section")]: true,
          [addPrefixLanding("section-logo")]: true,
        })}
      >
        <div className={addPrefixLanding("section-logo-container")}>
          <div className="image-container">
            <img src={Logo} alt="mainLogo" />
          </div>
          <DownArrow
            onClick={(e) => {
              e.stopPropagation();
              window.location.href = "#video";
            }}
          />
        </div>
      </section>
      <section
        className={classNames({
          [addPrefixLanding("home-section")]: true,
          [addPrefixLanding("section-about")]: true,
        })}
        lang={i18n.language}
      >
        <div className="divisor top"></div>
        <div>
          <p>{t("landing about mainText 1")}</p>
          <p>{t("landing about mainText 2")}</p>
        </div>
        <div className="divisor bottom"></div>
      </section>
      <section
        id="video"
        className={classNames({
          [addPrefixLanding("home-section")]: true,
          [addPrefixLanding("section-video")]: true,
        })}
      >
        <div className="player-wrapper">
          <ReactPlayer
            ref={player}
            url="https://vimeo.com/822079339"
            height="100%"
            width="100%"
            className="react-player"
            volume={1}
            playing={playingVideo}
            onClick={() => setPlayingVideo(false)}
            onEnded={() => {
              setPlayingVideo(false);
              player.seekTo(0);
            }}
          />
          {!playingVideo && (
            <div className="video-placeholder">
              <div
                className="play-button-container"
                onClick={() => setPlayingVideo(true)}
              >
                <div className="play-button" />
              </div>
            </div>
          )}
        </div>
      </section>
      <section
        className={classNames({
          [addPrefixLanding("home-section")]: true,
          [addPrefixLanding("home-section-1")]: true,
          "line-position-ar": lang === "ar",
        })}
        lang={i18n.language}
      >
        <div className={addPrefixLanding("section-container")}>
          <div
            className={classNames({
              [addPrefixLanding("section-container-more-information")]: true,
              [addPrefixLanding("section-container-more-information-ar")]:
                lang === "ar",
            })}
          >
            <div
              className={classNames({
                [addPrefixLanding("section-container__column")]: true,
              })}
            >
              <h2
                className={classNames({
                  [addPrefixLanding("section-title-2")]: true,
                  [addPrefixLanding("section-title")]: true,
                })}
              >
                {t("landing about section-more-information")}
              </h2>
            </div>
            <div
              className={classNames({
                [addPrefixLanding("section-container__column")]: true,
                "flex-center": true,
              })}
            >
              <div className={addPrefixLanding("section-1-content-2")}>
                <Link
                  className={classNames({
                    [addPrefixLanding(
                      "section-1-content-2-button-redirect"
                    )]: true,
                  })}
                  to="/about-the-tool"
                ></Link>
              </div>
            </div>
          </div>
          <div
            className={classNames({
              [addPrefixLanding("section-container-encrypted")]: true,
            })}
          >
            <div
              className={classNames({
                [addPrefixLanding("section-container__column")]: true,
                "flex-center": true,
              })}
            >
              <div className={addPrefixLanding("section-1-content")}>
                <h2 className={addPrefixLanding("section-title")}>
                  {t("landing about section-encrypted")}
                </h2>
                <p>{t("landing about section-encrypted-description")}</p>
              </div>
            </div>
            <div
              className={classNames({
                [addPrefixLanding("section-container__column")]: true,
                "flex-center": true,
              })}
            >
              <div className={addPrefixLanding("section-1-content")}>
                <Link
                  className={classNames({
                    [addPrefixLanding("login-mobile-button")]: true,
                  })}
                  to="/sign-up"
                >
                  {t("landing about section-encrypted-start-incident-btn")}
                </Link>
                <Link
                  className={classNames({
                    [addPrefixLanding("button-circle")]: true,
                    [addPrefixLanding("button-login")]: true,
                    [addPrefixLanding("hide-mobile")]: true,
                  })}
                  to="/sign-up"
                >
                  <span>
                    {t("landing about section-encrypted-start-incident-btn")}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className={addPrefixLanding("section-container")}>
          <div
            className={classNames({
              [addPrefixLanding("section-container-preview")]: true,
            })}
          >
            <div
              className={addPrefixLanding("section-container-preview-content")}
            >
              <div className={addPrefixLanding("container-preview-link")}>
                <span>{t("landing about section-encrypted-faqs")}</span>
                <Link
                  className={classNames({
                    [addPrefixLanding("preview-link")]: true,
                  })}
                  to="/support-resources/help-with-the-tool"
                >
                  {t("landing about section-encrypted-preview-btn")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className={classNames({
          [addPrefixLanding("home-section-2")]: true,
          [addPrefixLanding("section-2")]: true,
          "line-position-ar": lang === "ar",
          "line-position-es": lang === "es",
          "line-position-fr": lang === "fr",
          "line-position-ch": lang === "ch",
        })}
        lang={i18n.language}
      >
        <div className={addPrefixLanding("section-container")}>
          <div
            className={classNames({
              [addPrefixLanding("section-container__row")]: true,
            })}
          >
            <h2
              className={classNames({
                [addPrefixLanding("section-title")]: true,
              })}
            >
              {t("landing about section-anonymity")}
            </h2>
          </div>
          <div
            className={classNames({
              [addPrefixLanding("section-container__row")]: true,
            })}
          >
            <div className={addPrefixLanding("container-about-the-tool-items")}>
              {aboutTheToolItems.map((item, index) => {
                return <AboutTheToolItem key={"item-" + index} {...item} />;
              })}
            </div>
          </div>
        </div>
      </section>
      <section
        className={classNames({
          [addPrefixLanding("home-section")]: true,
          [addPrefixLanding("section-3")]: true,
          "line-height-fr": lang === "fr",
          "line-height-es": lang === "es",
          "line-height-ru": lang === "ru",
          "line-height-ch": lang === "ch",
          "line-height-ar": lang === "ar",
          "line-position-ar": lang === "ar",
        })}
        lang={i18n.language}
      >
        <div
          className={classNames({
            [addPrefixLanding("section-container__row")]: true,
          })}
        >
          <h2 className={addPrefixLanding("section-title")}>
            {t("landing about section-howtouse")}
          </h2>
        </div>
        <div
          className={classNames({
            [addPrefixLanding("section-container")]: true,
            "relative-position": true,
          })}
        >
          {getContentHowTo()}
          <span className="show-mobile red-line-vertical tools-home-red-line-vertical" />
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}

export default Home;

