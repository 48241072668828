import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import classNames from "classnames";

export const useConfirmDelete = (
  setSubModalContent,
  setModalSubmit,
  setSubModalView
) => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/dashboard/#messages");
    setSubModalContent("start-message");
    setModalSubmit(false);
    setSubModalView(true);
  };

  return {
    handleSubmit,
  };
};

function DialogThankyouFormSubmitted(props) {
  const { t, i18n } = useTranslation("global");
  const { handleSubmit } = useConfirmDelete(
    props.setSubModalContent,
    props.setModalSubmit,
    props.setSubModalView
  );

  const closeModal = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  return (
    <Fragment>
      <div
        className={classNames({
          "content-sublightbox__title submodal-incident__form": true,
          "content-sublightbox__title-ar": i18n.language === "ar",
        })}
      >
        {t("incident-form submission-confirmation-thankyou-title")}
      </div>
      <div className="content-sublightbox__content submodal-incident__form">
        <div
          className="content-sublightbox__content-description submodal-incident__form"
          lang={i18n.language}
        >
          <p
            className={classNames({
              "ar-text-align": i18n.language === "ar",
            })}
          >
            {t(
              "incident-form submission-confirmation-thankyou-title-description"
            )}
          </p>
        </div>
        <div className="content-sublightbox__options submodal-incident__form">
          <Button
            class={classNames({
              "custom btn-close-thankyou": true,
              "ar-letter-spacing": i18n.language === "ar",
            })}
            text={t("incident-form submission-confirmation-thankyou-btn-close")}
            onClick={closeModal}
          />
          <Button
            class={classNames({
              "custom btn-start-message-unhcr": true,
              "ar-letter-spacing": i18n.language === "ar",
            })}
            text={t(
              "incident-form submission-confirmation-thankyou-btn-start-message"
            )}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default DialogThankyouFormSubmitted;

