import React, { useContext, useEffect, useState } from "react";
import { incidentItemId } from "adapters/incidentForm";
import closeIconSubModal from "assets/images/close-btn-white.svg";
import classNames from "classnames";
import AsideNavbar from "components/AsideNavbar";
import HowToUseTheTool from "components/HowToUseTheTool";
import MessagingTab from "components/MessagingTab";
import Modal from "components/Modal";
import ModalChat from "components/ModalChat";
import ResourcesTab from "components/ResourcesTab";
import CookiesBanner from "components/cookiesBanner";
import CloseDialog from "components/dialogs/CloseDialog";
import DialogCreateNewIncident from "components/dialogs/DialogCreateNewIncident";
import DialogDeleteAccount from "components/dialogs/DialogDeleteAccount";
import DialogDeleteIncident from "components/dialogs/DialogDeleteIncident";
import DialogDownloadIncident from "components/dialogs/DialogDownloadIncident";
import DialogEditIncident from "components/dialogs/DialogEditIncident";
import LogoutSession from "components/dialogs/DialogLogoutSession";
import DialogNotificationSettings from "components/dialogs/DialogNotificationSettings";
import DialogNotifications from "components/dialogs/DialogNotifications";
import DialogOverviewCreateIncident from "components/dialogs/DialogOverviewCreateIncident";
import DialogOverviewEditIncident from "components/dialogs/DialogOverviewEditIncident";
import DialogShareIncident from "components/dialogs/DialogShareIncident";
import DialogStartMessage from "components/dialogs/DialogStartMessage";
import DialogThankyouShareForm from "components/dialogs/DialogThankyouShareForm";
import LogoutOnClick from "components/dialogs/LogoutOnClick";
import ModalChatContent from "components/message-system/ModalChatContent";
import UserSettings from "components/user/UserSettings";
import ModalContext from "context/ModalContext";
import UserSettingsContext from "context/UserSettingContext";
import {
  contactFormJSON,
  getUserMenuItems,
  incidentView,
  passwordFormJSON,
  twoFactorAuthErrors,
} from "helpers/constants";
import { getCookieData, showPhoneNumber } from "helpers/helpers";
import { validationsFormChangeEmailFirstStep } from "../../helpers/validations";
import InactivityTimer from "helpers/inactivityTimer";
import { validationsFormPassword } from "helpers/validations";
import useLogout from "hooks/useLogout";
import useRefreshToken from "hooks/useRefreshToken";
import { i18nGetData } from "i18n";
import { cipher, decipher, hash } from "lib/utils/encryption";
import IncidentForms from "pages/platform/incidents";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import {
  deleteChatHistory,
  getChatRoomById,
  getChatRoomMessages,
  getChatRooms,
  updateChatStatus,
} from "services/chats";
import { getIncidents } from "services/incidents";
import { getNotifications } from "services/notifications";
import {
  getUser,
  updatePassword,
  updateUserInfo,
  updateSurvivorInfo,
  resendTwoFactorAuthCode,
  updateTwoFactorAuthSetting,
} from "services/users";
import { ReactComponent as MenuIcon } from "../../assets/images/menu-icon-white.svg";
import logoBlue from "../../assets/images/pseash-logo-blue.svg";
import DialogInactivitySession from "components/dialogs/DialogInactivitySession";
import DeleteAccountSuccessful from "components/dialogs/DialogDeleteAccountSuccessful";
import ChangeEmailFirstStep from "components/dialogs/changeEmailDialogs/ChangeEmailFirstStep";
import ChangeEmailThirdStep from "components/dialogs/changeEmailDialogs/ChangeEmailThirdStep";
import ChangeEmailFourthStep from "components/dialogs/changeEmailDialogs/ChangeEmailFourthStep";
import ChangeEmailSecondStep from "components/dialogs/changeEmailDialogs/ChangeEmailSecondStep";
import CodeVerification from "components/TwoFactorAuthentication/CodeVerification";
import HeaderForm from "components/HeaderForm";

const UserDashboard = ({
  socket,
  html2pdf,
  setFormStep,
  modalChat,
  setModalChat,
}) => {
  const location = useLocation();
  const { t, i18n } = useTranslation("global");
  //chat Temp
  const [currentChatRoom, setCurrentChatRoom] = useState({});

  //Global context
  const [modal, setModal] = useContext(ModalContext);
  const [subModal, setSubModal] = useState(false);
  const [, setUserSettings] = useContext(UserSettingsContext);

  //Component state
  const [modalContent, setModalContent] = useState("");
  const [subModalContent, setSubModalContent] = useState("");
  const [modalSubmitContent, setModalSubmitContent] = useState("");
  const [modalSubmit, setModalSubmit] = useState(false);
  const [formToDelete, setFormToDelete] = useState();
  const [userData, setUserData] = useState({});
  const [userType, setUserType] = useState("");

  const [contactForm, setContactForm] = useState(contactFormJSON);
  const [passwordForm, setPasswordForm] = useState(passwordFormJSON);
  const [toggleSettings, setToggleSettings] = useState(true);
  const [toggleUserInfo, setToggleUserInfo] = useState(true);
  const [togglePassword, setTogglePassword] = useState(true);
  const [errors, setErrors] = useState({});
  const [notificationList, setNotificationList] = useState([]);
  const [chatroomList, setChatroomList] = useState([]);
  const [messagingView, setMessagingView] = useState("loading");
  const [statusNavigationButtons, setStatusNavigationButtons] = useState(true);
  const [open, setOpen] = useState(false);

  // Messaging states
  const [messages, setMessages] = useState([]);
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);

  // news
  const [selectedIncident, setSelectedIncident] = useState({});
  const [selectedIncidentView, setSelectedIncidentView] = useState("");
  const [incidents, setIncidents] = useState([]);
  const [pageIncident, setPageIncident] = useState(1);
  const [expiredModal, setExpiredModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [showInactivityModal, setShowInactivityModal] = useState(false);
  const [deleteSuccessfulModal, setDeleteSuccessfulModal] = useState(false);
  const [timeOutModal, setTimeOutModal] = useState(false);

  // Change survivor email
  const [changeEmailModal, setChangeEmailModal] = useState(false);
  const [changeEmailStep, setChangeEmailStep] = useState(1);
  const [changeEmailData, setChangeEmailData] = useState({});
  const [verificationCodeAttempts, setVerificationCodeAttempts] = useState(0);

  const logout = useLogout();
  const onRefreshToken = useRefreshToken(setExpiredModal);

  const getToken = () => {
    return getCookieData("auth", { path: "/" }) !== ""
      ? getCookieData("auth", { path: "/" }).accessToken
      : "";
  };

  let currentTab;

  const fetchData = async () => {
    setLoading(true);
    await Promise.resolve(getUser({ token: getToken() }))
      .then((res) => {
        if (res) {
          const user = res?.user || {};
          setUserSettings(user?.setting);
          setUserType(user?.role);
          setUserData(user);
          setTwoFactorAuth({
            option: user?.setting.two_factor_authentication,
            phoneNumber: user?.setting.phone,
          });

          setContactForm({
            ...contactForm,
            first_name: decipher(user?.p_content_key, user?.first_name),
            last_name: decipher(user?.p_content_key, user?.last_name),
            id: user?.id,
            key: user?.p_content_key,
            email: user?.email,
          });
          setPasswordForm({
            ...passwordForm,
            hashed_email: user?.hashed_email,
          });
          updateNotifications();
          getIncidentsList();
          updateMessages();
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else if (err.response.status === 403) {
          setIsMessagesLoading(false);
          setMessagingView("view-welcome");
          setSelectedIncidentView(incidentView.EMPTY);
          setExpiredModal(true);
        }

        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = new InactivityTimer({
      onRefreshToken,
      logout,
      setShowInactivityModal,
      closeAllModals
    });

    return () => {
      timer.cleanUp();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFormStep(pageIncident);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIncident]);

  const joinRoom = (
    subject,
    anonymous,
    incidents,
    p_content_key,
    chatRoomInfo,
    isNewChat = false
  ) => {
    const roomData = {
      subject: subject,
      anonymous: anonymous,
      incidents: incidents,
      userId: userData?.id,
      p_content_key: p_content_key,
      chatRoomId: chatRoomInfo.id,
    };

    socket.emit("join_room", roomData);
    updateMessages();
    setCurrentChatRoom(chatRoomInfo);
  };

  /* SendMessage() was removed in the JXUI-392 PR Commit */

  const handleDeleteAccount = () => {
    setSubModalContent("delete-account");
    setSubModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordForm({
      ...passwordForm,
      [name]: value,
    });
  };

  const handleChangeUserSettings = (e) => {
    const { name, value } = e.target;
    setContactForm({
      ...contactForm,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validationsFormPassword(passwordForm));
    if (Object.keys(validationsFormPassword(passwordForm)).length === 0) {
      const password = {
        new_password: hash(passwordForm.new_password),
        hashed_email: passwordForm.hashed_email,
        old_password: hash(passwordForm.current_password),
      };

      const data = JSON.stringify(password);

      Promise.resolve(updatePassword({ token: getToken(), formData: data }))
        .then((res) => {
          if (res) {
            setPasswordForm({
              ...passwordForm,
              message: i18nGetData("setting form-password updated"),
            });
            setTogglePassword(true);
          }
        })
        .catch((error) => {
          if (error.response.data.error === "BAD_PASS") {
            setErrors({
              current_password: i18nGetData(
                "settings form validations password-no-match"
              ),
            });
          }
          console.log("err", error);
        })
        .finally(() => {
          //final action
        });
    }
  };

  const editAdminInfo = () => {
    const newUserInfo = {
      id: contactForm.id,
      first_name: contactForm.first_name
        ? cipher(contactForm.key, contactForm.first_name)
        : "",
      last_name: contactForm.last_name
        ? cipher(contactForm.key, contactForm.last_name)
        : "",
    };

    const data = JSON.stringify(newUserInfo);

    Promise.resolve(updateUserInfo({ token: getToken(), formData: data }))
      .then((res) => {
        if (res) {
          setContactForm({
            ...contactForm,
            message: i18nGetData("setting form-information updated"),
          });
          setToggleUserInfo(true);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
      .catch((error) => {
        console.log("err", error);
      })
      .finally(() => {
        //final action
      });
  };

  const changeEmailVerify2fa = (code2fa) => {
    const newUserInfo = {
      ...changeEmailData,
      auth2faCode: code2fa,
    };
    setChangeEmailData(newUserInfo);
    const data = JSON.stringify(newUserInfo);

    Promise.resolve(updateSurvivorInfo({ token: getToken(), formData: data }))
      .then((res) => {
        if (res?.message === "NEW_USER_EMAIL_NOT_VERIFIED") {
          setChangeEmailStep(3);
        }
      })
      .catch(({ response }) => {
        if (response.data.error === "BAD_2FA") {
          setErrors({
            code2fa: "settings change-email-error 2fa",
          });
        }
      });
  };

  // const changeEmailVerify = (formData) => {
  // if (formData.email !== contactForm.email) {
  //   alert('emails dont match')
  // }

  const changeEmailVerify = (formData) => {
    const errors = validationsFormChangeEmailFirstStep(
      formData,
      contactForm.email
    );

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      const newUserInfo = {
        firstname: cipher(contactForm.key, contactForm.first_name),
        lastname: cipher(contactForm.key, contactForm.last_name),
        new_hashed_email: hash(formData.email),
        newEmail: formData.email,
        auth: {
          password: hash(formData.password),
          emailCode: formData.verificationCode,
          recoveryKey: formData.recoveryCode,
        },
      };
      setChangeEmailData(newUserInfo);
      const data = JSON.stringify(newUserInfo);

      Promise.resolve(updateSurvivorInfo({ token: getToken(), formData: data }))
        .then((res) => {
          if (res?.message === "NEW_USER_EMAIL_NOT_VERIFIED") {
            setChangeEmailStep(3);
          } else if (res?.message === "2FA_CODE_SENDED") {
            setChangeEmailStep(2);
          }
        })
        .catch(({ response }) => {
          if (response.data.error === "BAD_VERIFICATION_CODE") {
            setVerificationCodeAttempts(response.data.attempts);
            if (response.data.attempts === 3) {
              setErrors({
                verificationCode: i18nGetData(
                  "settings change-email-error no-more-attempts"
                ),
              });
            } else {
              setErrors({
                verificationCode: i18nGetData(
                  "settings change-email-modal verification-code bad-code",
                  { attempts: 3 - response.data.attempts }
                ),
              });
            }
          } else if (response.data.error === "BAD_PASSWORD") {
            setErrors({
              password: i18nGetData("settings change-email-error-password"),
            });
          } else if (response.data.error === "BAD_RECOVERY_KEY") {
            setErrors({
              recoveryCode: i18nGetData(
                "settings change-email-error-recovery-code"
              ),
            });
          }
        });
    }
  };

  const verifyNewEmail = (verificationCode) => {
    const newUserInfo = {
      ...changeEmailData,
      newEmailCode: verificationCode,
    };
    setChangeEmailData(newUserInfo);

    const data = JSON.stringify(newUserInfo);

    Promise.resolve(updateSurvivorInfo({ token: getToken(), formData: data }))
      .then((res) => {
        if (res) {
          setChangeEmailStep(4);
          setToggleUserInfo(true);
        }
      })
      .catch(({ response }) => {
        if (response.data.error === "BAD_NEW_EMAIL") {
          setErrors({
            newEmailCode: i18nGetData(
              "landing form two-factor-authentication-error-1"
            ),
          });
        }
      });
  };

  const editSurvivorInfo = () => {
    const newUserInfo = {
      firstname: cipher(contactForm.key, contactForm.first_name),
      lastname: cipher(contactForm.key, contactForm.last_name),
      new_hashed_email: hash(contactForm.email),
    };

    const data = JSON.stringify(newUserInfo);

    Promise.resolve(updateSurvivorInfo({ token: getToken(), formData: data }))
      .then((res) => {
        if (res) {
          if (res?.message === "USER_EMAIL_NOT_VERIFIED") {
            setChangeEmailModal(true);
            setChangeEmailStep(1);
            setErrors({});
          } else if (res?.message === "2FA_CODE_SENDED") {
            setChangeEmailStep(2);
          } else {
            setContactForm({
              ...contactForm,
              message: i18nGetData("setting form-information updated"),
            });
            setToggleUserInfo(true);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmitContactForm = (e) => {
    e.preventDefault();
    if (userData?.role === "admin") {
      editAdminInfo();
    } else {
      editSurvivorInfo();
    }
  };

  const updateNotifications = () => {
    Promise.resolve(getNotifications({ token: getToken() }))
      .then((res) => {
        setNotificationList(res?.notifications);
      })
      .catch((error) => {
        console.log("err", error);
      })
      .finally(() => {
        //final action
      });
  };

  const updateMessages = () => {
    Promise.resolve(getChatRooms({ token: getToken() }))
      .then((res) => {
        if (res?.chatRooms.length > 0) {
          setChatroomList(res.chatRooms);
          setMessagingView("view-chat");
        } else {
          setMessagingView("view-welcome");
        }
      })
      .catch((error) => {
        console.log("err", error);
      })
      .finally(() => {
        //final action
      });
  };

  const getChatRoomData = (id) => {
    setCurrentChatRoom({});
    setMessages([]);
    setIsMessagesLoading(true);
    Promise.resolve(getChatRoomById({ token: getToken(), id: id }))
      .then((res) => {
        joinRoom(
          res?.chatRoom.subject,
          res?.chatRoom.anonymous,
          res?.chatRoom.incidents,
          res?.chatRoom.p_content_key,
          res?.chatRoom
        );
        setCurrentChatRoom(res?.chatRoom);

        Promise.resolve(
          getChatRoomMessages({ token: getToken(), id: id })
        ).then((res2) => {
          setMessages(
            res2?.messages.map((message) => ({
              ...message,
              message: decipher(res?.chatRoom.p_content_key, message.content),
            }))
          );
          setIsMessagesLoading(false);
        });
      })
      .catch((error) => {
        console.log("err", error);
      });
  };

  const markAsUnread = (id, action, role = userType) => {
    Promise.resolve(updateChatStatus({ token: getToken(), id, role, action }))
      .then(() => {
        if (action === "read" || action === "unread" || action === "close") {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log("err", error);
      })
      .finally(() => {
        //final action
      });
  };

  const deleteHistory = (id) => {
    Promise.resolve(deleteChatHistory({ token: getToken(), id: id }))
      .then(() => {
        socket.emit("refresh_for_change", { id });
      })
      .catch((error) => {
        console.log("err", error);
      })
      .finally(() => {
        updateMessages();
        setModalChat(false);
      });
  };

  const getIncidentsList = (action = "") => {
    if (action === "") setSelectedIncidentView("");
    Promise.resolve(getIncidents({ token: getToken() }))
      .then((res) => {
        setIncidents(res.incidents);
      })
      .catch((error) => {
        console.log("err", error);
      })
      .finally(() => {
        if (action === "") setSelectedIncidentView(incidentView.INCIDENT_LIST);
      });
  };

  const handleOpen = () => {
    setOpen((value) => !value);
  };

  const handleLogout = () => {
    logout();
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleCloseInactivityModal = () => {
    setShowInactivityModal(false);
  };

  /*
   * BEGIN: 2FA Verification Modal
   */

  const [twoFactorAuth, setTwoFactorAuth] = useState({});
  const [twoFactorAuthOpen, setTwoFactorAuthOpen] = useState(false);
  const [twoFactorAuthDescription, setTwoFactorAuthDescription] = useState("");
  const [twoFactorAuthCodeError, setTwoFactorAuthCodeError] = useState("");
  const [twoFactorAuthloading, setTwoFactorAuthLoading] = useState(false);
  const [twoFactorAuthDisableResend, setTwoFactorAuthDisableResend] =
    useState(false);

  const handleTwoFactorAuthErrors = (error) => {
    setTwoFactorAuthCodeError(
      error.response.data.error === twoFactorAuthErrors.BAD_CODE
        ? t("landing form two-factor-authentication-error-1")
        : error.response.data.error === twoFactorAuthErrors.PHONE_ERROR
        ? error.response.data.message
        : t("generic-error-message")
    );
  };

  const sendTwoFactorAuthCode = () => {
    setTwoFactorAuthCodeError("");
    setTwoFactorAuthLoading(true);

    Promise.resolve(
      resendTwoFactorAuthCode(getToken(), {
        two_factor_authentication: twoFactorAuth?.option,
        phone: twoFactorAuth?.phoneNumber,
      })
    )
      .then((res) => {
        if (res) {
          setTwoFactorAuthLoading(false);
          setTimeout(() => {
            setTwoFactorAuthDisableResend(false);
          }, 60000);
        }
      })
      .catch((error) => {
        handleTwoFactorAuthErrors(error);
        setTwoFactorAuthLoading(false);
      });
  };

  const onTwoFactorAuthOpen = () => {
    setTwoFactorAuthOpen(true);

    const descriptionSecondStep = `<p>${
      !!userData?.setting?.phone || twoFactorAuth?.phoneNumber
        ? `${t(
            "landing two-factor-authentication section-description-verification", {
              phone: showPhoneNumber(
                twoFactorAuth?.phoneNumber || userData?.setting?.phone
              ),
            }
          )}`
        : t(
            "landing two-factor-authentication section-description-verification-2"
          )
    }</p>`;

    setTwoFactorAuthDescription(descriptionSecondStep);
    sendTwoFactorAuthCode();
  };

  const onTwoFactorAuthClose = () => {
    setTwoFactorAuthOpen(false);
    setTwoFactorAuthCodeError("");
  };

  const onTwoFactorAuthSubmit = (code) => {
    setTwoFactorAuthCodeError("");
    setLoading(true);

    Promise.resolve(
      updateTwoFactorAuthSetting(getToken(), {
        code,
        two_factor_authentication: twoFactorAuth.option,
        phone: twoFactorAuth.phoneNumber,
      })
    )
      .then((res) => {
        if (res) {
          setLoading(false);
          logout();
        }
      })
      .catch((error) => {
        handleTwoFactorAuthErrors(error);
        setLoading(false);
      });
  };

  const handleTwoFactorAuthResendCode = () => {
    if (!twoFactorAuthDisableResend) {
      setTwoFactorAuthDisableResend(true);
      sendTwoFactorAuthCode();
    }
  };

  /*
   * END: 2FA Verification Modal
   */

  const displayTab = (id) => {
    switch (id) {
      case 1:
        return <HowToUseTheTool />;
      case 2:
        return (
          <div
            className={classNames({
              "portal-container": true,
              ["tab-" + currentTab.id]: true,
            })}
          >
            <IncidentForms
              user={userData}
              incidents={incidents}
              setIncidents={setIncidents}
              setSelectedIncidentView={setSelectedIncidentView}
              selectedIncidentView={selectedIncidentView}
              setSubModalContent={setSubModalContent}
              setSelectedIncident={setSelectedIncident}
              selectedIncident={selectedIncident}
              setSubModal={setSubModal}
              setStatusNavigationButtons={setStatusNavigationButtons}
              statusNavigationButtons={statusNavigationButtons}
              setPageIncident={setPageIncident}
              pageIncident={pageIncident}
              setModalContent={setModalContent}
              setModal={setModal}
              setFormToDelete={setFormToDelete}
              getIncidents={getIncidentsList}
              modalSubmitContent={modalSubmitContent}
              setModalSubmitContent={setModalSubmitContent}
              modalSubmit={modalSubmit}
              setModalSubmit={setModalSubmit}
              setTimeOutModal={setTimeOutModal}
              timeOutModal={timeOutModal}
            />
          </div>
        );
      case 3:
        return <ResourcesTab />;
      case 4:
        return (
          <MessagingTab
            setModal={setModal}
            setModalContent={setModalContent}
            setSubModal={setSubModal}
            setSubModalContent={setSubModalContent}
            modalChat={modalChat}
            setModalChat={setModalChat}
            view={messagingView}
            chatRooms={chatroomList}
            getChatRoomData={getChatRoomData}
            currentChatRoom={currentChatRoom}
            userData={userData}
            markAsUnread={markAsUnread}
            deleteHistory={deleteHistory}
            userType=""
            socket={socket}
            setSelectedIncident={setSelectedIncident}
            getIncidents={getIncidentsList}
          />
        );
      case 5:
        return (
          <UserSettings
            contactForm={contactForm}
            toggleUserInfo={toggleUserInfo}
            handleDeleteAccount={handleDeleteAccount}
            handleChangeUserSettings={handleChangeUserSettings}
            setToggleUserInfo={setToggleUserInfo}
            handleSubmitContactForm={handleSubmitContactForm}
            passwordForm={passwordForm}
            togglePassword={togglePassword}
            onOpen={onTwoFactorAuthOpen}
            handleChange={handleChange}
            errors={errors}
            setTogglePassword={setTogglePassword}
            handleSubmit={handleSubmit}
            role={userData?.role}
            phoneNumber={userData?.setting?.phone || ""}
            form={twoFactorAuth}
            setForm={setTwoFactorAuth}
            toggleSettings={toggleSettings}
            setToggleSettings={setToggleSettings}
            isSetting
            isLoading={loading}
            tokenTemp={getToken()}
            logout={logout}
          />
        );
      default:
        break;
    }
  };

  const getModalContent = () => {
    switch (modalContent) {
      case "notifications":
        return (
          <DialogNotifications
            setModal={setModalChat}
            modal={modalChat}
            setModalContent={setModalContent}
            notificationList={notificationList}
            updateNotifications={updateNotifications}
          />
        );
      case "notifications-settings":
        return (
          <DialogNotificationSettings
            setModal={setModal}
            setModalContent={setModalContent}
            notificationList={notificationList}
            updateNotifications={updateNotifications}
            role={userData?.role}
          />
        );
      case "create-new-incident":
        return (
          <DialogCreateNewIncident
            setModal={setModal}
            setModalContent={setModalContent}
            setSelectedIncidentView={setSelectedIncidentView}
            selectedIncidentView={selectedIncidentView}
          />
        );
      case "edit-incident":
        return (
          <DialogEditIncident
            setModal={setModal}
            setModalContent={setModalContent}
          />
        );
      case "overview-modal-create-incident":
        return (
          <DialogOverviewCreateIncident
            setModal={setModal}
            setModalContent={setModalContent}
            selectedIncidentView={selectedIncidentView}
            setSelectedIncidentView={setSelectedIncidentView}
            setPageIncident={setPageIncident}
          />
        );
      case "overview-modal-edit-incident":
        return (
          <DialogOverviewEditIncident
            setModal={setModal}
            formEdit={selectedIncident}
            setModalContent={setModalContent}
            setPageIncident={setPageIncident}
            setSelectedIncidentView={setSelectedIncidentView}
          />
        );
      default:
        break;
    }
  };

  const getSubModalContent = () => {
    switch (subModalContent) {
      case "delete-account":
        return (
          <DialogDeleteAccount
            setSubModal={setSubModal}
            setSubModalContent={setSubModalContent}
            setDeleteSuccessfulModal={setDeleteSuccessfulModal}
          />
        );
      case "delete-incident":
        return (
          <DialogDeleteIncident setSubModal={setSubModal} form={formToDelete} />
        );
      case "start-message":
        return (
          <DialogStartMessage
            setSubModal={setSubModal}
            setModalChat={setModalChat}
            tokenTemp={getToken()}
            incidentIds={
              incidents && incidents.length > 0
                ? incidents
                    .filter((item) => item.status === "submitted")
                    .map((item) => ({
                      value: item.id,
                      label: incidentItemId(item.id),
                    }))
                : []
            }
            setMessagingView={setMessagingView}
            joinRoom={joinRoom}
            userData={userData}
            getChatRoomData={getChatRoomData}
          />
        );
      case "share-incident":
        return (
          <DialogShareIncident
            setSubModal={setSubModal}
            setModalChat={setModalChat}
            tokenTemp={getToken()}
            selectedFormId={selectedIncident}
            setSubModalContent={setSubModalContent}
            userData={userData}
            getIncidents={getIncidentsList}
          />
        );
      case "thankyou-form-shared":
        return (
          <DialogThankyouShareForm
            setSubModal={setSubModal}
            setSubModalContent={setSubModalContent}
          />
        );
      case "download-incident":
        return (
          <DialogDownloadIncident
            setSubModal={setSubModal}
            form={selectedIncident}
            html2pdf={html2pdf}
          />
        );
      default:
        break;
    }
  };

  const userMenuItems = getUserMenuItems();
  if (location.hash) {
    currentTab = userMenuItems.find((item, i) => item.url === location.hash);
    currentTab = currentTab ? currentTab : userMenuItems[0];
  } else {
    currentTab = userMenuItems[1];
  }

  React.useEffect(() => {
    socket.on("check_current_token", (accessToken) => {
      const sessionToken = getToken();

      if (accessToken && !expiredModal && sessionToken !== accessToken) {
        window.location.reload();
      }
    });

    return () => socket.off("check_current_token");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiredModal, socket]);

  React.useEffect(() => {
    userData?.id && socket.emit("verify_session", { id: userData.id });
  }, [socket, userData.id]);

  const closeAllModals = () =>{
    setTwoFactorAuthOpen(false);
    setDeleteSuccessfulModal(false);
    setChangeEmailModal(false);
    setShowPopup(false);
    setModal(false);
    setModalContent("");
    setSubModal(false);
    setSubModalContent("")
  }

  return (
    <div
      className={classNames({
        "app-main__content": true,
        "app-main__content--resize": open,
        "app-main__content--chat-open": modalChat,
      })}
      id="app-main"
    >
      {twoFactorAuthOpen && (
        <Modal>
          <div className="lightbox-component verify-2fa">
            <CloseDialog
              closeVerifyModal
              isFloatButton
              onClose={onTwoFactorAuthClose}
            />
            <div className="container-form__two-factor-auth">
              <HeaderForm
                title={t("landing two-factor-authentication section-title")}
                description={twoFactorAuthDescription}
                lang={i18n.language}
              />
              <CodeVerification
                onSubmit={onTwoFactorAuthSubmit}
                handleResendCode={handleTwoFactorAuthResendCode}
                isSetting
                disableResend={twoFactorAuthDisableResend}
                onCancel={onTwoFactorAuthClose}
                codeError={twoFactorAuthCodeError}
                loading={twoFactorAuthloading}
                inviteAdminEmail={""}
              />
            </div>
          </div>
        </Modal>
      )}
      {showInactivityModal && (
        <Modal>
          <div
            className={classNames({
              "lightbox-component": true,
              "inactivity-modal": true,
            })}
            lang={i18n.language}
            id="inactivity-modal"
          >
            <CloseDialog
              closeVerifyModal
              isFloatButton
              onClose={handleCloseInactivityModal}
            />
            <DialogInactivitySession
              setShowInactivityModal={setShowInactivityModal}
            />
          </div>
        </Modal>
      )}
      {expiredModal && (
        <Modal>
          <div
            className={classNames({
              "lightbox-component": true,
              "expired-session": true,
            })}
            lang={i18n.language}
          >
            <div
              className={classNames({
                "lightbox-container": true,
                "expired-session": true,
              })}
            >
              <LogoutSession logout={logout} />
            </div>
          </div>
        </Modal>
      )}
      {deleteSuccessfulModal && (
        <Modal>
          <div
            className={classNames({
              "lightbox-component": true,
              "delete-account": true,
            })}
            lang={i18n.language}
          >
            <DeleteAccountSuccessful />
          </div>
        </Modal>
      )}
      {changeEmailModal && (
        <Modal key="changeEmailModal">
          <div
            className={classNames({
              "lightbox-component": true,
              "delete-account": true,
            })}
            lang={i18n.language}
          >
            <div className="close-btn-change-email">
              <button
                className="custom btn-close"
                onClick={(e) => {
                  setChangeEmailModal(false);
                }}
              >
                <img src={closeIconSubModal} alt="Close button" />
              </button>
            </div>
            {changeEmailStep === 1 && (
              <ChangeEmailFirstStep
                attempts={verificationCodeAttempts}
                token={getToken()}
                key="changeEmailModal_1"
                onCancel={() => setChangeEmailModal(false)}
                onConfirm={changeEmailVerify}
                oldEmail={userData.email}
                userData={userData}
                errors={errors}
                setErrors={setErrors}
              />
            )}
            {changeEmailStep === 2 && (
              <ChangeEmailSecondStep
                token={getToken()}
                onCancel={() => setChangeEmailModal(false)}
                onConfirm={changeEmailVerify2fa}
                userData={userData}
                errors={errors}
              />
            )}
            {changeEmailStep === 3 && (
              <ChangeEmailThirdStep
                token={getToken()}
                errors={errors}
                onCancel={() => setChangeEmailModal(false)}
                onConfirm={verifyNewEmail}
                newEmail={changeEmailData.newEmail}
                newHashedEmail={changeEmailData.new_hashed_email}
              />
            )}
            {changeEmailStep === 4 && (
              <ChangeEmailFourthStep
                onClose={() => setChangeEmailModal(false)}
                email={changeEmailData.newEmail}
              />
            )}
          </div>
        </Modal>
      )}
      {showPopup && (
        <Modal>
          <div
            className={classNames({
              "lightbox-component": true,
              "expired-session": true,
            })}
            lang={i18n.language}
          >
            <div
              className={classNames({
                "logout-confirm": true,
                "lightbox-container": true,
                "expired-session": true,
              })}
            >
              <LogoutOnClick
                logout={handleLogout}
                closePopup={handleClosePopup}
              />
            </div>
          </div>
        </Modal>
      )}
      {modal && (
        <Modal>
          <div
            className={classNames({
              "lightbox-component": true,
              [`${modalContent}`]: true,
            })}
          >
            <CloseDialog
              setModal={setModal}
              setModalContent={setModalContent}
              view={modalContent}
              getIncidentsList={getIncidentsList}
            />
            <div
              className={classNames({
                "lightbox-container": true,
                [`${modalContent}`]: true,
              })}
            >
              {getModalContent()}
            </div>
          </div>
        </Modal>
      )}
      {modalChat && (
        <ModalChat>
          <ModalChatContent
            setModalChat={setModalChat}
            setModalContent={setModalContent}
            socket={socket}
            userData={userData}
            currentChatRoom={currentChatRoom}
            messages={messages}
            isMessagesLoading={isMessagesLoading}
            setCurrentChatRoom={setCurrentChatRoom}
            markAsUnread={markAsUnread}
            setSelectedIncident={setSelectedIncident}
            setSelectedIncidentView={setSelectedIncidentView}
            setChatroomList={setChatroomList}
            chatroomList={chatroomList}
          />
        </ModalChat>
      )}
      <AsideNavbar
        handleToggle={displayTab}
        toggle={currentTab.id}
        menuItems={userMenuItems}
        userData={userData}
        setModal={setModal}
        setModalContent={(type) => {
          updateNotifications();
          setModalContent(type);
        }}
        subModal={subModal}
        setModalChat={setModalChat}
        setSubModal={setSubModal}
        setCurrentChatRoom={setCurrentChatRoom}
        setSubModalContent={setSubModalContent}
        notificationList={notificationList}
        openMenu={open}
        setOpen={setOpen}
        getIncidentsList={getIncidentsList}
        setShowPopup={setShowPopup}
      />

      <div
        className={classNames({
          "survivor-interface": true,
          "chat-modal-open": modalChat,
        })}
      >
        <div className="survivor-interface__app-nav">
          <div className="container-nav" lang={i18n.language}>
            <div className="logo-container">
              <Link to="/home" target="_blank">
                <img className="logo" src={logoBlue} alt="Logo" />
              </Link>
            </div>
            <div className="nav-title">{currentTab.title}</div>
          </div>
          <div className="survivor-interface-menu-button" onClick={handleOpen}>
            <MenuIcon />
          </div>
        </div>

        <div
          className={classNames({
            "survivor-interface__main-content": true,
            "vh-dashboard":
              !incidents.length &&
              selectedIncidentView === "incident-list" &&
              (location.hash === "#your-incident-forms" ||
                location.hash === ""),
            "start-message-open": subModal && !formToDelete,
            "sub-modal-open": subModal,
            "sub-modal-open--settings":
              subModal && subModalContent === "delete-account",
            "confirm-modal-height": modalSubmit,
          })}
        >
          {subModal && (
            <div
              className={classNames({
                "sub-lightbox-component": true,
                [`${subModalContent}`]: true,
              })}
            >
              <div className="close-sub-lightbox">
                <button
                  className="small custom btn-close"
                  onClick={(e) => {
                    setSubModal(false);
                  }}
                >
                  <img src={closeIconSubModal} alt="Close button" />
                </button>
              </div>
              <div
                className={classNames({
                  "container-sublightbox": true,
                  [`${subModalContent}`]: true,
                })}
              >
                <div className="content-sublightbox">
                  {getSubModalContent()}
                </div>
              </div>
            </div>
          )}
          {displayTab(currentTab.id)}
        </div>
      </div>
      <CookiesBanner />
    </div>
  );
};

export default UserDashboard;
