import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import jdoeLogo from "../../../../assets/images/jdoe-icon.svg";
import unhcrLogo from "../../../../assets/images/unhcr-icon.svg";
import { addPrefixLanding } from "../../../../helpers/helpers";

function LandingFooterBar() {
  const { t, i18n } = useTranslation("global");
  return (
    <div className={addPrefixLanding("footer-navigationbar")}>
      <div className={addPrefixLanding("footer-nav-container")}>
        <div className={addPrefixLanding("footer-icons-container")}>
          <div>
            <img src={unhcrLogo} alt="UNHCR" />
          </div>
          <a href="https://jdoe.io/">
            <img src={jdoeLogo} alt="JDOE" />
          </a>
        </div>
        <div className={addPrefixLanding("footer-text-content")}></div>
        <div
          className={addPrefixLanding("footer-text-content")}
          lang={i18n.language}
        >
          <Link to="/terms-of-use" target="_blank">
            {t("landing footer terms")}
          </Link>
          <Link to="/privacy-statement" target="_blank">
            {t("landing footer policy")}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LandingFooterBar;
