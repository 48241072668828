import { getOptionsPlace } from "helpers/constants";
import { i18nGetData } from "../i18n";

export const getPerpetrators = (list) => {
  return list === undefined
    ? []
    : typeof list === "object" && list !== null
    ? list.map((item, index) => {
        return {
          content: [
            {
              question: i18nGetData(
                "incident-form perpetrator question-know-perpetrator"
              ),
              answer:
                item.knew_perpetrator !== null
                  ? parseInt(item.knew_perpetrator) === 0
                    ? i18nGetData(
                        "incident-form message start-message-anonymous-no"
                      )
                    : i18nGetData(
                        "incident-form message start-message-anonymous-yes"
                      )
                  : "",
            },
            {
              question: i18nGetData(
                "incident-form other-info perpetrator-first-name"
              ),
              answer: item.first_name,
            },
            {
              question: i18nGetData(
                "incident-form other-info perpetrator-last-name"
              ),
              answer: item.last_name,
            },
            {
              question: i18nGetData("incident-form perpetrator question-email"),
              answer: item?.email || item?.perpetrator_email,
            },
            {
              question: i18nGetData(
                "incident-form perpetrator question-how-do-you-know"
              ),
              answer:
                item.relation_with_perpetrator === "incident-form perpetrator question-how-do-you-know-answer other" ||
                item.relation_with_perpetrator === i18nGetData(
                  "incident-form perpetrator question-how-do-you-know-answer other"
                )
                  ? item.custom_relation_with_perpetrator
                  : i18nGetData(item.relation_with_perpetrator),
            },
          ],
        };
      })
    : [];
};

export const getNotes = (data) => {
  const list =
    typeof data === "string"
      ? JSON.parse(data)
      : typeof data === "object" && data !== null
      ? data
      : [];
  return typeof list === "string"
    ? []
    : list.map((item, index) => {
        return {
          content: [
            {
              question: i18nGetData("incident-form review name"),
              answer: item.name,
            },
            {
              question: i18nGetData("incident-form review notes"),
              answer: item.note,
            },
          ],
        };
      });
};

export const getReviewData = (form) => {
  const optionsPlaces = getOptionsPlace();
  const place = optionsPlaces.find((i) => i.value === form.place);

  const dateOfIncident =
    form.date !== "" && form.date !== null ? form.date : "";
  const timeOfIncident =
    form.time_of_incident !== "" && form.time_of_incident !== null
      ? ", " + form.time_of_incident
      : "";

  const placeOfIncident =
    form?.custom_place && form.custom_place !== ""
      ? form.custom_place
      : place && place?.name
      ? place.name
      : "";

  const formattedReview = [
    {
      title: i18nGetData("incident-form review incident-info-title"),
      content: [
        {
          question: i18nGetData(
            "incident-form review incident-info-date-of-incident"
          ),
          answer: dateOfIncident !== "" ? dateOfIncident : "",
          answer2: timeOfIncident,
          type: "date",
        },
        {
          question: i18nGetData(
            "incident-form review incident-info-description"
          ),
          answer: form.what_happened,
        },
        {
          question: i18nGetData(
            "incident-form incident question-where-did-this-occur"
          ),
          answer: placeOfIncident,
        },
      ],
    },
    {
      title: i18nGetData("incident-form review perpetrator-info-title"),
      items: getPerpetrators(form.perpetrators),
      type: "list",
    },
    {
      title: i18nGetData("incident-form review documentation-title"),
      content: [
        {
          question: i18nGetData(
            "incident-form review documentation-files-related"
          ),
          answer:
            form.upload_file_option !== null
              ? form.upload_file_option
                ? i18nGetData(
                    "incident-form message start-message-anonymous-yes"
                  )
                : i18nGetData(
                    "incident-form message start-message-anonymous-no"
                  )
              : "",
          docs: form.files?.length && form.files,
        },
      ],
      type: "docs",
    },
    {
      title: i18nGetData("incident-form review impact-title"),
      content: [
        {
          question: i18nGetData("incident-form impact question-feel"),
          answer: form.feelings,
        },
        {
          question: i18nGetData("incident-form impact question-support"),
          answer:
            form.need_support !== null
              ? form.need_support
                ? i18nGetData(
                    "incident-form message start-message-anonymous-yes"
                  )
                : i18nGetData(
                    "incident-form message start-message-anonymous-no"
                  )
              : "",
        },
        {
          question: i18nGetData(
            "incident-form impact question-kind-of-support"
          ),
          answer: form.support_type,
        },
      ],
    },
  ];

  return formattedReview;
};
