import React, { Fragment } from "react";
import classNames from "classnames";
import Button from "components/Button";
import { useTranslation } from "react-i18next";

function Perpetrator({
  id,
  errors,
  perpetrator,
  perpetratorsList,
  handleChangePerpetrator,
  handleRemovePerpetrator,
  setCountPerpetrator,
  countPerpetrator,
  handleAddPerpetrator,
}) {
  const { t, i18n } = useTranslation("global");

  const optionsRelationWithPerpetrator = [
    { value: "incident-form perpetrator question-how-do-you-know-answer-1" },
    { value: "incident-form perpetrator question-how-do-you-know-answer-2" },
    { value: "incident-form perpetrator question-how-do-you-know-answer-4" },
    { value: "incident-form perpetrator question-how-do-you-know-answer-5" },
    { value: "incident-form perpetrator question-how-do-you-know-answer-6" },
    { value: "incident-form perpetrator question-how-do-you-know-answer other" },
  ];

  const attr = "perpetrator";

  return (
    <Fragment>
      <div className="perpetrator-separator">
        <p>
          {" "}
          {t("incident-form validations perpetrator-required")}#{id + 1}
        </p>
        <hr />
      </div>
      <section
        className={classNames({
          "perpetrators-container-form": true,
          "perpetrators-container-form-ar": i18n.language === "ar",
        })}
      >
        <div
          className={classNames({
            "small-column step-perpetrator": true,
            "ar-letter-spacing": i18n.language === "ar",
          })}
        >
          <div className="container-question">
            <div
              className={classNames({
                "question-title": true,
                "question-required": true,
              })}
              lang={i18n.language}
            >
              {t("incident-form perpetrator question-know-perpetrator")}
            </div>
            <div className="question-answers">
              <div
                className={classNames({
                  "container-radio-buttons-options": true,
                  "radio-button-container-ar": i18n.language === "ar",
                })}
              >
                <div className="radio-button-container">
                  <input
                    data-type={"number"}
                    type="radio"
                    id={"knew_perpetrator-" + id + "-1"}
                    name={"knew_perpetrator-" + id}
                    value={1}
                    checked={parseInt(perpetrator["knew_perpetrator"]) === 1}
                    onChange={(e) => {
                      handleChangePerpetrator(e, id);
                    }}
                  />
                  <label
                    htmlFor={"knew_perpetrator-" + id + "-1"}
                    lang={i18n.language}
                  >
                    {t(
                      "incident-form perpetrator question-know-perpetrator-answer-1"
                    )}
                  </label>
                </div>
                <div className="radio-button-container">
                  <input
                    data-type={"number"}
                    type="radio"
                    id={"knew_perpetrator-" + id + "-2"}
                    name={"knew_perpetrator-" + id}
                    value={0}
                    checked={parseInt(perpetrator["knew_perpetrator"]) === 0}
                    onChange={(e) => {
                      handleChangePerpetrator(e, id);
                    }}
                  />
                  <label
                    htmlFor={"knew_perpetrator-" + id + "-2"}
                    lang={i18n.language}
                  >
                    {t(
                      "incident-form perpetrator question-know-perpetrator-answer-2"
                    )}
                  </label>
                </div>
              </div>
              {errors["knew_perpetrator" + id] && (
                <span className="error-message" lang={i18n.language}>
                  {errors["knew_perpetrator" + id]}
                </span>
              )}
              <span className="note-message-form" lang={i18n.language}>
                {t(
                  "incident-form perpetrator question-know-perpetrator-message"
                )}
              </span>
            </div>
            <div className="perpetrator-list__options">
              {perpetratorsList.length > 1 && (
                <Button
                  class="btn-remove-perpetrator"
                  text={t("incident-form perpetrator btn-remove-perpetrator")}
                  onClick={(e) => {
                    e.preventDefault();
                    handleRemovePerpetrator(perpetrator, id);
                    setCountPerpetrator((current) => current - 1);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div
          className={classNames({
            "big-column step-perpetrator": true,
            "ar-letter-spacing": i18n.language === "ar",
          })}
        >
          <div className="container-question" lang={i18n.language}>
            <div
              className={classNames({
                "question-title": true,
                "question-required":
                  parseInt(perpetrator["knew_perpetrator"]) === 1
                    ? true
                    : false,
              })}
            >
              {t("incident-form perpetrator question-first-name")}
            </div>
            <div className="question-answers">
              <input
                data-type={attr}
                type="text"
                autoComplete="off"
                maxLength={100}
                placeholder={t(
                  "incident-form perpetrator question-first-name-placeholder"
                )}
                name={"first_name-" + id}
                value={perpetrator["first_name"]}
                onChange={(e) => {
                  handleChangePerpetrator(e, id);
                }}
              />
              <span className="note-message-form">
                {t("incident-form perpetrator question-first-name-message")}
              </span>
              {errors["first_name" + id] && (
                <span className="error-message">
                  {errors["first_name" + id]}
                </span>
              )}
            </div>
          </div>

          <div className="container-question" lang={i18n.language}>
            <div
              className={classNames({
                "question-title": true,
                "question-required":
                  parseInt(perpetrator["knew_perpetrator"]) === 1
                    ? true
                    : false,
              })}
            >
              {t("incident-form perpetrator question-last-name")}
            </div>
            <div className="question-answers">
              <input
                data-type={attr}
                type="text"
                autoComplete="off"
                maxLength={100}
                placeholder={t(
                  "incident-form perpetrator question-last-name-placeholder"
                )}
                name={"last_name-" + id}
                value={perpetrator["last_name"]}
                onChange={(e) => {
                  handleChangePerpetrator(e, id);
                }}
              />
              <span className="note-message-form">
                {t("incident-form perpetrator question-last-name-message")}
              </span>
              {errors["last_name" + id] && (
                <span className="error-message">
                  {errors["last_name" + id]}
                </span>
              )}
            </div>
          </div>

          <div className="container-question" lang={i18n.language}>
            <div
              className={classNames({
                "question-title": true,
                "question-required": false,
              })}
            >
              {t("incident-form perpetrator question-email")}
            </div>
            <div className="question-answers">
              <input
                data-type={attr}
                type="email"
                maxLength={100}
                placeholder={t(
                  "incident-form perpetrator question-email-placeholder"
                )}
                name={"perpetrator_email-" + id}
                value={perpetrator["perpetrator_email"]}
                onChange={(e) => {
                  handleChangePerpetrator(e, id);
                }}
              />
              <span
                className={classNames({
                  "note-message-form ": true,
                  "note-message-form-perpetrator": i18n.language !== "ar",
                  "note-message-form-perpetrator-ar": i18n.language === "ar",
                })}
              >
                {t("incident-form perpetrator question-email-message")}
              </span>
              {errors["perpetrator_email" + id] && (
                <span className="error-message">
                  {errors["perpetrator_email" + id]}
                </span>
              )}
            </div>
          </div>

          <div className="container-question" lang={i18n.language}>
            <div
              className={classNames({
                "question-title": true,
              })}
            >
              {t("incident-form perpetrator question-how-do-you-know")}
            </div>

            <div className="question-answers">
              <div
                className={classNames({
                  "container-radio-buttons-options": true,
                  "radio-button-container-ar": i18n.language === "ar",
                })}
              >
                {optionsRelationWithPerpetrator.map((option, index) => {
                  return (
                    <div
                    className={classNames({
                      "radio-button-container radio-button-container-perpetrator": true,
                      "radio-button-container-ar": i18n.language === "ar",
                    })}
                      key={index}
                    >
                      <input
                        data-type={"text"}
                        type="radio"
                        id={"relation_with_perpetrator-" + id + "-" + index}
                        name={"relation_with_perpetrator-" + id}
                        value={option.value}
                        checked={
                          perpetrator["relation_with_perpetrator"] ===
                          option.value
                        }
                        onChange={(e) => {
                          handleChangePerpetrator(e, id);
                        }}
                      />
                      <label
                        htmlFor={
                          "relation_with_perpetrator-" + id + "-" + index
                        }
                      >
                        {t(option.value)}
                      </label>
                    </div>
                  );
                })}
              </div>
              {perpetrator["relation_with_perpetrator"] === "incident-form perpetrator question-how-do-you-know-answer other" && (
                <div className="subcontainer-answer require_field">
                  <input
                    data-type={attr}
                    type="text"
                    maxLength={100}
                    autoComplete="off"
                    placeholder={t(
                      "incident-form perpetrator question-how-do-you-know-answer other placeholder"
                    )}
                    name={"custom_relation_with_perpetrator-" + id}
                    value={perpetrator["custom_relation_with_perpetrator"]}
                    onChange={(e) => {
                      handleChangePerpetrator(e, id);
                    }}
                    required
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {countPerpetrator < 5 && countPerpetrator === id + 1 && (
        <div className="add-perpetrator-container">
          <Button
            class="btn-add-item-perpetrator"
            text={
              countPerpetrator === 0
                ? t("incident-form perpetrator btn-add-perpetrator")
                : t("incident-form perpetrator btn-add-perpetrator")
            }
            onClick={handleAddPerpetrator}
          />
        </div>
      )}
    </Fragment>
  );
}

export default Perpetrator;

