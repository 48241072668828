import React, { useEffect, useState } from "react";
import classNames from "classnames";
import LandingModals from "pages/landing/modals";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useSignUp } from "../../hooks/useSignUp";
import Button from "../Button";
import { decipher } from "lib/utils/encryption";
import { ADMIN_INVITE_CODE } from "helpers/constants";
import { initialFormSignUp } from "helpers/constants";
import Modal from "components/Modal";
import CloseDialog from "components/dialogs/CloseDialog";

const SignUpForm = (props) => {
  const { t, i18n } = useTranslation("global");

  const [form, setForm] = useState(initialFormSignUp);
  const [modal, setModal] = useState(false);
  const [expiredModal, setExpiredModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const invite = params.get("e");

  const { errors, handleChange, handleSubmit } = useSignUp(
    isAdmin,
    form,
    setForm
  );

  useEffect(() => {
    const { fromLanding } = location?.state || {};
    if (fromLanding) {
      setModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (invite) {
      const inviteBase64 = Buffer.from(invite, "base64");
      const eInvite = inviteBase64.toString();
      const dInvite = JSON.parse(eInvite);
      if (Date.now() > Number(dInvite.expiry)) {
        setExpiredModal(true);
      } else {
        setIsAdmin(true);
        setForm({
          ...initialFormSignUp,
          email: decipher(ADMIN_INVITE_CODE, dInvite.email),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setExpiredModal(false);
  };

  // 1. All of your information will be fully encrypted, and no one has access to your encryption key except for you.
  // 2. This means that all of your data is confidential and cannot be accessed by any parties without your explicit consent.
  // 3. Only an email and password are required to create an account.
  // 4. You can use an email address of your choice. 5. By creating an account, you agree to the Terms and Conditions.

  return (
    <div className={"container-form__" + props.pathname}>
      {modal && <LandingModals setModal={setModal} />}
      <div className="container-body__form">
        <form className="container-form">
          <div className="container_form__row two_columns">
            <div
              className={classNames({
                "container_form__col two_columns": true,
                require_field: isAdmin,
                "ar-letter-spacing": i18n.language === "ar",
              })}
              lang={i18n.language}
            >
              <label>{t("landing form create-account-name")}</label>
              <input
                type="text"
                placeholder={
                  isAdmin
                    ? t("landing form create-account-name-placeholder-admin")
                    : t("landing form create-account-name-placeholder")
                }
                name="first_name"
                maxLength={100}
                required={isAdmin}
                onChange={handleChange}
                value={form.first_name}
              />
              {errors.first_name && (
                <span className="error-message">{errors.first_name}</span>
              )}
            </div>

            <div
              className={classNames({
                "container_form__col two_columns": true,
                require_field: isAdmin,
                "ar-letter-spacing": i18n.language === "ar",
              })}
              lang={i18n.language}
            >
              <label>{t("landing form create-account-last-name")}</label>
              <input
                type="text"
                placeholder={
                  isAdmin
                    ? t(
                        "landing form create-account-last-name-placeholder-admin"
                      )
                    : t("landing form create-account-last-name-placeholder")
                }
                maxLength={100}
                name="last_name"
                required={isAdmin}
                onChange={handleChange}
                value={form.last_name}
              />
              {errors.last_name && (
                <span className="error-message">{errors.last_name}</span>
              )}
            </div>
          </div>
          <div className="container_form__row" lang={i18n.language}>
            <div
              className={classNames({
                container_form__col: true,
                require_field: true,
                "ar-letter-spacing": i18n.language === "ar",
              })}
            >
              <label>{t("landing form create-account-email")}</label>
              <input
                className={classNames({
                  "error-field": errors.email,
                })}
                type="email"
                placeholder={`${t(
                  "landing form create-account-email-placeholder"
                )}*`}
                name="email"
                onChange={handleChange}
                maxLength={100}
                value={form.email}
                required
                disabled={isAdmin}
              />
              {errors.email && (
                <span className="error-message">{errors.email}</span>
              )}
            </div>
          </div>
          <div className="container_form__row" lang={i18n.language}>
            <div
              className={classNames({
                container_form__col: true,
                require_field: true,
                "ar-letter-spacing": i18n.language === "ar",
              })}
            >
              <label>{t("landing form create-account-password")}</label>
              <input
                className={classNames({
                  "error-field": errors.password,
                })}
                type="password"
                name="password"
                maxLength={100}
                placeholder={`${t("landing form login-password-placeholder")}*`}
                onChange={handleChange}
                value={form.password}
                required
              />
              {errors.password !== "no-match" && (
                <span className="error-message">{errors.password}</span>
              )}
              <span
                className={classNames({
                  "form_message password_field": true,
                  "error-message": errors.password === "no-match",
                })}
                lang={i18n.language}
              >
                {t("landing form create-account-password-note")}
              </span>
            </div>
          </div>
          <div className="container_form__row" lang={i18n.language}>
            <div
              className={classNames({
                container_form__col: true,
                require_field: true,
                "ar-letter-spacing": i18n.language === "ar",
              })}
            >
              <label>{t("landing form create-account-confirm-password")}</label>
              <input
                className={classNames({
                  "error-field": errors.repeat_password,
                })}
                type="password"
                name="repeat_password"
                placeholder={`${t(
                  "landing form login-confirm-password-placeholder"
                )}*`}
                onChange={handleChange}
                value={form.repeat_password}
                maxLength={100}
                required
              />
              {errors.repeat_password && (
                <span className="error-message">{errors.repeat_password}</span>
              )}
            </div>
          </div>
          <div className="container_form__row" lang={i18n.language}>
            <div
              className={classNames({
                "container_form__col terms-and-conditions": true,
                "ar-letter-spacing": i18n.language === "ar",
              })}
            >
              <input
                type="checkbox"
                id="terms_and_conditions"
                name="terms_and_conditions"
                value={form.terms}
                onChange={handleChange}
              />
              <span htmlFor="terms_and_conditions">
                <Link to="/terms-of-use" target="_blank">
                  {t("landing form create-account-accept-terms-and-conditions")}
                </Link>
              </span>
            </div>
          </div>
          {form.terms_and_conditions === false && (
            <div className="container_form__row" lang={i18n.language}>
              <span className="error-message">{errors.message}</span>
            </div>
          )}
          {errors.error_response && (
            <div className="container_form__row" lang={i18n.language}>
              <span className="error-message">{errors.error_response}</span>
            </div>
          )}

          <div
            className={classNames({
              container_form__row: true,
              "ar-letter-spacing": i18n.language === "ar",
            })}
            lang={i18n.language}
          >
            <div className="container-form__question-label">
              <span>{t("landing form create-account-have-account-text")}</span>
              <Link to="/login" state={{ fromSignUp: true }} className="link">
                {t("landing form create-account-login-link")}
              </Link>
            </div>
          </div>
          <div
            className={classNames({
              "button-landing-container": true,
              "ar-letter-spacing": i18n.language === "ar",
            })}
          >
            <Link className="back-button-link left-arrow" to="/">
              {t("landing form login-btn-back")}
            </Link>
            <div className="container_form__row">
              <div className="container_form__col buttons-container">
                <span
                  className="required-fields-text hide-mobile"
                  lang={i18n.language}
                >
                  {t("landing form create-account-required-field-text")}
                </span>
                <Button
                  class="btn-sign-up"
                  text={t("landing form create-account-btn-start")}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
          {expiredModal && (
            <Modal>
              <div
                className={classNames({
                  "lightbox-component": true,
                  "invite-admin-success-modal": true,
                  "invite-expired-modal": true,
                })}
                lang={i18n.language}
                id="invite-admin-modal"
              >
                <CloseDialog
                  closeVerifyModal
                  isFloatButton
                  onClose={handleClose}
                />
                <div className="invite-admin-success-container">
                  <h1 className="invite-admin-success-container__title">
                    {t("landing form invite-expired title")}
                  </h1>
                  <p className="invite-admin-success-container__description">
                    {t("landing form invite-expired description")}
                  </p>
                  <Button
                    class="invite-admin-success-container__close-button"
                    text={t(
                      "incident-form submission-confirmation-thankyou-btn-close"
                    )}
                    onClick={handleClose}
                  />
                </div>
              </div>
            </Modal>
          )}
        </form>
      </div>
    </div>
  );
};

export default SignUpForm;
