import { incidentIdTitle } from "adapters/incidentForm";
import { incidentStatus, overviewStatus } from "helpers/constants";
import { decipher } from "lib/utils/encryption";
import moment from "moment";
import "moment/locale/ar";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/ru";
import "moment/locale/zh-cn";
import Cookies from "universal-cookie";
import routes from "../routes";

//Platform and landing custom prefix
export const getPrefix = (layout) => {
  return layout !== "" ? "app-platform" : "app-" + layout;
};

export const addPrefixLanding = (classesName) => {
  let classesNameWithPrefix = "";
  classesName.split(" ").forEach((className) => {
    classesNameWithPrefix =
      classesNameWithPrefix +
      (classesNameWithPrefix ? " " : "") +
      "app-landing-" +
      className;
  });
  return classesNameWithPrefix;
};

export const saveCookieData = (name, data, options) => {
  const base64Data = window.btoa(
    unescape(encodeURIComponent(JSON.stringify(data)))
  );

  const cookies = new Cookies();
  cookies.set(name, base64Data, { ...options, secure: true });
};

export const getCookieData = (name, options) => {
  const cookies = new Cookies();
  let value = null;
  value = cookies.get(name, options);

  return value
    ? JSON.parse(decodeURIComponent(escape(window.atob(value))))
    : null;
};

export const clearCookiesData = () => {
  const language = getCookie("language");
  sessionStorage.clear();
  const cookies = new Cookies();
  cookies.remove("auth", { path: "/" });
  createCookie("language", language, 1000);
};

export const getSurvivorName = (user) => {
  if (user) {
    const first_name = decipher(user?.p_content_key, user?.first_name);
    const last_name = decipher(user?.p_content_key, user?.last_name);
    return `${first_name}${last_name}`.toLocaleLowerCase();
  }

  return null;
};

export const getSearchIncident = (text, incidents, anonymously) => {
  const result = incidents.filter(
    (item) =>
      (item &&
        item.id &&
        incidentIdTitle(item.id).includes(text.toString().toLowerCase())) ||
      (item && item?.survivor
        ? getSurvivorName(item.survivor).includes(text.toLowerCase())
        : anonymously.includes(text.toLowerCase()))
  );

  return result;
};

//Get route by key
export const getRouteByKey = (key) => {
  return routes.filter((item) => item.key === key)[0];
};

//Notifications, chats and chatrooms use this format for Dates. (dddd, hh:mm a)
export const getCustomDateFormat = (date, i18n) => {
  if (i18n.language === "ch") {
    moment.locale("zh-cn");
  } else {
    moment.locale(i18n.language);
  }

  return moment.utc(date).local().format("dddd, h:mm a");
};

export const getChatUserName = (senderId, chatRoomSettings) => {};

export const getAnswerById = (answer, options) => {
  return options.map((item) => item.value === answer);
};

export const getAnswerLabel = (answer, key) => {
  if (parseInt(answer) || answer === "true" || answer === "false") {
    return answer;
  } else if (parseInt(answer)) {
    return getAnswerById(answer, key);
  }
};

export const validateFileSize = (numeroBase2) => {
  const megabytes = numeroBase2 / 1024 / 1024;
  return megabytes <= 200;
};

export const limitText = (text, limit) => {
  let newText = "";
  for (let i = 0; i < limit && i < text?.length - 1; i++)
    newText = newText + text[i];
  return (newText = text?.length > limit ? newText + "... " : newText);
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const createCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let days = d.toUTCString();

  document.cookie = `${cname} = ${cvalue}; expires = ${days}`;
};

export const incidentStep = (
  form_step,
  what_happened,
  place,
  date,
  custom_place
) => {
  const isWhatHappenedCompleted = !!what_happened;
  const isDateCompleted = !!date;
  const isPlaceCompleted = !!place
    ? place === "7"
      ? !!custom_place
      : true
    : false;

  return {
    stepStatus:
      isWhatHappenedCompleted && isDateCompleted && isPlaceCompleted
        ? overviewStatus.DONE
        : form_step >= 1 &&
          (isWhatHappenedCompleted || isDateCompleted || isPlaceCompleted)
        ? overviewStatus.IN_PROGRESS
        : overviewStatus.INCOMPLETE,
    isWhatHappenedCompleted,
    isDateCompleted,
    isPlaceCompleted,
  };
};

export const perpetratorsStep = (form_step, perpetrators) => {
  const knewPerpetrators =
    perpetrators.length > 0
      ? perpetrators.filter((per) => per.knew_perpetrator !== null)
      : [];
  const isKnewPerpetratorsCompleted =
    perpetrators.length > 0
      ? perpetrators.length - knewPerpetrators.length > 0
        ? false
        : true
      : false;
  const theirDetails =
    knewPerpetrators.length > 0
      ? knewPerpetrators.filter((per) => !!per.first_name && !!per.last_name)
      : [];
  const isTheirDetailsCompleted =
    perpetrators.length > 0
      ? perpetrators.length - theirDetails.length > 0
        ? false
        : true
      : false;

  return {
    stepStatus:
      form_step >= 2 && isKnewPerpetratorsCompleted && isTheirDetailsCompleted
        ? overviewStatus.DONE
        : form_step >= 2 &&
          (isKnewPerpetratorsCompleted || isTheirDetailsCompleted)
        ? overviewStatus.IN_PROGRESS
        : overviewStatus.INCOMPLETE,
    isKnewPerpetratorsCompleted,
    isTheirDetailsCompleted,
  };
};

export const documentationStep = (form_step, upload_file_option) => {
  return {
    stepStatus:
      form_step >= 3 && upload_file_option !== null
        ? overviewStatus.DONE
        : overviewStatus.INCOMPLETE,
    isAnyDocumentationCompleted: upload_file_option !== null,
  };
};

export const impactStep = (form_step, feelings) => {
  const isFeelingCompleted = !!feelings;

  return {
    stepStatus:
      form_step >= 4 && isFeelingCompleted
        ? overviewStatus.DONE
        : form_step >= 4 && isFeelingCompleted
        ? overviewStatus.IN_PROGRESS
        : overviewStatus.INCOMPLETE,
    isFeelingCompleted,
  };
};

export const reviewStep = (form_step, isAnonymous, status) => {
  const isReviewCompleted = form_step > 4;
  const isAnonymousCompleted = !!isAnonymous;
  const isSubmitCompleted =
    status === incidentStatus.SUBMITTED.id ||
    status === incidentStatus.SHARED.id;
  const isShareCompleted = status === incidentStatus.SHARED.id;

  return {
    stepStatus:
      form_step >= 5 &&
      isReviewCompleted &&
      isAnonymousCompleted &&
      isSubmitCompleted &&
      isShareCompleted
        ? overviewStatus.DONE
        : form_step >= 5 &&
          (isReviewCompleted ||
            isAnonymousCompleted ||
            isSubmitCompleted ||
            isShareCompleted)
        ? overviewStatus.IN_PROGRESS
        : overviewStatus.INCOMPLETE,
    isReviewCompleted,
    isAnonymousCompleted,
    isSubmitCompleted,
    isShareCompleted,
  };
};

export const nextStep = (incident) => {
  const firstStep = incident
    ? incidentStep(
        incident.form_step,
        incident.what_happened,
        incident.place,
        incident.date,
        incident.custom_place
      )
    : {};
  const secondStep = incident
    ? perpetratorsStep(incident.form_step, incident.perpetrators)
    : {};
  const thirdStep = incident
    ? documentationStep(incident.form_step, incident.upload_file_option)
    : {};
  const fourthStep = incident
    ? impactStep(incident.form_step, incident.feelings)
    : {};

  if (firstStep?.stepStatus === overviewStatus.INCOMPLETE) return 1;
  if (secondStep?.stepStatus === overviewStatus.INCOMPLETE) return 2;
  if (thirdStep?.stepStatus === overviewStatus.INCOMPLETE) return 3;
  if (fourthStep?.stepStatus === overviewStatus.INCOMPLETE) return 4;
  return 5;
};

export const characterLimitMessage = (charQuantity, text) =>
  `(${text}: ${charQuantity})`;

export const answerBuilder = (stringToBeSplitted, answerHTML = []) => {
  const extractedAnchorTag =
    stringToBeSplitted.indexOf("<a") >= 0 &&
    stringToBeSplitted.indexOf("a>") > 0
      ? stringToBeSplitted.substring(
          stringToBeSplitted.indexOf("<a"),
          stringToBeSplitted.indexOf("a>") + 2
        )
      : "";

  if (extractedAnchorTag) {
    const splittedResult = stringToBeSplitted.split(extractedAnchorTag);

    answerHTML.push(splittedResult[0]);
    answerHTML.push(extractedAnchorTag);

    return answerBuilder(splittedResult[1], answerHTML);
  } else {
    if (answerHTML.length > 0) answerHTML.push(stringToBeSplitted);

    return answerHTML.length > 0 ? answerHTML : [stringToBeSplitted];
  }
};

export const showPhoneNumber = (phoneNumber) => {
  if (phoneNumber && phoneNumber !== "") {
    const firstPart = String(phoneNumber).substring(0, 2);
    const secondPart = String(phoneNumber).substring(
      phoneNumber.length - 4,
      phoneNumber.length
    );
    const middlePart = "*".repeat(phoneNumber.length - 6);
    return `${firstPart}${middlePart}${secondPart}`;
  }
  return "";
};

export const domainStringSelection = (environment = "production") => {
  const domainOptions = {
    develop: process.env.REACT_APP_INVITE_ADMIN_DOMAIN_DEVELOP,
    qa: process.env.REACT_APP_INVITE_ADMIN_DOMAIN_QA,
    staging: process.env.REACT_APP_INVITE_ADMIN_DOMAIN_STAGING,
    production: process.env.REACT_APP_INVITE_ADMIN_DOMAIN_PRODUCTION,
  };

  return domainOptions[environment.toLowerCase()];
};

export const domainValidationSelection = (environment = "production") => {
  const domainOptions = {
    develop: process.env.REACT_APP_INVITE_ADMIN_DOMAIN_VALIDATION_DEVELOP,
    qa: process.env.REACT_APP_INVITE_ADMIN_DOMAIN_VALIDATION_QA,
    staging: process.env.REACT_APP_INVITE_ADMIN_DOMAIN_VALIDATION_STAGING,
    prod: process.env.REACT_APP_INVITE_ADMIN_DOMAIN_VALIDATION_PRODUCTION,
  };

  return new RegExp(domainOptions[environment.toLowerCase()]);
};
