import React, { Fragment } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { addPrefixLanding } from "../../helpers/helpers";
import Footer from "./components/Footer";
import Header from "./components/Header";

function MessageWithVCO() {
  const { t, i18n } = useTranslation("global");

  return (
    <Fragment>
      <Header />
      <section
        className={classNames({
          [addPrefixLanding("home-section")]: true,
          [addPrefixLanding("section-1")]: true,
        })}
        lang={i18n.language}
      >
        <div className={addPrefixLanding("section-container")}>
          <div
            className={classNames({
              [addPrefixLanding("section-container__row")]: true,
              [addPrefixLanding("horizontal-orientation")]: true,
              [addPrefixLanding("center-content")]: true,
            })}
          >
            <div
              className={classNames({
                [addPrefixLanding("section-container__column")]: true,
                "flex-center": true,
              })}
            >
              <div className={addPrefixLanding("section-1-content")}>
                <h2 className={addPrefixLanding("section-title")}>
                  {t("landing resources section-message")}
                </h2>
                <p lang={i18n.language}>{t("landing resources section-message-description")}</p>
              </div>
            </div>
            <div
              className={classNames({
                [addPrefixLanding("section-container__column")]: true,
                "flex-center": true,
              })}
            >
              <div className={addPrefixLanding("section-1-content")}>
                <Link
                  className={classNames({
                    [addPrefixLanding("button-circle")]: true,
                    [addPrefixLanding("button-login")]: true,
                  })}
                  to="/sign-up"
                >
                  <span>{t("landing resources section-message-btn")}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}

export default MessageWithVCO;

