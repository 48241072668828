import { useEffect } from "react";
import ReactDOM from "react-dom";

function ModalChat({ children }) {
  const portalNode = document.createElement("div");
  portalNode.setAttribute("id", "portal-chat");

  useEffect(() => {
    document.querySelector(".app-main__content").appendChild(portalNode);
    return () => {
      portalNode.remove();
    };
  }, [portalNode]);

  return ReactDOM.createPortal(children, portalNode);
}

export default ModalChat;
