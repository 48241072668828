import React from "react";
const NO_DATA_LABEL = "No data available for the selected period";

function NoData({ title }) {
  return (
    <div className="item-info-data">
      <div className="item-info-data__title">{title}</div>
      <div className="item-info-data__info">{NO_DATA_LABEL}</div>
    </div>
  );
}

export default NoData;
