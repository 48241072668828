import React, { Fragment } from "react";
import { incidentView } from "helpers/constants";
import { nextStep } from "helpers/helpers";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import FormRoadMap from "../FormRoadMap";
import classNames from "classnames";

function DialogOverviewEditIncident({
  formEdit,
  setSelectedIncidentView,
  setModalContent,
  setModal,
  setPageIncident,
}) {
  const { t, i18n } = useTranslation("global");

  const handleNext = () => {
    setModal(false);
    setPageIncident(nextStep(formEdit));
    setSelectedIncidentView(incidentView.EDIT_INCIDENT);
    setModalContent("");
  };

  const handleBack = () => {
    setModalContent("edit-incident");
  };

  return (
    <Fragment>
      <div className="roadmap-container-modal">
        <FormRoadMap
          form={formEdit}
          setPageIncident={setPageIncident}
          setModal={setModal}
          setModalContent={setModalContent}
          setSelectedIncidentView={setSelectedIncidentView}
        />
      </div>
      <div className="navigation-buttons">
        <Button
          class={classNames({
            "btn-back-overview left-arrow": true,
            "ar-letter-spacing": i18n.language === "ar",
          })}
          text={t("incident-form navigation-back")}
          onClick={handleBack}
        />
        <Button
          text={t("incident-form continue-edit-button")}
          class={classNames({
            "btn-goto-edit-incident": true,
            "ar-letter-spacing": i18n.language === "ar",
          })}
          onClick={handleNext}
        />
      </div>
    </Fragment>
  );
}

export default DialogOverviewEditIncident;
