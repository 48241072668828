import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../Button";
import {
  twoFactorAuthentication,
  optionRadios,
  Role,
} from "../../helpers/constants";
import { PHONE_RE_GL } from "../../helpers/regexps";
import classNames from "classnames";

function TwoFactorAuthForm({
  role,
  form,
  setForm,
  onSubmit,
  isSetting = false,
  toggleSettings,
  setToggleSettings,
  codeError = "",
}) {
  const { t, i18n } = useTranslation("global");
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    const newOption = { ...form, [name]: value };

    setErrors(validationsForm({ ...form, [name]: value }));
    setForm(newOption);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validationsForm(form));

    if (Object.keys(validationsForm(form)).length === 0) {
      onSubmit(form);
    }
  };

  const validationsForm = (form) => {
    let errors = {};

    if (form.option !== twoFactorAuthentication.DISABLED) {
      if (form.phoneNumber) {
        if (!PHONE_RE_GL.test(form.phoneNumber.trim())) {
          errors.phoneNumber = t(
            "landing form two-factor-authentication-phone-invalid"
          );
        }
      } else {
        errors.phoneNumber = t(
          "landing form two-factor-authentication-phone-required"
        );
      }
    }

    return errors;
  };

  return (
    <form className="container-form">
      <div className="container_form__row ">
        <div className="container_form__col">
          <div className="question-answers">
            <div className="container-radio-buttons-options"  lang={i18n.language}>
              {(isSetting && form?.option !== twoFactorAuthentication.DISABLED
                ? true
                : form?.option !== twoFactorAuthentication.DISABLED) && (
                <div
                  className={classNames({
                    "phone-container require_field": true,
                    "phone-container-2": isSetting,
                    "ar-letter-spacing": i18n.language === "ar",
                  })}
                >
                  {!isSetting && (
                    <label>
                      {t("landing form two-factor-authentication-phone-number")}
                    </label>
                  )}
                  <input
                    type={"tel"}
                    className={classNames({
                      "error-field": errors.phoneNumber,
                      "tel-ar": i18n.language === "ar",
                    })}
                    placeholder={"+15555555555"}
                    name="phoneNumber"
                    maxLength={16}
                    defaultValue={form?.phoneNumber}
                    onKeyUp={handleChange}
                    pattern={PHONE_RE_GL}
                    required
                    disabled={isSetting ? toggleSettings : false}
                  />
                  {errors.phoneNumber && (
                    <span className="error-message">{errors.phoneNumber}</span>
                  )}
                  {codeError !== "" && (
                    <span className="error-message">{codeError}</span>
                  )}
                  <span className="text-input-description">
                    {t(
                      "landing form two-factor-authentication-phone-number-description-1"
                    )}
                  </span>
                  <span className="text-input-description">
                    {t(
                      "landing form two-factor-authentication-phone-number-description-2"
                    )}
                  </span>
                </div>
              )}
              {(isSetting ? true : role !== Role.ADMIN) &&
                optionRadios.map((option, index) =>
                  option.users.includes(role) ? (
                    <div className="radio-button-container" key={index}>
                      <div
                        className={classNames({
                          "radio-container": !isSetting,
                          "radio-container-2": isSetting,
                          "ar-letter-spacing": i18n.language === "ar",
                        })}
                      >
                        <input
                          data-type={"text"}
                          type="radio"
                          name="option"
                          value={option.value}
                          checked={form?.option === option.value}
                          onChange={handleChange}
                          disabled={
                            isSetting && role === Role.ADMIN
                              ? true
                              : isSetting && role !== Role.ADMIN
                              ? toggleSettings
                              : false
                          }
                        />
                      </div>
                      <div
                        className={classNames({
                          "radio-labels-container": !isSetting,
                          "radio-labels-container-2": isSetting,
                        })}
                      >
                        <label
                          className={classNames({
                            selected: form?.option === option.value,
                          })}
                          lang={i18n.language}
                        >
                          {t(option.title)}
                        </label>
                        <label
                          className={classNames({
                            selected: form?.option === option.value,
                          })}
                          lang={i18n.language}
                        >
                          {t(option.description)}
                        </label>
                      </div>
                    </div>
                  ) : null
                )}
            </div>
          </div>
        </div>
      </div>
      {!isSetting ? (
        <div className="button-two-factor-auth-container">
          <Link className="back-button-link left-arrow" to="/login">
            {t("incident-form navigation-back")}
          </Link>
          <div className="container_form__row">
            <div className="container_form__col buttons-container">
              <Button
                class="btn-reset-password"
                text={
                  form.option === twoFactorAuthentication.DISABLED
                    ? t("landing form two-factor-authentication-btn-continue")
                    : t("landing form two-factor-authentication-btn-set-up")
                }
                onClick={handleSubmit}
                disabled={
                  form.option !== twoFactorAuthentication.DISABLED &&
                  form.phoneNumber === ""
                    ? true
                    : false
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="tab-container__row buttons-container">
          <Button
            class={classNames({
              custom: true,
              "ar-letter-spacing": i18n.language === "ar",
            })}
            text={
              toggleSettings
                ? t("settings form edit-button")
                : t("settings form cancel-button")
            }
            onClick={(e) => {
              e.preventDefault();
              setToggleSettings(!toggleSettings);
              toggleSettings === false && window.location.reload();
            }}
          />
          {toggleSettings === false && (
            <Button
              text={t("settings form save-button")}
              onClick={handleSubmit}
              class={classNames({
                "btn-save-setting": true,
                "ar-letter-spacing": i18n.language === "ar",
              })}
            />
          )}
        </div>
      )}
    </form>
  );
}

export default TwoFactorAuthForm;

