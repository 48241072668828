import React, { Fragment } from "react";
import MediaItem from "components/MediaItem";
import { resourcePath, resourceType } from "helpers/constants";
import { useTranslation } from "react-i18next";
import Footer from "./components/Footer";
import Header from "./components/Header";

function About() {
  const { i18n } = useTranslation("global");

  const THUMBNAILS_BASE_URL = `${process.env.REACT_APP_RESOURCES_BASE_URL}/${resourcePath.ABOUT_RESOURCES_THUMBNAILS}/en`;
  const DOCS_BASE_URL = `${process.env.REACT_APP_RESOURCES_BASE_URL}/${resourcePath.ABOUT_RESOURCES_DOCS}/en`;
  const translations = i18n.getDataByLanguage("en");

  const aboutTheToolResources = [
    {
      title: translations.global["landing about item-1 title"],
      description: translations.global["landing about item-1 description"],
      thumbnailLink: `${THUMBNAILS_BASE_URL}/1.+What+is+NotOnlyMe+Cover+Image.png`,
      resourceLink: `${DOCS_BASE_URL}/1.+What+is+NotOnlyMe.pdf`,
      type: resourceType.PDF,
    },
    {
      title: translations.global["landing about item-2 title"],
      description: translations.global["landing about item-2 description"],
      thumbnailLink: `${THUMBNAILS_BASE_URL}/2.+How+it+Works+Cover+Image.png`,
      resourceLink: `${DOCS_BASE_URL}/2.+How+it+Works.pdf`,
      type: resourceType.PDF,
    },
    {
      title: translations.global["landing about item-3 title"],
      description: translations.global["landing about item-3 description"],
      thumbnailLink: `${THUMBNAILS_BASE_URL}/3.+Frequently+Asked+Questions+Cover+Image.png`,
      resourceLink: `${DOCS_BASE_URL}/3.+Frequently+Asked+Questions.pdf`,
      type: resourceType.PDF,
    },
    {
      title: translations.global["landing about item-4 title"],
      description: translations.global["landing about item-4 description"],
      thumbnailLink: `${THUMBNAILS_BASE_URL}/4.+Poster+Cover+Image.png`,
      resourceLink: `${DOCS_BASE_URL}/4.+Posters.pdf`,
      type: resourceType.PDF,
    },

    {
      title: translations.global["landing about item-5 title"],
      description: translations.global["landing about item-5 description"],
      thumbnailLink:
        "https://unhcr-public-files.s3.eu-west-1.amazonaws.com/resources/NotOnlyMe_images.png",
      resourceLink: null,
      type: resourceType.VIDEO,
    },
    {
      title: translations.global["landing about item-6 title"],
      description: translations.global["landing about item-6 description"],
      thumbnailLink: `${THUMBNAILS_BASE_URL}/6.+Powerpoint+Cover+Image.png`,
      resourceLink:
        "https://unhcr-public-files.s3.eu-west-1.amazonaws.com/resources/POWERPOINT_V3.pdf",
      type: resourceType.POWERPOINT,
    },
  ];

  return (
    <Fragment>
      <Header />
      <div className="about-tool" lang={i18n.language}>
        {aboutTheToolResources.map((item) => (
          <MediaItem
            title={item.title}
            description={item.description}
            thumbnail={item.thumbnailLink}
            url={item.resourceLink}
            type={item.type}
          />
        ))}
      </div>
      <Footer />
    </Fragment>
  );
}

export default About;
