import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { showPhoneNumber } from "helpers/helpers";
import { resend2faCodeForEmailChange } from "services/users";

const ChangeEmailSecondStep = ({
  onCancel,
  onConfirm,
  userData,
  token,
  errors,
}) => {
  const { t } = useTranslation("global");
  const [code2fa, setCode2fa] = useState("");
  const [attempts, setAttempts] = useState(3);
  const [waitTime, setWaitTime] = useState(0);

  const handleResendCode = async () => {
    if (attempts === 0) {
    } else {
      setAttempts(attempts - 1);
      resend2faCodeForEmailChange({ token });
      setWaitTime(60);
    }
  };

  useEffect(() => {
    waitTime > 0 && setTimeout(() => setWaitTime(waitTime - 1), 1000);
  }, [waitTime, setWaitTime]);

  const showResendButton = waitTime === 0 && attempts > 0;
  const showWaitingCounter = waitTime > 0 && attempts > 0;

  return (
    <div className="changeEmail__container changeEmail__container--verifyAccount">
      <h2 className="modal-title">
        {t("settings change-email-modal title 2")}
      </h2>
      <p className="modal-description">
        {t("settings change-email-modal description 2", {
          phone: showPhoneNumber(userData.setting.phone),
        })}
      </p>
      <div className="input-container">
        <label htmlFor="code2fa">
          {t("settings change-email-modal verification")}
        </label>
        <input
          id="code2fa"
          type="text"
          name="code2fa"
          value={code2fa}
          onChange={(e) => setCode2fa(e.target.value)}
          placeholder="*******"
        />
        {showWaitingCounter && (
          <div className="code-sended">
            <p>{t("settings change-email-modal code-sent")}</p>
            <span>
              {t("settings change-email-modal seconds-remaining", {
                seconds: waitTime,
              })}
            </span>
          </div>
        )}
        {showResendButton && (
          <label className="container-loading container-loading-small">
            {t("landing form verify-account form-not-receive-code-message")}
            <span
              className="error-message resend-code pointer"
              onClick={handleResendCode}
            >
              {t("landing form two-factor-authentication-code-description-2")}
            </span>
          </label>
        )}
        {errors.code2fa && (
          <span className="error-message">
            {t(errors.code2fa, { attempts })}
          </span>
        )}
      </div>
      <div className="footer">
        <button onClick={() => onConfirm(code2fa)} className="confirm-button">
          {t("settings change-email-modal button confirm 2")}
        </button>
        <button onClick={onCancel} className="cancel-button">
          {t("settings change-email-modal button cancel")}
        </button>
      </div>
    </div>
  );
};

export default ChangeEmailSecondStep;
