import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteIncident } from "services/incidents";
import { getCookieData } from "../../helpers/helpers";
import { i18nGetData } from "../../i18n";
import Button from "../Button";
import classNames from "classnames";

export const useConfirmDelete = (validateForm, initialForm, formToDelete) => {
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: name === "confirm_delete" ? !form.confirm_delete : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validateForm(form));
    if (Object.keys(validateForm(form)).length === 0) {
      const dataSession = getCookieData("auth", { path: "/" });

      Promise.resolve(
        deleteIncident({
          token: dataSession.accessToken,
          incident: JSON.stringify({ id: formToDelete.id }),
        })
      )
        .then((res) => {
          window.location.reload();
        })
        .catch((error) => {
          console.log("err", error);
        })
        .finally(() => {});
    }
  };

  return {
    form,
    errors,
    handleChange,
    handleSubmit,
  };
};

const initialForm = {
  confirm_delete: false,
};

const validationsForms = (form) => {
  const errors = {};

  if (form?.confirm_delete === false) {
    errors.message = i18nGetData(
      "your-incidents delete-incident confirm-error"
    );
  }

  return errors;
};

function DialogDeleteIncident(props) {
  const { t, i18n } = useTranslation("global");
  const { form, errors, handleChange, handleSubmit } = useConfirmDelete(
    validationsForms,
    initialForm,
    props.form
  );

  return (
    <Fragment>
      <div
        className={classNames({
          "content-sublightbox__title": true,
          "content-sublightbox__title-ar": i18n.language === "ar",
        })}
        lang={i18n.language}
      >
        {t("your-incidents delete-incident modal-title")}
      </div>
      <div className="content-sublightbox__content" lang={i18n.language}>
        <div
          className={classNames({
            "content-sublightbox__content-description": true,
            "ar-letter-spacing": i18n.language === "ar",
          })}
        >
          <p
            className={classNames({
              "ar-text-align": i18n.language === "ar",
            })}
            lang={i18n.language}
          >
            {t("your-incidents delete-incident modal-description")}
          </p>
          <form
            className={classNames({
              "content-sublightbox__form": true,
              "ar-letter-spacing": i18n.language === "ar",
            })}
          >
            <div className="container_form__row">
              <div
                className={classNames({
                  "container_form__col terms-and-conditions": true,
                  "container-ar": i18n.language === "ar",
                })}
              >
                <input
                  type="checkbox"
                  id="confirm_delete"
                  name="confirm_delete"
                  value={form.terms}
                  onChange={handleChange}
                />
                <label htmlFor="confirm_delete" lang={i18n.language}>
                  {t("your-incidents delete-incident modal-agree-checkbox")}
                </label>
              </div>
            </div>
            {form.confirm_delete === false && (
              <div className="container_form__row">
                <span className="error-message">{errors.message}</span>
              </div>
            )}
          </form>
        </div>
        <div
          className={classNames({
            "content-sublightbox__options": true,
            "ar-letter-spacing": i18n.language === "ar",
            "container-ar": i18n.language === "ar",
          })}
        >
          <Button
            class="custom btn-no-option"
            text={t("your-incidents delete-incident modal-cancel-button")}
            onClick={(e) => {
              e.preventDefault();
              props.setSubModal(false);
            }}
          />
          <Button
            class="custom btn-confirm-delete-account"
            text={t("your-incidents delete-incident modal-confirm-button")}
            onClick={handleSubmit}
            disabled={!form.confirm_delete}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default DialogDeleteIncident;

