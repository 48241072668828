import React, { Fragment } from "react";
import { incidentItemId } from "adapters/incidentForm";
import NotificationItem from "components/notifications/NotificationItem";
import { incidentStatus } from "helpers/constants";
import { decipher } from "lib/utils/encryption";
import moment from "moment";
import "moment/locale/ar";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/ru";
import "moment/locale/zh-cn";
import { useTranslation } from "react-i18next";

function IncidentList(props) {
  const { t, i18n } = useTranslation("global");
  const [currentId, setCurrentId] = React.useState("");

  if (i18n.language === "ch") {
    moment.locale("zh-cn");
  } else {
    moment.locale(i18n.language);
  }

  //TODO: Remove session storage and use sessions from react

  const description = (item) => {
    let status;
    let dateAt;
    let shared = "";
    if (props.view === "admin") {
      status = item.isAnonymous
        ? `${t("platform incident-item-shared-title-admin-anonymously")} • ${t(
            "platform incident created"
          )} `
        : `${t("platform incident-item-shared-title-admin-by-user")} ${
            item.survivor?.first_name
              ? decipher(item.survivor.p_content_key, item.survivor.first_name)
              : "-"
          } ${
            item.survivor?.last_name
              ? decipher(item.survivor.p_content_key, item.survivor.last_name)
              : ""
          } • ${t("platform incident created")} `;
      dateAt = moment(item.created_at).format("LLL");
    } else {
      status =
        item.status === incidentStatus.IN_DRAFT.id || item.status === ""
          ? `${t(incidentStatus.IN_DRAFT.value)}  • ${t(
              "platform incident created"
            )} `
          : item.status === incidentStatus.SUBMITTED.id ||
            item.status === incidentStatus.SHARED.id
          ? `${t(incidentStatus.SUBMITTED.value)} `
          : "";

      shared =
        item.status === incidentStatus.SUBMITTED.id
          ? t(incidentStatus.SHARED.value_1)
          : item.status === incidentStatus.SHARED.id
          ? t(incidentStatus.SHARED.value_2)
          : "";

      dateAt =
        item.status === incidentStatus.IN_DRAFT.id
          ? moment(item.created_at).format("LLL")
          : moment(item.submitted_at).format("LLL");
    }

    return (
      <Fragment>
        <div className="incident-item__details">
          <span className="status">{status}</span>
          <span className="date-created">{dateAt}</span>{" "}
          {shared !== "" && <span className="status"> • {` ${shared}`}</span>}
        </div>
        { item.matchesCount > 0 && (
          <div className="incident-item__details">
            <span>{t(`incident-form status matches ${item.matchesCount > 1 ? 'plural' : 'singular'}`, { matches: item.matchesCount })}</span>
          </div>
        )}
      </Fragment>
    );
  };

  const reportName = (name) => {
    return name?.includes("-")
      ? `${t("your-incidents incident-prefix")}: ${incidentItemId(name)}`
      : name;
  };

  return (
    <div className="incident-list" lang={i18n.language}>
      {props.incidentList.length > 0 &&
        props.incidentList.map((item, index) => {
          return (
            <NotificationItem
              key={index}
              title={`${reportName(item.id)}`}
              description={description(item)}
              handleEdit={props.handleEdit}
              handleDelete={props.handleDelete}
              form={item}
              id={item.id}
              setSubModal={props.setSubModal}
              setSubModalContent={props.setSubModalContent}
              setSelectedFormId={props.setSelectedFormId}
              type="incident"
              viewIncident={props.handleShowIncidentItem}
              view={props.view}
              setSelectedIncidentView={props.setSelectedIncidentView}
              currentId={currentId}
              setCurrentId={setCurrentId}
              isRead={item.isRead}
              getIncidents={props.getIncidents}
            />
          );
        })}
    </div>
  );
}

export default IncidentList;
