import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { resendNewEmailCode } from "services/users";

const ChangeEmailThirdStep = ({
  onCancel,
  onConfirm,
  errors,
  newEmail,
  newHashedEmail,
  token,
}) => {
  const { t } = useTranslation("global");
  const [verificationCode, setVerificationCode] = useState("");
  const [attempts, setAttempts] = useState(3);
  const [waitTime, setWaitTime] = useState(0);

  const handleResendCode = async () => {
    if (attempts === 0) {
    } else {
      setAttempts(attempts - 1);
      resendNewEmailCode({
        token,
        formData: {
          newEmail: newEmail,
          new_hashed_email: newHashedEmail,
        },
      });
      setWaitTime(60);
    }
  };

  useEffect(() => {
    waitTime > 0 && setTimeout(() => setWaitTime(waitTime - 1), 1000);
  }, [waitTime, setWaitTime]);

  const showResendButton = waitTime === 0 && attempts > 0;
  const showWaitingCounter = waitTime > 0 && attempts > 0;

  return (
    <div className="changeEmail__container">
      <h2 className="modal-title">
        {t("settings change-email-modal title 3")}
      </h2>
      <p className="modal-description">
        {t("settings change-email-modal description 3")}
      </p>
      <div className="input-container">
        <label htmlFor="verificationCode">
          {t("settings change-email-modal verification")}
        </label>
        <input
          id="verificationCode"
          type="text"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          placeholder="*******"
        />
        {showWaitingCounter && (
          <div className="code-sended">
            <p>{t("settings change-email-modal code-sent")}</p>
            <span>
              {t("settings change-email-modal seconds-remaining", {
                seconds: waitTime,
              })}
            </span>
          </div>
        )}
        {showResendButton && (
          <label className="container-loading container-loading-small">
            {t("landing form verify-account form-not-receive-code-message")}
            <span
              className="error-message resend-code pointer"
              onClick={handleResendCode}
            >
              {t("landing form two-factor-authentication-code-description-2")}
            </span>
          </label>
        )}
        <p>
          {t("landing form verify-account form-remaining-attempts-hint", {
            attempts,
          })}
        </p>
        {errors.newEmailCode && (
          <span className="error-message">{errors.newEmailCode}</span>
        )}
      </div>
      <div className="footer">
        <button
          onClick={() => onConfirm(verificationCode)}
          className="confirm-button"
        >
          {t("settings change-email-modal button confirm")}
        </button>
        <button onClick={onCancel} className="cancel-button">
          {t("settings change-email-modal button cancel")}
        </button>
      </div>
    </div>
  );
};

export default ChangeEmailThirdStep;
