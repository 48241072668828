import React, { useContext, useEffect, useState } from "react";
import { incidentItemId } from "adapters/incidentForm";
import closeIconSubModal from "assets/images/close-btn-white.svg";
import classNames from "classnames";
import AsideNavbar from "components/AsideNavbar";
import Button from "components/Button";
import Loading from "components/Loading";
import MessagingTab from "components/MessagingTab";
import Modal from "components/Modal";
import ModalChat from "components/ModalChat";
import ResourcesTab from "components/ResourcesTab";
import Dashboard from "components/admin/Dashboard";
import CookiesBanner from "components/cookiesBanner";
import CloseDialog from "components/dialogs/CloseDialog";
import DialogDeleteAccount from "components/dialogs/DialogDeleteAccount";
import DeleteAccountSuccessful from "components/dialogs/DialogDeleteAccountSuccessful";
import DialogDeleteIncident from "components/dialogs/DialogDeleteIncident";
import DialogDownloadIncident from "components/dialogs/DialogDownloadIncident";
import DialogInactivitySession from "components/dialogs/DialogInactivitySession";
import LogoutSession from "components/dialogs/DialogLogoutSession";
import DialogNotificationSettings from "components/dialogs/DialogNotificationSettings";
import DialogNotifications from "components/dialogs/DialogNotifications";
import DialogStartMessage from "components/dialogs/DialogStartMessage";
import LogoutOnClick from "components/dialogs/LogoutOnClick";
import ModalChatContent from "components/message-system/ModalChatContent";
import UserSettings from "components/user/UserSettings";
import ModalContext from "context/ModalContext";
import SubModalContext from "context/SubModalContext";
import CodeVerification from "components/TwoFactorAuthentication/CodeVerification";
import HeaderForm from "components/HeaderForm";
import UserSettingsContext from "context/UserSettingContext";
import {
  contactFormJSON,
  getAdminMenuItems,
  incidentView,
  inviteAdminInitialForm,
  passwordFormJSON,
  twoFactorAuthErrors,
} from "helpers/constants";
import { getCookieData, showPhoneNumber } from "helpers/helpers";
import InactivityTimer from "helpers/inactivityTimer";
import {
  validationsFormContact,
  validationsFormPassword,
} from "helpers/validations";
import useLogout from "hooks/useLogout";
import useRefreshToken from "hooks/useRefreshToken";
import { i18nGetData } from "i18n";
import { cipher, decipher, hash } from "lib/utils/encryption";
import IncidentForms from "pages/platform/incidents";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import {
  deleteChatHistory,
  getChatRoomById,
  getChatRoomMessages,
  getChatRooms,
  updateChatStatus,
} from "services/chats";
import { getIncidents } from "services/incidents";
import { getNotifications } from "services/notifications";
import {
  getUser,
  resendTwoFactorAuthCode,
  updateTwoFactorAuthSetting,
  sendInviteCode,
  updatePassword,
  updateUserInfo,
  inviteAdminSubmit,
} from "services/users";
import { ReactComponent as MenuIcon } from "../../assets/images/menu-icon-white.svg";
import logoBlue from "../../assets/images/pseash-logo-blue.svg";
import InviteAdminForm from "components/forms/inviteAdminForm";

const AdminDashboard = ({ socket, html2pdf, modalChat, setModalChat }) => {
  const { t, i18n } = useTranslation("global");
  const location = useLocation();
  //chat Temp
  const [currentChatRoom, setCurrentChatRoom] = useState({});

  //Global context
  const [modal, setModal] = useContext(ModalContext);
  const [subModal, setSubModal] = useContext(SubModalContext);
  const [open, setOpen] = useState(false);
  const [, setUserSettings] = useContext(UserSettingsContext);

  //Component state
  const [modalContent, setModalContent] = useState("");
  const [subModalContent, setSubModalContent] = useState("");
  const [formToDelete, setFormToDelete] = useState();
  const [userData, setUserData] = useState({});
  const [userType, setUserType] = useState("");
  const [contactForm, setContactForm] = useState(contactFormJSON);
  const [passwordForm, setPasswordForm] = useState(passwordFormJSON);
  const [toggleSettings, setToggleSettings] = useState(true);
  const [toggleUserInfo, setToggleUserInfo] = useState(true);
  const [togglePassword, setTogglePassword] = useState(true);
  const [errors, setErrors] = useState({});
  const [notificationList, setNotificationList] = useState([]);
  const [chatroomList, setChatroomList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [messagingView, setMessagingView] = useState("loading");

  // Messaging
  const [messages, setMessages] = useState([]);
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);

  // news
  const [selectedIncident, setSelectedIncident] = useState({});
  const [selectedIncidentView, setSelectedIncidentView] = useState("");
  const [incidentsCopy, setIncidentsCopy] = useState([]);
  const [incidents, setIncidents] = useState([]);
  const [expiredModal, setExpiredModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showInactivityModal, setShowInactivityModal] = useState(false);
  const [deleteSuccessfulModal, setDeleteSuccessfulModal] = useState(false);

  const logout = useLogout();
  const onRefreshToken = useRefreshToken(setExpiredModal);

  const getTokenTemp = () => {
    return getCookieData("auth", { path: "/" }) !== ""
      ? getCookieData("auth", { path: "/" }).accessToken
      : "";
  };

  let currentTab;

  const fetchData = async () => {
    setLoading(true);
    await Promise.resolve(getUser({ token: getTokenTemp() }))
      .then((res) => {
        if (res) {
          const user = res?.user || {};
          setUserSettings(user?.setting);
          setTwoFactorAuth({
            option: user?.setting.two_factor_authentication,
            phoneNumber: user?.setting.phone,
          });

          setUserData(user);
          setUserType(user?.role);
          setContactForm({
            ...contactForm,
            first_name: decipher(user?.p_content_key, user?.first_name),
            last_name: decipher(user?.p_content_key, user?.last_name),
            id: user?.id,
            key: user?.p_content_key,
            email: user?.email,
          });
          setPasswordForm({
            ...passwordForm,
            hashed_email: user?.hashed_email,
          });
          updateNotifications();
          getIncidentsList();
          updateMessages();
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else if (err.response.status === 403) {
          setIsMessagesLoading(false);
          setMessagingView("view-welcome");
          setSelectedIncidentView(incidentView.EMPTY);
          setExpiredModal(true);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = new InactivityTimer({
      onRefreshToken,
      logout,
      setShowInactivityModal,
      closeAllModals,
    });

    return () => {
      timer.cleanUp();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const joinRoom = (
    subject,
    anonymous,
    incidents,
    p_content_key,
    chatRoomInfo
  ) => {
    const roomData = {
      subject: subject,
      anonymous: anonymous,
      incidents: incidents,
      userId: userData?.id,
      p_content_key: p_content_key,
      chatRoomId: chatRoomInfo.id,
    };

    socket.emit("join_room", roomData);
    updateMessages();
    setCurrentChatRoom(chatRoomInfo);
  };

  const handleDeleteAccount = () => {
    setSubModalContent("delete-account");
    setSubModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordForm({
      ...passwordForm,
      [name]: value,
    });
  };

  const handleChangeUserSettings = (e) => {
    const { name, value } = e.target;
    setContactForm({
      ...contactForm,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validationsFormPassword(passwordForm));
    if (Object.keys(validationsFormPassword(passwordForm)).length === 0) {
      const password = {
        new_password: hash(passwordForm.new_password),
        hashed_email: passwordForm.hashed_email,
        old_password: hash(passwordForm.current_password),
      };

      const data = JSON.stringify(password);

      Promise.resolve(updatePassword({ token: getTokenTemp(), formData: data }))
        .then((res) => {
          if (res) {
            setPasswordForm({
              ...passwordForm,
              message: t("landing form reset-password-success"),
            });
            setTogglePassword(true);
          }
        })
        .catch((error) => {
          if (error.response.data.error === "BAD_PASS") {
            setErrors({
              current_password: i18nGetData(
                "settings form validations password-no-match"
              ),
            });
          }
          console.log("err", error);
        })
        .finally(() => {
          //final action
        });
    }
  };

  const handleOpen = () => {
    setOpen((value) => !value);
  };

  const handleLogout = () => {
    logout();
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleSubmitContactForm = (e) => {
    e.preventDefault();

    setErrors(validationsFormContact(contactForm));
    if (Object.keys(validationsFormContact(contactForm)).length === 0) {
      const newUserInfo = {
        id: contactForm.id,
        first_name: cipher(contactForm.key, contactForm.first_name),
        last_name: cipher(contactForm.key, contactForm.last_name),
      };

      const data = JSON.stringify(newUserInfo);

      Promise.resolve(updateUserInfo({ token: getTokenTemp(), formData: data }))
        .then((res) => {
          if (res) {
            setContactForm({
              ...contactForm,
              message: i18nGetData("setting form-information updated"),
            });
            setToggleUserInfo(true);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        })
        .catch((error) => {
          console.log("err", error);
        })
        .finally(() => {
          //final action
        });
    }
  };

  const updateNotifications = () => {
    Promise.resolve(getNotifications({ token: getTokenTemp() }))
      .then((res) => {
        setNotificationList(res?.notifications);
      })
      .catch((error) => {
        console.log("err", error);
      })
      .finally(() => {
        //final action
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateMessages = () => {
    Promise.resolve(getChatRooms({ token: getTokenTemp() }))
      .then((res) => {
        if (res?.chatRooms.length > 0) {
          setChatroomList(res.chatRooms);
          setMessagingView("view-chat");
        } else {
          setMessagingView("view-welcome");
        }
      })
      .catch((error) => {
        console.log("err", error);
      })
      .finally(() => {
        //final action
      });
  };

  const getChatRoomData = (id) => {
    setCurrentChatRoom({});
    setMessages([]);
    setIsMessagesLoading(true);
    Promise.resolve(getChatRoomById({ token: getTokenTemp(), id: id }))
      .then((res) => {
        joinRoom(
          res?.chatRoom.subject,
          res?.chatRoom.anonymous,
          res?.chatRoom.incidents,
          res?.chatRoom.p_content_key,
          res?.chatRoom
        );
        setCurrentChatRoom(res?.chatRoom);

        Promise.resolve(
          getChatRoomMessages({ token: getTokenTemp(), id: id })
        ).then((res2) => {
          setMessages(
            res2?.messages.map((message) => ({
              ...message,
              message: decipher(res?.chatRoom.p_content_key, message.content),
            }))
          );
          setIsMessagesLoading(false);
        });
      })
      .catch((error) => {
        console.log("err", error);
      });
  };

  const markAsUnread = (id, action, role = userType) => {
    Promise.resolve(
      updateChatStatus({ token: getTokenTemp(), id, role, action })
    )
      .then(() => {
        if (action === "read" || action === "unread" || action === "close") {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log("updatechatestatus error", error);
      })
      .finally(() => {
        //final action
      });
  };

  const deleteHistory = (id) => {
    Promise.resolve(deleteChatHistory({ token: getTokenTemp(), id: id }))
      .then(() => {})
      .catch((error) => {
        console.log("err", error);
      })
      .finally(() => {
        //final action
        updateMessages();
        setModalChat(false);
      });
  };

  const getIncidentsList = () => {
    setSelectedIncidentView("");
    Promise.resolve(getIncidents({ token: getTokenTemp() }))
      .then((res) => {
        setIncidents(res.incidents);
        setIncidentsCopy(res.incidents);
      })
      .catch((error) => {
        console.log("err", error);
      })
      .finally(() => {
        setSelectedIncidentView(incidentView.INCIDENT_LIST);
      });
  };

  /*
   * BEGIN: Invite Admin Modal
   */

  const [openInviteAdmin, setOpenInviteAdmin] = useState(false);
  const [inviteAdminSuccessModal, setInviteAdminSuccessModal] = useState(false);
  const [inviteAdminForm, setInviteAdminForm] = useState(
    inviteAdminInitialForm
  );

  const handleCloseInviteAdminModal = () => {
    setOpenInviteAdmin(false);
  };

  const handleCloseInviteAdminSuccessModal = () => {
    setInviteAdminSuccessModal(false);
    setInviteAdminForm(inviteAdminInitialForm);
  };

  /*
   * END: Invite Admin Modal
   */

  /*
   * BEGIN: 2FA Verification Modal
   */

  const [twoFactorAuth, setTwoFactorAuth] = useState({});
  const [twoFactorAuthOpen, setTwoFactorAuthOpen] = useState(false);
  const [twoFactorAuthDescription, setTwoFactorAuthDescription] = useState("");
  const [twoFactorAuthCodeError, setTwoFactorAuthCodeError] = useState("");
  const [twoFactorAuthloading, setTwoFactorAuthLoading] = useState(false);
  const [twoFactorAuthDisableResend, setTwoFactorAuthDisableResend] =
    useState(false);

  const handleTwoFactorAuthErrors = (error) => {
    setTwoFactorAuthCodeError(
      error.response.data.error === twoFactorAuthErrors.BAD_CODE
        ? t("landing form two-factor-authentication-error-1")
        : error.response.data.error === twoFactorAuthErrors.PHONE_ERROR
        ? error.response.data.message
        : t("generic-error-message")
    );
  };

  const sendTwoFactorAuthCode = () => {
    setTwoFactorAuthCodeError("");
    setTwoFactorAuthLoading(true);

    Promise.resolve(
      resendTwoFactorAuthCode(getTokenTemp(), {
        two_factor_authentication: userData.setting.two_factor_authentication,
        phone: twoFactorAuth?.phoneNumber,
      })
    )
      .then((res) => {
        if (res) {
          setTwoFactorAuthLoading(false);
          setTimeout(() => {
            setTwoFactorAuthDisableResend(false);
          }, 60000);
        }
      })
      .catch((error) => {
        handleTwoFactorAuthErrors(error);
        setTwoFactorAuthLoading(false);
      });
  };

  const onTwoFactorAuthOpen = () => {
    setTwoFactorAuthOpen(true);

    const descriptionSecondStep = `<p>${
      !!userData?.setting?.phone || twoFactorAuth?.phoneNumber
        ? `${t(
            "landing two-factor-authentication section-description-verification",
            {
              phone: showPhoneNumber(
                twoFactorAuth?.phoneNumber || userData?.setting?.phone
              ),
            }
          )}`
        : t(
            "landing two-factor-authentication section-description-verification-2"
          )
    }</p>`;

    setTwoFactorAuthDescription(descriptionSecondStep);
    sendTwoFactorAuthCode();
  };

  const onTwoFactorAuthClose = () => {
    setTwoFactorAuthOpen(false);
    setTwoFactorAuthCodeError("");
  };

  const onTwoFactorAuthSubmit = (code) => {
    setTwoFactorAuthCodeError("");
    setLoading(true);

    if (inviteAdminForm.invite_admin_email) {
      Promise.resolve(
        inviteAdminSubmit(getTokenTemp(), {
          password: hash(inviteAdminForm.invite_admin_password),
          email: inviteAdminForm.invite_admin_email,
          two_fa_code: code,
        })
      )
        .then((res) => {
          if (res) {
            setLoading(false);
            setInviteAdminSuccessModal(true);
            onTwoFactorAuthClose();
          }
        })
        .catch((error) => {
          console.log(error);
          handleTwoFactorAuthErrors(error);
          setLoading(false);
        });
    } else {
      Promise.resolve(
        updateTwoFactorAuthSetting(getTokenTemp(), {
          code,
          two_factor_authentication: twoFactorAuth.option,
          phone: twoFactorAuth.phoneNumber,
        })
      )
        .then((res) => {
          if (res) {
            setLoading(false);
            logout();
          }
        })
        .catch((error) => {
          handleTwoFactorAuthErrors(error);
          setLoading(false);
        });
    }
  };

  const handleTwoFactorAuthResendCode = () => {
    if (!twoFactorAuthDisableResend) {
      setTwoFactorAuthDisableResend(true);
      sendTwoFactorAuthCode();
    }
  };

  /*
   * END: 2FA Verification Modal
   */

  /*
   * BEGIN: Invite Admin Modal
   */

  const [blockDeleteAdminModal, setBlockDeleteAdminModal] = useState(false);

  const handleCloseBlockDeleteAdminModal = () => {
    setBlockDeleteAdminModal(false);
  };

  /*
   * END: Invite Admin Modal
   */

  const displayTab = (id) => {
    switch (id) {
      case 1:
        return <Dashboard loading={loading} />;
      case 2:
        return (
          <div
            className={classNames({
              "portal-container": true,
              ["tab-" + currentTab.id]: true,
            })}
          >
            {loading ? (
              <Loading />
            ) : (
              <IncidentForms
                user={userData}
                incidents={incidents}
                setIncidents={setIncidents}
                incidentsCopy={incidentsCopy}
                setSelectedIncidentView={setSelectedIncidentView}
                selectedIncidentView={selectedIncidentView}
                setSubModalContent={setSubModalContent}
                setSelectedIncident={setSelectedIncident}
                selectedIncident={selectedIncident}
                setSubModal={setSubModal}
                setModalContent={setModalContent}
                setModal={setModal}
                setFormToDelete={setFormToDelete}
                loading={loading}
                getIncidents={getIncidentsList}
              />
            )}
          </div>
        );
      case 3:
        return <ResourcesTab />;
      case 4:
        return (
          <MessagingTab
            setModal={setModal}
            setModalContent={setModalContent}
            setSubModal={setSubModal}
            setSubModalContent={setSubModalContent}
            modalChat={modalChat}
            setModalChat={setModalChat}
            view={messagingView}
            chatRooms={chatroomList}
            senderId={userData?.id}
            getChatRoomData={getChatRoomData}
            currentChatRoom={currentChatRoom}
            markAsUnread={markAsUnread}
            userData={userData}
            deleteHistory={deleteHistory}
            userType={userType}
            socket={socket}
            loading={loading}
          />
        );
      case 5:
        return (
          <UserSettings
            contactForm={contactForm}
            errors={errors}
            form={twoFactorAuth}
            handleChange={handleChange}
            handleChangeUserSettings={handleChangeUserSettings}
            handleDeleteAccount={handleDeleteAccount}
            handleSubmit={handleSubmit}
            handleSubmitContactForm={handleSubmitContactForm}
            isLoading={loading}
            isSetting
            logout={logout}
            onOpen={onTwoFactorAuthOpen}
            openInviteAdmin={openInviteAdmin}
            passwordForm={passwordForm}
            phoneNumber={userData?.setting?.phone || ""}
            role={userData?.role}
            sendInviteCode={sendInviteCode}
            setForm={setTwoFactorAuth}
            setOpenInviteAdmin={setOpenInviteAdmin}
            setTogglePassword={setTogglePassword}
            setToggleSettings={setToggleSettings}
            setToggleUserInfo={setToggleUserInfo}
            setTwoFactorAuthOpen={setTwoFactorAuthOpen}
            togglePassword={togglePassword}
            toggleSettings={toggleSettings}
            toggleUserInfo={toggleUserInfo}
            tokenTemp={getTokenTemp()}
            setBlockDeleteAdminModal={setBlockDeleteAdminModal}
          />
        );
      default:
        break;
    }
  };

  const getModalContent = () => {
    switch (modalContent) {
      case "notifications":
        return (
          <DialogNotifications
            setModal={setModal}
            setModalContent={setModalContent}
            notificationList={notificationList}
            updateNotifications={updateNotifications}
          />
        );
      case "notifications-settings":
        return (
          <DialogNotificationSettings
            setModal={setModal}
            setModalContent={setModalContent}
            notificationList={notificationList}
            updateNotifications={updateNotifications}
            role={userData?.role}
          />
        );
      default:
        break;
    }
  };

  const getSubModalContent = () => {
    switch (subModalContent) {
      case "delete-account":
        return (
          <DialogDeleteAccount
            setSubModal={setSubModal}
            setSubModalContent={setSubModalContent}
            setDeleteSuccessfulModal={setDeleteSuccessfulModal}
          />
        );
      case "delete-incident":
        return (
          <DialogDeleteIncident setSubModal={setSubModal} form={formToDelete} />
        );
      case "start-message":
        return (
          <DialogStartMessage
            setSubModal={setSubModal}
            setModalChat={setModalChat}
            tokenTemp={getTokenTemp()}
            incidentIds={
              incidents && incidents.length > 0
                ? incidents.map((item) => ({
                    value: item.id,
                    label: incidentItemId(item.id),
                  }))
                : []
            }
            setMessagingView={setMessagingView}
            joinRoom={joinRoom}
            getChatRoomData={getChatRoomData}
          />
        );
      case "download-incident":
        return (
          <DialogDownloadIncident
            setSubModal={setSubModal}
            form={selectedIncident}
            html2pdf={html2pdf}
          />
        );
      default:
        break;
    }
  };

  const handleCloseInactivityModal = () => {
    setShowInactivityModal(false);
  };

  React.useEffect(() => {
    socket.on("reload_chatroom_list", (res) => {
      if (res?.id) {
        const chat = chatroomList.findIndex((chat) => chat.id === res?.id);
        if (chat === -1) {
          setMessagingView("loading");
          updateMessages();
        }

        if (res?.refresh_for_change) {
          if (chat !== -1 && currentChatRoom.id === String(res.id)) {
            setModalChat(false);
          }
          setMessagingView("loading");
          updateMessages();
        }
      }
    });

    return () => socket.off("reload_chatroom_list");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatroomList, socket, updateMessages]);

  const adminMenuItems = getAdminMenuItems();
  if (location.hash) {
    currentTab = adminMenuItems.find((item, i) => item.url === location.hash);
    currentTab = currentTab ? currentTab : adminMenuItems[0];
  } else {
    currentTab = adminMenuItems[1];
  }

  React.useEffect(() => {
    socket.on("check_current_token", (accessToken) => {
      const sessionToken = getTokenTemp();

      if (accessToken && !expiredModal && sessionToken !== accessToken) {
        window.location.reload();
      }
    });

    return () => socket.off("check_current_token");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiredModal, socket]);

  React.useEffect(() => {
    userData?.id && socket.emit("verify_session", { id: userData.id });
  }, [socket, userData.id]);

  const closeAllModals = () =>{
    setTwoFactorAuthOpen(false);
    setDeleteSuccessfulModal(false);
    setShowPopup(false);
    setOpenInviteAdmin(false);
    setBlockDeleteAdminModal(false);
    setInviteAdminSuccessModal(false);
    setModal(false);
    setModalContent("");
    setSubModal(false);
    setSubModalContent("")
  }

  return (
    <div
      className={classNames({
        "app-main__content": true,
        "app-main__content--resize": open,
        "app-main__content--chat-open": modalChat,
        "app-main__content--delete-open": subModalContent === "delete-account",
      })}
      id="app-main"
    >
      {showInactivityModal && (
        <Modal>
          <div
            className={classNames({
              "lightbox-component": true,
              "inactivity-modal": true,
            })}
            lang={i18n.language}
            id="inactivity-modal"
          >
            <CloseDialog
              closeVerifyModal
              isFloatButton
              onClose={handleCloseInactivityModal}
            />
            <DialogInactivitySession
              setShowInactivityModal={setShowInactivityModal}
            />
          </div>
        </Modal>
      )}
      {twoFactorAuthOpen && (
        <Modal>
          <div className="lightbox-component verify-2fa">
            <CloseDialog
              closeVerifyModal
              isFloatButton
              onClose={onTwoFactorAuthClose}
            />
            <div className="container-form__two-factor-auth">
              <HeaderForm
                title={t("landing two-factor-authentication section-title")}
                description={twoFactorAuthDescription}
                lang={i18n.language}
              />
              <CodeVerification
                onSubmit={onTwoFactorAuthSubmit}
                handleResendCode={handleTwoFactorAuthResendCode}
                isSetting
                disableResend={twoFactorAuthDisableResend}
                onCancel={onTwoFactorAuthClose}
                codeError={twoFactorAuthCodeError}
                loading={twoFactorAuthloading}
                inviteAdminEmail={inviteAdminForm.invite_admin_email}
              />
            </div>
          </div>
        </Modal>
      )}
      {openInviteAdmin && (
        <Modal>
          <div
            className={classNames({
              "lightbox-component": true,
              "invite-admin-modal": true,
            })}
            id="invite-admin-modal"
          >
            <CloseDialog
              closeVerifyModal
              isFloatButton
              onClose={handleCloseInviteAdminModal}
            />
            <InviteAdminForm
              onTwoFactorAuthOpen={onTwoFactorAuthOpen}
              onClose={handleCloseInviteAdminModal}
              setInviteAdminForm={setInviteAdminForm}
            />
          </div>
        </Modal>
      )}
      {blockDeleteAdminModal && (
        <Modal>
          <div
            className={classNames({
              "lightbox-component": true,
              "block-delete-admin-modal": true,
            })}
            lang={i18n.language}
            id="block-delete-admin-modal"
          >
            <CloseDialog
              closeVerifyModal
              isFloatButton
              onClose={handleCloseBlockDeleteAdminModal}
            />
            <div className="block-delete-admin-container">
              <h1 className="block-delete-admin-container__title">
                Deletion Blocked
              </h1>
              <p className="block-delete-admin-container__description">
                We are not able to delete your account as it is the only
                administrator account. Please invite another admin before
                deleting your acount.
              </p>

              <Button
                class="block-delete-admin-container__close-button"
                text={"Close"}
                onClick={handleCloseBlockDeleteAdminModal}
              />
            </div>
          </div>
        </Modal>
      )}
      {inviteAdminSuccessModal && (
        <Modal>
          <div
            className={classNames({
              "lightbox-component": true,
              "invite-admin-success-modal": true,
            })}
            lang={i18n.language}
            id="invite-admin-modal"
          >
            <CloseDialog
              closeVerifyModal
              isFloatButton
              onClose={handleCloseInviteAdminSuccessModal}
            />
            <div className="invite-admin-success-container">
              <h1 className="invite-admin-success-container__title">
                Admin Invited
              </h1>
              <p className="invite-admin-success-container__description">
                An invitation has been sent to{" "}
                {inviteAdminForm.invite_admin_email}.
              </p>

              <Button
                class="invite-admin-success-container__close-button"
                text={"Close"}
                onClick={handleCloseInviteAdminSuccessModal}
              />
            </div>
          </div>
        </Modal>
      )}
      {expiredModal && (
        <Modal>
          <div
            className={classNames({
              "lightbox-component": true,
              "expired-session": true,
            })}
          >
            <div
              className={classNames({
                "lightbox-container": true,
                "expired-session": true,
              })}
            >
              <LogoutSession logout={logout} />
            </div>
          </div>
        </Modal>
      )}
      {deleteSuccessfulModal && (
        <Modal>
          <div
            className={classNames({
              "lightbox-component": true,
              "delete-account": true,
            })}
            lang={i18n.language}
          >
            <DeleteAccountSuccessful setSubModalContent={setSubModalContent} />
          </div>
        </Modal>
      )}
      {modal && (
        <Modal>
          <div
            className={classNames({
              "lightbox-component": true,
              [`${modalContent}`]: true,
            })}
          >
            <CloseDialog
              setModal={setModal}
              setModalContent={setModalContent}
              view={modalContent}
              userData={userData}
              userType={userType}
            />
            <div
              className={classNames({
                "lightbox-container": true,
                [`${modalContent}`]: true,
              })}
            >
              {getModalContent()}
            </div>
          </div>
        </Modal>
      )}
      {showPopup && (
        <Modal>
          <div
            className={classNames({
              "lightbox-component": true,
              "expired-session": true,
            })}
            lang={i18n.language}
          >
            <div
              className={classNames({
                "logout-confirm": true,
                "lightbox-container": true,
                "expired-session": true,
              })}
            >
              <LogoutOnClick
                logout={handleLogout}
                closePopup={handleClosePopup}
              />
            </div>
          </div>
        </Modal>
      )}
      {modalChat && (
        <ModalChat>
          <ModalChatContent
            setModalChat={setModalChat}
            setModalContent={setModalContent}
            socket={socket}
            userData={userData}
            userType={userType}
            currentChatRoom={currentChatRoom}
            messages={messages}
            isMessagesLoading={isMessagesLoading}
            setCurrentChatRoom={setCurrentChatRoom}
            markAsUnread={markAsUnread}
            setSelectedIncident={setSelectedIncident}
            setSelectedIncidentView={setSelectedIncidentView}
            chatroomList={chatroomList}
            setChatroomList={setChatroomList}
          />
        </ModalChat>
      )}
      <AsideNavbar
        handleToggle={displayTab}
        toggle={currentTab.id}
        menuItems={adminMenuItems}
        userData={userData}
        setModal={setModal}
        setModalContent={(type) => {
          updateNotifications();
          setModalContent(type);
        }}
        subModal={subModal}
        setModalChat={setModalChat}
        setSubModal={setSubModal}
        setCurrentChatRoom={setCurrentChatRoom}
        setSubModalContent={setSubModalContent}
        notificationList={notificationList}
        openMenu={open}
        setOpen={setOpen}
        getIncidentsList={getIncidentsList}
        setShowPopup={setShowPopup}
      />

      <div
        className={classNames({
          "survivor-interface": true,
          "chat-modal-open": modalChat,
        })}
      >
        <div className="survivor-interface__app-nav">
          <div className="container-nav" lang={i18n.language}>
            <div className="logo-container">
              <Link to="/home" target="_blank">
                <img className="logo" src={logoBlue} alt="Logo" />
              </Link>
            </div>
            <div className="nav-title">{currentTab.title}</div>
          </div>
          <div className="survivor-interface-menu-button" onClick={handleOpen}>
            <MenuIcon />
          </div>
        </div>
        <div
          className={classNames({
            "survivor-interface__main-content": true,
            "start-message-open": subModal && !formToDelete,
            "sub-modal-open": subModal,
            "sub-modal-delete-open": subModalContent === "delete-account",
          })}
        >
          {subModal && (
            <div
              className={classNames({
                "sub-lightbox-component": true,
                [`${subModalContent}`]: true,
              })}
            >
              <div className="close-sub-lightbox">
                <button
                  className="small custom btn-close"
                  onClick={(e) => {
                    if (subModalContent === "download-incident") {
                      window.location.reload();
                    } else {
                      setSubModal(false);
                      setSubModalContent("");
                    }
                  }}
                >
                  <img src={closeIconSubModal} alt="Close button" />
                </button>
              </div>
              <div
                className={classNames({
                  "container-sublightbox": true,
                  [`${subModalContent}`]: true,
                })}
              >
                <div className="content-sublightbox">
                  {getSubModalContent()}
                </div>
              </div>
            </div>
          )}
          {displayTab(currentTab.id)}
        </div>
      </div>
      <CookiesBanner />
    </div>
  );
};

export default AdminDashboard;
