import { HEROKU_URL, RTYPE_DICT } from "../../helpers/regexps";

import crypto from "crypto";
import util from "util";

let public_key = null;
let query_url = HEROKU_URL;

// gets public key of server
export const getPublicKey = () => {
  return new Promise((resolve, reject) => {
    if (public_key) {
      resolve(public_key);
    } else {
      fetch(query_url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: "{pkey}" }),
      })
        .then((response = "{}") => response.json())
        .then((response) => {
          public_key = (response.data || {}).pkey;
          if (public_key) {
            resolve(public_key);
          } else {
            reject("key response null");
          }
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
};

//TODO beef this up to include signatures & encryption of return data
export const cryptquery = (query, encrypted = true) => {
  return getPublicKey()
    .then((pkey) => {
      if (encrypted) {
        const new_secret = genSecret();
        const encrypted_query = cipher(new_secret, query);
        const encrypted_key = crypto
          .publicEncrypt(pkey, new Buffer(new_secret, "base64"))
          .toString("base64");
        return fetch(query_url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: encrypted_query,
            key: encrypted_key,
            encrypted: "true",
          }),
          timeout: 5000,
        }).then((response) => {
          return response.json();
        });
      } else {
        return fetch(query_url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: query,
          }),
          timeout: 5000,
        }).then((response) => {
          return response.json();
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const hash = (input) => {
  if (input) {
    let hash = crypto.createHash("sha256");
    hash.update(input.replace(/ /g, "").toLowerCase());
    return hash.digest("base64");
  }
};

export const cipher = (secret, cleartext) => {
  if (cleartext) {
    const cipher = crypto.createCipher(
      "aes-256-ctr",
      new Buffer(secret, "base64")
    );
    let encrypted = cipher.update(cleartext, "utf8", "base64");
    encrypted += cipher.final("base64");
    return encrypted;
  }
};

export const decipher = (secret, encrypted) => {
  if (encrypted) {
    const decipher = crypto.createDecipher(
      "aes-256-ctr",
      new Buffer(secret, "base64")
    );
    let cleartext = decipher.update(encrypted, "base64", "utf8");
    cleartext += decipher.final("utf8");
    return cleartext;
  }
};

export const cipherIV = (secret, cleartext, iv) => {
  if (cleartext) {
    const cipher = crypto.createCipheriv(
      "aes-256-cbc",
      new Buffer(secret, "base64"),
      new Buffer(iv, "base64")
    );
    let encrypted = cipher.update(cleartext, "utf8", "base64");
    encrypted += cipher.final("base64");
    return encrypted;
  }
};

export const decipherIV = (secret, encrypted, iv) => {
  if (encrypted) {
    try {
      const decipher = crypto.createDecipheriv(
        "aes-256-cbc",
        new Buffer(secret, "base64"),
        new Buffer(iv, "base64")
      );
      let cleartext = decipher.update(encrypted, "base64", "utf8");
      cleartext += decipher.final("utf8");

      return cleartext;
    } catch (err) {
      return null;
    }
  }
};

export const genIV = () => {
  return crypto.randomBytes(16).toString("base64");
};

export const genSecret = () => {
  return crypto.randomBytes(32).toString("base64");
};

// returns promise of salt for client or lawyer login
// TODO redundancy with encryption.strengthenPassword function in signin and signup
export const getZeroKey = (loginInput, passwordInput, usertype) => {
  // eslint-disable-next-line no-unused-vars
  let t = this;
  return new Promise((resolve, reject) => {
    let saltquery = util.format(
      'query RootQueryType{saltRequest(loginhash:"%s" usertype:%d){salt message}}',
      hash(loginInput.toString()),
      usertype
    );
    cryptquery(saltquery)
      .then((response) => {
        return ((response || {}).data || {}).saltRequest || {};
      })
      .then((saltdata) => {
        if (!saltdata.message && saltdata.salt) {
          deriveZeroKey(passwordInput, saltdata.salt).then((derived) => {
            resolve(derived);
          });
        } else {
          reject();
        }
      });
  });
};

export const deriveZeroKey = (raw, salt) => {
  return new Promise((resolve, reject) => {
    crypto.pbkdf2(raw, salt, 2000, 32, "sha512", (err, derivedKey) => {
      resolve({ password_zero_key: derivedKey.toString("base64"), salt: salt });
    });
  });
};

/**
 * Returns a derived key from a report secret.
 * @param raw
 * @param salt
 * @return {Promise<any> | Promise<*>}
 */
export const deriveReportKey = (raw, salt) => {
  return new Promise((resolve, reject) => {
    crypto.pbkdf2(raw, salt, 10, 32, "sha512", (err, derivedKey) => {
      resolve({
        report_zero_key: derivedKey.toString("base64"),
        salt: salt,
        secret: raw,
      });
    });
  });
};

/**
 * Processes a report key object and extracts the ciphered raw key from the report secret.
 * @param privateKey - The private key of the lawyer.
 * @param salt - The unique salt
 * @param reportSecret - The report secret encrypther with the lawyers key.
 * @param reportKey - The ciphered report key.
 * @return {Promise<any> | Promise<*>}
 */

export const getReportKey = (privateKey, salt, reportSecret, reportKey) => {
  return new Promise((resolve, reject) => {
    const decrypted_report_secret = rsaDecrypt(reportSecret, privateKey);
    crypto.pbkdf2(
      decrypted_report_secret,
      salt,
      10,
      32,
      "sha512",
      (err, derivedKey) => {
        if (err) {
          reject("Encryption Error: " + err.toString());
        }
        const dec_report_zero_key = derivedKey.toString("base64");
        const dec_report_key = decipher(dec_report_zero_key, reportKey);
        resolve(dec_report_key);
      }
    );
  });
};

export const decipherReport = (report, report_key) => {
  report.lat = parseFloat(decipher(report_key, report.lat));
  report.lon = parseFloat(decipher(report_key, report.lon));
  report.latPublic = parseFloat(report.latPublic);
  report.lonPublic = parseFloat(report.lonPublic);
  report.description = report.description
    ? decipher(report_key, report.description)
    : "";
  report.perpetrators = report.perpetrators.map((perp) => {
    return {
      perpName: perp.name ? decipher(report_key, perp.name) : "",
      perpLastName: perp.lastName ? decipher(report_key, perp.lastName) : "",
      perpId: perp.fbid ? decipher(report_key, perp.fbid) : "",
      perpInstaId: perp.instagramid
        ? decipher(report_key, perp.instagramid)
        : "",
      perpTwitterId: perp.twitterid ? decipher(report_key, perp.twitterid) : "",
      perpPhone: perp.phone ? decipher(report_key, perp.phone) : "",
      perpEntityName: perp.entityName
        ? decipher(report_key, perp.entityName)
        : "",
      relationChosen: true,
      relationType: perp.relationType,
      relation: RTYPE_DICT[perp.relationType],
    };
  });
  return report;
};

export const rsaEncrypt = (cleartext, public_key) => {
  return crypto
    .publicEncrypt(public_key, Buffer.from(cleartext, "utf8"))
    .toString("base64");
};

export const rsaDecrypt = (ciphertext, private_key) => {
  return crypto
    .privateDecrypt(private_key, new Buffer(ciphertext, "base64"))
    .toString("utf8");
};
