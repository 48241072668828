import classNames from "classnames";
import React, { useState } from "react";
import Button from "../Button";
import { useTranslation } from "react-i18next";

export const useNotificationRecoveryKey = () => {
  const [toggleCopy, setToggleCopy] = useState(false);

  const copyToClipboard = (str) => {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
  };

  const handleCopyKey = (e) => {
    e.preventDefault();

    const { children } = e.target.parentElement;

    const { innerText } = Array.from(children)[0];

    copyToClipboard(innerText);
    setToggleCopy(true);

    setTimeout(() => {
      setToggleCopy(false);
    }, 3000);
  };

  return {
    toggleCopy,
    handleCopyKey,
  };
};

function NotificationRecoveryKey({ containerClass, recoveryKey }) {
  const { t, i18n } = useTranslation("global");
  const { toggleCopy, handleCopyKey } = useNotificationRecoveryKey();
  return (
    <div
      className={classNames({
        "container-form__question-label flex-column info-notification": true,
        "ar-letter-spacing": i18n.language === "ar",
      })}
    >
      <span
        className={classNames({
          "ar-text-align": i18n.language === "ar",
        })}
        lang={i18n.language}
      >
        <strong>{t("recovery-key title")}</strong>
      </span>
      <p
        className={classNames({
          "ar-text-align": i18n.language === "ar",
        })}
        lang={i18n.language}
      >
        {t("recovery-key detail")}
      </p>
      <div className="highlight-recovery-key">
        <code>{recoveryKey}</code>
        <Button
          text={
            toggleCopy
              ? t("recovery-key copied-to-clipboard")
              : t("recovery-key copy")
          }
          class={classNames({
            custom: true,
            copied: toggleCopy,
          })}
          onClick={handleCopyKey}
        />
      </div>
    </div>
  );
}

export default NotificationRecoveryKey;

