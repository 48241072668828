import React from "react";
import classNames from "classnames";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { EMAIL_RE } from "../../helpers/regexps";
import { useForgotPassword } from "../../hooks/useForgotPassword";
import { i18nGetData } from "../../i18n";
import Button from "../Button";
import HeaderForm from "../HeaderForm";

const initialForm = {
  email: "",
};

const validationsForm = (form) => {
  let errors = {};
  if (!form.email.trim()) {
    errors.email = i18nGetData("landing form forgot-password-email-required");
  } else if (!EMAIL_RE.test(form.email.trim())) {
    errors.email = i18nGetData("landing form forgot-password-email-invalid");
  }

  return errors;
};

function ForgotPassword() {
  const { t, i18n } = useTranslation("global");
  const title = t("landing forgot password section-title");
  const description = `<p>${t(
    "landing forgot password section-description"
  )}</p>`;

  const { form, errors, handleChange, handleBlur, handleSubmit } =
    useForgotPassword(initialForm, validationsForm);

  return (
    <div className="container-form__sign-up">
      <HeaderForm
        title={title}
        description={description}
        lang={i18n.language}
      />
      <div className="container-body__form">
        <form className="container-form">
          <div className="container_form__row ">
            <div
              className={classNames({
                container_form__col: true,
                require_field: true,
                "ar-letter-spacing": i18n.language === "ar",
              })}
              lang={i18n.language}
            >
              <label>{t("landing form forgot-password-email")}</label>
              <input
                className={classNames({
                  "error-field": errors.email,
                })}
                type="email"
                placeholder={`${t(
                  "landing form forgot-password-email-placeholder"
                )}*`}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={form.email}
                required
              />
              {errors.email && (
                <span className="error-message">{errors.email}</span>
              )}
            </div>
          </div>

          <div
            className={classNames({
              "button-landing-container": true,
              "ar-letter-spacing": i18n.language === "ar",
            })}
          >
            <Link
              className="back-button-link btn-to-login left-arrow"
              to="/login"
            >
              {t("landing form forgot-password-btn-back")}
            </Link>
            <div className="container_form__row">
              <div className="container_form__col buttons-container">
                <span
                  className="required-fields-text hide-mobile"
                  lang={i18n.language}
                >
                  {t("incident-form general top-message-required")}
                </span>
                <Button
                  class="btn-send-verification"
                  text={t(
                    "landing form forgot-password-btn-send-verification-code"
                  )}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;

