import React, { Fragment } from "react";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AnswerItemRadioButton from "../answers/AnswerItemRadioButton";

import { useTranslation } from "react-i18next";
import AnswerItemSelect from "../answers/AnswerItemSelect";
import InputTextSubfield from "../answers/InputTextSubfield";
import RadioButton from "../answers/RadioButton";
import Button from "../Button";

function Question(props) {
  const { t, i18n } = useTranslation("global");

  const getAnswersComponet = () => {
    switch (props.answerType) {
      case "select":
        return <AnswerItemSelect {...props} />;
      case "datepicker":
        return (
          <Fragment>
            <DatePicker
              selected={props.startDate}
              maxDate={new Date()}
              onChange={props.handleDate}
              isClearable={true}
              dateFormat="MM/dd/yyyy"
              placeholderText={t(
                "incident-form incident question-date-placeholder"
              )}
            />

            {props.errors[props.fieldName] && (
              <span className="error-message">
                {props.errors[props.fieldName]}
              </span>
            )}
            <span className="note-message-form date-field">
              {props.fieldMessage}
            </span>
            <Button
              text={
                props.toggleDate
                  ? t("incident-form incident question-date-btn-range-remove")
                  : t("incident-form incident question-date-btn-range-add")
              }
              class={classNames({
                "custom btn-add-item range": true,
                "btn-add-item-ar": i18n.language === "ar",
              })}
              onClick={(e) => {
                e.preventDefault(e);
                props.setToggleDate(!props.toggleDate);
              }}
            />
            <span className="note-message-form date-field">
              {t("incident-form incident question-date-range-message")}
            </span>
            {(props.toggleDate || props?.endDate) && (
              <DatePicker
                selected={props.endDate}
                maxDate={new Date()}
                onChange={props.handleEndDate}
                selectsEnd
                startDate={props.startDate}
                endDate={props.endDate}
                minDate={props.startDate}
                isClearable={true}
                dateFormat="MM/dd/yyyy"
                placeholderText={t(
                  "incident-form incident question-date-range-placeholdetText"
                )}
                disabled={!props?.startDate}
              />
            )}
            {props.errors[props.fieldName] && (
              <span className="error-message">
                {props.errors[props.fieldName]}
              </span>
            )}
          </Fragment>
        );
      case "textarea":
        return (
          <Fragment>
            <textarea
              name={props.fieldName}
              type="text"
              placeholder={props.placeHolder}
              rows="10"
              maxLength={3000}
              value={props.form[props.fieldName]}
              onChange={props.handleChange}
            />
            {props.errors[props.fieldName] && (
              <span className="error-message">
                {props.errors[props.fieldName]}
              </span>
            )}
            {props.fieldMessage ? (
              <span className="note-message-form textarea-field">
                {props.fieldMessage}
              </span>
            ) : null}
          </Fragment>
        );
      case "radiobutton":
        return <AnswerItemRadioButton {...props} />;
      case "radio":
        return (
          <Fragment>
            <div
              className={classNames({
                "container-radio-buttons-options": true,
                "radio-button-container-ar": i18n.language === "ar"
              })}
            >
              {props.options.map((option, index) => (
                <RadioButton
                  {...props}
                  key={index}
                  name={option.name}
                  id={index}
                  value={option.value}
                />
              ))}
            </div>
            <InputTextSubfield {...props} />
          </Fragment>
        );
      case "input-text":
        return (
          <Fragment>
            {props?.fieldName === "time_of_incident" ? (
              <Fragment>
                <input
                  type="text"
                  value={props.form[props.fieldName] || ""}
                  placeholder={t(
                    "incident-form incident question-time-placeholder"
                  )}
                  maxLength={50}
                  onChange={props.handleChange}
                  name={props.fieldName}
                />
                {props.fieldMessage ? (
                  <span className="note-message-form textarea-field">
                    {props.fieldMessage}
                  </span>
                ) : null}
                {/* Some snippets were removed in the JXUI-492 PR */}
                {props.errors[props.fieldName] && (
                  <span className="error-message">
                    {props.errors[props.fieldName]}
                  </span>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder={props.placeHolder}
                  maxLength={100}
                  name={props.fieldName}
                  value={props.form[props.fieldName]}
                  onChange={props.handleChange}
                />
                <span className="note-message-form">{props.fieldMessage}</span>
              </Fragment>
            )}
          </Fragment>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container-question">
      <div
        className={classNames({
          "question-title": true,
          "question-required": props.required,
          "container-question-ar": i18n.language === "ar"
        })}
        lang={i18n.language}
      >
        {props.questionTitle}
      </div>
      <div
        className={classNames({
          "question-answers": true,
          "radio-button-container-ar": i18n.language === "ar" && props.answerType === "radiobutton",
        })}
        lang={
          props.answerType === "radiobutton" || props.answerType === "radio"
            ? "en"
            : i18n.language
        }
      >
        {getAnswersComponet()}
      </div>
      {props?.subfield}
      {props?.subfieldContent?.subFieldName}
      {props.subfield && (
        <div className="subcontainer-answer require_field" lang={i18n.language}>
          <input
            type={props.subfieldContent.type}
            placeholder={props.subfieldContent.placeholder}
            name={props.subfieldContent.subFieldName}
            value={props.form[props.subfieldContent.subFieldName]}
            onChange={props.handleChange}
            required={props.subfieldContent.required}
          />
          {props.errors[props.subfieldContent.subFieldName] && (
            <span className="error-message">
              {props.errors[props.subfieldContent.subFieldName]}
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export default Question;
