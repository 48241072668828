import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { APP_LANGUAGES } from "../../helpers/constants";
import UserSettingsContext from "../../context/UserSettingContext";
import { createCookie, getCookie } from "../../helpers/helpers";

function LanguageSelector() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation("global");
  const [toggle, setToggle] = useState(false);
  const [userSettings, setUserSettings] = useContext(UserSettingsContext);
  const langCookie = getCookie("language");

  useEffect(() => {
    if (!userSettings) {
      langCookie.length &&
        setUserSettings({ ...userSettings, language: langCookie });
    } else {
      createCookie("language", userSettings.language, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggle = (e) => {
    setToggle(!toggle);
  };
  const handleSelectLang = (e) => {
    const language = e.target.getAttribute("data-lang");
    if (language) {
      setUserSettings({ ...userSettings, language });
      setToggle(false);
      createCookie("language", language, 1000);

      // Change the direction and language of the html tag based on the selected language
      const htmlElement = document.querySelector("html");
      if (language === "ar") {
        htmlElement.setAttribute("lang", language);
        htmlElement.setAttribute("dir", "ltr");
      } else {
        htmlElement.setAttribute("lang", language);
      }
    }
  };

  return (
    <div className="dropdown-lang-selector">
      <span
        className={classNames({
          clicked: toggle,
        })}
        onClick={handleToggle}
      >
        {i18n.language}
      </span>
      <ul
        className={classNames({
          "items-available": true,
          open: toggle,
        })}
      >
        {APP_LANGUAGES.map((item, i) => {
          if (item.id === i18n.language) return "";
          return (
            <li key={i} data-lang={item.id} onClick={handleSelectLang}>
              {item.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default LanguageSelector;
