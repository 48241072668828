import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ResourceBox from "./resources/resourceBox";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import Loading from "components/Loading";
import classNames from "classnames";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { resourcePath } from "helpers/constants";

function ResourcesTab({ isLanding = false }) {
  const containerRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [pageWidth, setPageWidth] = useState(
    containerRef?.current?.offsetWidth > 900
      ? 900
      : containerRef?.current?.offsetWidth
  );
  const { i18n, t } = useTranslation("global");
  const [resourceSelected, setResourceSelected] = useState(null);

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageWidth(
      containerRef.current.offsetWidth > 900
        ? 900
        : containerRef.current.offsetWidth
    )
  }

  const downloadDocument = ({ url, fileName }) => {
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const fileUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = fileUrl;
        a.download = `${fileName}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  const RESOURCES = [
    {
      title: t("resources what-is-sexual-harassment"),
      url: "/2.+What+Is+Sexual+Harrassment.pdf",
    },
    {
      title: t("resources iam-a-victim"),
      url: "/1.+I+am+a+survivor.pdf",
    },
    {
      title: t("resources where-to-go"),
      url: "/4.+Where+can+I+go+for+help+regarding+sexual+harassment+in+UNHCR.pdf",
    },
    {
      title: t("resources victim-care-officer"),
      url: "/3.+Victim+Care+Officer.pdf",
    },
    {
      title: t("resources report-sexual-harassment"),
      url: "/5.+I+want+to+formally+report+sexual+harassment+as+misconduct+to+UNHCR.pdf",
    },
    {
      title: t("resources informal-resolution-option"),
      url: "/6.+I+am+looking+for+an+informal+resolution+option.pdf",
    },
    {
      title: t("resources protection-from-retaliation"),
      url: "/7.+Protection+from+retaliation.pdf",
    },
    {
      title: t("resources relevant-unhcr-policies"),
      url: "/8.+Relevant+UNHCR+Policies+and+Administrative+Instructions.pdf",
    },
  ];

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (containerRef.current) {
        setPageWidth(
          containerRef.current.offsetWidth > 900
            ? 900
            : containerRef.current.offsetWidth
        );
      }
    });

    return () => {
      window.removeEventListener("resize", () => {
        if (containerRef.current) {
          setPageWidth(
            containerRef.current.offsetWidth > 900
              ? 900
              : // eslint-disable-next-line react-hooks/exhaustive-deps
                containerRef.current.offsetWidth
          );
        }
      });
    };
  }, []);

  if (resourceSelected !== null) {
    const url = `${process.env.REACT_APP_RESOURCES_BASE_URL}/${resourcePath.DOCS}/${i18n.language}${RESOURCES[resourceSelected].url}#toolbar=0&navpanes=0&scrollbar=0`;
    const fileName = RESOURCES[resourceSelected].title;

    return (
      <div
        className={classNames({
          "resource-page": true,
          "resource-page-landing": isLanding,
        })}
      >
        <div className="resource-page-buttons">
          <button
            className="btn-back-incidentlist left-arrow"
            onClick={() => setResourceSelected(null)}
          >
            {t("incident-form re-open back-button")}
          </button>
          <button
            onClick={() => downloadDocument({ url, fileName })}
            className="btn-no-option down-arrow"
          >
            {t("landing resources internal-resource-downloadpdf-btn")}
          </button>
        </div>
        <div className="resource-page-preview" ref={containerRef}>
          <Document
            file={`${process.env.REACT_APP_RESOURCES_BASE_URL}/${resourcePath.DOCS}/${i18n.language}${RESOURCES[resourceSelected].url}`}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<Loading />}
            renderMode="svg"
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderAnnotationLayer={true}
                renderTextLayer={false}
                externalLinkTarget="_blank"
                width={pageWidth > 900 ? 900 : pageWidth}
              />
            ))}
          </Document>
        </div>
      </div>
    );
  }

  return (
    <div className="resources-tab">
      <div className="resources-tab__main-container">
        {RESOURCES.map((resource, index) => (
          <ResourceBox
            {...resource}
            key={resource.url}
            viewResource={() => setResourceSelected(index)}
          />
        ))}
      </div>
    </div>
  );
}

export default ResourcesTab;

