import React, { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { answerBuilder, getCookie } from "../../../../helpers/helpers";

function FAQItem({ FAQ, key }) {
  const { t } = useTranslation("global");
  const [open, setOpen] = useState(false);
  const lang = getCookie("language");

  const handleClick = () => {
    setOpen((value) => !value);
  };

  return (
    <li
      className={classNames({
        "open-faq": open,
        "faq-item": true,
        "faq-item-ar": lang === "ar",
      })}
      key={key}
    >
      <h2 onClick={handleClick}>{t(FAQ.ask)}</h2>
      {open ? (
        <p>
          {answerBuilder(t(FAQ.question)).map((element) => (
            <div
              className="inline-element"
              dangerouslySetInnerHTML={{
                __html: element,
              }}
            />
          ))}
        </p>
      ) : (
        ""
      )}
    </li>
  );
}

export default FAQItem;
