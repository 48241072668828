import axios from "axios";
import { BASE_URL, ENDPOINT } from "helpers/constants";

export const login = async ({ user, language }) => {
  const res = await axios.post(BASE_URL + ENDPOINT.user.login, user, {
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": language,
    },
  });
  return res.data;
};

export const refreshToken = async (accessToken, refreshToken) => {
  const res = await axios.post(
    BASE_URL + ENDPOINT.user.refresh_token,
    { refreshToken },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    }
  );
  return res.data;
};

export const logout = async (token) => {
  const res = await axios.post(BASE_URL + ENDPOINT.user.logout, [null], {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  return res.data;
};
