import React, { Fragment } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { addPrefixLanding, getCookie } from "../../helpers/helpers";
import Footer from "./components/Footer";
import Header from "./components/Header";

function RecordAnIncident() {
  const { i18n, t } = useTranslation("global");
  const lang = getCookie("language");

  const howToUseToolItems = [
    {
      title: t("landing about section-howtouse-not-ready"),
      items: [
        {
          title: t("landing about section-howtouse-not-ready-item-1-title"),
          description: t(
            "landing about section-howtouse-not-ready-item-1-description"
          ),
        },
        {
          title: t("landing about section-howtouse-not-ready-item-2-title"),
          description: t(
            "landing about section-howtouse-not-ready-item-2-description"
          ),
        },
        {
          title: t("landing about section-howtouse-not-ready-item-3-title"),
          description: t(
            "landing about section-howtouse-not-ready-item-3-description"
          ),
        },
      ],
    },
    {
      title: t("landing about section-howtouse-ready"),
      items: [
        {
          title: t("landing about section-howtouse-ready-item-1-title"),
          description: t(
            "landing about section-howtouse-ready-item-1-description"
          ),
        },
        {
          title: t("landing about section-howtouse-ready-item-2-title"),
          description: t(
            "landing about section-howtouse-ready-item-2-description"
          ),
        },
        {
          title: t("landing about section-howtouse-ready-item-3-title"),
          description: t(
            "landing about section-howtouse-ready-item-3-description"
          ),
        },
        {
          title: t("landing about section-howtouse-ready-item-4-title"),
          description: t(
            "landing about section-howtouse-ready-item-4-description"
          ),
        },
      ],
    },
  ];

  const getContentHowTo = () => {
    return howToUseToolItems.map((item, index) => {
      return (
        <div
          key={index}
          className={classNames({
            [addPrefixLanding("section-container__row")]: true,
          })}
        >
          <div className={addPrefixLanding("how-to__title")}>{item.title}</div>
          <div className={addPrefixLanding("how-to__content")}>
            {item.items.map((subItem, i) => {
              return (
                <div
                  key={i}
                  className={classNames({
                    [addPrefixLanding("how-to__items")]: true,
                    "four-columns": item.items.length > 3,
                  })}
                >
                  <div
                    className={classNames({
                      "container-info": true,
                      "container-info-ar": i18n.language === "ar",
                    })}
                  >
                    <div className="title">{subItem.title}</div>
                    <div className="description">{subItem.description}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };

  return (
    <Fragment>
      <Header />
      <section
        className={classNames({
          [addPrefixLanding("home-section")]: true,
          [addPrefixLanding("section-1")]: true,
          [addPrefixLanding("record-incident")]: true,
        })}
      >
        <svg
          width="13"
          height="8"
          viewBox="0 0 13 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="arrow-incident"
        >
          <path
            d="M1.94629 1.2876L6.94629 6.2876L11.9463 1.2876"
            stroke="#FF6C6C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
        <div className={addPrefixLanding("section-container")}>
          <div
            className={classNames({
              [addPrefixLanding("section-container__row")]: true,
              [addPrefixLanding("horizontal-orientation")]: true,
              [addPrefixLanding("center-content")]: true,
            })}
          >
            <div
              className={classNames({
                [addPrefixLanding("section-container__column")]: true,
                "flex-center": true,
              })}
              lang={i18n.language}
            >
              <div className={addPrefixLanding("section-1-content")}>
                <h2 className={addPrefixLanding("section-title")}>
                  {t("landing record an incident section-record")}
                </h2>
                <p>{t("landing record an incident section-description-1")}</p>
                <p>{t("landing record an incident section-description-2")}</p>
              </div>
            </div>
            <div
              className={classNames({
                [addPrefixLanding("section-container__column")]: true,
                "flex-center": true,
              })}
              lang={i18n.language}
            >
              <div className={addPrefixLanding("section-1-content")}>
                <Link
                  className={classNames({
                    [addPrefixLanding("login-mobile-button")]: true,
                  })}
                  to="/sign-up"
                >
                  {t("landing about section-encrypted-start-incident-btn")}
                </Link>
                <Link
                  className={classNames({
                    [addPrefixLanding("button-circle")]: true,
                    [addPrefixLanding("button-login")]: true,
                    "hide-mobile": true,
                  })}
                  to="/sign-up"
                >
                  <span>
                    {t("landing about section-encrypted-start-incident-btn")}
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div
            className={classNames({
              [addPrefixLanding("section-container__row")]: true,
              [addPrefixLanding("horizontal-orientation")]: true,
              [addPrefixLanding("center-content")]: true,
            })}
          >
            <div
              className={classNames({
                [addPrefixLanding("section-container__column")]: true,
              })}
            >
              <div
                className={classNames({
                  "app-landing-container-preview-link": true,
                  "app-landing-container-preview-link-ar":
                    i18n.language === "ar",
                })}
                lang={i18n.language}
              >
                <span>
                  {t("landing record an incident section-record-not-sure")}
                </span>
                <Link
                  className={classNames({
                    [addPrefixLanding("preview-link")]: true,
                    [addPrefixLanding("preview-link-ar")]:
                      i18n.language === "ar",
                  })}
                  to="/support-resources/help-with-the-tool"
                >
                  {t("landing record an incident section-record-btn-preview")}
                </Link>
              </div>
            </div>
          </div>
          <span className="show-mobile red-line-vertical tools-red-line-vertical" />
        </div>
      </section>
      <section
        className={classNames({
          [addPrefixLanding("home-section")]: true,
          [addPrefixLanding("section-3")]: true,
          "line-height-fr": lang === "fr",
          "line-height-es-record-incident": lang === "es",
          "line-height-ru": lang === "ru",
          "line-height-ch": lang === "ch",
          "line-height-ar": lang === "ar",
        })}
        lang={i18n.language}
      >
        <div className={addPrefixLanding("section-container")}>
          {getContentHowTo()}
          <span className="show-mobile red-line-vertical tools-red-line-vertical" />
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}

export default RecordAnIncident;

