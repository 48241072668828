import { useEffect } from "react";
import ReactDOM from "react-dom";

function Modal({ children }) {
  const portalNode = document.createElement("div");
  portalNode.setAttribute("id", "portal");

  useEffect(() => {
    document.body.appendChild(portalNode);
    return () => {
      portalNode.remove();
    };
  }, [portalNode]);

  return ReactDOM.createPortal(children, portalNode);
}

export default Modal;
