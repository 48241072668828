import classNames from "classnames";
import { getCookie } from "helpers/helpers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ResourceBox = ({ title, viewResource }) => {
  const { t, i18n } = useTranslation("global");
  const [isOpened, setIsOpened] = useState(false);
  const language = getCookie("language");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpened && !event.target.closest(".resource-item")) {
        setIsOpened(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpened]);

  return (
    <div className="resource-item">
      <h3 lang={i18n.language}>{title}</h3>
      <div className="resource-content-button" lang={i18n.language}>
        <button
          onClick={viewResource}
          className={classNames({
            "resource-button": true,
            "resource-button-ru": language === "ru",
          })}
        >
          {t("landing about section-unhcr-resources-openbutton")}
        </button>
      </div>
    </div>
  );
};

export default ResourceBox;
