import { useState } from "react";
import { inviteAdminInitialForm } from "../helpers/constants";
import { getCookieData } from "../helpers/helpers";
import { inviteAdminFormValidations } from "../helpers/validations";
import { hash } from "../lib/utils/encryption";
import { inviteAdminVerificationCode, sendInviteCode } from "../services/users";

const useInviteAdmin = () => {
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState(inviteAdminInitialForm);
  const [counter, setCounter] = useState(0);
  const [attempts, setAttempts] = useState(3);

  const handleSubmit = async () => {
    setErrors(inviteAdminFormValidations(form));
    const dataSession = getCookieData("auth", { path: "/" });

    if (Object.keys(inviteAdminFormValidations(form)).length > 0) {
      return;
    }

    let response;

    await Promise.resolve(
      inviteAdminVerificationCode(
        {
          invite_code: form.invite_admin_verification_code,
          email: form.invite_admin_email,
          password: hash(form.invite_admin_password),
        },
        dataSession.accessToken
      )
    )
      .then((res) => {
        response = res.status;
      })
      .catch((err) => {
        console.log(err);
        response = err.response.data.error;
      });

    return response;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setErrors(inviteAdminFormValidations({ ...form, [name]: value }));
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleResendCode = (event) => {
    event.preventDefault();
    sendInviteCode({}, getCookieData("auth", { path: "/" }).accessToken);
    setAttempts(attempts - 1);
    setCounter(59);
  };

  return {
    attempts,
    counter,
    errors,
    form,
    handleChange,
    handleSubmit,
    handleResendCode,
    setCounter,
    setErrors,
    setForm,
  };
};

export default useInviteAdmin;
