import React, { Fragment, useEffect, useState } from "react";
import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { Role, notificationSettingsJSON } from "../../helpers/constants";
import { getCookieData } from "../../helpers/helpers";
import Button from "../Button";
import Loading from "components/Loading";
import { getUserSetting, updateUserSetting } from "services/users";

export const useSettings = (initialForm) => {
  const [form, setForm] = useState(initialForm);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const dataSession = getCookieData("auth", { path: "/" });

    setLoading(true);
    Promise.resolve(getUserSetting(dataSession.accessToken))
      .then((res) => {
        setForm(res.setting);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value === "true" ? true : false,
    });

    const dataSession = getCookieData("auth", { path: "/" });

    setLoading(true);
    await Promise.resolve(
      updateUserSetting(dataSession.accessToken, {
        ...form,
        [name]: value === "true" ? true : false,
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleSubmit = async () => {
    const dataSession = getCookieData("auth", { path: "/" });
    setLoading(true);
    await Promise.resolve(updateUserSetting(dataSession.accessToken, form))
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return {
    loading,
    form,
    handleChange,
    handleSubmit,
  };
};

function DialogNotificationSettings(props) {
  const { t, i18n } = useTranslation("global");
  const { loading, form, handleChange, handleSubmit } = useSettings(
    notificationSettingsJSON
  );

  const goToResourcesPage = () => {
    props.setModal(false);
  };

  return (
    <Fragment>
      <div className="lightbox-container__title" lang={i18n.language}>
        <h3>{t("notifications settings title")}</h3>
      </div>
      <div className="lightbox-container__content" lang={i18n.language}>
        <div className="lightbox-container__options">
          <Button
            class="left-arrow btn-option-notification-list"
            text={t("notifications settings back-to-notifications-button")}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
              props.setModalContent("notifications");
            }}
          />
        </div>
      </div>

      {loading ? (
        <div className="loading-container">
          <Loading />
        </div>
      ) : (
        <div
          className={classNames({
            "notification-settings-container": true,
          })}
        >
          {props.role === Role.ADMIN && (
            <div className="setting-option">
              <div className="title-option" lang={i18n.language}>
                {t("notifications settings new-incident-form-submitted")}
              </div>
              <div
                className={classNames({
                  "container-radio-buttons-options notification-settings": true,
                  "container-radio-buttons-options-ar": i18n.language === "ar",
                  "radio-button-container-ar": i18n.language === "ar",
                })}
              >
                <div className="radio-button-container">
                  <input
                    type="radio"
                    id="new-incident-yes"
                    name="submitted"
                    value="true"
                    checked={form.submitted === true}
                    onChange={handleChange}
                  />
                  <label htmlFor="new-resource-yes">
                    {t("notifications settings agree")}
                  </label>
                </div>
                <div className="radio-button-container">
                  <input
                    type="radio"
                    id="new-incident-no"
                    name="submitted"
                    value="false"
                    checked={form.submitted === false}
                    onChange={handleChange}
                  />
                  <label htmlFor="new-resource-no">
                    {t("notifications settings not-agree")}
                  </label>
                </div>
              </div>

              <div className="description-container" lang={i18n.language}>
                <span
                  className={classNames({
                    "form_message notification-settings": true,
                    "notification-settings-ar": i18n.language === "ar",
                  })}
                >
                  {t(
                    "notifications settings new-incident-form-submitted-detail"
                  )}
                </span>
              </div>
            </div>
          )}
          <div className="setting-option">
            <div className="title-option" lang={i18n.language}>
              {t("notifications settings match-identified-option")}
            </div>
            <div
              className={classNames({
                "container-radio-buttons-options notification-settings": true,
                "container-radio-buttons-options-ar": i18n.language === "ar",
                "radio-button-container-ar": i18n.language === "ar",
              })}
            >
              <div className="radio-button-container">
                <input
                  type="radio"
                  id="match-option-yes"
                  name="match_identified"
                  value="true"
                  checked={form.match_identified === true}
                  onChange={handleChange}
                />
                <label htmlFor="match-option-yes">
                  {t("notifications settings agree")}
                </label>
              </div>
              <div className="radio-button-container">
                <input
                  type="radio"
                  id="match-option-no"
                  name="match_identified"
                  value="false"
                  checked={form.match_identified === false}
                  onChange={handleChange}
                />
                <label htmlFor="match-option-no">
                  {t("notifications settings not-agree")}
                </label>
              </div>
            </div>
            <div className="description-container" lang={i18n.language}>
              <span
                className={classNames({
                  "form_message notification-settings": true,
                  "notification-settings-ar": i18n.language === "ar",
                })}
              >
                {t("notifications settings match-identified-detail")}
              </span>
            </div>
          </div>
          <div className="setting-option">
            <div className="title-option" lang={i18n.language}>
              {props.role === Role.ADMIN
                ? t("notifications settings new-message-option-admin")
                : t("notifications settings new-message-option")}
            </div>
            <div
              className={classNames({
                "container-radio-buttons-options notification-settings": true,
                "container-radio-buttons-options-ar": i18n.language === "ar",
                "radio-button-container-ar": i18n.language === "ar",
              })}
            >
              <div className="radio-button-container">
                <input
                  type="radio"
                  id="new-message-yes"
                  name="new_message"
                  value="true"
                  checked={form.new_message === true}
                  onChange={handleChange}
                />
                <label htmlFor="new-message-yes">
                  {t("notifications settings agree")}
                </label>
              </div>
              <div className="radio-button-container">
                <input
                  type="radio"
                  id="new-message-no"
                  name="new_message"
                  value="false"
                  checked={form.new_message === false}
                  onChange={handleChange}
                />
                <label htmlFor="new-message-no">
                  {t("notifications settings not-agree")}
                </label>
              </div>
            </div>
            <div className="description-container" lang={i18n.language}>
              <span
                className={classNames({
                  "form_message notification-settings": true,
                  "notification-settings-ar": i18n.language === "ar",
                })}
              >
                {props.role === Role.ADMIN
                  ? t("notifications settings new-message-detail-admin")
                  : t("notifications settings new-message-detail")}
              </span>
            </div>
          </div>
          <div className="setting-option">
            <div className="title-option" lang={i18n.language}>
              {t("notifications settings new-resource-available-option")}
            </div>
            <div
              className={classNames({
                "container-radio-buttons-options notification-settings": true,
                "container-radio-buttons-options-ar": i18n.language === "ar",
                "radio-button-container-ar": i18n.language === "ar",
              })}
            >
              <div className="radio-button-container">
                <input
                  type="radio"
                  id="new-resource-yes"
                  name="new_resource"
                  value="true"
                  checked={form.new_resource === true}
                  onChange={handleChange}
                />
                <label htmlFor="new-resource-yes">
                  {t("notifications settings agree")}
                </label>
              </div>
              <div className="radio-button-container">
                <input
                  type="radio"
                  id="new-resource-no"
                  name="new_resource"
                  value="false"
                  checked={form.new_resource === false}
                  onChange={handleChange}
                />
                <label htmlFor="new-resource-no">
                  {t("notifications settings not-agree")}
                </label>
              </div>
            </div>

            <div className="description-container">
              <span
                className={classNames({
                  "form_message notification-settings": true,
                  "notification-settings-ar": i18n.language === "ar",
                })}
                lang={i18n.language}
              >
                <Trans
                  t={t}
                  i18nKey="notifications settings new-resource-available-detail"
                >
                  Notify me when a new{" "}
                  <a onClick={goToResourcesPage} href="#resources">
                    resource
                  </a>{" "}
                  is available in the system
                </Trans>
              </span>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default DialogNotificationSettings;

