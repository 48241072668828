import React, { Fragment } from "react";
import Loading from "components/Loading";
import { incidentView } from "helpers/constants";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import FormRoadMap from "../FormRoadMap";
import classNames from "classnames";

function DialogOverviewCreateIncident({
  setModalContent,
  setModal,
  selectedIncidentView,
  setPageIncident,
  setSelectedIncidentView,
}) {
  const { t, i18n } = useTranslation("global");

  const handleNext = () => {
    setModal(false);
    setModalContent("");
  };

  const handleBack = () => {
    setModalContent("create-new-incident");
  };

  return (
    <Fragment>
      {selectedIncidentView === incidentView.CREATE_INCIDENT ? (
        <div className="loading-container">
          <Loading />
        </div>
      ) : (
        <>
          <div className="roadmap-container-modal">
            <FormRoadMap
              setPageIncident={setPageIncident}
              setModal={setModal}
              setModalContent={setModalContent}
              setSelectedIncidentView={setSelectedIncidentView}
            />
          </div>
          <div className="navigation-buttons">
            <Button
              text={t("platform overview-pop-up-btn-back")}
              class={classNames({
                "btn-back-overview left-arrow": true,
                "ar-letter-spacing": i18n.language === "ar",
              })}
              onClick={handleBack}
            />
            <Button
              text={t("platform overview-pop-up-btn-start")}
              class={classNames({
                "btn-goto-incident": true,
                "ar-letter-spacing": i18n.language === "ar",
              })}
              onClick={handleNext}
            />
          </div>
        </>
      )}
    </Fragment>
  );
}

export default DialogOverviewCreateIncident;

